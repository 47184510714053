import HttpClient from "../../utils/HttpClient";

export default class MyDiaryRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public updateEmotions(text: string, userId: number) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      `tenants/${tenantId}/users/${userId}/emotions/new`,
      text,
      {
        headers: {
          "Content-Type": "text/plain", // Assicurati che il tipo di contenuto sia impostato correttamente
        },
      }
    );
  }

  public retriveEmotions(userId: number, startDate: Date, endDate: Date) {
    const tenantId = localStorage.getItem("tenantId");
    const queryParams = `?startPeriod=${encodeURIComponent(
      startDate.toISOString()
    )}&endPeriod=${encodeURIComponent(endDate.toISOString())}`;

    return this.instance.get(
      "tenants/" +
        tenantId +
        "/users/" +
        userId +
        "/emotions/retrive" +
        queryParams
    );
  }
}
