import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { Config } from "../../../config/Config";
import _ from "lodash";
import { RootState } from "../../../app/store";
import WellnessRepository from "../WellnessRepository";

export interface WellnessNutritionalView {
  id: number;
  name: string;
  image: string;
  price: number;
  city: string;
  isIndividual: boolean;
  remotelyOrPresenceEnum: string;
  typology: string;
}

export const getWellnessNutritionalViewAsync = createAsyncThunk(
  "wellnessNutritional/getWellnessNutritionalView",
  async (data: { queryString?: string }) => {
    const wellNessRepository = new WellnessRepository();
    const response = await wellNessRepository.getWellnessNutritionalView(
      data?.queryString
    );
    const activityTails = _.get(
      response,
      Config.WELLNESS_NUTRITIONAL_ACTIVITY_TAIL_RESPONSE
    );
    return activityTails;
  }
);

const WellnessNutritionalViewAdapter =
  createEntityAdapter<WellnessNutritionalView>({
    selectId: (WellnessNutritionalView) => WellnessNutritionalView.id,
  });

export const wellnessNutritionalViewSlice = createSlice({
  name: "wellnessNutritionalViews",
  initialState: WellnessNutritionalViewAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    activityTailsEmptyState: (state) => {
      WellnessNutritionalViewAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getWellnessNutritionalViewAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          WellnessNutritionalViewAdapter.setAll(state, action.payload ?? []);
          state.status = "idle";
        }
      )
      .addCase(getWellnessNutritionalViewAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getWellnessNutritionalViewAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const WellnessNutritionalViewSelector =
  WellnessNutritionalViewAdapter.getSelectors<RootState>(
    (state) => state.wellnessNutritionalViews
  );

export const { activityTailsEmptyState } = wellnessNutritionalViewSlice.actions;
export const selectWellnessNutritionalViewSliceStatus = (state: any) =>
  state.activityTails.status;
export const selectWellnessNutritionalViewSliceReasonCode = (state: any) =>
  state.activityTails.reasonCode;

//#endRegion Status

export default wellnessNutritionalViewSlice.reducer;
