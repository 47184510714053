/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcUser = ({ className, color = "#36B39E" }) => {
  return (
    <svg
      className={`twenty-ic-user ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        className="ellipse-7"
        cx="10.1663"
        cy="6"
        rx="3.5"
        ry="4"
        stroke={color}
        strokeWidth="2"
      />
      <path
        className="path"
        d="M17 18C17 15.2386 13.866 13 10 13C6.13401 13 3 15.2386 3 18"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};
