import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseTab } from "../../ui/components/BaseTab";
import { SearchInput } from "../../ui/components/Input";
import { TwentyIcFiltersOrder } from "../../ui/components/icons/TwentyIcFiltersOrder";
import Tag from "../../ui/components/Tag/Tag";

interface OfficeCoworkingFilterBar {
  filterOpened?: boolean;
  setSwitchSelected: (e: any) => any;
  setModalOpened: (e: any) => any;
  filters: Map<string, string>;
  searched: (e: any) => any;
  setFilters: (e: any) => any;
  switchStatus: number;
}

export const OfficeCoworkingFilterBar: React.FC<OfficeCoworkingFilterBar> = ({
  filterOpened,
  setSwitchSelected,
  setModalOpened,
  filters,
  searched,
  setFilters,
  switchStatus,
}) => {
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  function removeFilter(map: Map<string, string>, value: any) {
    map.forEach(function (valore, key) {
      if (valore === value) {
        map.delete(key);
      }
    });
    setFilters(map);
  }

  useEffect(() => {
    setModalOpened(showModal);
  }, [showModal]);

  function FirsTextTagReader(element: string) {
    switch (element) {
      case "cities":
        return t("smartPlan.components.tagElements.location");
      case "includedServices":
        return t("smartPlan.components.tagElements.includedService");
      case "minPrice":
        return t("smartPlan.components.tagElements.minPrice");
      case "maxPrice":
        return t("smartPlan.components.tagElements.maxPrice");
      default:
        return element;
    }
  }

  function SecondTextTagReader(e1: string, e2: string | string[]) {
    if (e1 === "includedServices") {
      if (Array.isArray(e2)) {
        var newEl = [] as string[];
        e2.map((element) => {
          newEl.push(t("coworking.filters." + element));
        });
        return newEl;
      } else {
        return t("coworking.filters." + e2);
      }
    }
    if (e1 === "cities") {
      return e2;
    }
    if (e1 === "minPrice" || e1 === "maxPrice") {
      return e2;
    }
  }

  return (
    <>
      <div className="smartplan-filterbar">
        {/* BUTTON ZONE */}
        <div className="smartplan-filterBar-tabs">
          <BaseTab
            select={switchStatus === 0}
            text={"Tutti i coworking"}
            onClick={() => {
              setSwitchSelected(0);
              navigate("/officeCoworking");
            }}
          ></BaseTab>
          <BaseTab
            select={switchStatus === 1}
            text={"Richieste in corso"}
            onClick={() => {
              setSwitchSelected(1);
              navigate("/officeCoworking/myOrders");
            }}
          ></BaseTab>
          <BaseTab
            select={switchStatus === 2}
            text={t("smartPlan.components.filterBar.mine")}
            onClick={() => {
              setSwitchSelected(2);
              navigate("/officeCoworking/myOrders");
            }}
          ></BaseTab>
        </div>
        {/* END BUTTON ZONE */}
        {/* SEARCH ZONE */}
        {!switchStatus && (
          <div className="smartplan-filterBar-search">
            <SearchInput
              baseInputText={t("smartPlan.components.filterBar.searchByName")}
              inputSearched={(e) => searched(e)}
            />
            <div className="smartplan-filterBar-filterbutton">
              <BaseTab
                icon={
                  <TwentyIcFiltersOrder color="grey" className={undefined} />
                }
                select={!filterOpened}
                isButton={true}
                text={t("smartPlan.common.filters")}
                children={
                  !!filters &&
                  filters?.size > 0 && (
                    <div className="smartplan-filterBar-filterbutton-counter">
                      <div className="smartplan-filterbar-filterbutton-counter-text">
                        {filters.size}
                      </div>
                    </div>
                  )
                }
                onClick={() => {
                  !switchStatus && setShowModal(!showModal);
                }}
              />
            </div>
          </div>
        )}
        {/* END SEARCH ZONE */}
      </div>
      {/* TAG ZONE */}

      {!switchStatus && filters.size > 0 && (
        <React.Fragment>
          <div className="smartplan-filterBar-tags">
            <div className={"smartplan-filterBar-tags-section"}>
              {Array.from(filters.entries()).map(
                ([textOne, textTwo], index) => (
                  <Tag
                    key={index}
                    textOne={FirsTextTagReader(textOne)}
                    textTwo={SecondTextTagReader(textOne, textTwo)}
                    isRemoved={() => {
                      removeFilter(filters, textTwo);
                    }}
                  />
                )
              )}
            </div>
            {filters.size > 1 && (
              <div className="smartplan-filterBar-tags-reset">
                {/* <Tag
                  resetAllFilterButton={true}
                  isRemoved={() => {
                    resetFilters(filters);
                  }}
                /> */}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      {/* END TAG ZONE */}
    </>
  );
};
