import HttpClient from "../../utils/HttpClient";
import { UserInfo } from "./UserSlice";

export default class UserInfoRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getUserInfo(userInfoId: number, tenantId: number) {
    return this.instance.get("/tenants/" + tenantId + "/users/" + userInfoId);
  }

  public createUserInfo(data: any, tenantId: number, inviteCode: any) {
    const queryParams = `?inviteCode=${encodeURIComponent(inviteCode)}`;
    return this.instance.post(
      "/auth/tenants/" + tenantId + "/addEmployee" + queryParams,
      data
    );
  }

  public updateUserInfo(userInfo: UserInfo, tenantId: number) {
    return this.instance.patch(
      "/tenants/" + tenantId + "/user/" + userInfo.id,
      userInfo
    );
  }

  public verifyUserInvite(email: any, inviteCode: any) {
    const queryParams = `?inviteCode=${encodeURIComponent(
      inviteCode
    )}&email=${encodeURIComponent(email)}`;
    return this.instance.post("/auth/verifyInvite" + queryParams);
  }
}
