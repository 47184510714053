import React, { useEffect, useState } from "react";
import { ModalWrapper } from "../../../ui/components/Modals/ModalWrapper";
import { ModalBody } from "../../../ui/components/Modals/ModalBody";
import { ModalFooter } from "../../../ui/components/Modals/ModalFooter";
import { t } from "i18next";
import { IconCreditsWithTitle } from "../../../ui/components/icons/TwentyIcCreditsCoinsAdd/IconCreditsWithTitle";
import { Button, Dropdown, Space } from "antd";
import { MenuProps } from "antd/lib";
import "./CreditManagementAdmin.css";
import { TwentyIcArrowDown } from "../../../ui/components/icons/TwentyIcArrowDown";
import { store } from "../../../app/store";
import {
  CheckoutItem,
  createCheckoutSessionForCreditsAsync,
} from "../../myorders/MyOrdersSlice";
import { TwentyIcCreditCoins } from "../../../ui/components/TwentyIcCreditCoins";
import { getRateAsync } from "../../Wallet/WalletSlice";
import { truncateDecimalNumber } from "../../../utils/utils";
import _ from "lodash";

interface ChargeCreditsModalProps {
  onClose: () => void;
  title: string;
}

export const ChargeCreditsModal: React.FC<ChargeCreditsModalProps> = ({
  onClose,
  title,
}) => {
  const [credits, setCredits] = useState<string>("0");
  const [price, setPrice] = useState<number>(0.0);
  const [isStripeLoading, setIsStripeLoading] = useState<boolean>(false);

  const creditsPack: MenuProps["items"] = [
    {
      label: 50,
      key: 50,
    },
    {
      label: 100,
      key: 100,
    },
    {
      label: 200,
      key: 200,
    },
    {
      label: 300,
      key: 300,
    },
    {
      label: 400,
      key: 400,
    },
    {
      label: 500,
      key: 500,
    },
    {
      label: 1000,
      key: 1000,
    },
    {
      label: 2000,
      key: 2000,
    },
    {
      label: 3000,
      key: 3000,
    },
    {
      label: 4000,
      key: 4000,
    },
    {
      label: 5000,
      key: 5000,
    },
    {
      label: 6000,
      key: 6000,
    },
    {
      label: 7000,
      key: 7000,
    },
    {
      label: 8000,
      key: 8000,
    },
    {
      label: 9000,
      key: 9000,
    },
    {
      label: 10000,
      key: 10000,
    },
  ];

  const checkoutItem: CheckoutItem = {
    successURL: _.isEqual(process.env.REACT_APP_DEPLOYMENT, "local")
      ? process.env.REACT_APP_LOCAL_URL + "credits"
      : process.env.REACT_APP_PUBLIC_URL + "credits",
    cancelURL: _.isEqual(process.env.REACT_APP_DEPLOYMENT, "local")
      ? process.env.REACT_APP_LOCAL_URL + "credits"
      : process.env.REACT_APP_PUBLIC_URL + "credits",
    price: credits,
  };

  const redirectToStripe = (stripeCheckoutURL: string) => {
    if (stripeCheckoutURL) {
      window.location.href = stripeCheckoutURL;
    }
  };

  const onCreditsClick: MenuProps["onClick"] = ({ key }) => {
    setCredits(key);
    store.dispatch(getRateAsync(key)).then((result: any) => {
      if (result.payload !== null) {
        let price = truncateDecimalNumber(+key * result.payload);
        setPrice(price);
      }
    });
  };

  return (
    <>
      <div className="modal-credits">
        <ModalWrapper open={true} closeAction={onClose}>
          <ModalBody
            title={
              <IconCreditsWithTitle
                color="#FFFFFF"
                className={undefined}
                colorC="#FFFFFF"
                title={title}
              />
            }
          >
            <div className="modal-dropdown-credits">
              <div className="modal-dropdown-credits-title">Crediti</div>
              <div className="modal-dropdown-credits-subtitle">
                Fai una ricarica
              </div>
              <div className="modal-dropdown-credits-amount">
                N° Crediti
                <div className="modal-dropdown-credits-min">
                  Min. 50 crediti
                </div>
              </div>
              <div className="modal-dropdown-credits-dropdown">
                <Dropdown
                  overlayStyle={{ maxHeight: "300px", overflowY: "auto" }}
                  menu={{
                    items: creditsPack,
                    onClick: onCreditsClick,
                  }}
                >
                  <div className="button-dropdown-seats">
                    <Button>
                      <div className="space-dropdown-seats">
                        <Space
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className={"myService-filtermodal-text"}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <TwentyIcCreditCoins
                              className={undefined}
                              color={"#222729"}
                              colorC={"#222729"}
                            />
                            {credits !== "0" ? credits : "Seleziona crediti"}
                          </div>
                          <TwentyIcArrowDown
                            color="#36B39E"
                            className={undefined}
                          />
                        </Space>
                      </div>
                    </Button>
                  </div>
                </Dropdown>
              </div>
              <div className="modal-dropdown-price">
                Prezzo totale
                <div className="modal-dropdown-price-euro">
                  {typeof price === "number" && !Number.isNaN(price)
                    ? price
                    : 0}{" "}
                  €
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter
            primaryLabel={"Procedi con il pagamento"}
            secondaryLabel={t("account.modalChangePassowrd.buttonCancel")}
            primaryAction={() => {
              setIsStripeLoading(!isStripeLoading);
              store
                .dispatch(
                  createCheckoutSessionForCreditsAsync({ checkoutItem })
                )
                .then((stripeResult: any) => {
                  localStorage.setItem("stripeId", stripeResult.payload?.id);
                  localStorage.setItem("credits", credits);
                  redirectToStripe(stripeResult.payload?.url);
                });
            }}
            secondaryAction={onClose}
            disablePrimaryButton={credits === "0"}
            isActionPerforming={isStripeLoading}
          />
        </ModalWrapper>
      </div>
    </>
  );
};

export default ChargeCreditsModal;
