import { Checkbox } from "antd";
import { Logo } from "../../../ui/components/Logo/Logo";
import { ModalBody } from "../../../ui/components/Modals/ModalBody";
import { ModalFooter } from "../../../ui/components/Modals/ModalFooter";
import { ModalWrapper } from "../../../ui/components/Modals/ModalWrapper";
import "../PrivacyAndPolicy/PrivacyAndPolicy.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export const PrivacyAndPolicy = () => {
  const [accepted, setAccepted] = useState(true);
  const navigate = useNavigate();
  return (
    <div className="daccountdetails">
      <div className="daccountdetails-container">
        <div className="daccountdetails-logo">
          <Logo width="186px" height="28px" />
        </div>
      </div>

      <div className="modal-wrapper-privacy-policy">
        <ModalWrapper open={true}>
          <div className="modal-body-privacy-policy">
            <ModalBody title="Accettazione termini e condizioni">
              <div className="text-privacy-policy">
                Con l'apertura dell'account, il Fornitore dichiara di conoscere
                e accettare integralmente i presenti Termini e Condizioni e di
                aver preso visione dell'Informativa sul trattamento dei dati
                personali disponibile al seguente link:{" "}
                <a
                  className="linkterminiecondizioni"
                  href="https://everywheretew.it/termini-e-condizioni-piattaforma"
                  target="blank"
                >
                  Termini e condizioni.
                </a>
                <br />
                {t("common.privacyPolicy")}
              </div>
            </ModalBody>
          </div>
          <ModalFooter
            disablePrimaryButton={accepted}
            primaryLabel="Procedi"
            primaryAction={() => {
              navigate("/addProvider");
            }}
            hasCheckbox={true}
            onCheck={() => setAccepted(!accepted)}
          />
        </ModalWrapper>
      </div>
    </div>
  );
};
