export const TwentyIcArrowLeft = ({
  className,
  color = "black",
  onClick = () => {},
}) => {
  return (
    <svg
      onClick={onClick}
      className={`twenty-ic-arrow-left ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12 5C12 7.5 8 10 8 10C8 10 12 12.5 12 15"
        stroke={color}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
