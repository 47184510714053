import HttpClient from "../../utils/HttpClient";
import { ChangePassword } from "./ChangePasswordSlice";

export default class ChangePasswordRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public changePassword(changePassword: ChangePassword) {
    const userId = localStorage.getItem("customerId");
    return this.instance.patch(
      "auth/users/" + userId + "/changePassword",
      changePassword
    );
  }

  public changePasswordOnFirstAccess(changePassword: ChangePassword) {
    const userId = localStorage.getItem("customerId");
    const tenentId = localStorage.getItem("tenantId");
    return this.instance.patch(
      "tenants/" +
        tenentId +
        "/users/" +
        userId +
        "/changePasswordOnFirstAccess",
      changePassword
    );
  }
}
