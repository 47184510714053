import "./Footer.css";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-copyright">© EverywhereSB, s.r.l</div>
      <div className="footer-label">contact@everywheretew.it</div>
      <a
        href="https://everywheretew.it/privacy-policy-piattaforma"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="footer-label">Privacy Policy</div>
      </a>
    </div>
  );
};
