import React, { useEffect, useState } from "react";
import { BaseTab } from "../../ui/components/BaseTab";
import { t } from "i18next";
import { SearchInput } from "../../ui/components/Input";
import "./MyTeamFilterBar.css";
import { IconUserAdd } from "../../ui/components/icons/IconUserAdd";
import { Button, Space } from "antd";
interface MyTeamFilterBarProps {
  filterOpened?: boolean;
  searched: (e: any) => any;
  filters?: Map<string, string>;
  modalStatus?: boolean;
  setFilters?: (e: any) => any;
  setSwitchSelected?: (e: any) => any;
  setModalStatus: (e: any) => any;
  setType: (e: any) => any;
}

export const MyTeamFilterBar: React.FC<MyTeamFilterBarProps> = ({
  searched,
  setModalStatus,
  modalStatus,
  setType,
}) => {
  const profile = localStorage.getItem("securityProfile");
  const [elementSelected, setElementSelected] = useState(
    profile === "SUPER_ADMIN" ? 1 : 4
  );
  const [modalStatuss, setModalStatuss] = useState(false);

  const superAdminTabComponents = () => {
    return (
      <div className="myteam-filterBar-tabs">
        <BaseTab
          select={elementSelected === 1}
          text={t("myTeam.components.filterBar.providers")}
          onClick={() => {
            if (elementSelected !== 1) {
              setElementSelected(1);
              setType(1);
            }
          }}
        ></BaseTab>
        <BaseTab
          select={elementSelected === 2}
          text={t("myTeam.components.filterBar.admins")}
          onClick={() => {
            if (elementSelected !== 2) {
              setElementSelected(2);
              setType(2);
            }
          }}
        ></BaseTab>
        <BaseTab
          select={elementSelected === 3}
          text={t("myTeam.components.filterBar.employees")}
          onClick={() => {
            if (elementSelected !== 3) {
              setElementSelected(3);
              setType(3);
            }
          }}
        ></BaseTab>
      </div>
    );
  };

  const tabComponents = () => {
    return (
      <div className="myteam-filterBar-tabs">
        <BaseTab
          select={elementSelected === 4}
          text={t("myTeam.components.filterBar.myTeam")}
          onClick={() => {
            if (elementSelected !== 4) {
              setElementSelected(4);
              setType(4);
            }
          }}
        ></BaseTab>
        {false && (
          <>
            <BaseTab
              select={elementSelected === 5}
              text={t("myTeam.components.filterBar.groups")}
              onClick={() => {
                if (elementSelected !== 5) {
                  setElementSelected(5);
                  setType(5);
                }
              }}
            ></BaseTab>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="myteam-filterbar">
        {/* BUTTON ZONE */}
        {profile === "SUPER_ADMIN"
          ? superAdminTabComponents()
          : tabComponents()}
        {/* END BUTTON ZONE */}
        {/* SEARCH ZONE */}
        <div className="myteam-filterbar-leftsection">
          <div className="myteam-filterBar-search">
            <SearchInput
              baseInputText="Ricerca"
              inputSearched={(e) => searched(e)}
            />
          </div>
          {profile === "ADMIN" ? (
            <Button
              style={{
                borderColor: "#36B39E",
                backgroundColor: "#36B39E",
                boxShadow: "none",
                height: "100%",
                display: "flex",
                gap: "4px",
                alignItems: "center",
              }}
              onClick={() => setModalStatus(!modalStatus)}
            >
              <Space style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconUserAdd
                    color="#FFFFFF"
                    className={undefined}
                  ></IconUserAdd>
                </div>
                <div className="smartplan-filtermodal-text-white">
                  {t("myTeam.components.filterBar.newMember")}
                </div>
              </Space>
            </Button>
          ) : null}{" "}
        </div>
      </div>
      {/* END SEARCH ZONE */}
    </>
  );
};
