import { TwentyIcArrowLineRight } from "../icons/TwentyIcArrowLineRight";

import "./PublicAccountActivityTail.css";
import { ImageConfig } from "../../../config/ImageConfig";
import { IconUser } from "../icons/IconUser";
export interface PublicAccountActivityTailProps {
  id: number | undefined;
  firstName: string;
  lastName: string;
  personalPhotography: string;
  typologies: any[];
  navigation: (e: any) => any;
}

const PublicAccountActivityTail: React.FC<PublicAccountActivityTailProps> = ({
  id,
  firstName,
  lastName,
  personalPhotography,
  typologies,
  navigation,
}) => {
  let imageBuilder = null;

  imageBuilder = (
    <>
      <div className="nutritional-image-container">
        <img
          className="activity-public-account-tail__container"
          alt="Rectangle"
          src={ImageConfig.BACKGROUND_DEFAULT_SERVICE}
        />
        {personalPhotography && (
          <img
            className="activity-public-account-tail__container-nutritional"
            alt="Rectangle"
            src={personalPhotography}
          />
        )}
      </div>
    </>
  );

  function formatTypologies(typologies: any) {
    if (typologies && typologies.length > 0) {
      return typologies
        .map((el: any) => el.name)
        .join(", ")
        .toUpperCase();
    }
    return "";
  }

  return (
    <>
      <div className="activity-public-account-tail__wrapper">
        <span className="activity-public-account-tail__image">
          {imageBuilder}
        </span>
        <div className="activity-public-account-tail__frame-wrapper">
          <div className="activity-public-account-tail__sections">
            <div className="activity-tail__first-section">
              <div className="activity-tail__first-section-wrapper">
                <IconUser color="#86D1C5" className="" />
                <div className="activity-tail__first-title">Online</div>
              </div>
              <div className="activity-tail__alert-title"></div>
            </div>
            <div className="activity-public-account-tail__second-section">
              <div className="activity-public-account-tail__second-section-wrapper">
                <div className="activity-public-account-tail__second-title">
                  {firstName + " " + lastName}
                </div>
                <div className="activity-public-account-tail__third-title">
                  {formatTypologies(typologies)}
                </div>
              </div>
              <div className="activity-public-account-tail____twentyicarrowlineright ">
                <TwentyIcArrowLineRight
                  color="#36B39E"
                  className="activity-public-account-tail____wentyicarrowlineright"
                  onClick={() => {
                    navigation(id);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicAccountActivityTail;
