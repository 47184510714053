/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const TwentyIcHandServices = ({ className, color }) => {
  return (
    <svg
      className={`twenty-ic-hand-services ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rect"
        height="5"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
        width="5"
        x="3"
        y="3"
      />
      <rect
        className="rect"
        height="5"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
        width="5"
        x="3"
        y="12"
      />
      <path
        className="path"
        d="M11.5 5.5H17"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M11.5 14.5H17"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
