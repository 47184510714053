import { t } from "i18next";
import { Logo } from "../../ui/components/Logo/Logo";

import { IconTik } from "../../ui/components/icons/IconTik";
import "../Meetings/Meetings.css";
import { useLocation, useNavigate } from "react-router-dom";
import { TwentyIcArrowLineLeft } from "../../ui/components/icons/TwentyIcArrowLineLeft";
import { TwentyIcEverywhere } from "../../ui/components/icons/TwentyIcEverywhere";
import "../Meetings/Meetings.css";
import { useEffect } from "react";
import AuthenticationService from "../../utils/AuthenticationService";

export const ConfirmMeeting = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("validationCode");
  const serviceName = queryParams.get("serviceName");
  const providerName = queryParams.get("providerName");
  const meetingIdString = queryParams.get("meetingId");
  const authenticationService = new AuthenticationService();

  useEffect(() => {
    if (meetingIdString && code) {
      let meetingId = +meetingIdString;
      authenticationService.confirmMeeting(meetingId, code);
    }
  }, [code]);

  return (
    <>
      <div className="meetings-wrapper">
        <div className="payment-everywhere-logo">
          <Logo width="186px" height="28px" />
        </div>
        <div className="paymentsuccess-content">
          <div className="paymentsuccess-frame">
            <div className="paymentsuccess-frame-firstsection">
              <div className="paymentsuccess-frame-firstsection-iconcontainer">
                <IconTik size={40} className={undefined}></IconTik>
              </div>
              <div className="paymentsuccess-frame-firstsection-text">
                <div className="paymentsuccess-frame-firstsection-firsttext">
                  {t("meetings.confirmedMeeting")}
                </div>
                <div className="paymentsuccess-frame-firstsection-subtitle">
                  {serviceName}
                </div>
                <div className="paymentsuccess-frame-firstsection-subtitle">
                  {providerName}
                </div>
              </div>
            </div>
            <div className="divider" />
            <div className="paymentsuccess-frame-thirdsection">
              <div
                className="meetings-secondbutton-feedback"
                onClick={() => {}}
              >
                <TwentyIcEverywhere className={""} color="black" />
                <div className="paymentfailed-buttontext">
                  {t("meetings.feedback")}
                </div>
              </div>
              <div
                className="paymentsuccess-frame-thirdsection-thirdbutton"
                onClick={() => {
                  navigate("/");
                }}
              >
                <TwentyIcArrowLineLeft
                  className={undefined}
                ></TwentyIcArrowLineLeft>
                <div className="paymentfailed-buttontext">
                  {t("common.homepage")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ConfirmMeeting;
