import React from "react";
import { TwentyIcCreditsCoinsAdd } from "./TwentyIcCreditsCoinsAdd";

interface IconCreditsWithTitleProps {
  className: string | undefined;
  color: string;
  colorC: string;
  title: string;
}

export const IconCreditsWithTitle: React.FC<IconCreditsWithTitleProps> = ({
  className,
  color,
  colorC,
  title,
}) => {
  return (
    <>
      <TwentyIcCreditsCoinsAdd
        color={color}
        className={className}
        colorC={colorC}
      />
      {"   " + title}
    </>
  );
};
