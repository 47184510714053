import { t } from "i18next";
import { Logo } from "../../ui/components/Logo/Logo";
import { IconDoc } from "../../ui/components/icons/IconDoc";
import { IconTik } from "../../ui/components/icons/IconTik";
import { TwentyIcArrowLineLeft } from "../../ui/components/icons/TwentyIcArrowLineLeft";
import { useState, useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { ImageConfig } from "../../config/ImageConfig";
import WellnessActivityTailComponent from "../../ui/components/ActivityTail/WellnessActivityTail";
import { IconAddCalendar } from "../../ui/components/icons/IconAddCalendar";
import {
  MyOrderDetail,
  myOrderDetailSelector,
  getOrderAsync,
} from "../myorders/MyOrderDetailSlice";
import { WellnessPsycho } from "../Wellness/WellnessSlice/WellnessPsychoSlice";

export const WellnessPsychoPaymentSuccess = () => {
  const wellnessPsychoRoute = useMatch(
    "wellness/psycho/:psychoId/success/:bookingId"
  );

  const navigate = useNavigate();

  const [id, setId] = useState<number>(-1);
  const [wellnessPsycho, setWellnessPsycho] = useState<WellnessPsycho>();

  let myOrderDetail: MyOrderDetail = useAppSelector(
    (state) =>
      myOrderDetailSelector.selectById(state, id) ?? ({} as MyOrderDetail)
  );

  useEffect(() => {
    if (myOrderDetail.wellnessPsycho) {
      setWellnessPsycho(myOrderDetail.wellnessPsycho);
    }
  }, [myOrderDetail]);

  useEffect(() => {
    if (wellnessPsychoRoute != null) {
      if (wellnessPsychoRoute.params.bookingId) {
        setId(parseInt(wellnessPsychoRoute.params.bookingId));
        store.dispatch(
          getOrderAsync(parseInt(wellnessPsychoRoute.params.bookingId))
        );
        return;
      }
    }
  }, [wellnessPsychoRoute]);

  return (
    <>
      <div className="paymentsuccess-wrapper">
        <div className="payment-everywhere-logo">
          <Logo width="186px" height="28px" />
        </div>
        <div className="paymentsuccess-content">
          <div className="paymentsuccess-frame">
            <div className="paymentsuccess-frame-firstsection">
              <div className="paymentsuccess-frame-firstsection-iconcontainer">
                <IconTik size={40} className={undefined}></IconTik>
              </div>
              <div className="paymentsuccess-frame-firstsection-text">
                <div className="paymentsuccess-frame-firstsection-firsttext">
                  {t("payments.success.firstText")}
                </div>
                <div className="paymentsuccess-frame-firstsection-subtitle">
                  {t("payments.success.thankYou")}
                </div>
              </div>
            </div>
            <div className="paymentsuccess-frame-secondsection">
              {wellnessPsycho && (
                <WellnessActivityTailComponent
                  activityTailType="PSYCHO"
                  id={wellnessPsycho.id}
                  img={
                    wellnessPsycho.image
                      ? wellnessPsycho.image
                      : ImageConfig.BACKGROUND_DEFAULT_SERVICE
                  }
                  mode={wellnessPsycho.remotelyOrPresenceEnum}
                  title={wellnessPsycho.name}
                  typology={wellnessPsycho.typology}
                  isIndividual={wellnessPsycho.isIndividual}
                  arrow={false}
                />
              )}
            </div>
            <div className="paymentsuccess-frame-thirdsection">
              <div
                className="paymentsuccess-frame-thirdsection-firstbutton"
                onClick={() => {}}
              >
                <IconAddCalendar></IconAddCalendar>
                <div
                  className="paymentfailed-buttontext"
                  onClick={() => {
                    navigate("/wellness/psycho/myOrders/" + id);
                  }}
                >
                  {t("payments.success.buttonCalendar")}
                </div>
              </div>
              <div
                className="paymentsuccess-frame-thirdsection-secondbutton"
                onClick={() => {
                  navigate("/wellness/psycho/myOrders/" + id);
                }}
              >
                <IconDoc className={undefined}></IconDoc>
                <div className="paymentfailed-buttontext">
                  {t("payments.success.buttonOrder")}
                </div>
              </div>
              <div
                className="paymentsuccess-frame-thirdsection-thirdbutton"
                onClick={() => {
                  navigate("/wellness/psycho");
                }}
              >
                <TwentyIcArrowLineLeft
                  className={undefined}
                ></TwentyIcArrowLineLeft>
                <div className="paymentfailed-buttontext">
                  {t("payments.success.buttonServices")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WellnessPsychoPaymentSuccess;
