import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

interface ContextProps {
  value: any;
  setValue: Dispatch<SetStateAction<any>>;
}
interface PreviewProviderProps {
  children: React.ReactNode;
}

const PreviewContext = createContext<ContextProps | undefined>(undefined);

export const PreviewProvider: React.FC<PreviewProviderProps> = ({
  children,
}: any) => {
  const [value, setValue] = useState<any>({} as any);

  return (
    <PreviewContext.Provider value={{ value, setValue }}>
      {children}
    </PreviewContext.Provider>
  );
};

export const usePreviewContext = () => {
  const context = useContext(PreviewContext);
  if (!context) {
    throw new Error("useWellness must be used within a PreviewProvider");
  }
  return context;
};
