/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcEuro = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-euro ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11.0954 17C8.98112 17 7.36066 16.4258 6.23398 15.2775C5.12121 14.1169 4.56483 12.4005 4.56483 10.1283V9.87173C4.56483 7.58726 5.1073 5.87086 6.19225 4.72251C7.29111 3.57417 8.88376 3 10.9702 3C12.2777 3 13.3557 3.19546 14.2042 3.58639C15.0666 3.9651 15.7134 4.4904 16.1446 5.1623C16.5897 5.83421 16.8331 6.61606 16.8748 7.50785H13.9329C13.8773 6.84817 13.6061 6.32897 13.1192 5.95026C12.6463 5.57155 11.93 5.3822 10.9702 5.3822C9.78788 5.3822 8.91853 5.73647 8.36215 6.44503C7.81967 7.14136 7.54844 8.32635 7.54844 10C7.54844 11.6614 7.83358 12.8464 8.40388 13.555C8.98808 14.2635 9.88525 14.6178 11.0954 14.6178C12.0691 14.6178 12.7924 14.4284 13.2653 14.0497C13.7382 13.6588 14.0025 13.1396 14.0581 12.4921H17C16.9583 13.3717 16.7149 14.1536 16.2697 14.8377C15.8385 15.5096 15.1918 16.041 14.3294 16.4319C13.4809 16.8106 12.4029 17 11.0954 17ZM3 12.2723V10.788H12.0551V12.2723H3ZM3.37556 9.48691V8.00262H12.4307V9.48691H3.37556Z"
        fill="#222729"
      />
    </svg>
  );
};
