/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconMail = ({ className, color }) => {
  return (
    <svg
      className={`icon-mail ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rect"
        height="14"
        stroke={color ? color : "#38342F"}
        strokeWidth="2"
        width="18"
        x="1"
        y="3"
      />

      <path
        className="path"
        d="M4 8L8.8906 11.2604C9.5624 11.7083 10.4376 11.7083 11.1094 11.2604L16 8"
        stroke={color ? color : "#38342F"}
        strokeLinecap="square"
        strokeWidth="2"
      />
    </svg>
  );
};
