//#region Type

import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import FaqRepository from "./FaqRepository";
import _ from "lodash";
import { Config } from "../../config/Config";
import { normalize } from "normalizr";
import { faqsSchema } from "./FaqNormalization";
import { RootState } from "../../app/store";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";

export interface Faq {
  id: number;
  question: string;
  answer: string;
}

//#endRegion Type

//#region API

export const getSmartPlanFaqsAsync = createAsyncThunk(
  "faq/getSmartPlanFaqs",
  async (data: { smartPlanId: number }) => {
    const faqRepository = new FaqRepository();
    const response = await faqRepository.getSmartPlanFaqs(data.smartPlanId);
    const faqs = _.get(response, Config.FAQ_RESPONSE);
    const normalizeData = normalize(faqs, faqsSchema);
    return normalizeData.entities;
  }
);
export const getMasterClassFaqsAsync = createAsyncThunk(
  "faq/getMasterClassFaqs",
  async (data: { masterClassId: number }) => {
    const faqRepository = new FaqRepository();
    const response = await faqRepository.getMasterClassFaqs(data.masterClassId);
    const faqs = _.get(response, Config.FAQ_RESPONSE);
    const normalizeData = normalize(faqs, faqsSchema);
    return normalizeData.entities;
  }
);

export const getWellnessPsychoFaqsAsync = createAsyncThunk(
  "faq/getWellnessPsychoFaqs",
  async (data: { wellnessPsychoId: number }) => {
    const faqRepository = new FaqRepository();
    const response = await faqRepository.getWellnessPsychoFaqs(
      data.wellnessPsychoId
    );
    const faqs = _.get(response, Config.FAQ_RESPONSE);
    const normalizeData = normalize(faqs, faqsSchema);
    return normalizeData.entities;
  }
);
export const getWellnessBodyFaqsAsync = createAsyncThunk(
  "faq/getWellnessBodyFaqs",
  async (data: { wellnessBodyId: number }) => {
    const faqRepository = new FaqRepository();
    const response = await faqRepository.getWellnessBodyFaqs(
      data.wellnessBodyId
    );
    const faqs = _.get(response, Config.FAQ_RESPONSE);
    const normalizeData = normalize(faqs, faqsSchema);
    return normalizeData.entities;
  }
);
export const getWellnessNutritionalFaqsAsync = createAsyncThunk(
  "faq/getWellnessNutritionalFaqs",
  async (data: { wellnessBodyId: number }) => {
    const faqRepository = new FaqRepository();
    const response = await faqRepository.getWellnessNutritionalFaqs(
      data.wellnessBodyId
    );
    const faqs = _.get(response, Config.FAQ_RESPONSE);
    const normalizeData = normalize(faqs, faqsSchema);
    return normalizeData.entities;
  }
);
export const getOfficeCoworkingFaqsAsync = createAsyncThunk(
  "faq/getOfficeCoworkingFaqs",
  async (data: { officeCoworkingId: number }) => {
    const faqRepository = new FaqRepository();
    const response = await faqRepository.getOfficeCoworkingFaqs(
      data.officeCoworkingId
    );
    const faqs = _.get(response, Config.FAQ_RESPONSE);
    const normalizeData = normalize(faqs, faqsSchema);
    return normalizeData.entities;
  }
);

//#endRegion API

//#region Slice

const faqAdaper = createEntityAdapter<Faq>({
  selectId: (faq) => faq.id,
});

export const faqSlice = createSlice({
  name: "faq",
  initialState: faqAdaper.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    faqsEmptyState: (state) => {
      faqAdaper.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getSmartPlanFaqsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          faqAdaper.setAll(state, action.payload.faq ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getSmartPlanFaqsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getSmartPlanFaqsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getMasterClassFaqsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          faqAdaper.setAll(state, action.payload.faq ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getMasterClassFaqsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getMasterClassFaqsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getWellnessPsychoFaqsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          faqAdaper.setAll(state, action.payload.faq ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessPsychoFaqsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getWellnessPsychoFaqsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getWellnessNutritionalFaqsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          faqAdaper.setAll(state, action.payload.faq ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessNutritionalFaqsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getWellnessNutritionalFaqsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getWellnessBodyFaqsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          faqAdaper.setAll(state, action.payload.faq ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessBodyFaqsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getWellnessBodyFaqsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const faqSelector = faqAdaper.getSelectors<RootState>(
  (state) => state.faq
);

export const { faqsEmptyState } = faqSlice.actions;
export const selectFaqSliceStatus = (state: any) => state.faq.status;
export const selectFaqSliceReasonCode = (state: any) => state.faq.reasonCode;

//#endRegion Status

export default faqSlice.reducer;
