/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconLock = ({ className, color }) => {
  return (
    <svg
      className={`icon-lock ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" opacity="0" />

      <rect
        className="rect"
        height="7"
        stroke={color ? color : "#38342F"}
        strokeWidth="2"
        width="12"
        x="4"
        y="11"
      />

      <path
        className="path"
        d="M7 5C7 3.34315 8.34315 2 10 2V2C11.6569 2 13 3.34315 13 5V11H7V5Z"
        stroke={color ? color : "#38342F"}
        strokeWidth="2"
      />
    </svg>
  );
};
