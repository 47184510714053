/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconDownload = ({ className, color = "#38342F" }) => {
  return (
    <svg
      className={`icon-download ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3 12V17H17V12"
        stroke={color ? color : "#38342F"}
        strokeWidth="2"
      />
      <path
        className="path"
        d="M11.0507 3V2H9.05074V3H11.0507ZM10.0507 12.9997L9.45888 13.8058L10.0634 14.2497L10.6588 13.7936L10.0507 12.9997ZM9.05074 3V12.9997H11.0507V3H9.05074ZM13.412 9.16545L9.44265 12.2059L10.6588 13.7936L14.6282 10.7532L13.412 9.16545ZM10.6426 12.1937L6.61236 9.23443L5.42865 10.8465L9.45888 13.8058L10.6426 12.1937Z"
        fill={color ? color : "#38342F"}
      />
    </svg>
  );
};
