import { useEffect, useRef, useState } from "react";
import AuthenticationService from "../../utils/AuthenticationService";
import { useLocation, useNavigate } from "react-router-dom";
import Form from "../../ui/components/Form/Form";
import { Button } from "../../ui/components/Button/Button";
import { Logo } from "../../ui/components/Logo/Logo";
import "./ResetPassword.css";
import { ToastNotification } from "../../ui/components/utils/ToastNotification";
import i18next from "i18next";
import { t } from "i18next";
import Input from "antd/es/input/Input";
import { IconLock } from "../../ui/components/icons/IconLock";

export const Resetpassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const authenticationService = new AuthenticationService();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const emailURL = queryParams.get("email");

  const handleChangePassword = (event: any) => {
    // event.preventDefault();

    let errorMessage = "";

    if (password !== confirmPassword) {
      errorMessage = t("toastNotification.passwordMismatch");
    } else if (password.length < 8) {
      errorMessage = t("toastNotification.password8Char");
    } else if (!/[A-Z]/.test(password)) {
      errorMessage = t("toastNotification.passwordMaiuscLetter");
    } else if (!/\d/.test(password)) {
      errorMessage = t("toastNotification.passwordContainsNumber");
    } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
      errorMessage = t("toastNotification.passwordContainsSpecialChar");
    }

    if (errorMessage === "") {
      setIsLoading(true);
      authenticationService.resetPassword(
        password,
        code ?? "",
        emailURL ?? "",
        (result: any) => {
          navigate("/password-changed");
          setIsLoading(false);
        },
        (error) => {
          console.log("LOGIN ERROR: " + error);
          if (error.response === undefined || error.response.status === 500) {
            ToastNotification({
              status: "error",
              description: "",
              toastId: "loginFailed",
              title: i18next.t("loginPage.toastNotification.networkError"),
            });
          }
          setIsLoading(false);
        }
      );
    } else {
      ToastNotification({
        status: "error",
        description: errorMessage,
        toastId: "",
        title: t("toastNotification.incorrectCredentials"),
      });
    }
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  const submitRef = useRef<any>();
  useEffect(() => {
    const keyUp = (event: any) => {
      if (event.keyCode === 13 && password !== "") {
        handleChangePassword(password);
      }
    };

    if (submitRef && submitRef.current) {
      submitRef.current.addEventListener("keydown", keyUp);
      let parentRef = submitRef;
      return () => {
        parentRef.current &&
          parentRef.current.removeEventListener("keydown", keyUp);
      };
    }
  });

  const handleControlPassword = (password: string, confirmPassword: string) => {
    if (password !== "" && confirmPassword !== "") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="resetPassword" ref={submitRef}>
      <div className="resetPassword-container">
        <div className="resetPassword-logo">
          <Logo width="186px" height="28px" />
        </div>
        <div className="resetPassword-content">
          <div className="resetPassword-form-container">
            <span className="resetPassword-up-span">
              {t("resetPassword.resetPassword.title")}
            </span>
            <span className="resetPassword-desc-span">
              {t("resetPassword.resetPassword.desc")}
            </span>
            <div className="resetPassword-form">
              <div className="resetPassword-input-container">
                <div className="resetPassword-input-group">
                  <div>
                    <div className="text-field-password">
                      <Form className="style-form">
                        <div className="text-field-title-container">
                          <span className="password-span">
                            {t("loginPage.password")}
                          </span>
                          <div className="min-ca-reset-password">
                            Min. 8 car.
                          </div>
                        </div>
                        <Input
                          id="password-field"
                          value={password}
                          type="password"
                          style={{
                            width: "400px",
                            height: "50px",
                            marginTop: "4px",
                          }}
                          size="large"
                          placeholder="Password"
                          prefix={
                            <IconLock color={"#BDBEBF"} className={undefined} />
                          }
                          onChange={handlePasswordChange}
                        />
                      </Form>
                      <Form>
                        <div className="label-text-area">
                          <span className="password-span">
                            {" "}
                            {t("resetPassword.resetPassword.heading2")}
                          </span>
                        </div>
                        <Input
                          id="password-field"
                          value={confirmPassword}
                          type="password"
                          style={{
                            width: "400px",
                            height: "50px",
                            marginTop: "4px",
                          }}
                          size="large"
                          placeholder="Conferma password"
                          prefix={
                            <IconLock color={"#BDBEBF"} className={undefined} />
                          }
                          onChange={handleConfirmPasswordChange}
                        />
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="resetPassword-button-wrapper">
              <Button
                size="XL"
                onClick={handleChangePassword}
                disabled={handleControlPassword(password, confirmPassword)}
                text={
                  isLoading ? "" : t("resetPassword.resetPassword.buttonLabel")
                }
                type={undefined}
                state={undefined}
                icon={undefined}
                iconRight={undefined}
                isActionPerforming={isLoading}
              />
            </div>
          </div>

          <p className="resetPassword-forgot-password">
            <span
              className="resetPassword-text-3"
              onClick={() => {
                navigate("/login");
              }}
            >
              {t("resetPassword.resetPassword.footer")}
            </span>
          </p>
        </div>
        <div className="resetPassword-footer-bottom">
          <div className="resetPassword-text-4">© EverywhereSB, s.r.l</div>
          <div className="resetPassword-text-5">
            <div className="resetPassword-text-6">contact@everywheretew.it</div>
            <div className="resetPassword-text-6">Privacy Policy</div>
          </div>
        </div>
      </div>
    </div>
  );
};
