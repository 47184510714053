import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TwentyIcCreditCoins } from "../../ui/components/TwentyIcCreditCoins";
import { IconLink } from "../../ui/components/icons/IconLink";
import { IconMail } from "../../ui/components/icons/IconMail";
import { TwentyIcPhone } from "../../ui/components/icons/TwentyIcPhone";
import { Button } from "../../ui/components/Button/Button";

interface OfficeCoworkingSummaryComponentsProps {
  type: string;
  website?: string;
  telephone?: string;
  sosTelephone?: string;
  email?: string;
  referral?: string;
  price?: number;
  voucherPrice?: number;
  disabled?: boolean;
  isBuying?: boolean;
  daysPurchasing?: number;
  purchasing: () => any;
  onClick?: () => any;
  goToBooks?: () => any;
}

export const OfficeCoworkingSummary: React.FC<
  OfficeCoworkingSummaryComponentsProps
> = ({
  website,
  telephone,
  sosTelephone,
  email,
  voucherPrice,
  disabled,
  isBuying,
  daysPurchasing,
  purchasing,
  onClick,
  goToBooks,
}) => {
  const urlLocation = useLocation();
  const isPreview = urlLocation.pathname.includes("previewService");
  const [disabledButton, setDisabledButton] = useState<boolean>();

  useEffect(() => {
    setDisabledButton(disabled);
  }, [disabled]);

  return (
    <div className="frame-17">
      <div className="text-wrapper-11">{t("wellness.summary")}</div>
      <div className="rectangle-9" />
      <div className="frame-18">
        {website && (
          <div className="frame-19">
            <div className="text-wrapper-14">{t("wellness.website")}</div>
            <div className="frame-20">
              <IconLink className={undefined} color="#36B39E" />
              <div className="text-wrapper-15">{website}</div>
            </div>
          </div>
        )}
      </div>
      {telephone && (
        <div className="frame-22-summary">
          <div className="text-wrapper-14">{t("wellness.telephone")}</div>
          <div className="frame-20">
            <TwentyIcPhone color="#36B39E" className="icon-instance-node-3" />
            <div className="text-wrapper-15">{telephone}</div>
          </div>
        </div>
      )}
      {sosTelephone && (
        <div className="frame-22-summary">
          <div className="text-wrapper-14">{t("wellness.sosTelephone")}</div>
          <div className="frame-20">
            <TwentyIcPhone color="#36B39E" className="icon-instance-node-3" />
            <div className="text-wrapper-15">{sosTelephone}</div>
          </div>
        </div>
      )}
      {email && (
        <div className="frame-22-summary">
          <div className="text-wrapper-14">{t("wellness.email")}</div>
          <div className="frame-20">
            <IconMail className="icon-instance-node-3" color="#36B39E" />
            <div className="text-wrapper-15">
              {
                new DOMParser().parseFromString(email, "text/html")
                  .documentElement.textContent
              }
            </div>
          </div>
        </div>
      )}
      <div className="rectangle-9" />
      <div className="frame-23">
        <div className="text-wrapper-14">{"COSTO"}</div>
        <div className="frame-24">
          <div style={{ display: "flex", alignItems: "center" }}>
            <h1 className="h-1">
              {isPreview ? voucherPrice + "€" : voucherPrice}
            </h1>
            <div style={{ position: "relative", top: "4px" }}>
              {!isPreview ? (
                <TwentyIcCreditCoins
                  size="40"
                  className={undefined}
                  color="#36B39E"
                  colorC="#36B39E"
                />
              ) : (
                ""
              )}
            </div>
          </div>
          {daysPurchasing && (
            <div className="text-wrapper-14">{daysPurchasing + " Giorni"}</div>
          )}
        </div>
      </div>
      {isBuying ? (
        <Button
          type="tertiary"
          size="XL"
          state="default"
          onClick={() => {
            purchasing();
          }}
          text={"Procedi con l'acquisto"}
          disabled={disabledButton}
          iconRight={undefined}
          icon={undefined}
        />
      ) : (
        <Button
          type="primary"
          size="XL"
          state="default"
          onClick={() => {
            if (goToBooks) {
              goToBooks();
            }
            if (onClick) {
              onClick();
            }
          }}
          text={"Verifica disponibilità"}
          disabled={disabledButton}
          iconRight={undefined}
          icon={undefined}
        />
      )}
    </div>
  );
};
