import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import MyDiaryRepository from "./MyDiaryRepository";
import { Config } from "../../config/Config";
import _ from "lodash";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { RootState } from "../../app/store";

export interface Emotions {
  sadness: number;
  joy: number;
  fear: number;
  disgust: number;
  anger: number;
}

export interface MyDiaryEmotions {
  id: number;
  timestamp: Date;
  emotion: Emotions;
  stress: number;
}

export const updateEmotionsAsync = createAsyncThunk(
  "emotions/updateEmotions",
  async ({ text, userId }: any) => {
    const myDiaryRepository = new MyDiaryRepository();
    const response = await myDiaryRepository.updateEmotions(text, userId);
    const myDiary = _.get(response, Config.MYDIARY_RESPONSE);
    return myDiary;
  }
);

export const retriveEmotionsAsync = createAsyncThunk(
  "emotions/retriveEmotions",
  async ({ startDate, endDate, userId }: any) => {
    const myDiaryRepository = new MyDiaryRepository();
    const response = await myDiaryRepository.retriveEmotions(
      userId,
      startDate,
      endDate
    );
    const myDiary = _.get(response, Config.MYDIARY_RESPONSE);
    return myDiary;
  }
);

const myDiaryAdapter = createEntityAdapter<MyDiaryEmotions>({
  selectId: (myDiary) => myDiary.id,
});

export const myDiarySlice = createSlice({
  name: "myDiary",
  initialState: myDiaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    myDiaryEmptyState: (state) => {
      myDiaryAdapter.setAll(state, []);
      state.status = "idle";
      state.reasonCode = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        updateEmotionsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          myDiaryAdapter.removeAll(state);
          myDiaryAdapter.setOne(state, action.payload);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(updateEmotionsAsync.pending, (state: any) => {
        state.statys = "loading";
        state.reasonCode = "";
      })
      .addCase(updateEmotionsAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = "";
      })
      .addCase(
        retriveEmotionsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          myDiaryAdapter.removeAll(state);
          myDiaryAdapter.setOne(state, action.payload);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(retriveEmotionsAsync.pending, (state: any) => {
        state.statys = "loading";
        state.reasonCode = "";
      })
      .addCase(retriveEmotionsAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = "";
      });
  },
});

export const myDiarySelector = myDiaryAdapter.getSelectors<RootState>(
  (state) => state.myDiary
);

export const { myDiaryEmptyState } = myDiarySlice.actions;
export const selectMyDiarySliceStatus = (state: any) => state.myDiary.status;
export const selectMyDiarySliceReasonCode = (state: any) =>
  state.myDiary.reasonCode;

//#endRegion Status

export default myDiarySlice.reducer;
