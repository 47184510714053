import HttpClient from "../../utils/HttpClient";
import { UserSendMapWrapper } from "./CreditHistorySlice";

export default class CreditRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getMyEmployeeRecharge(userId: number) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/users/" +
        userId +
        "/transactions/employeeRecharge"
    );
  }

  public getMyAdminRecharge(userId: number) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/users/" +
        userId +
        "/transactions/adminRecharge"
    );
  }

  public rechargeEmployee(userId: number, users: UserSendMapWrapper[]) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      "/tenants/" + tenantId + "/users/" + userId + "/transactions/send",
      users
    );
  }
}
