import { t } from "i18next";
import React from "react";
import { Button } from "../../../ui/components/Button/Button";
import { truncateDecimalNumber } from "../../../utils/utils";
import { BaseInfo } from "./BaseInfo";
import "./OrderSummary.css";

export interface OrderSummaryProps {
  departure?: string;
  comeBack?: string;
  price?: number;
  paidDeposit?: number;
  numberOfPeople?: number;
  bookingStatus?: any;
  isWellnessService?: boolean;
}

export const OrderSummary: React.FC<OrderSummaryProps> = ({
  departure,
  comeBack,
  price,
  numberOfPeople,
  bookingStatus,
  isWellnessService,
}) => {
  return (
    <>
      <div className="summary-containter">
        <div className="summary-title">{"Riepilogo"}</div>
        <div className="summary-divider" />
        <div className="summary-first-section">
          {isWellnessService ? (
            <>
              <div className="summary-first-row">
                <div className="summary-first-section-left">Data</div>
                <div className="summary-first-section-right">Da concordare</div>
              </div>
            </>
          ) : (
            <>
              <div className="summary-first-row">
                <div className="summary-first-section-left">Partenza</div>
                <div className="summary-first-section-right">{departure}</div>
              </div>
              <div className="summary-first-row">
                <div className="summary-first-section-left">Ritorno</div>
                <div className="summary-first-section-right">{comeBack}</div>
              </div>
            </>
          )}
          <div className="summary-first-row">
            <div className="summary-first-section-left">Posti</div>
            <div className="summary-first-section-right">
              {numberOfPeople}
              {numberOfPeople && numberOfPeople > 1 ? " persone" : " persona"}
            </div>
          </div>
          <div className="summary-divider" />
          <div className="summary-first-row">
            <div className="summary-first-section-left">
              {isWellnessService ? "Totale crediti" : "Prezzo unitario"}
            </div>
            <div className="summary-first-section-right">
              {isWellnessService ? price : price + "€"}
            </div>
          </div>
          {!isWellnessService ? (
            <>
              <div className="summary-first-row">
                <div className="summary-first-section-left">
                  x{numberOfPeople}
                </div>
                <div className="summary-first-section-right">
                  {price &&
                    numberOfPeople &&
                    truncateDecimalNumber(price * numberOfPeople)}
                  €
                </div>
              </div>
              <div className="summary-first-row">
                <div className="summary-first-section-left">IVA 22%</div>
                <div className="summary-first-section-right">
                  {price &&
                    numberOfPeople &&
                    truncateDecimalNumber(price * numberOfPeople * 0.22)}
                  €
                </div>
              </div>
              <div className="summary-first-row">
                <div className="summary-first-section-left">
                  Totale da saldare
                </div>
                <div className="summary-first-section-right">
                  {price &&
                    numberOfPeople &&
                    truncateDecimalNumber(
                      price * numberOfPeople + price * numberOfPeople * 0.22
                    )}
                  €
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="order-button-wrapper ">
            {bookingStatus === "PENDING" && (
              <Button
                textColor={"white"}
                size="XL"
                onClick={() => {}}
                disabled={false}
                text={"Salda"}
                type="primary"
                isActionPerforming={""}
                state={undefined}
                icon={undefined}
                iconRight={undefined}
              />
            )}
            {bookingStatus === "COMPLETED"}
            {bookingStatus === "CANCELLED" && (
              <div className="paystatus-canceled">
                {t("myOrders.payCanceled")}
              </div>
            )}
            {(bookingStatus === undefined || bookingStatus === "") &&
              t("myOrders.notAvabile")}
          </div>
          {bookingStatus === "PENDING" ||
          (bookingStatus === "COMPLETED" && isWellnessService) ? (
            <>
              {/* <div className="order-button-cancel">
                Annulla questo pagamento
              </div> */}
              <BaseInfo />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
