//#region Type

import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import ActivityRepository from "./ActivityRepository";
import { Config } from "../../config/Config";
import _ from "lodash";
import { normalize } from "normalizr";
import { activitiesSchema } from "./ActivityNormalization";
import { RootState } from "../../app/store";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";

export interface Activity {
  id: number;
  imageUrl: string;
  title: string;
  subtitle: string;
}

//#endRegion Type

//#region API

export const getSmartPlanActivityAsync = createAsyncThunk(
  "activity/getActivities",
  async (data: { smartPlanId: number }) => {
    const activityRepository = new ActivityRepository();
    const response = await activityRepository.getSmartPlanActivity(
      data.smartPlanId
    );

    const activities = _.get(response, Config.ACTIVITY_RESPONSE);
    const normalizeData = normalize(activities, activitiesSchema);
    return normalizeData.entities;
  }
);

//#endRegion API

//#region Slice

const activitiesAdapter = createEntityAdapter<Activity>({
  selectId: (activity) => activity.id,
});

export const activitySlice = createSlice({
  name: "activity",
  initialState: activitiesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    activityEmptyState: (state) => {
      activitiesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getSmartPlanActivityAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          activitiesAdapter.setAll(state, action.payload.activity ?? []);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getSmartPlanActivityAsync.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(getSmartPlanActivityAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const { activityEmptyState } = activitySlice.actions;

export const activitySelector = activitiesAdapter.getSelectors<RootState>(
  (state) => state.activity
);

export const selectActivitySliceStatus = (state: any) => state.activity.status;
export const selectActivitySliceReasonCode = (state: any) =>
  state.activity.reasonCode;

//#endRegion Status

export default activitySlice.reducer;
