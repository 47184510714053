//#region Type

import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import IncludedServiceRepository from "./IncludedServiceRepository";
import { Config } from "../../config/Config";
import _ from "lodash";
import { normalize } from "normalizr";
import { includedServicesSchema } from "./IncludedServiceNormalization";
import { RootState } from "../../app/store";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";

export interface IncludedService {
  id: number;
  title: string;
  description: string;
  serviceIds: number;
}

//#endRegion Type

//#region API

export const getSmartPlanIncludedServiceAsync = createAsyncThunk(
  "includedService/getSmartPlanIncludedServices",
  async (data: { smartPlanId: number }) => {
    const includedServiceRepository = new IncludedServiceRepository();
    const response =
      await includedServiceRepository.getSmartPlanIncludedServices(
        data.smartPlanId
      );
    const includedServices = _.get(response, Config.INCLUDED_SERVICE_RESPONSE);
    const normalizeData = normalize(includedServices, includedServicesSchema);
    return normalizeData.entities;
  }
);

export const getMasterClassIncludedServiceAsync = createAsyncThunk(
  "includedService/getMasterClassIncludedServices",
  async (data: { masterClassId: number }) => {
    const includedServiceRepository = new IncludedServiceRepository();
    const response =
      await includedServiceRepository.getMasterClassIncludedServices(
        data.masterClassId
      );
    const includedServices = _.get(response, Config.INCLUDED_SERVICE_RESPONSE);
    const normalizeData = normalize(includedServices, includedServicesSchema);
    return normalizeData.entities;
  }
);

export const getWellnessPsychoIncludedServices = createAsyncThunk(
  "includedService/getWellnessPsychoIncludedServices",
  async (data: { wellnessId: number }) => {
    const includedServiceRepository = new IncludedServiceRepository();
    const response =
      await includedServiceRepository.getWellnessPsychoIncludedServices(
        data.wellnessId
      );
    const includedServices = _.get(response, Config.INCLUDED_SERVICE_RESPONSE);
    const normalizeData = normalize(includedServices, includedServicesSchema);
    return normalizeData.entities;
  }
);

export const getWellnessBodyIncludedServices = createAsyncThunk(
  "includedService/getWellnessBodyIncludedServices",
  async (data: { wellnessId: number }) => {
    const includedServiceRepository = new IncludedServiceRepository();
    const response =
      await includedServiceRepository.getWellnessBodyIncludedServices(
        data.wellnessId
      );
    const includedServices = _.get(response, Config.INCLUDED_SERVICE_RESPONSE);
    const normalizeData = normalize(includedServices, includedServicesSchema);
    return normalizeData.entities;
  }
);

export const getWellnessNutritionalIncludedServices = createAsyncThunk(
  "includedService/getWellnessNutritionalIncludedServices",
  async (data: { wellnessId: number }) => {
    const includedServiceRepository = new IncludedServiceRepository();
    const response =
      await includedServiceRepository.getWellnessNutritionalIncludedServices(
        data.wellnessId
      );
    const includedServices = _.get(response, Config.INCLUDED_SERVICE_RESPONSE);
    const normalizeData = normalize(includedServices, includedServicesSchema);
    return normalizeData.entities;
  }
);

//#endRegion API

//#region Slice

const includedServiceAdapter = createEntityAdapter<IncludedService>({
  selectId: (includedService) => includedService.id,
});

export const includedServiceSlice = createSlice({
  name: "includedService",
  initialState: includedServiceAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    includedServicesEmptyState: (state) => {
      includedServiceAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getSmartPlanIncludedServiceAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          includedServiceAdapter.setAll(
            state,
            action.payload.includedService ?? []
          );
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getSmartPlanIncludedServiceAsync.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(getSmartPlanIncludedServiceAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getMasterClassIncludedServiceAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          includedServiceAdapter.setAll(
            state,
            action.payload.includedService ?? []
          );
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getMasterClassIncludedServiceAsync.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(getMasterClassIncludedServiceAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getWellnessBodyIncludedServices.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          includedServiceAdapter.setAll(
            state,
            action.payload.includedService ?? []
          );
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessBodyIncludedServices.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(getWellnessBodyIncludedServices.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getWellnessNutritionalIncludedServices.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          includedServiceAdapter.setAll(
            state,
            action.payload.includedService ?? []
          );
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessNutritionalIncludedServices.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(
        getWellnessNutritionalIncludedServices.rejected,
        (state: any) => {
          state.statys = "failed";
          state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
        }
      )
      .addCase(
        getWellnessPsychoIncludedServices.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          includedServiceAdapter.setAll(
            state,
            action.payload.includedService ?? []
          );
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessPsychoIncludedServices.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(getWellnessPsychoIncludedServices.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const includedServiceSelector =
  includedServiceAdapter.getSelectors<RootState>(
    (state) => state.includedService
  );

export const { includedServicesEmptyState } = includedServiceSlice.actions;
export const selectincludedServiceSliceStatus = (state: any) =>
  state.includedService.status;
export const selectincludedServiceSliceReasonCode = (state: any) =>
  state.includedService.reasonCode;

//#endRegion Status

export default includedServiceSlice.reducer;
