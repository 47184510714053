import { t } from "i18next";
import { ModalBody } from "../../../ui/components/Modals/ModalBody";
import { ModalFooter } from "../../../ui/components/Modals/ModalFooter";
import { ModalWrapper } from "../../../ui/components/Modals/ModalWrapper";
// import "../../masterclass/masterclassModal/MasterClassModal.css";
import Form from "../../../ui/components/Form/Form";
import { useState } from "react";
import "./ChangePasswordModal.css";
import { TwentyIcHelp } from "../../../ui/components/icons/TwentyIcHelp";
import _ from "lodash";
import Input from "antd/es/input/Input";
import { TwentyIcUnlock } from "../../../ui/components/icons/TwentyIcUnlock";
import { TwentyIcLockNewPassword } from "../../../ui/components/icons/TwentyIcLockNewPassword";
import { TwentyIcLock } from "../../../ui/components/icons/TwentyIcLock";

interface ChangePasswordProps {
  onClose: () => void;
  onClick: (newPassword: string, oldPassword: string) => void;
  title: string;
  isActionPerforming: boolean;
}

export const ChangePasswordModal: React.FC<ChangePasswordProps> = ({
  onClose,
  onClick,
  title,
  isActionPerforming,
}) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function handlePassword(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) {
    if (
      oldPassword !== "" &&
      newPassword !== "" &&
      confirmPassword !== "" &&
      newPassword === confirmPassword
    ) {
      return false;
    } else {
      return true;
    }
  }

  const handleOldPasswordChange = (e: any) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e: any) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <div className="modal-wrapper-change-password">
      <ModalWrapper open={true} closeAction={onClose}>
        <div className="modal-body-change-password">
          <ModalBody title={title}>
            <Form>
              <br />
              <span className="label-textfield">
                {t("account.modalChangePassowrd.oldPassword")}
              </span>
              <div
                className={
                  oldPassword !== ""
                    ? "modal-textfield-change-password-not-empty"
                    : "modal-textfield-change-password-empty"
                }
              >
                <Input
                  id="password-field"
                  value={oldPassword}
                  type="password"
                  style={{
                    width: "540px",
                    height: "50px",
                    marginTop: "5px",
                    marginBottom: "8px",
                  }}
                  size="large"
                  placeholder="Password"
                  prefix={
                    <TwentyIcUnlock color={"#BDBEBF"} className={undefined} />
                  }
                  onChange={handleOldPasswordChange}
                />
              </div>
            </Form>
            <Form>
              <div className="modal-changepassword-newpassword-titlecontainer">
                <span className="label-textfield">
                  {t("account.modalChangePassowrd.newPassword")}
                  <span className="icon-textfield"></span>
                </span>
                <span className="modal-changepassword-newpassword-secondtitle">
                  Min. 8 car.
                </span>
              </div>
              <div
                className={
                  newPassword !== ""
                    ? "modal-textfield-change-password-not-empty"
                    : "modal-textfield-change-password-empty"
                }
              >
                <Input
                  id="password-field"
                  value={newPassword}
                  type="password"
                  style={{
                    width: "540px",
                    height: "50px",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                  size="large"
                  placeholder="Password"
                  prefix={
                    <TwentyIcLockNewPassword
                      color={"#BDBEBF"}
                      className={undefined}
                    />
                  }
                  onChange={handleNewPasswordChange}
                />
              </div>
            </Form>
            <Form>
              <span className="label-textfield">
                {t("account.modalChangePassowrd.confirmPassword")}
              </span>
              <div
                className={
                  confirmPassword !== ""
                    ? "modal-textfield-change-password-not-empty"
                    : "modal-textfield-change-password-empty"
                }
              >
                <Input
                  id="password-field"
                  value={confirmPassword}
                  type="password"
                  style={{
                    width: "540px",
                    height: "50px",
                    marginTop: "5px",
                    marginBottom: "8px",
                  }}
                  size="large"
                  placeholder="Password"
                  prefix={
                    <TwentyIcLock color={"#BDBEBF"} className={undefined} />
                  }
                  onChange={handleConfirmPasswordChange}
                />
              </div>
            </Form>
          </ModalBody>
        </div>
        <ModalFooter
          primaryLabel={t("account.modalChangePassowrd.buttonConfirm")}
          secondaryLabel={t("account.modalChangePassowrd.buttonCancel")}
          primaryAction={() => onClick(newPassword, oldPassword)}
          secondaryAction={onClose}
          disablePrimaryButton={handlePassword(
            oldPassword,
            newPassword,
            confirmPassword
          )}
          isActionPerforming={isActionPerforming}
        />
      </ModalWrapper>
    </div>
  );
};
