/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcZen = ({ className, color = "white", size = "20" }) => {
  return (
    <svg
      className={`twenty-ic-zen ${className}`}
      fill="none"
      height={size}
      viewBox="0 0 20 20"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M2 13C2.69863 11.8044 4.00442 11 5.5 11C6.99558 11 8.30137 11.8044 9 13C8.30137 14.1956 6.99558 15 5.5 15C4.00442 15 2.69863 14.1956 2 13Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M10 4C11.1956 4.69863 12 6.00442 12 7.5C12 8.99558 11.1956 10.3014 10 11C8.8044 10.3014 8 8.99558 8 7.5C8 6.00442 8.8044 4.69863 10 4Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M11 13C11.6986 11.8044 13.0044 11 14.5 11C15.9956 11 17.3014 11.8044 18 13C17.3014 14.1956 15.9956 15 14.5 15C13.0044 15 11.6986 14.1956 11 13Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
