import React, { useEffect, useState } from "react";
import "./MasterClassFilterBar.css";
import { BaseTab } from "../BaseTab/BaseTab";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

interface MasterClassFilterBarProps {
  setSwitchSelected: (e: any) => any;
  switchStatus: boolean;
}

export const MasterClassFilterBar: React.FC<MasterClassFilterBarProps> = ({
  setSwitchSelected,
  switchStatus,
}) => {
  const [elementSelected, setElementSelected] = useState(switchStatus);
  const navigate = useNavigate();

  useEffect(() => {
    setElementSelected(switchStatus);
  }, [switchStatus]);

  useEffect(() => {
    setSwitchSelected(elementSelected);
  }, [elementSelected]);

  return (
    <>
      <div className="masterclass-filterbar">
        {/* BUTTON ZONE */}
        <div className="masterclass-filterBar-tabs">
          <BaseTab
            select={!elementSelected}
            text={t("masterClass.components.filterBar.allMasterclass")}
            onClick={() => {
              if (!!elementSelected) {
                setElementSelected(!elementSelected);
                navigate("/masterclass");
              }
            }}
          ></BaseTab>
          <BaseTab
            select={elementSelected}
            text={t("masterClass.components.filterBar.mine")}
            onClick={() => {
              if (!elementSelected) {
                setElementSelected(!elementSelected);
                navigate("/masterclass/myOrders");
              }
            }}
          ></BaseTab>
        </div>
        {/* END BUTTON ZONE */}
      </div>
    </>
  );
};
