import React, { useEffect, useRef, useState } from "react";
import "./AddMemberModal.css";
import { IconClose } from "../../ui/components/icons/IconClose";
import { t } from "i18next";
import { Button, Form, Input, Space } from "antd";
import { TwentyIcTik } from "../../ui/components/icons/TwentyIcTik";
import { IconAdd } from "../../ui/components/icons/IconAdd";
import { IconRemove } from "../../ui/components/icons/IconRemove";

interface AddMemberModalProps {
  closeModal: (e: any) => any;
  statusModal: boolean;
  setParam: (e: any) => any;
}

export const AddMemberModal: React.FC<AddMemberModalProps> = ({
  closeModal,
  statusModal,
  setParam,
}) => {
  const [form] = Form.useForm();
  const expression = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const [modalStatus, setModalStatus] = useState(statusModal);
  const [emails, setEmails] = useState<string[]>([]);
  let sendable = true;
  const [isValid, setIsValid] = useState(true);
  const scrollContainerRef = useRef(null);
  useEffect(() => {
    let map = new Map();
    let optSelected = [] as string[];
    if (emails.length > 0) {
      emails.map((e) => {
        optSelected.push(e);
      });
      map.set("email", optSelected);
    }
    setParam(map);
    closeModal(modalStatus);
  }, [modalStatus]);

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 0 },
    },
  };

  const handleFormFinish = (values: any) => {
    if (!!values.names) {
      sendable = true;
      values.names.map((v: any) => {
        if (!expression.test(v)) {
          sendable = false;
        }
      });
      if (sendable) {
        setIsValid(true);

        setEmails(values.names.map((name: any) => name));
        setModalStatus(!modalStatus);
      } else {
        setIsValid(false);
        setTimeout(() => setIsValid(true), 5000);
      }
    } else {
      setModalStatus(!modalStatus);
    }
  };

  const handleReset = () => {
    form.resetFields();
    setModalStatus(!modalStatus);
    setEmails([]);
  };

  return (
    <>
      <div className="mn-modal-backdrop"></div>
      <div className="addmember-modal-wrapper">
        <div className="addmember-modal-header">
          <div className="addmember-modal-header-text">
            {t("myTeam.components.filterBar.addMembers")}
          </div>
          <div className="addmember-modal-header-icon">
            <IconClose
              color="white"
              className={undefined}
              onClick={() => {
                setModalStatus(!modalStatus);
              }}
            ></IconClose>
          </div>
        </div>
        <div className="addmember-modal-content">
          <div className="addmember-modal-content-text-first">
            {t("myTeam.components.filterBar.plaseInsert")}
          </div>
          <Form
            form={form}
            onFinish={handleFormFinish}
            name="dynamic_form_item"
            {...formItemLayoutWithOutLabel}
            className="addmember-formstyle"
          >
            <Form.List name="names">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <div
                    ref={scrollContainerRef}
                    style={
                      fields.length > 5
                        ? { overflow: "scroll", maxHeight: "300px" }
                        : { maxHeight: "300px" }
                    }
                  >
                    {fields.map((field, index) => (
                      <Form.Item
                        style={{
                          width: "100%",
                        }}
                        {...formItemLayoutWithOutLabel}
                        required={false}
                        key={field.key}
                      >
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            display: "flex",
                            gap: "10px",
                            width: "100%",
                          }}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: t(
                                  "myTeam.components.filterBar.errorEmail"
                                ),
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              placeholder={"Email " + (index + 1)}
                              style={{
                                height: "40px",
                                minWidth: "405px",
                              }}
                            />
                          </Form.Item>
                          <div className="addmember-icon-remove">
                            <IconRemove
                              className="dynamic-delete-button"
                              trigger={() => remove(field.name)}
                            />
                          </div>
                        </div>
                      </Form.Item>
                    ))}
                  </div>
                  <Form.Item>
                    <Button
                      type="text"
                      onClick={() => {
                        add();
                        setTimeout(() => {
                          if (scrollContainerRef.current) {
                            const container =
                              scrollContainerRef.current as HTMLDivElement;
                            container.scrollTop = container.scrollHeight;
                          }
                        }, 0);
                      }}
                      className="addmember-modal-firstbuttonstyle"
                      icon={<IconAdd color="#36B39E" className={undefined} />}
                    >
                      {t("myTeam.components.filterBar.addEmail")}
                    </Button>

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="addmember-modal-footer">
              <div className="addmember-email-error">
                {!isValid && "Email inserite non valide"}
              </div>
              <div className="smartPlan-filtermodal-content-buttons">
                <Form.Item
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  <Button
                    onClick={handleReset}
                    className="addmember-modal-resetbuttonstyle"
                  >
                    <Space>
                      <div className="addmember-smartplan-filtermodal-text">
                        {t("smartPlan.common.cancel")}
                      </div>
                    </Space>
                  </Button>
                </Form.Item>
                <Form.Item
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  <Button
                    htmlType="submit"
                    className="addmember-modal-secondbuttonstyle"
                  >
                    <Space>
                      <TwentyIcTik
                        className={
                          "addmember-smartPlan-filtermodal-content-vector-confirm"
                        }
                      />
                      <div className="addmember-filtermodal-text-white">
                        {t("smartPlan.common.confirm")}
                      </div>
                    </Space>
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
