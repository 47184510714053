import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import TeamUsersInfoRepository from "./TeamUsersInfoRepository";
import { Config } from "../../config/Config";
import _ from "lodash";
import { RootState } from "../../app/store";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";

//#region Type
export type TeamUserInfo = {
  id: number;
  fullName: string;
  email: string;
  role: string;
  city: string;
  admin: boolean;
  isFirstAccess: boolean;
  lastAccess: Date;
  securityProfile: string;
  tenantId: number;
};
//#endregion Type

export const getFilteredUsersInfoWithoutUser = createAsyncThunk(
  "userInfo/getFilteredUsersInfoWithoutUser",
  async (data, thunkAPI) => {
    const teamUsersRepository = new TeamUsersInfoRepository();
    const response = await teamUsersRepository.getFilteredUsers();
    const usersInfo = _.get(response, Config.USERS_INFO_RESPONSE_PATH);
    if (response.data) {
      thunkAPI.dispatch(setNumberOfElements(response.data.length));
    }
    if (usersInfo) {
      return usersInfo.filter(
        (el: TeamUserInfo) =>
          el.id.toString() !== localStorage.getItem("customerId")
      );
    } else {
      return [];
    }
  }
);

export const getFilteredUsersInfoPaginationAsync = createAsyncThunk(
  "userInfo/getFilteredUsersInfoPaginationAsync",
  async (data, thunkAPI) => {
    const teamUsersRepository = new TeamUsersInfoRepository();
    const response = await teamUsersRepository.getFilteredUsers();
    const usersInfo = _.get(response, Config.USERS_INFO_RESPONSE_PATH);
    if (response.data) {
      thunkAPI.dispatch(setNumberOfElements(response.data.length));
    }
    if (usersInfo) {
      return usersInfo;
    } else {
      return [];
    }
  }
);

export const addNewUsersViaEmailAsync = createAsyncThunk(
  "userInfo/addNewUsersViaEmail",
  async (data: { queryString: string }, thunkAPI) => {
    const teamUsersRepository = new TeamUsersInfoRepository();
    const response = await teamUsersRepository.addNewUsersViaEmail(
      data?.queryString
    );
    const usersInfo = _.get(response, Config.USERS_INFO_RESPONSE_PATH);
    if (response.data) {
      thunkAPI.dispatch(setNumberOfElements(response.data.length));
    }
    if (usersInfo) {
      return usersInfo;
    } else {
      return [];
    }
  }
);

//#region Slice
const teamUsersInfoAdapter = createEntityAdapter<TeamUserInfo>({
  selectId: (teamUserInfo) => teamUserInfo.id,
  sortComparer: (a, b) => a.fullName.localeCompare(b.fullName),
});

export const teamUsersInfoSlice = createSlice({
  name: "teamUsersInfo",
  initialState: teamUsersInfoAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalElements: 0,
  }),
  reducers: {
    usersInfoStatusAndReasonCodeEmptyState: (state: any) => {
      state.reasonCode = "";
      state.status = "idle";
    },
    usersInfoEmptyState: (state: any) => {
      teamUsersInfoAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(getFilteredUsersInfoPaginationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getFilteredUsersInfoPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          teamUsersInfoAdapter.setAll(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredUsersInfoPaginationAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
        }
      )
      .addCase(getFilteredUsersInfoWithoutUser.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getFilteredUsersInfoWithoutUser.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          teamUsersInfoAdapter.setAll(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredUsersInfoWithoutUser.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
        }
      )
      .addCase(
        addNewUsersViaEmailAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          teamUsersInfoAdapter.upsertMany(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = "invited";
        }
      )
      .addCase(addNewUsersViaEmailAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        addNewUsersViaEmailAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = "";
        }
      );
    //#endregion Entity Reducers
  },
});

//#region Status

export const teamUserInfoSelectors =
  teamUsersInfoAdapter.getSelectors<RootState>((state) => state.teamUsersInfo);

export const selectTeamUsersInfoSliceElements = (state: any) =>
  state.teamUsersInfo.totalElements;

export const selectTeamUsersInfoSliceStatus = (state: any) =>
  state.teamUsersInfo.status;

export const selectTeamUsersInfoSliceReasonCode = (state: any) =>
  state.teamUsersInfo.reasonCode;

export const {
  usersInfoStatusAndReasonCodeEmptyState,
  usersInfoEmptyState,
  setNumberOfElements,
} = teamUsersInfoSlice.actions;
//#endregion Status

export default teamUsersInfoSlice.reducer;
