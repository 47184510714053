import { t } from "i18next";
import { TwentyIcCloseSmall } from "../../ui/components/icons/TwentyIcCloseSmall";
import { useEffect } from "react";
import { store } from "../../app/store";
import { Wallet, getWalletAsync, walletSelector } from "../Wallet/WalletSlice";
import { useAppSelector } from "../../app/hooks";
import "./OfficeCoworkingPurchaseModal.css";
import { TwentyIcCreditCoins } from "../../ui/components/TwentyIcCreditCoins";
import { Button, Space } from "antd";
import { TwentyIcTik } from "../../ui/components/icons/TwentyIcTik";
import { TwentyIcArrowRight } from "../../ui/components/icons/TwentyIcArrowRight";
import { TwentyIcArrowLineRight } from "../../ui/components/icons/TwentyIcArrowLineRight";
import { TwentyIcCreditsCoinsAdd } from "../../ui/components/icons/TwentyIcCreditsCoinsAdd/TwentyIcCreditsCoinsAdd";

export interface OfficeCoworkingPurchaseModal {
  purchasePrice: number;
  modalStatus: boolean;
  creditModalStatus: boolean;
  creditModal: (e: any) => any;
  modalClosed: (e: any) => any;
}

export const OfficeCoworkingPurchaseModal: React.FC<
  OfficeCoworkingPurchaseModal
> = ({
  purchasePrice,
  modalStatus,
  modalClosed,
  creditModalStatus,
  creditModal,
}) => {
  const wallet: Wallet = useAppSelector(walletSelector.selectAll)[0];
  const userType = localStorage.getItem("securityProfile");

  useEffect(() => {
    store.dispatch(getWalletAsync());
  }, []);

  return (
    <>
      <div className="mn-modal-backdrop"></div>
      <div className="smartplan-filtermodal">
        <div className="smartplan-filtermodal-header">
          <div className="smartplan-filtermodal-header-text">Acquista</div>
          <div
            className="smartplan-filtermodal-header-iconclose"
            onClick={() => {
              modalClosed(!modalStatus);
            }}
          >
            <TwentyIcCloseSmall className={undefined}></TwentyIcCloseSmall>
          </div>
        </div>
        <div className="officecoworking-purchase-modal-content">
          <div>
            <div className="recharge-employee-modal-content-firstsection-section-title">
              CREDITI DISPONIBILI
            </div>
            <div className="recharge-employee-modal-content-firstsection-section-title-credits">
              <TwentyIcCreditCoins
                className={undefined}
                color="#36b39e"
                colorC="#36b39e"
              />
              {wallet?.currentCredits}
            </div>
          </div>
          {/*If service can be purchased */}
          {wallet?.currentCredits >= purchasePrice ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div className="officecoworking-purchasetitleprice">
                  Costo del servizio
                </div>
                <div className="officecoworking-purchaseprice">
                  {purchasePrice}
                </div>
              </div>

              <br />
              <div className="officecoworking-filtermodal-content-buttons">
                <Button
                  onClick={() => {
                    modalClosed(!modalStatus);
                  }}
                  style={{
                    borderColor: "transparent",
                    boxShadow: "none",
                    height: "40px",
                  }}
                >
                  <Space>
                    <div className="smartplan-filtermodal-text">
                      {t("smartPlan.common.cancel")}
                    </div>
                  </Space>
                </Button>
                <Button
                  style={{
                    borderColor: "#36B39E",
                    backgroundColor: "#36B39E",
                    boxShadow: "none",
                    height: "40px",
                  }}
                  onClick={() => {}}
                >
                  <Space>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div className="smartplan-filtermodal-text-white">
                        Procedi con l'acquisto
                      </div>
                      <TwentyIcArrowLineRight
                        className={undefined}
                        onClick={undefined}
                      />
                    </div>
                  </Space>
                </Button>
              </div>
            </>
          ) : userType === "ADMIN" ? (
            <>
              <div className="officecoworking-purchasemodal-notenoughtcredits">
                Non hai abbastanza crediti per procedere con l’acquisto. <br />
                Effettua una ricarica per procedere
              </div>
              <Button
                style={{
                  borderColor: "grey",
                  backgroundColor: "transparent",
                  boxShadow: "grey",
                  width: "100%",
                  height: "50px",
                }}
                onClick={() => {
                  modalClosed(!modalStatus);
                  creditModal(!creditModalStatus);
                }}
              >
                <Space>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <TwentyIcCreditsCoinsAdd
                      color={"grey"}
                      colorC={"grey"}
                      className={undefined}
                    />
                    <div className="officecowrking-purchasemodal-text">
                      Effettua una ricarica
                    </div>
                  </div>
                </Space>
              </Button>
            </>
          ) : (
            <div className="officecoworking-purchasemodal-notenoughtcredits">
              Non hai abbastanza crediti per procedere con l’acquisto. <br />
              Richiedi nuovi crediti al tuo Admin.
            </div>
          )}
        </div>
      </div>
    </>
  );
};
