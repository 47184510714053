import { t } from "i18next";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { Footer } from "../../screens/Footer/Footer";
import SmartPlanActivityTailComponent from "../../ui/components/ActivityTail/SmartPlanActivityTail";
import { SmartPlanFilterBar } from "../../ui/components/FilterBar/SmartPlanFilterBar";
import SmartPlanFilterModal from "../../ui/components/Modals/SmartPlanFilterModal";
import MyOrders from "../../ui/components/MyOrders/MyOrders";
import { IconClose } from "../../ui/components/icons/IconClose";
import { IconTik } from "../../ui/components/icons/IconTik";
import { TwentyIcEverywhere2 } from "../../ui/components/icons/TwentyIcEverywhere2";
import { getQueryString } from "../../utils/utils";
import {
  MyOrder,
  getAllOrdersAsync,
  myOrdersElementOrderedEmptyState,
  myOrdersSelector,
  selectMyOrdersSliceElementOrdered,
} from "../myorders/MyOrdersSlice";
import {
  SmartDatePlan,
  getAllSmartDatePlansAsync,
  smartDatePlanSelector,
} from "./SmartDatePlan/SmartDatePlanSlice";
import "./SmartPlan.css";
import {
  SmartPlanActivityTail,
  getSmartPlanActivityTailAsync,
  smartPlanActivityTailSelector,
} from "./SmartPlanActivityTailSlice";
import { TypeOfLandformValues } from "./SmartPlanSlice";

export interface QueryParams {
  [paramName: string]: any;
}

export const SmartPlan = () => {
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;
  const [switchSelected, setSwitchSelected] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);
  const [refreshed, setRefreshed] = useState(false);
  const [filters, setFilters] = useState(new Map());
  const [modalParams, setQueryParams] = useState<QueryParams>({});
  const elementsLimit = 3;
  const activityTails: SmartPlanActivityTail[] = useAppSelector(
    smartPlanActivityTailSelector.selectAll
  );
  const [activityTailsByCategories, setActivityTailsByCategories] = useState<{
    [key: string]: SmartPlanActivityTail[];
  }>();
  const [activityTailsCategories, setActivityTailsCategories] = useState<
    string[]
  >([]);

  let refresh = false;

  const myOrders: MyOrder[] = useAppSelector(myOrdersSelector.selectAll);
  const [elementOrderData, setElementOrderData] =
    useState<SmartPlanActivityTail>();

  const [stringifiedQueryParams, setStringifiedQueryParams] = useState("");
  const [prevStringifiedQueryParams, setPrevStringifiedQueryParams] =
    useState("");

  const smartDatePlan: SmartDatePlan[] = useAppSelector(
    smartDatePlanSelector.selectAll
  );

  let elementOrderedId = useAppSelector(selectMyOrdersSliceElementOrdered);

  const [liked, setLiked] = useState(false);

  useEffect(() => {
    if (elementOrderData) {
      setSwitchSelected(true);
    }
  }, [elementOrderData]);

  useEffect(() => {
    if (!refresh) {
      if (smartDatePlan.length === 0) {
        store.dispatch(getAllSmartDatePlansAsync());
      }
      if (activityTails.length === 0) {
        store.dispatch(getSmartPlanActivityTailAsync({}));
      }
      if (myOrders.length === 0) {
        store.dispatch(getAllOrdersAsync({ queryString: "?type=smartPlan" }));
      }
      refresh = true;
    }
  }, []);

  useEffect(() => {
    switchSelected &&
      store.dispatch(getAllOrdersAsync({ queryString: "?type=smartPlan" }));
  }, [switchSelected]);

  useEffect(() => {
    if (stringifiedQueryParams !== prevStringifiedQueryParams) {
      store.dispatch(
        getSmartPlanActivityTailAsync({ queryString: stringifiedQueryParams })
      );
    }
    setPrevStringifiedQueryParams(stringifiedQueryParams);
  }, [stringifiedQueryParams]);

  useEffect(() => {
    if (elementOrderedId !== 0) {
      let elementOrderedData =
        activityTails.find(
          (el) =>
            el.id ===
            myOrders.find((e) => e.id === elementOrderedId)?.smartPlanId
        ) ?? ({} as SmartPlanActivityTail);
      setElementOrderData(elementOrderedData);
    }
  }, [elementOrderedId]);

  useEffect(() => {
    setIsFiltered(filters.size > 0);
    refreshActivityTail(filters);
  }, [filters, filters.size]);

  const refreshActivityTail = (
    params: Map<string, string[] | string>
  ): void => {
    const newQueryParams = { ...queryParams }; // Clona gli attuali query parameters

    if (params.size > 0) {
      params.forEach((value, key) => {
        // Gestisci i valori nulli o vuoti in modo appropriato
        if (
          value !== null &&
          value !== undefined &&
          (Array.isArray(value) ? value.length > 0 : value !== "")
        ) {
          newQueryParams[key] = value;
        } else {
          delete newQueryParams[key];
        }
      });
    } else {
      // Se la mappa dei parametri è vuota, azzeriamo i parametri esistenti
      Object.keys(newQueryParams).forEach((key) => {
        delete newQueryParams[key];
      });
    }
    setQueryParams(newQueryParams);
    setStringifiedQueryParams(getQueryString(newQueryParams));

    return;
  };

  function getCategory(landform: TypeOfLandformValues) {
    switch (landform) {
      case "MOUNTAIN":
        return t("smartPlan.components.activityTail.MOUNTAIN");
      case "SEA":
        return t("smartPlan.components.activityTail.SEA");
      case "CITY":
        return t("smartPlan.components.activityTail.CITY");
      case "HILL":
        return t("smartPlan.components.activityTail.HILL");
      case "LAKES":
        return t("smartPlan.components.activityTail.LAKES");
      case "PARKS":
        return t("smartPlan.components.activityTail.PARKS");
    }
  }

  function fillMyOrdersResults(): JSX.Element[] {
    var elements = [];
    if (!!myOrders) {
      for (let i = 0; i < myOrders.length; i++) {
        let temp = activityTails.find(
          (el) => el.id === myOrders[i].smartPlanId
        );
        let temp2 = smartDatePlan.find(
          (el) => el.id === myOrders[i].smartPlanDateId
        );
        elements.push(
          <React.Fragment>
            <MyOrders
              id={myOrders[i].id}
              date={myOrders[i].bookingDate}
              price={temp2?.price.toString()}
              deposit={temp2?.deposit.toString()}
              image={temp?.image}
              status={myOrders[i].status.toString()}
              titleOne={temp?.city}
              titleTwo={temp?.region}
              personsNumber={myOrders[i].numberOfPeople}
              smartPlanId={myOrders[i].smartPlanId}
              type={"SmartPlan"}
            />
          </React.Fragment>
        );
      }
      return elements;
    } else {
      return [];
    }
  }

  useEffect(() => {
    const tempActivityTailsByCategoriesMinMax = activityTails.reduce(
      (group: any, activityTail: SmartPlanActivityTail) => {
        const activityTailName = t(
          "smartPlan.components.activityTail." + activityTail.landform
        );
        if (activityTailName) {
          group[activityTailName] = group[activityTailName] ?? [];
          group[activityTailName].push(activityTail);
        }
        return group;
      },
      {}
    );

    setActivityTailsByCategories(tempActivityTailsByCategoriesMinMax);
    setActivityTailsCategories(
      Object.keys(tempActivityTailsByCategoriesMinMax)
    );
  }, [activityTails]);

  function fillActivityTailsResults(): JSX.Element[] {
    var elements = [];
    if (!!activityTails) {
      var filtered = activityTails.filter(
        (x: SmartPlanActivityTail) =>
          x.startDate
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchKey?.toLocaleLowerCase()) ||
          x.endDate
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchKey?.toLocaleLowerCase()) ||
          x.city
            ?.toLocaleLowerCase()
            .includes(searchKey?.toLocaleLowerCase()) ||
          x.region?.toLocaleLowerCase().includes(searchKey?.toLocaleLowerCase())
      );
      for (let i = 0; i < Math.ceil(filtered.length / 3); i++) {
        var children = [];
        var columnCounter = 0;
        for (
          let j = i * elementsLimit;
          j < filtered.length && columnCounter < elementsLimit;
          j++
        ) {
          children.push(
            <React.Fragment>
              <SmartPlanActivityTailComponent
                id={filtered[j].id}
                img={filtered[j].image}
                startDate={filtered[j].startDate}
                endDate={filtered[j].endDate}
                city={filtered[j].city}
                region={filtered[j].region}
                previewDescription={filtered[j].previewDescription}
                minPeople={filtered[j].minPeople}
                maxPeople={filtered[j].maxPeople}
                placesLeft={filtered[j].placesLeft}
                isFavorite={filtered[j].isFavorite}
                arrow={true}
                reactionButton={() => {
                  setLiked(!liked);
                }}
              />
            </React.Fragment>
          );
          columnCounter++;
        }
        children.push(fillEmptyActivityTailsResults(columnCounter));
        elements.push(
          <div className="smartplan-activitytail-container-row">{children}</div>
        );
      }
      return elements;
    }
    return [];
  }

  function fillEmptyActivityTailsResults(counter: number): JSX.Element[] {
    var elements = [];
    for (var i = 0; i < elementsLimit - counter; i++) {
      elements.push(
        <div className="smartplan-activitytail-transparent-container">
          <SmartPlanActivityTailComponent
            id={undefined}
            img={undefined}
            startDate={new Date()}
            endDate={new Date()}
            city={undefined}
            region={undefined}
            previewDescription={undefined}
            minPeople={0}
            maxPeople={0}
            placesLeft={undefined}
            isFavorite={false}
            reactionButton={() => {}}
          />
        </div>
      );
    }
    return elements;
  }

  return (
    <>
      <div className="smartplan-content">
        <div className="frame-16">
          <div className="frame-17">
            <div className="smartplan-topbar">
              <div className="smartplan-topbar-title-iconplace">
                <TwentyIcEverywhere2 className="smartplan-topbar-title-iconplace-icon" />
              </div>
              <div className="smartplan-topbar-title">
                {t("smartPlan.common.smartplan")}
              </div>
            </div>
          </div>
        </div>
        <SmartPlanFilterBar
          switchStatus={switchSelected}
          setModalOpened={(value) => {
            !switchSelected && setModalOpened(value);
          }}
          searched={(e) => {
            setSearchKey(e);
          }}
          setSwitchSelected={(value) => setSwitchSelected(value)}
          filters={filters}
          setFilters={(e) => {
            setFilters(e);
            setRefreshed(!refreshed);
          }}
        />
        {!switchSelected && activityTails.length === 0 && (
          <div className="smartplan-myorderempty-container">
            <div className="smartplan-myorderempty-element">
              <TwentyIcEverywhere2
                dimension={40}
                color="#222729"
                className={"smartplan-myorderempty-icon"}
              ></TwentyIcEverywhere2>
              <div className="smartplan-myorderempty-firsttext">
                {t("smartPlan.noSmartplan")}
              </div>
              <div className="smartplan-myorderempty-secondtext">
                {t("smartPlan.noSmartplanSecondText")}
              </div>
            </div>
          </div>
        )}
        {isFiltered && !switchSelected && <div className="divider" />}
        {!_.isEmpty(elementOrderData) && (
          <div className="smartplan-ordersuccess-container">
            <div className="smartplan-ordersuccess-textcontainer">
              <IconTik className={undefined}></IconTik>
              <div className="smartplan-ordersuccess-text">
                {t("myOrders.smartOrderCompleted").replace(
                  "*smartName",
                  elementOrderData
                    ? elementOrderData.city + " " + elementOrderData.region
                    : ""
                )}
              </div>
            </div>
            <div
              className="smartplan-ordersuccess-icon"
              onClick={() => {
                setElementOrderData(undefined);
                store.dispatch(myOrdersElementOrderedEmptyState());
              }}
            >
              <IconClose
                className={undefined}
                onClick={undefined}
                color={undefined}
              ></IconClose>
            </div>
          </div>
        )}
        {(isFiltered || (switchSelected && myOrders.length > 0)) && (
          <div className="smartplan-activitytail-results-bar">
            <div className="smartplan-activitytail-results-title">
              {(!switchSelected || (switchSelected && myOrders.length > 0)) && (
                <div className="smartplan-activitytail-results-title-one">
                  {t("smartPlan.common.results")}
                </div>
              )}

              {(!switchSelected || (switchSelected && myOrders.length > 0)) && (
                <div className="smartplan-activitytail-results-title-two">
                  (
                  {!switchSelected
                    ? activityTails.filter(
                        (x: SmartPlanActivityTail) =>
                          x.startDate
                            ?.toString()
                            .toLocaleLowerCase()
                            .includes(searchKey?.toLocaleLowerCase()) ||
                          x.endDate
                            ?.toString()
                            .toLocaleLowerCase()
                            .includes(searchKey?.toLocaleLowerCase()) ||
                          x.city
                            ?.toLocaleLowerCase()
                            .includes(searchKey?.toLocaleLowerCase()) ||
                          x.region
                            ?.toLocaleLowerCase()
                            .includes(searchKey?.toLocaleLowerCase())
                      ).length
                    : myOrders.length}
                  )
                </div>
              )}
            </div>
          </div>
        )}
        {!switchSelected ? (
          !isFiltered ? (
            <div className="smartplan-activitytail-container">
              {activityTailsCategories &&
                activityTailsCategories.length > 0 &&
                activityTailsCategories.map((activityTailCategory) => {
                  return (
                    <React.Fragment>
                      {}
                      <div className="smartplan-activitytail-container-title">
                        {activityTailsByCategories &&
                        activityTailsByCategories[activityTailCategory].filter(
                          (x: SmartPlanActivityTail) =>
                            x.startDate
                              ?.toString()
                              .toLocaleLowerCase()
                              .includes(searchKey?.toLocaleLowerCase()) ||
                            x.endDate
                              ?.toString()
                              .toLocaleLowerCase()
                              .includes(searchKey?.toLocaleLowerCase()) ||
                            x.city
                              ?.toLocaleLowerCase()
                              .includes(searchKey?.toLocaleLowerCase()) ||
                            x.region
                              ?.toLocaleLowerCase()
                              .includes(searchKey?.toLocaleLowerCase())
                        ).length
                          ? activityTailCategory
                          : null}
                      </div>
                      <div className="smartplan-activitytail-container-row">
                        {activityTailsByCategories &&
                          !_.isEmpty(activityTailsByCategories) &&
                          activityTailsByCategories[activityTailCategory]
                            .filter(
                              (x: SmartPlanActivityTail) =>
                                x.startDate
                                  ?.toString()
                                  .toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase()) ||
                                x.endDate
                                  ?.toString()
                                  .toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase()) ||
                                x.city
                                  ?.toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase()) ||
                                x.region
                                  ?.toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase())
                            )
                            .filter(
                              (activityTail, index) => index < elementsLimit
                            )
                            .map((activityTail) => {
                              return (
                                <React.Fragment>
                                  <SmartPlanActivityTailComponent
                                    id={activityTail.id}
                                    isFavorite={
                                      activityTail.isFavorite || liked
                                    }
                                    startDate={activityTail.startDate}
                                    endDate={activityTail.endDate}
                                    city={activityTail.city}
                                    region={activityTail.region}
                                    previewDescription={
                                      activityTail.previewDescription
                                    }
                                    minPeople={activityTail.minPeople}
                                    maxPeople={activityTail.maxPeople}
                                    placesLeft={activityTail.placesLeft}
                                    arrow={true}
                                    img={activityTail.image}
                                    // firstTitleIsADate={false}
                                    reactionButton={function () {
                                      setLiked(!liked);
                                    }}
                                  />
                                </React.Fragment>
                              );
                            })}
                        {activityTailsByCategories &&
                          fillEmptyActivityTailsResults(
                            activityTailsByCategories[
                              activityTailCategory
                            ].filter(
                              (x: SmartPlanActivityTail) =>
                                x.startDate
                                  ?.toString()
                                  .toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase()) ||
                                x.endDate
                                  ?.toString()
                                  .toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase()) ||
                                x.city
                                  ?.toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase()) ||
                                x.region
                                  ?.toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase())
                            ).length
                          )}
                        {activityTails
                          .filter(
                            (x: SmartPlanActivityTail) =>
                              x.startDate
                                ?.toString()
                                .toLocaleLowerCase()
                                .includes(searchKey?.toLocaleLowerCase()) ||
                              x.endDate
                                ?.toString()
                                .toLocaleLowerCase()
                                .includes(searchKey?.toLocaleLowerCase()) ||
                              x.city
                                ?.toLocaleLowerCase()
                                .includes(searchKey?.toLocaleLowerCase()) ||
                              x.region
                                ?.toLocaleLowerCase()
                                .includes(searchKey?.toLocaleLowerCase())
                          )
                          .filter(
                            (activityTail) =>
                              getCategory(activityTail.landform) ===
                              activityTailCategory
                          ).length -
                          elementsLimit >
                          0 && (
                          <div
                            className="smartplan-activitytail-container-row-button"
                            onClick={() => {
                              filters.set(
                                "landform",
                                activityTails
                                  .filter(
                                    (activityTail) =>
                                      getCategory(activityTail.landform) ===
                                      activityTailCategory
                                  )
                                  .at(0)?.landform
                              );
                              setRefreshed(!refreshed);
                            }}
                          >
                            +
                            {activityTails.filter(
                              (activityTail) =>
                                getCategory(activityTail.landform) ===
                                activityTailCategory
                            ).length - elementsLimit}
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          ) : (
            fillActivityTailsResults()
          )
        ) : (
          <div className="smartplan-myorder-container">
            {myOrders.length > 0 ? (
              fillMyOrdersResults()
            ) : (
              <div className="smartplan-myorderempty-container">
                <div className="smartplan-myorderempty-element">
                  <TwentyIcEverywhere2
                    dimension={40}
                    color="#222729"
                    className={"smartplan-myorderempty-icon"}
                  ></TwentyIcEverywhere2>
                  <div className="smartplan-myorderempty-firsttext">
                    {t("myOrders.smartOrderEmptyFirstText")}
                  </div>
                  <div className="smartplan-myorderempty-secondtext">
                    {t("myOrders.smartOrderEmptySecondText")}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {modalOpened && !switchSelected && (
          <SmartPlanFilterModal
            setParam={(e) => {
              setFilters(e);
            }}
            modalChange={(value) => {
              setModalOpened(value);
            }}
            isModalOpened={modalOpened}
            queryParams={modalParams}
          ></SmartPlanFilterModal>
        )}
      </div>
      <Footer />
    </>
  );
};
