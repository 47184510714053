export const TwentyIcCreditsCoinsAdd = ({ className, color, colorC }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M9.91085 14C9.35612 14 8.83952 13.9001 8.36107 13.7004C7.88955 13.4933 7.47697 13.2085 7.12333 12.846C6.76969 12.4836 6.49232 12.062 6.29123 11.5811C6.09708 11.0929 6 10.5677 6 10.0055C6 9.43597 6.09708 8.91077 6.29123 8.42996C6.49232 7.94175 6.76969 7.51641 7.12333 7.15395C7.4839 6.79149 7.89995 6.5104 8.37147 6.31068C8.84993 6.10356 9.36305 6 9.91085 6C10.313 6 10.7083 6.06288 11.0966 6.18863C11.4849 6.31438 11.842 6.49191 12.1679 6.72122C12.5007 6.94313 12.7781 7.20943 13 7.52011L11.7935 8.78502C11.5369 8.43736 11.2491 8.17846 10.9302 8.00832C10.6181 7.83819 10.2784 7.75312 9.91085 7.75312C9.61961 7.75312 9.34572 7.8123 9.08915 7.93065C8.83952 8.04161 8.6211 8.19695 8.43388 8.39667C8.24666 8.59639 8.10104 8.8331 7.99703 9.1068C7.89302 9.38049 7.84101 9.68007 7.84101 10.0055C7.84101 10.3236 7.89302 10.6195 7.99703 10.8932C8.10797 11.1595 8.25706 11.3962 8.44428 11.6033C8.63843 11.8031 8.86379 11.9584 9.12036 12.0693C9.38385 12.1803 9.66815 12.2358 9.97325 12.2358C10.3269 12.2358 10.6528 12.1544 10.951 11.9917C11.2561 11.8289 11.53 11.5885 11.7727 11.2705L12.948 12.5021C12.7261 12.8054 12.4522 13.0717 12.1263 13.301C11.8004 13.5229 11.4468 13.6967 11.0654 13.8225C10.684 13.9408 10.2992 14 9.91085 14Z"
          fill={colorC}
        />
        <path
          d="M15.5 12V19M19 15.5L12 15.5"
          stroke={colorC}
          stroke-width="2"
          stroke-linecap="round"
        />
      </g>
    </svg>
  );
};
