/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcCameraAdd = ({ className, color = "black" }) => {
  return (
    <svg
      className={`twenty-ic-camera-add ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M1 5.79045C1 4.68588 1.89543 3.79045 3 3.79045H6.23529L7.82353 2H12.1765L13.7647 3.79045H17C18.1046 3.79045 19 4.68588 19 5.79045V15C19 16.1046 18.1046 17 17 17H3C1.89543 17 1 16.1046 1 15V5.79045Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M10 6V14"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M14 10L6 10"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
