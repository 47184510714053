export class Config {
  // #regin SmartPlan
  static SMART_PLAN_ACTIVITY_TAIL_RESPONSE = "data";

  static PUBLIC_ACCOUNT_RESPONSE = "data";
  static PUBLIC_ACCOUNT_VIEW_RESPONSE = "data";
  static MY_ORDER_RESPONSE = "data";
  static SMART_PLAN_RESPONSE = "data";
  static UPDATE_USER_INFO_RESPONSE = "data";

  // #endRegin SmartPlan

  // #regin Activity

  static ACTIVITY_RESPONSE = "data._embedded.activities";

  // #endRegion Activity

  // #regin Faq

  static FAQ_RESPONSE = "data._embedded.faqs";

  // #endRegion Faq

  // #regin includedServices

  static INCLUDED_SERVICE_RESPONSE = "data._embedded.includedServices";

  // #endRegion FincludedServices

  // #regin smartDatePlan

  static SMART_DATE_PLAN_RESPONSE = "data._embedded.smartPlanDates";

  // #endRegion smartDatePlan

  // #regin gallery

  static GALLERY_RESPONSE = "data._embedded.galleries";

  // #endRegion gallery

  // #regin smartPlanOrder

  static CREATE_ORDER_RESPONSE = "data";

  // #endRegion smartPlanOrder

  //#region USERS
  static USERS_INFO_RESPONSE_PATH = "data";
  //#endregion USERS

  // #regin masterClass

  static MASTER_CLASS_ACTIVITY_TAIL_RESPONSE = "data";
  static MASTER_CLASS_RESPONSE = "data";
  static MASTER_CLASSES_RESPONSE = "data._embedded.masterClasses";

  // #endRegion masterClass
  // #regin virtualCoworking

  static VIRTUAL_COWORKING_RESPONSE = "data";

  // #endRegion virtualCoworking

  // #regin tenant

  static TENANT_RESPONSE = "data";

  // #endRegion tenant

  // #regin userInfo

  static USER_INFO_RESPONSE = "data";

  // #endRegion userInfo

  // #regin changePassword
  static CHANGE_PASSWORD_RESPONSE = "data";
  // #endRegion changePassword

  // #region wellnessPsycho
  static WELLNESS_PSYCHO_DETAILS = "data";
  static CREATE_WELLNESS_PSYCHO_RESPONSE = "data";
  static UPDATE_WELLNESS_PSYCHO_RESPONSE = "data";

  // #endRegion wellnessPsycho
  // #region wellnessPsycho
  static WELLNESS_PSYCHO_ACTIVITY_TAIL_RESPONSE = "data";
  static WELLNESS_PSYCHO_RESPONSE = "data";

  //#region wellnessBody
  static WELLNESS_BODY_ACTIVITY_TAIL_RESPONSE = "data";
  static WELLNESS_BODY_RESPONSE = "data";
  static CREATE_WELLNESS_BODY_RESPONSE = "data";
  static UPDATE_WELLNESS_BODY_RESPONSE = "data";

  // #endRegion wellnessBody

  // #region wellnessNutritional
  static WELLNESS_NUTRITIONAL_RESPONSE = "data";
  static CREATE_WELLNESS_NUTRITIONAL_RESPONSE = "data";
  static UPDATE_WELLNESS_NUTRITIONAL_RESPONSE = "data";
  static WELLNESS_NUTRITIONAL_ACTIVITY_TAIL_RESPONSE = "data";

  // #endRegion wellnessNutritional

  // #region wellnessTable
  static WELLNESS_TABLE_RESPONSE = "data";
  // #endRegion wellnessTable

  // #region wellnessTable
  static MYDIARY_RESPONSE = "data";
  // #endRegion wellnessTable

  // #region wallet
  static WALLET_RESPONSE = "data";
  // #endRegion wallet

  static CREDIT_EMPLOYEE_RECHARGE_RESPONSE = "data";

  // #region meetings
  static MEETING_REPORT_RESPONSE = "data";
  static MEETING_VIEW_RESPONSE = "data";
  // #endRegion meetings

  // #region officeCoworkings
  static OFFICE_COWORKING_RESPONSE = "data";
  static OFFICE_COWORKING_ACTIVITY_TAILS = "data";
  // #endRegion officeCoworkings
}
