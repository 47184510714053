import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import MeetingsRepository from "../MeetingsRepository";
import { Config } from "../../../config/Config";
import _ from "lodash";
import { EverywhereSuccessCodes } from "../../../config/EverywhereSuccessCodes";
import { RootState } from "../../../app/store";

export type MeetingsForReportSlice = {
  id: number;
  totalProfit: number;
  toBeCollected: number;
  lastCollectionDate: Date;
  lastCollectionPrice: number;
  totalInvoices: number;
  profitForMonth: any;
};

//#endRegion Type

//#region API

export const getMeetingsReport = createAsyncThunk(
  "meetingsSlice/getMeetingsReport",
  async (year: number) => {
    const mettingsRepository = new MeetingsRepository();
    const response = await mettingsRepository.getMeetingsReport(year);
    const meetingsReport = _.get(response, Config.MEETING_REPORT_RESPONSE);
    return meetingsReport;
  }
);

//#endRegion API

//#region Slice

const meetingsReportAdapter = createEntityAdapter<MeetingsForReportSlice>({
  selectId: (mettingReport) => mettingReport.id,
});

export const meetingsReportSlice = createSlice({
  name: "userInfo",
  initialState: meetingsReportAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    meetingsReportEmptyState: (state) => {
      meetingsReportAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getMeetingsReport.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          meetingsReportAdapter.upsertOne(state, action.payload);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getMeetingsReport.pending, (state: any) => {
        state.statys = "loading";
        state.reasonCode = "";
      })
      .addCase(getMeetingsReport.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = "";
      });
  },
});

//#endRegion Slice

//#region Status

export const meetingsReportSelector =
  meetingsReportAdapter.getSelectors<RootState>(
    (state) => state.meetingsReport
  );

export const { meetingsReportEmptyState } = meetingsReportSlice.actions;
export const selectMeetingsReportSliceStatus = (state: any) =>
  state.meetingsReport.status;
export const selectMeetingsReportSliceReasonCode = (state: any) =>
  state.meetingsReport.reasonCode;

//#endRegion Status

export default meetingsReportSlice.reducer;
