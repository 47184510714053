/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcStatLine = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-stat-line ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M2 14L7.02857 9.73333L13.8857 11.3333L18 6"
        stroke={color}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
