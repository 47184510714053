import { toast, TypeOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import iconWarning from "../../../img/IconWarning.svg";
import iconSuccessfully from "../../../img/IconSuccessfully.svg";
// import { IconAlert } from "../ui/Icon/Line/Alert";
// import { IconSuccess } from "../ui/Icon/Solid/Success";

/**
 * This method is used to check which Toast Notification
 * to choose based on the status.
 */

interface ToastNotificationProps {
  status: TypeOptions;
  description: string;
  toastId?: string;
  title?: string;
  onClose?: (e: any) => any;
}

export const ToastNotification = ({
  status,
  description,
  toastId,
  title,
  onClose,
}: ToastNotificationProps) => {
  const options = {
    toastId: toastId ?? undefined,
    position: toast.POSITION.BOTTOM_RIGHT,
    closeButton: true,
    style: { fontSize: "12px" },
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    type: status,
    icon:
      status === "success" ? (
        <img
          className="rectangle-3"
          alt="Rectangle"
          src={iconSuccessfully}
          style={{ zIndex: 1000 }}
        />
      ) : status === "error" ? (
        <img
          className="rectangle-3"
          alt="Rectangle"
          src={iconWarning}
          style={{ zIndex: 1000 }}
        />
      ) : (
        ""
      ),
    onClose: onClose,
  };

  toast(
    <>
      {title && (
        <div className="toast-title">
          <span>{title}</span>
        </div>
      )}
      <div className="toast-description">
        <span>{description}</span>
      </div>
    </>,
    options
  );
};
