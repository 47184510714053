import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import WellnessRepository from "../WellnessRepository";
import { Config } from "../../../config/Config";
import _ from "lodash";
import { EverywhereErrorCodes } from "../../../config/EverywhereErrorCodes";
import { EverywhereSuccessCodes } from "../../../config/EverywhereSuccessCodes";
import { RootState } from "../../../app/store";

export type ServiceViewSlice = {
  id: number;
  name: string;
  price: number;
};

export const getServicesByProviderAsync = createAsyncThunk(
  "servicesByProvider/getServicesByProvider",
  async (data: { tenantId: number; userId: number; providerType?: string }) => {
    const wellnessRepository = new WellnessRepository();
    const response = await wellnessRepository.getServicesByProvider(
      data.tenantId,
      data.userId,
      data.providerType
    );
    const servicesByProvider = _.get(response, Config.WELLNESS_TABLE_RESPONSE);

    if (servicesByProvider) {
      return servicesByProvider;
    } else {
      return [];
    }
  }
);

const wellnessServicesViewAdapter = createEntityAdapter<ServiceViewSlice>({
  selectId: (service) => service.id,
});

export const wellnessServicesView = createSlice({
  name: "wellnessServicesView",
  initialState: wellnessServicesViewAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    wellnessServicesViewEmptyState: (state) => {
      wellnessServicesViewAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getServicesByProviderAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          wellnessServicesViewAdapter.setAll(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getServicesByProviderAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getServicesByProviderAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

export const wellnessServicesViewSelector =
  wellnessServicesViewAdapter.getSelectors<RootState>(
    (state) => state.wellnessServicesView
  );

export const { wellnessServicesViewEmptyState } = wellnessServicesView.actions;
export const wellnessServicesViewSliceReasonCode = (state: any) =>
  state.wellnessServicesView.reasonCode;

export const wellnessServicesViewSliceStatus = (state: any) =>
  state?.wellnessServicesView?.status;

export default wellnessServicesView.reducer;
