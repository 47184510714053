import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./Modal.css";
import { IconClose } from "../icons/IconClose";

export const ModalWrapper = ({
  open,
  closeAction,
  children,
  leftColumn,
  rightColumn,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const closeModal = () => {
    if (closeAction) {
      closeAction();
    }
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <>
      {isOpen && (
        <>
          <div className="mn-modal">
            <div className="mn-modal__dialog" {...props}>
              <header className="mn-modal__dialog-header">
                <div className="mn-modal__dialog-close">
                  <IconClose
                    size={14}
                    color={
                      rightColumn || leftColumn
                        ? "--global-colors-ui-white"
                        : "--global-colors-ink-light"
                    }
                    onClick={closeModal}
                  />
                </div>
              </header>
              {!children ? (
                <div className="mn-modal__columns">
                  {leftColumn && (
                    <div className="mn-modal__left-column">{leftColumn}</div>
                  )}
                  {rightColumn && (
                    <div className="mn-modal__right-column">{rightColumn}</div>
                  )}
                </div>
              ) : (
                children
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

ModalWrapper.propTypes = {
  open: PropTypes.bool,
  closeAction: PropTypes.func,
};
