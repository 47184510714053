import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { ImageConfig } from "../../../config/ImageConfig";
import HeaderMenu from "../../../layout/HeaderMenu";
import { Footer } from "../../../screens/Footer/Footer";
import { AssistanceBoxComponent } from "../../../ui/components/AssistanceBoxComponent";

import { Descriptions } from "../../../ui/components/Descriptions";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";

import { usePreviewContext } from "../../../Context/PrewiewContext";
import { PublicAccountSummaryComponent } from "../../../ui/components/PublicAccountSummaryComponent";
import { TwentyIcTik } from "../../../ui/components/icons/TwentyIcTik";
import { useAppSelector } from "../../../app/hooks";
import {
  PublicAccount,
  publicAccountSelector,
} from "../PublicAccount/PublicAccountSlice";

export const PreviewAccountDetails = () => {
  const firstName = localStorage.getItem("firstName");

  const lastName = localStorage.getItem("lastName");

  const businessName = localStorage.getItem("businessName");

  const savedImageData = localStorage.getItem("imagePublicAccountPreview");

  const publicAccountList: PublicAccount[] = useAppSelector(
    publicAccountSelector.selectAll
  );

  const navigate = useNavigate();

  const { value: publicAccountPreview } = usePreviewContext();

  const generateFullName = () => {
    let name = "";
    if (
      publicAccountPreview?.firstName &&
      publicAccountPreview?.firstName !== undefined &&
      publicAccountPreview?.lastName &&
      publicAccountPreview?.lastName !== undefined
    ) {
      name =
        publicAccountPreview.firstName + " " + publicAccountPreview.lastName;
    } else if (
      publicAccountPreview?.firstName &&
      publicAccountPreview?.firstName !== undefined
    ) {
      name = publicAccountPreview.firstName;
    } else if (
      publicAccountPreview?.lastName &&
      publicAccountPreview?.lastName !== undefined
    ) {
      name = publicAccountPreview?.lastName;
    }

    return name;
  };

  return (
    <>
      <HeaderMenu>
        <TopBar
          title={generateFullName()}
          hasEdit={false}
          hasDate={false}
          breadcrumbText={
            "Account" +
            " > " +
            " Profilo Pubblico" +
            " > " +
            "Anteprima Profilo Pubblico"
          }
          icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
          onClick={() => navigate(-1)}
        />
      </HeaderMenu>

      <div className="wellnessbodydetail-container">
        <div className="wellness-details-image-container-provider">
          <img
            className="rectangle-8"
            alt=""
            src={ImageConfig.IMAGE_DEFAULT_SERVICE}
          />
          <img
            className="wellness-image2-user-provider-nutrition"
            alt=""
            src={
              savedImageData !== null && savedImageData !== ""
                ? savedImageData
                : publicAccountList[0]?.personalPhotography ?? ""
            }
          />
        </div>

        <div className="overlap-2">
          <div className="wellnessbodydetail-bady-page">
            <div className="service-info-title">Informazioni</div>

            <div className="service-container-public-account">
              {publicAccountPreview &&
                publicAccountPreview.providerType !== 2 && (
                  <>
                    <div className="service-wrapper-public-account">
                      <div className="service-title">
                        Campi di specializzazione
                      </div>
                      <div className="service-subtitle" style={{ maxWidth: "200px" }}>
                        {publicAccountPreview?.typologies?.map(
                          (typology: string, index: number) => (
                            <div
                              style={{
                                display: "flex",
                                padding: "5px",
                                paddingLeft: "0",
                              }}
                              key={index}
                            >
                              <div>
                                <TwentyIcTik
                                  size={20}
                                  className={undefined}
                                  color="#36B39E"
                                />
                              </div>
                              <div>{typology}</div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </>
                )}
              <div className="service-wrapper-public-account">
                <div className="service-title">Anni di esperienza</div>

                <div
                  className="service-subtitle"
                  style={{ padding: "5px 5px 5px 0px" }}
                >
                  {publicAccountPreview?.yearsOfExperience
                    ? publicAccountPreview?.yearsOfExperience + " Anni"
                    : ""}
                </div>
              </div>

              <div className="service-wrapper-public-account">
                <div className="service-title">Formazione e Competenze</div>

                <div
                  className="service-subtitle"
                  style={{ padding: "5px 5px 5px 0px" }}
                >
                  {publicAccountPreview?.trainingAndCompetition}
                </div>
              </div>
            </div>

            <div className="text-wrapper-21-description">
              {t("smartPlanDetail.description.description")}
            </div>

            <Descriptions
              initialText={
                publicAccountPreview?.profileDescription
                  ? publicAccountPreview?.profileDescription
                  : ""
              }
              maxLength={300}
            />
          </div>{" "}
          <div className="container-summary">
            <div className="summary-wrapper">
            <PublicAccountSummaryComponent
              email={publicAccountPreview?.email}
              telephone={publicAccountPreview?.telephone}
            />

            <AssistanceBoxComponent
              assistance={t("smartPlanDetail.componentAssistantBox.assistance")}
              whatsApp={t("smartPlanDetail.componentAssistantBox.whatsApp")}
              date={t("smartPlanDetail.componentAssistantBox.date")}
              whatsAppNumber={3773604135}
              whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."

                .replace("firstName*", firstName ?? "")

                .replace("lastName*", lastName ?? "")

                .replace("companyName*", businessName ?? "")}
              disabled={undefined}
            />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
