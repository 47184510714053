import { Assistenza1 } from "../Assistenza1";
import { Button } from "../Button/Button";
import { IconCalendario } from "../icons/IconCalendario";
import { TwentyIcWhatsapp } from "../icons/TwentyIcWhatsapp";
import "./AssistanceBoxComponent.css";
import { useEffect, useState } from "react";

export const AssistanceBoxComponent = ({
  assistance,
  whatsApp,
  date,
  whatsAppNumber,
  whatsAppMessageText,
  disabled,
}) => {
  const [disabledButton, setDisabledButton] = useState();

  useEffect(() => {
    setDisabledButton(disabled);
  }, [disabled]);
  return (
    <>
      <div className="frame-26">
        <p className="text-wrapper-18">{assistance}</p>
        <div className="frame-27">
          <div className="frame-28">
            <div className="text-wrapper-19"></div>
          </div>
          <div className="button-whatsApp">
            <Button
              type="line"
              size="XL"
              state="default"
              onClick={() => {
                // Appending the phone number to the URL
                let url = `https://web.whatsapp.com/send?phone=${whatsAppNumber}`;

                // Appending the message to the URL by encoding it
                url += `&text=${encodeURI(whatsAppMessageText)}&app_absent=0`;
                // Open our newly created URL in a new tab to send the message
                window.open(url);
              }}
              text={whatsApp}
              disabled={disabledButton}
              iconRight={undefined}
              icon={<TwentyIcWhatsapp />}
            />
          </div>
        </div>
        <div className="frame-29">
          <IconCalendario className="icon-instance-node-3" />
          <p className="text-wrapper-20">{date}</p>
        </div>
      </div>
    </>
  );
};
