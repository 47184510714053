import React from "react";

export const TwentyIcClose = ({ className, color }) => {
  return (
    <svg
      className={`twenty-ic-close ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 20 21"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" opacity="0.5">
        <path
          className="path"
          d="M16 16.5L4 4.5"
          stroke={color}
          strokeWidth="2"
        />
        <path
          className="path"
          d="M4 16.5L16 4.5"
          stroke={color}
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
