import React, { useEffect, useState } from "react";
import "../UsersTable/UsersTable.css";
import { TeamUserInfo } from "../../../feature/MyTeam/TeamUsersInfoSlice";
import { IconRemove } from "../icons/IconRemove";
import User from "../User/User";
import { TwentyIcCreditCoins } from "../TwentyIcCreditCoins";
import { InputNumber, Tooltip } from "antd";
import { UserSendMapWrapper } from "../../../feature/CreditManagement/CreditHistorySlice";
import { Wallet, walletSelector } from "../../../feature/Wallet/WalletSlice";
import { useAppSelector } from "../../../app/hooks";
import { areArraysEqual } from "../../../utils/utils";

interface UsersTableProps {
  remove: (e: any) => any;
  users: TeamUserInfo[];
  inputNumberDisabled: boolean;
  usersToRecharge: (e: any) => any;
  summary: boolean;
  summaryCompleted: UserSendMapWrapper[];
  staticPrice?: number;
  creditThreeShold?: number;
}

export const UsersTable: React.FC<UsersTableProps> = ({
  users,
  remove,
  inputNumberDisabled,
  usersToRecharge,
  summary,
  summaryCompleted,
  staticPrice,
  creditThreeShold,
}) => {
  const [userSendMapArray, setUserSendMapArray] = useState<
    UserSendMapWrapper[]
  >([]);
  const wallets: Wallet[] = useAppSelector(walletSelector.selectAll);

  const addUserSendMap = (user: any, balance: any) => {
    const newUserSendMap: UserSendMapWrapper = {
      userId: user,
      balance: balance,
    };

    const existingIndex = userSendMapArray.findIndex(
      (item) => item.userId === newUserSendMap.userId
    );

    if (existingIndex !== -1) {
      const updatedUserSendMapArray = [...userSendMapArray];
      updatedUserSendMapArray[existingIndex] = newUserSendMap;
      setUserSendMapArray(updatedUserSendMapArray);
    } else {
      setUserSendMapArray([...userSendMapArray, newUserSendMap]);
    }
  };

  useEffect(() => {
    summaryCompleted.length > 1 && setUserSendMapArray(summaryCompleted);
  }, [summaryCompleted]);

  useEffect(() => {
    usersToRecharge(userSendMapArray);
  }, [userSendMapArray]);

  useEffect(() => {
    if (!summary) {
      let tempList = [];
      for (let i = 0; i < users.length; i++) {
        const newUserSendMap: UserSendMapWrapper = {
          userId: users[i].id,
          balance:
            staticPrice !== undefined && staticPrice > 0 && inputNumberDisabled
              ? staticPrice
              : creditThreeShold ?? 0,
        };
        tempList.push(newUserSendMap);
      }
      setUserSendMapArray(tempList);
    } else {
      setUserSendMapArray(summaryCompleted);
    }
  }, []);

  return (
    <>
      <div className="userstable-container">
        <div className="userstable-header">
          <div
            className="userstable-header-text"
            style={{ marginLeft: "20px" }}
          >
            UTENTI{"  (" + users?.length + ")"}
          </div>
          <div
            className="userstable-header-text"
            style={{ marginRight: "20px" }}
          >
            CREDITI
          </div>
        </div>
        <div className="userstable-content">
          {users?.map((user: TeamUserInfo) => {
            const words = user.fullName.split(" ");
            const firstWord = words[0] || "";
            const secondWord = words[1] || "";
            const initials = `${firstWord.charAt(0)}${secondWord.charAt(0)}`;
            return (
              <>
                <div className="userstable-content-cards">
                  <div className="userstable-content-card">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {!summary && (
                        <Tooltip title="Elimina">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              remove(user.id);
                            }}
                          >
                            <IconRemove
                              className={undefined}
                              trigger={() => {}}
                            />
                          </div>
                        </Tooltip>
                      )}
                      <User name={initials} round={true} size="m" />
                      <div className="userstable-content-card-user">
                        <div className="userstable-content-card-user-row">
                          <div className="userstable-content-card-user-row-text-primary">
                            {user.fullName}
                          </div>
                          {!summary && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <TwentyIcCreditCoins
                                color="#222729"
                                colorC="#222729"
                                className={undefined}
                              />
                              <div className="userstable-content-card-user-creditsavabile">
                                {
                                  wallets.find(
                                    (e: Wallet) => e.userId === user.id
                                  )?.currentCredits
                                }
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="userstable-content-card-user-row">
                          <div className="userstable-content-card-user-row-text-secondary">
                            {user.email}
                          </div>
                        </div>
                      </div>
                    </div>

                    {!summary ? (
                      <InputNumber
                        value={
                          summaryCompleted.find((e) => e.userId === user.id)
                            ?.balance
                        }
                        onChange={(value) => {
                          if (
                            value !== null &&
                            creditThreeShold !== undefined &&
                            value >= creditThreeShold
                          ) {
                            addUserSendMap(user.id, value);
                          }
                        }}
                        prefix={
                          <>
                            <TwentyIcCreditCoins
                              className={undefined}
                              color="grey"
                              colorC="grey"
                            />
                          </>
                        }
                        disabled={inputNumberDisabled}
                      />
                    ) : (
                      <div className="userstable-content-textbalance">
                        {
                          summaryCompleted.find((u) => u.userId === user.id)
                            ?.balance
                        }
                      </div>
                    )}
                  </div>
                </div>
                <div className="divider" />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
