import React, { FunctionComponent, ScriptHTMLAttributes } from "react";
import "./HSChat.css";

const w: any = window;

// eslint-disable-next-line no-underscore-dangle, no-multi-assign
const _hsq = (w._hsq = w._hsq || []);

const HubSpotElementId = "hs-script-loader";

const createScriptNode = (
  props: ScriptHTMLAttributes<HTMLScriptElement> = {}
): HTMLElement => {
  const scriptNode: HTMLScriptElement = document.createElement("script");
  scriptNode.type = "text/javascript";
  Object.keys(props).forEach((key) => {
    const keyName = key;
    // TODO: fix to proper typing
    (scriptNode as any)[keyName] = (props as any)[keyName];
  });
  return scriptNode;
};

const closeChatFunction = () => {
  if (w.HubSpotConversations && w.HubSpotConversations.widget) {
    w.HubSpotConversations.widget.close();
  }
};

const HubSpot = {
  execute: (action: () => void) => {
    // If external API methods are already available, use them.
    if (w.HubSpotConversations) {
      action();
      return;
    }
    /*
      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
      These callbacks will be called once the external API has been initialized.
    */
    w.hsConversationsOnReady = w.hsConversationsOnReady || [];
    w.hsConversationsOnReady.push(action);
  },
  // On logout clear cookies so we can work on different chatflows
  resetWidget: () => {
    const status = w.HubSpotConversations?.widget.status();
    if (status?.loaded) {
      w.removeEventListener("click", closeChatFunction);
      w.HubSpotConversations.clear({ resetWidget: true });
      w.hsConversationsOnReady = [];
    }
  },
  loadWidget: () => {
    const status = w.HubSpotConversations?.widget.status();
    if (!status?.pending && !status?.loaded) {
      w.HubSpotConversations.widget.load();
      w.addEventListener("click", closeChatFunction);
    }
  },
  setUserIdentity: ({
    email,
    accountId,
  }: {
    email: string;
    accountId: string;
  }) => {
    // Identify current user on chat
    _hsq.push([
      "identify",
      {
        email,
        accountId,
      },
    ]);
  },
};

type HubSpotWidgetProps = {
  email?: string;
  token: string;
  accountId?: string;
};

const HubSpotWidget: FunctionComponent<HubSpotWidgetProps> = ({
  email,
  token,
  accountId,
}) => {
  // sleep time expects milliseconds
  const sleep = (time: number) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const onConversationsAPIReady = () => {
    if (email && email !== "" && accountId) {
      HubSpot.setUserIdentity({ email, accountId });
      // wait 2 secs to let user identity be properly set!
      sleep(2000).then(() => {
        if (
          email &&
          email !== "" &&
          accountId &&
          localStorage.getItem("jwtToken") != null
        )
          HubSpot.loadWidget();
      });
    }
  };

  React.useEffect(() => {
    if (email && email !== "" && token && token !== "") {
      w.hsConversationsSettings = {
        loadImmediately: false,
        identificationEmail: email,
        identificationToken: token,
      };
      const script = document.getElementById(HubSpotElementId);

      if (!script) {
        const scriptNode = createScriptNode({
          src: `//js-eu1.hs-scripts.com/${accountId}.js`,
          id: HubSpotElementId,
          async: false,
          defer: true,
        });
        document.body.appendChild(scriptNode);
      }
    }
    // Load Widget when ready
    HubSpot.execute(onConversationsAPIReady);

    return function cleanUp() {
      // rivedere questi metodi per il corretto refresh del widget
      // w.HubSpotConversations?.widget.remove();
      // w.HubSpotConversations?.widget.refresh();
      HubSpot.resetWidget();
      const script = document.getElementById(HubSpotElementId);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [email]);

  return null;
};

export { HubSpotWidget };
