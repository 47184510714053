import { t } from "i18next";
import { Logo } from "../../ui/components/Logo/Logo";
import { IconDoc } from "../../ui/components/icons/IconDoc";
import { IconTik } from "../../ui/components/icons/IconTik";
import { TwentyIcArrowLineLeft } from "../../ui/components/icons/TwentyIcArrowLineLeft";
import { useState, useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import {
  MyOrderDetail,
  myOrderDetailSelector,
  getOrderAsync,
} from "../myorders/MyOrderDetailSlice";
import { MasterClassDetail } from "../masterclass/MasterClassSlice";
import MasterClassActivityTailComponent from "../../ui/components/ActivityTail/MasterClassActivityTail";

export const MasterclassPaymentSuccess = () => {
  const masterclassRoute = useMatch(
    "masterclass/:masterclassId/success/:bookingId"
  );

  const navigate = useNavigate();

  const [id, setId] = useState<number>(-1);
  const [masterclass, setMasterclass] = useState<MasterClassDetail>();

  let myOrderDetail: MyOrderDetail = useAppSelector(
    (state) =>
      myOrderDetailSelector.selectById(state, id) ?? ({} as MyOrderDetail)
  );

  useEffect(() => {
    if (myOrderDetail.masterClass) {
      setMasterclass(myOrderDetail.masterClass);
    }
  }, [myOrderDetail]);

  useEffect(() => {
    if (masterclassRoute != null) {
      if (masterclassRoute.params.bookingId) {
        setId(parseInt(masterclassRoute.params.bookingId));
        store.dispatch(
          getOrderAsync(parseInt(masterclassRoute.params.bookingId))
        );
        return;
      }
    }
  }, [masterclassRoute]);

  function formatTitle(
    title: string | undefined,
    maxLength: number = 58
  ): string {
    return title && title.length > maxLength
      ? title.slice(0, maxLength - " ...".length) + "..."
      : title || "";
  }

  return (
    <>
      <div className="paymentsuccess-wrapper">
        <div className="payment-everywhere-logo">
          <Logo width="186px" height="28px" />
        </div>
        <div className="paymentsuccess-content">
          <div className="paymentsuccess-frame">
            <div className="paymentsuccess-frame-firstsection">
              <div className="paymentsuccess-frame-firstsection-iconcontainer">
                <IconTik size={40} className={undefined}></IconTik>
              </div>
              <div className="paymentsuccess-frame-firstsection-text">
                <div className="paymentsuccess-frame-firstsection-firsttext">
                  {t("payments.success.firstText")}
                </div>
                <div className="paymentsuccess-frame-firstsection-subtitle">
                  {t("payments.success.thankYou")}
                </div>
              </div>
            </div>
            <div className="paymentsuccess-frame-secondsection">
              {masterclass && (
                <MasterClassActivityTailComponent
                  id={masterclass.id}
                  city={masterclass.city}
                  image={masterclass.image}
                  startDate={masterclass.startDate}
                  endDate={masterclass.endDate}
                  placesLeft={masterclass.placesLeft}
                  title={formatTitle(masterclass.title)}
                />
              )}
            </div>
            <div className="paymentsuccess-frame-thirdsection">
              <div
                className="paymentsuccess-frame-thirdsection-secondbutton"
                onClick={() => {
                  navigate("/masterclass/myOrders/" + id);
                }}
              >
                <IconDoc className={undefined}></IconDoc>
                <div className="paymentfailed-buttontext">
                  {t("payments.success.buttonOrder")}
                </div>
              </div>
              <div
                className="paymentsuccess-frame-thirdsection-thirdbutton"
                onClick={() => {
                  navigate("/masterclass");
                }}
              >
                <TwentyIcArrowLineLeft
                  className={undefined}
                ></TwentyIcArrowLineLeft>
                <div className="paymentfailed-buttontext">
                  {t("payments.success.buttonServices")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MasterclassPaymentSuccess;
