import React from "react";
import "../Vector/Vector.css";
import vector from "../../../../img/Vector-17.svg";

export const Vector = () => {
  return (
    <div className="box">
      <img className="vector" alt="Vector" src={vector} />
    </div>
  );
};
