import { useLocation } from "react-router-dom";
import MasterclassPaymentSuccess from "./MasterclassPaymentSuccess";
import "./PaymentSuccess.css";
import SmartplanPaymentSuccess from "./SmartplanPaymentSuccess";
import WellnessBodyPaymentSuccess from "./WellnessBodyPaymentSuccess";
import WellnessNutritionalPaymentSuccess from "./WellnessNutritionalPaymentSuccess";
import WellnessPsychoPaymentSuccess from "./WellnessPsychoPaymentSuccess";

export const PaymentSuccess = () => {
  const urlLocation = useLocation();
  const { pathname } = urlLocation;

  const isSmartPlanSuccess =
    pathname.includes("/smartplan/") && pathname.includes("/success");
  const isMasterClassSuccess =
    pathname.includes("/masterclass/") && pathname.includes("/success");
  const isWellnessBodySuccess =
    pathname.toLocaleLowerCase().includes("/wellness/body/") &&
    pathname.includes("success");
  const isWellnessNutritionalSuccess =
    pathname.toLocaleLowerCase().includes("/wellness/nutritional/") &&
    pathname.includes("/success");
  const isWellnessPsychoSuccess =
    pathname.toLocaleLowerCase().includes("/wellness/psycho/") &&
    pathname.includes("/success");

  return (
    <>
      {isSmartPlanSuccess && <SmartplanPaymentSuccess />}
      {isMasterClassSuccess && <MasterclassPaymentSuccess />}
      {isWellnessBodySuccess && <WellnessBodyPaymentSuccess />}
      {isWellnessNutritionalSuccess && <WellnessNutritionalPaymentSuccess />}
      {isWellnessPsychoSuccess && <WellnessPsychoPaymentSuccess />}
    </>
  );
};

export default PaymentSuccess;
