import { t } from "i18next";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { Footer } from "../../screens/Footer/Footer";
import MasterClassActivityTailComponent from "../../ui/components/ActivityTail/MasterClassActivityTail";
import { MasterClassFilterBar } from "../../ui/components/FilterBar/MasterClassFilterBar";
import MyOrders from "../../ui/components/MyOrders/MyOrders";
import { IconClose } from "../../ui/components/icons/IconClose";
import { IconTik } from "../../ui/components/icons/IconTik";
import { TwentyIcLesson } from "../../ui/components/icons/TwentyIcLesson";
import {
  MyOrder,
  getAllOrdersAsync,
  myOrdersElementOrderedEmptyState,
  myOrdersSelector,
  selectMyOrdersSliceElementOrdered,
} from "../myorders/MyOrdersSlice";
import "./MasterClass.css";
import {
  MasterClassActivityTail,
  getMasterClassActivityTailAsync,
  masterClassActivityTailSelector,
} from "./MasterClassActivityTailSlice";

export const MasterClass = () => {
  const [switchSelected, setSwitchSelected] = useState(false);
  const elementsLimit = 3;
  const [liked, setLiked] = useState(false);
  const masterClasses: MasterClassActivityTail[] = useAppSelector(
    masterClassActivityTailSelector.selectAll
  );
  let elementOrderedId = useAppSelector(selectMyOrdersSliceElementOrdered);
  const myOrders: MyOrder[] = useAppSelector(myOrdersSelector.selectAll);
  const [elementOrderData, setElementOrderData] =
    useState<MasterClassActivityTail>();

  let refresh = false;

  useEffect(() => {
    if (!refresh) {
      if (masterClasses.length === 0) {
        store.dispatch(getMasterClassActivityTailAsync({}));
      }
      if (myOrders.length === 0) {
        store.dispatch(getAllOrdersAsync({ queryString: "?type=masterClass" }));
      }
      refresh = true;
    }
  }, []);

  useEffect(() => {
    if (elementOrderData) {
      setSwitchSelected(true);
    }
  }, [elementOrderData]);

  useEffect(() => {
    if (elementOrderedId !== 0) {
      let elementOrderedData =
        masterClasses.find(
          (el) =>
            el.id ===
            myOrders.find((e) => e.id === elementOrderedId)?.masterClassId
        ) ?? ({} as MasterClassActivityTail);
      setElementOrderData(elementOrderedData);
    }
  }, [elementOrderedId]);

  useEffect(() => {
    switchSelected &&
      store.dispatch(getAllOrdersAsync({ queryString: "?type=masterClass" }));
  }, [switchSelected]);

  function fillEmptyActivityTailsResults(counter: number): JSX.Element[] {
    var elements = [];
    for (var i = 0; i < elementsLimit - counter; i++) {
      elements.push(
        <div className="masterclass-activitytail-transparent-container">
          <MasterClassActivityTailComponent
            id={undefined}
            placesLeft={undefined}
            startDate={new Date()}
            endDate={new Date()}
            city={undefined}
            image={undefined}
            title={undefined}
            isFavorite={false}
            reactionButton={() => {}}
          />
        </div>
      );
    }
    return elements;
  }

  function fillMyOrdersResults(): JSX.Element[] {
    var elements = [];
    if (!!myOrders) {
      for (let i = 0; i < myOrders.length; i++) {
        let temp = masterClasses.find(
          (el) => el.id === myOrders[i].masterClassId
        );
        elements.push(
          <React.Fragment>
            <MyOrders
              id={myOrders[i].id}
              date={myOrders[i].bookingDate}
              price={temp?.price?.toString()}
              image={temp?.image}
              status={myOrders[i].status.toString()}
              titleOne={temp?.title}
              titleTwo={""}
              personsNumber={myOrders[i].numberOfPeople}
              masterClassId={myOrders[i].masterClassId}
              type={"MasterClass"}
              deposit={temp?.deposit?.toString()}
            />
          </React.Fragment>
        );
      }
      return elements;
    } else {
      return [];
    }
  }

  function fillActivityTailsResults(): JSX.Element[] {
    var elements = [];
    if (!!masterClasses) {
      for (let i = 0; i < Math.ceil(masterClasses.length / 3); i++) {
        var children = [];
        var columnCounter = 0;
        for (
          let j = i * elementsLimit;
          j < masterClasses.length && columnCounter < elementsLimit;
          j++
        ) {
          children.push(
            <React.Fragment>
              <MasterClassActivityTailComponent
                id={masterClasses[j].id}
                image={masterClasses[j].image}
                startDate={masterClasses[j].startDate}
                endDate={masterClasses[j].endDate}
                city={masterClasses[j].city}
                title={masterClasses[j].title}
                placesLeft={masterClasses[j].placesLeft}
                isFavorite={false}
                reactionButton={() => {
                  setLiked(!liked);
                }}
                arrow={true}
              />
            </React.Fragment>
          );
          columnCounter++;
        }
        children.push(fillEmptyActivityTailsResults(columnCounter));
        elements.push(
          <div className="masterclass-activitytail-container-row">
            {children}
          </div>
        );
      }
      return elements;
    }
    return [];
  }

  return (
    <>
      <div className="masterclass-content">
        <div className="frame-16">
          <div className="frame-17">
            <div className="masterclass-topbar">
              <div className="masterclass-topbar-title-iconplace">
                <TwentyIcLesson
                  className="masterclass-topbar-title-iconplace-icon"
                  color="#36B39E"
                />
              </div>
              <div className="masterclass-topbar-title">
                {t("masterClass.common.masterclass")}
              </div>
            </div>
          </div>
        </div>
        <MasterClassFilterBar
          switchStatus={switchSelected}
          setSwitchSelected={(value) => setSwitchSelected(value)}
        />
        {!switchSelected && masterClasses.length > 0 && (
          <div className="divider" />
        )}
        {!_.isEmpty(elementOrderData) && (
          <div className="masterclass-ordersuccess-container">
            <div className="masterclass-ordersuccess-textcontainer">
              <IconTik className={undefined}></IconTik>
              <div className="masterclass-ordersuccess-text">
                {t("myOrders.masterOrderCompleted").replace(
                  "*masterName",
                  elementOrderData ? elementOrderData.title : ""
                )}
              </div>
            </div>
            <div
              className="masterclass-ordersuccess-icon"
              onClick={() => {
                setElementOrderData(undefined);
                store.dispatch(myOrdersElementOrderedEmptyState());
              }}
            >
              <IconClose
                className={undefined}
                onClick={undefined}
                color={undefined}
              ></IconClose>
            </div>
          </div>
        )}
        {((!switchSelected && masterClasses.length > 0) ||
          (switchSelected && myOrders.length > 0)) && (
          <div className="masterclass-activitytail-results-bar">
            <div className="masterclass-activitytail-results-title">
              <div className="masterclass-activitytail-results-title-one">
                {t("masterClass.common.results")}
              </div>
              <div className="masterclass-activitytail-results-title-two">
                ({!switchSelected ? masterClasses.length : myOrders.length})
              </div>
            </div>
          </div>
        )}
        {!switchSelected ? (
          <div className="masterclass-activitytail-container">
            {masterClasses.length === 0 && (
              <div className="masterclass-noelements-container">
                <div className="masterclass-noelement-container-slide">
                  <div className="masterclass-noelement-container-slide-icon ">
                    <TwentyIcLesson
                      tail="m"
                      color="#222729"
                      className={undefined}
                    />
                  </div>
                  <div className="masterclass-noelement-container-slide-text">
                    <div className="masterclass-noelement-container-slide-textone">
                      {t("masterClass.common.noResultsTextone")}
                    </div>
                    <div className="masterclass-noelement-container-slide-texttwo">
                      {t("masterClass.common.noResultsTexttwo")}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {fillActivityTailsResults()}
          </div>
        ) : (
          <div className="masterclass-myorder-container">
            {myOrders.length > 0 ? (
              fillMyOrdersResults()
            ) : (
              <div className="masterclass-myorderempty-container">
                <div className="masterclass-myorderempty-element">
                  <TwentyIcLesson
                    tail="m"
                    color="#222729"
                    className={"masterclass-myorderempty-icon"}
                  />
                  <div className="masterclass-myorderempty-firsttext">
                    {t("masterClass.common.ordersEmptyTextOne")}
                  </div>
                  <div className="masterclass-myorderempty-secondtext">
                    {t("masterClass.common.ordersEmptyTextTwo")}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};
