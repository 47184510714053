/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcPinMap = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-pin-map ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M5.05025 3.94113C7.78392 1.35296 12.2161 1.35296 14.9497 3.94113C17.6834 6.52929 17.6834 10.7255 14.9497 13.3137L10 18L5.05025 13.3137C2.31658 10.7255 2.31658 6.52929 5.05025 3.94113Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        className="circle"
        cx="10"
        cy="9"
        r="2"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
