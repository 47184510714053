import HttpClient from "../../utils/HttpClient";

import _ from "lodash";
import { WellnessNutritional } from "./WellnessSlice/WellnessNutritionalSlice";
import { WellnessBody } from "./WellnessSlice/WellnessBodySlice";
import { WellnessPsycho } from "./WellnessSlice/WellnessPsychoSlice";

export default class WellnessRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getWellnessBodyView(queryString?: string) {
    return this.instance.get("wellnessBodyView" + (queryString ?? ""));
  }

  public getWellnessBody(id: number) {
    return this.instance.get("/wellnessBodyService/" + id);
  }
  public getWellnessBodyDetails(id: number) {
    return this.instance.get("/wellnessBody/" + id);
  }
  public getWellnessNutritional(id: number) {
    return this.instance.get("/wellnessNutritionalService/" + id);
  }
  public getWellnessNutritionalDetails(id: number) {
    return this.instance.get("/wellnessNutritional/" + id);
  }
  public getWellnessNutritionalView(queryString?: string) {
    return this.instance.get("wellnessNutritionalView" + (queryString ?? ""));
  }
  public getWellnessPsycho(id: number) {
    return this.instance.get("/wellnessPsychoService/" + id);
  }
  public getWellnessPsychoView(queryString?: string) {
    return this.instance.get("wellnessPsychoView" + (queryString ?? ""));
  }
  public getWellnessPsychoDetails(id: number) {
    return this.instance.get("/wellnessPsycho/" + id);
  }
  public createWellnessBody(wellnessBody: WellnessBody) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      "tenants/" + tenantId + "/wellnessBody",
      wellnessBody
    );
  }
  public createWellnessNutritional(wellnessNutritional: WellnessNutritional) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.post(
      "tenants/" + tenantId + "/wellnessNutrition",
      wellnessNutritional
    );
  }
  public createWellnessPsycho(wellnessPsycho: WellnessPsycho) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.post(
      "tenants/" + tenantId + "/wellnessPsycho",
      wellnessPsycho
    );
  }

  public getWellnessTable() {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get("tenants/" + tenantId + "/myService");
  }

  public deleteWellnessBody(wellnessBodyId: number) {
    const tenantId = localStorage.getItem("tenantId");
    const path = "/tenants/" + tenantId + "/wellnessBody/" + wellnessBodyId;
    return this.instance.delete(path);
  }

  public deleteWellnessNutritional(wellnessNutritionalId: number) {
    const tenantId = localStorage.getItem("tenantId");
    const path =
      "/tenants/" + tenantId + "/wellnessNutritional/" + wellnessNutritionalId;
    return this.instance.delete(path);
  }

  public deleteWellnessPsycho(wellnessPsychoId: number) {
    const tenantId = localStorage.getItem("tenantId");
    const path = "/tenants/" + tenantId + "/wellnessPsycho/" + wellnessPsychoId;
    return this.instance.delete(path);
  }

  public partiallyUpdateWellNessBody(id: number, wellNessBody: WellnessBody) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.patch(
      "/tenants/" + tenantId + "/wellnessBody/" + id,
      wellNessBody
    );
  }

  public partiallyUpdateWellNessNutritional(
    id: number,
    wellNessNutritional: WellnessNutritional
  ) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.patch(
      "/tenants/" + tenantId + "/wellnessNutritional/" + id,
      wellNessNutritional
    );
  }

  public partiallyUpdateWellnessPsycho(
    id: number,
    wellnessPsycho: WellnessPsycho
  ) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.patch(
      "/tenants/" + tenantId + "/wellnessPsycho/" + id,
      wellnessPsycho
    );
  }

  public getServicesByProvider(
    tenantId: number,
    userId: number,
    providerType: any
  ) {
    const params: any = {
      providerType: providerType,
    };

    return this.instance.get("/tenants/" + tenantId + "/services/" + userId, {
      params,
    });
  }

  public changeApproval(
    tenantId: number,
    userId: number,
    request: any,
    wellnessId: number
  ) {
    return this.instance.patch(
      "/tenants/" + tenantId + "/user/" + userId + "/wellness/" + wellnessId,
      request
    );
  }
}
