import { IconCalendario } from "../icons/IconCalendario";
import { TwentyIcArrowLineRight } from "../icons/TwentyIcArrowLineRight";
import favouritesImg from "../../../img/frame-2178-1.svg";
import favouritesImgLiked from "../../../img/frame-2178-2.svg";
import { useTranslation } from "react-i18next";
import "./MasterClassActivityTail.css";
import { useNavigate } from "react-router-dom";
export interface MasterClassActivityTailProps {
  id: number | undefined;
  placesLeft: any;
  startDate: Date;
  endDate: Date;
  title: any;
  city: any;
  image: any;
  isFavorite?: boolean;
  reactionButton?: (e: any) => any;
  arrow?: boolean;
}

const MasterClassActivityTailComponent: React.FC<
  MasterClassActivityTailProps
> = ({
  id,
  image,
  startDate,
  endDate,
  title,
  city,
  placesLeft,
  isFavorite,
  reactionButton,
  arrow,
}) => {
  const { t } = useTranslation();

  const months = [
    t("timeFormats.monthsFirstThree.January"),
    t("timeFormats.monthsFirstThree.February"),
    t("timeFormats.monthsFirstThree.March"),
    t("timeFormats.monthsFirstThree.April"),
    t("timeFormats.monthsFirstThree.May"),
    t("timeFormats.monthsFirstThree.June"),
    t("timeFormats.monthsFirstThree.July"),
    t("timeFormats.monthsFirstThree.August"),
    t("timeFormats.monthsFirstThree.September"),
    t("timeFormats.monthsFirstThree.October"),
    t("timeFormats.monthsFirstThree.November"),
    t("timeFormats.monthsFirstThree.December"),
  ];

  // console.log("startDate", startDate);
  // console.log("endDate", endDate);

  const startDateNotFormatted = new Date(startDate);
  const endDateNotFormatted = new Date(endDate);

  const startDay = startDateNotFormatted.getDate();
  const startMonth = startDateNotFormatted.getMonth() + 1;
  const startYear = startDateNotFormatted.getFullYear();

  const endDay = endDateNotFormatted.getDate();
  const endMonth = endDateNotFormatted.getMonth() + 1;
  const endYear = endDateNotFormatted.getFullYear();

  // TODO: Anche questa è una porcheria e va rifatta

  const compareDates = (startDate: Date) => {
    const currentDate = new Date();

    return startDate.getTime < currentDate.getTime;
  };

  function setDate() {
    // TODO: questa è una porcheria e va rifatta
    if (startYear === 1970 || !compareDates(startDateNotFormatted))
      return "Da definire";

    const isSameDay = startDay === endDay;
    const isSameMonth = startMonth === endMonth;
    const isSameYear = startYear === endYear;

    const startMonthName = months[startMonth - 1];
    const endMonthName = months[endMonth - 1];

    if (isSameDay && isSameMonth && isSameYear) {
      return endDay + " " + endMonthName + " " + endYear;
    } else if (!isSameDay && isSameMonth && isSameYear) {
      return `
        ${t("masterClass.components.activityTail.fromDate")}
        ${startDay} ${startMonthName} ${startYear}
        ${t("masterClass.components.activityTail.toDate")}
        ${endDay} ${endMonthName} ${endYear}
        `;
    } else if (!isSameYear) {
      return `
        ${t("masterClass.components.activityTail.fromDate")}
        ${startDay} ${startMonthName} ${startYear}
        ${t("masterClass.components.activityTail.toDate")}
        ${endDay} ${endMonthName} ${endYear}
        `;
    } else if (!isSameMonth && isSameYear) {
      return `
        ${t("masterClass.components.activityTail.fromDate")} 
        ${startDay} ${startMonthName} 
        ${t("masterClass.components.activityTail.toDate")} 
        ${endDay} ${endMonthName} ${endYear}
        `;
    } else {
      return null;
    }
  }

  const navigate = useNavigate();

  return (
    <>
      <div className="activity-tail__wrapper">
        <span className="activity-tail__image">
          <img
            className="activity-tail__container"
            alt="Rectangle"
            src={image}
          />
          {/* {isMostPopular && (
            <span className="activity-tail__most-popular">
              <div className="activity-tail__most-popular-text">
                {t("masterClass.components.activityTail.mostPopular")}
              </div>
            </span>
          )} */}
          {/* <img
            className="activity-tail__frame"
            alt="Frame"
            src={!isFavorite ? favouritesImg : favouritesImgLiked}
            onClick={reactionButton}
          /> */}
        </span>
        <div className="activity-tail__frame-wrapper">
          <div className="activity-tail__sections">
            <div className="activity-tail__first-section">
              <div className="activity-tail__first-section-wrapper">
                {/* {firstTitleIsADate ? (
                  <IconCalendario color="#86D1C5" className="" />
                ) : (
                  <TwentyIcPinMap color="#86D1C5" className="" />
                )} */}
              </div>
              <div className="activity-tail__alert-title"></div>
            </div>
            <div className="activity-tail__second-section">
              <div className="activity-tail__second-section-wrapper">
                <div className="activity-tail__second-title">{title}</div>
                <div className="activity-tail__third-title">{city}</div>
              </div>
              {arrow && (
                <div className="activity-tail____twentyicarrowlineright ">
                  <TwentyIcArrowLineRight
                    color="#36B39E"
                    className="activity-tail____wentyicarrowlineright"
                    onClick={() => {
                      navigate("/masterclass/" + id + "/details");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MasterClassActivityTailComponent;
