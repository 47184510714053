import { t } from "i18next";
import { Button } from "../Button/Button";
import { IconCalendario } from "../icons/IconCalendario";
import { TwentyIcAdd } from "../icons/TwentyIcAdd";
import { TwentyIcPeople } from "../icons/TwentyIcPeople";
import { TwentyIcCreditCoins } from "../TwentyIcCreditCoins";
import { TwentyIcEuro } from "../icons/TwentyIcEuro";
import { useMatch } from "react-router-dom";

interface BookNowProps {
  label: any;
  price: number;
  month: string;
  startDay: string;
  endDay: string;
  onClick?: (e: any) => any;
}

export const BookNow: React.FC<BookNowProps> = ({
  label,
  price,
  month,
  startDay,
  endDay,
  onClick,
}) => {
  const isSmartPlan = useMatch("/smartplan/:id/details");
  console.log("");

  return (
    <div className="frame-33">
      <div className="frame-32">
        <div className="frame-45">
          <div className="frame-46">
            {startDay && endDay ? (
              <IconCalendario
                className="icon-instance-node-3"
                color="#36B39E"
              />
            ) : (
              <TwentyIcPeople className={undefined} color="#36B39E" />
            )}
            <div className="text-wrapper-30">{month}</div>
          </div>
          {startDay && endDay && (
            <>
              <div className="text-wrapper-31">•</div>
              <div className="text-wrapper-32">
                {t("smartPlanDetail.bookNow.from")}
                {startDay}
                {t("smartPlanDetail.bookNow.to")}
                {endDay}
              </div>
            </>
          )}
        </div>
        <div className="frame-47">
          <div className="frame-48">
            <div className="text-wrapper-33">
              {t("smartPlanDetail.bookNow.startingFrom")}
            </div>
            <div
              className="credit-service-wellness-view"
              style={{ display: "flex" }}
            >
              <div className="text-wrapper-34">{price}</div>
              <div style={{ top: "8px", position: "relative" }}>
                {isSmartPlan ? (
                  <div
                    className="icon-euro-smartplan"
                    style={{ height: "25px" }}
                  >
                    <TwentyIcEuro className={undefined} />
                  </div>
                ) : (
                  <TwentyIcCreditCoins
                    className={undefined}
                    color="#222729"
                    colorC="#222729"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="button-4-BookNow">
            <Button
              size="L"
              state="default"
              type="primary"
              text={label}
              onClick={onClick}
              disabled={undefined}
              icon={undefined}
              iconRight={undefined}
            />
          </div>
        </div>
      </div>
      <div className="line-bookNow" />
    </div>
  );
};
