export const TwentyIcArrowLineLeft1 = ({ className, onClick, color }) => {
  return (
    <svg
      className={`twenty-ic-arrow-line-left-1 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      stroke={color}
    >
      <g className="g" opacity="0.7">
        <path
          className="path"
          d="M7 4C7 7.5 1 10 1 10M1 10C1 10 7 12.5 7 16M1 10H19"
          stroke="#36B39E"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
