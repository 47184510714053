/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcPhone = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-phone ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3.50858 3.67294L6.35228 3.0167C6.66125 2.94561 6.97843 3.10693 7.10421 3.39677L8.41669 6.45921C8.53153 6.72717 8.45497 7.04162 8.22802 7.22482L7.17392 8.08758C6.81943 8.37772 6.70289 8.87443 6.92941 9.2726C7.82024 10.8385 9.1347 12.1646 10.7248 13.0684C11.1229 13.2946 11.6194 13.178 11.9094 12.8237L12.7725 11.7693C12.9584 11.5423 13.2701 11.4657 13.5381 11.5806L16.6005 12.8931C16.8931 13.0216 17.0544 13.3387 16.9833 13.6477L16.3271 16.4914C16.2587 16.7867 15.9962 17 15.6872 17C8.68465 17 3 11.3263 3 4.31277C3 4.00652 3.21054 3.74129 3.50858 3.67294Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
