/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcLesson = ({ tail = "s", className, color = "white" }) => {
  switch (tail) {
    case "m":
      return (
        <svg
          className={`twenty-ic-lesson ${className}`}
          fill="none"
          height="40"
          viewBox="0 0 20 20"
          width="40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="path"
            d="M2 5H18L16.7692 11H3.23077L2 5Z"
            stroke={color}
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            className="path"
            d="M4.5 5C4.5 3.5 5 2 7 2"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            className="path"
            d="M10 8.5V18"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            className="path"
            d="M7 18H13"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      );
    default:
      return (
        <svg
          className={`twenty-ic-lesson ${className}`}
          fill="none"
          height="20"
          viewBox="0 0 20 20"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="path"
            d="M2 5H18L16.7692 11H3.23077L2 5Z"
            stroke={color}
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            className="path"
            d="M4.5 5C4.5 3.5 5 2 7 2"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            className="path"
            d="M10 8.5V18"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            className="path"
            d="M7 18H13"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      );
  }
};
