// Importa CryptoJS
const CryptoJS = require("crypto-js");
export const getParamString = (
  queryParamName,
  queryParamValues,
  delimitator
) => {
  let queryParamValueArray;
  let queryString = "";
  if (Array.isArray(queryParamValues)) {
    queryParamValueArray = queryParamValues;
  } else if (typeof queryParamValues === "number") {
    queryParamValueArray = [queryParamValues];
  } else if (typeof queryParamValues === "boolean") {
    queryParamValueArray = [queryParamValues.toString()];
  } else {
    queryParamValueArray = queryParamValues.split(delimitator);
  }
  for (const queryParamValue of queryParamValueArray) {
    const operator = queryString.length === 0 ? "" : "&";
    if (!!queryParamValue) {
      queryString += `${operator}${queryParamName}=${encodeURIComponent(
        queryParamValue
      )}`;
    }
  }
  return queryString;
};

export const getQueryString = (allQueryParams, delimitator) => {
  let queryString = "";
  for (const queryParamName in allQueryParams) {
    const queryParamValues = allQueryParams[queryParamName];
    const operator = queryString.length === 0 ? "?" : "&";
    const result = getParamString(
      queryParamName,
      queryParamValues,
      delimitator
    );
    queryString += `${operator}${result}`;
  }
  return queryString.replaceAll("%20", "+");
};

export function capitalizeFirstLetter(str) {
  // Converte la stringa in lowercase
  str = str.toLowerCase();
  // Prende il primo carattere e lo converte in maiuscolo
  str = str.charAt(0).toUpperCase() + str.slice(1);
  return str;
}

export function truncateDecimalNumber(num) {
  if (typeof num !== "number") {
    throw new TypeError("num must be a number");
  }

  const precision = 2;
  const decimalPlaces = Math.pow(10, precision);
  return Math.floor(num * decimalPlaces) / decimalPlaces;
}

export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export function validateNumericInput(inputValue) {
  // Verifica che l'input sia composto solo da numeri
  if (/^\d*$/.test(inputValue)) {
    return inputValue;
  } else {
    return "";
  }
}

export function monthTranslator(value) {
  switch (value.toLowerCase()) {
    case "january":
      return "Gennaio";
    case "february":
      return "Febbraio";
    case "march":
      return "Marzo";
    case "april":
      return "Aprile";
    case "may":
      return "Maggio";
    case "june":
      return "Giugno";
    case "july":
      return "Luglio";
    case "august":
      return "Agosto";
    case "september":
      return "Settembre";
    case "october":
      return "Ottobre";
    case "november":
      return "Novembre";
    case "december":
      return "Dicembre";
    default:
      console.error("Il mese fornito non è valido");
      return value;
  }
}

export function monthTranslatorFromNumber(value) {
  switch (value) {
    case "1":
      return "Gennaio";
    case "2":
      return "Febbraio";
    case "3":
      return "Marzo";
    case "4":
      return "Aprile";
    case "5":
      return "Maggio";
    case "6":
      return "Giugno";
    case "7":
      return "Luglio";
    case "8":
      return "Agosto";
    case "9":
      return "Settembre";
    case "10":
      return "Ottobre";
    case "11":
      return "Novembre";
    case "12":
      return "Dicembre";
    default:
      console.error("Il mese fornito non è valido");
      return value;
  }
}

export function formatDate(inputDate) {
  const options = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  const date = new Date(inputDate);
  const formattedDate = new Intl.DateTimeFormat("it-IT", options).format(date);

  const formattedWithCapitalized = formattedDate
    .split(" ")
    .map((part) => capitalizeFirstLetter(part))
    .join(" ");

  return formattedWithCapitalized;
}

export function areArraysEqual(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }

  // Funzione di utilità per confrontare due oggetti
  const isObjectEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  };

  // Controlla ogni oggetto in array1 contro tutti gli oggetti in array2
  return array1.every((obj1) =>
    array2.some((obj2) => isObjectEqual(obj1, obj2))
  );
}

export function addDotToNumber(number) {
  let numberString = number.toString();

  let parts = [];
  while (numberString.length > 3) {
    parts.unshift(numberString.slice(-3));
    numberString = numberString.slice(0, -3);
  }
  parts.unshift(numberString);

  return parts.join(".");
}

export function formattedData(data) {
  if (data === "" || data === null || data === undefined) {
    return "";
  }
  var dataOrig = new Date(data);

  var day = dataOrig.getDate();
  var month = dataOrig.getMonth() + 1;
  var year = dataOrig.getFullYear();

  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  return day + "/" + month + "/" + year;
}

export function formatNumber(number) {
  if (number) {
    // Convert the number into a string and split it into integer and decimal parts
    const [integerPart, decimalPart] = number.toString().split(".");

    // Add the thousands separator to the integer part
    const integerPartWithSeparator = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      "."
    );

    // Format the decimal part by adding the comma
    const formattedDecimalPart = decimalPart ? `,${decimalPart}` : "";

    // Return the formatted number
    return integerPartWithSeparator + formattedDecimalPart;
  }
  return 0;
}

export function isURL(str) {
  try {
    new URL(str);
    return true;
  } catch (err) {
    return false;
  }
}

// Funzione per cifrare una password
export function encryptPassword(password, secretKey) {
  const ciphertext = CryptoJS.AES.encrypt(password, secretKey).toString();
  return ciphertext;
}

// Funzione per decifrare una password
export function decryptPassword(ciphertext, secretKey) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const originalPassword = bytes.toString(CryptoJS.enc.Utf8);
  return originalPassword;
}

export function formatText(input) {
  if (!input) return input; // Se l'input è vuoto o undefined, ritorna direttamente l'input

  // Sostituisce i ritorni a capo con <br><br> per ottenere due spaziature tra paragrafi
  let formattedText = input.replace(/\n/g, "<br><br>");

  // Sostituisce "- " con "<br>- " per andare a capo e mantenere il trattino
  formattedText = formattedText.replace(/- /g, "<br>- ");

  // Sostituisce "." con ".<br>" per andare a capo dopo ogni punto
  formattedText = formattedText.replace(/\./g, ".<br>");

  // Sostituisce le frasi in maiuscolo con <strong>frase</strong> e va a capo solo se la frase ha più di una parola
  formattedText = formattedText.replace(
    /\b([A-Z][A-Z\s]+)\b/g,
    function (match) {
      // Se la frase in maiuscolo ha più di una parola, la rende in grassetto e aggiunge un ritorno a capo
      if (match.trim().split(/\s+/).length > 1) {
        return "<strong>" + match.trim() + "</strong><br>";
      } else {
        return match; // Altrimenti, ritorna la frase senza modifiche
      }
    }
  );

  return formattedText;
}
