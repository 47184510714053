import { t } from "i18next";
import React from "react";
import { ModalBody } from "../../../ui/components/Modals/ModalBody";
import { ModalFooter } from "../../../ui/components/Modals/ModalFooter";
import { ModalWrapper } from "../../../ui/components/Modals/ModalWrapper";
import { TwentyIcCreditCoins } from "../../../ui/components/TwentyIcCreditCoins";
import "../WellnessBookingModal/WellnessBookingModal.css";

interface WellnessBookingModalProps {
  onClose: () => void;
  onClick: (data?: number) => void;
  price: number;
  isActionPerforming: boolean;
  totalCredit?: number;
}

export const WellnessBookingModal: React.FC<WellnessBookingModalProps> = ({
  onClose,
  onClick,
  price,
  isActionPerforming,
  totalCredit,
}) => {
  return (
    <div className="modal-booking-wellness">
      <ModalWrapper open={true} closeAction={onClose}>
        <ModalBody title="Acquista">
          <div className="descr-modal">
            <div className="description-modal-wellness-all">
              {"Crediti disponibili"}
            </div>
            <div className="credit-available">
              <div className="total-credit-icon-modal-wellness">
                <TwentyIcCreditCoins
                  className={undefined}
                  color="#36B39E"
                  colorC="#36B39E"
                />
              </div>
              <div className="total-credit-modal-wellness">{totalCredit}</div>
            </div>
          </div>

          {totalCredit && totalCredit < price && (
            <div className="info-not-enough-credits">
              <div className="info-not-enough-credits-text">
                Non hai abbastanza crediti per procedere con l'acquisto. Puoi
                ricaricare autonomamente o fare una richiesta all'owner.
              </div>
            </div>
          )}
          <div className="rectangle-5" />

          <div className="cost-service">
            <div className="cost-service-text">Costo servizio</div>
            <div className="cost">
              {price}
              <div className="cost-service-text-icon">
                <TwentyIcCreditCoins
                  className={undefined}
                  color="#36B39E"
                  colorC="#36B39E"
                />
              </div>
            </div>
          </div>
          <div className="rectangle-5-wellness" />
        </ModalBody>
        <ModalFooter
          primaryLabel={t("smartPlanDetail.bookNow.bookNowModal.ok")}
          secondaryLabel={t("smartPlanDetail.bookNow.bookNowModal.cancel")}
          primaryAction={() => onClick()}
          secondaryAction={onClose}
          isActionPerforming={isActionPerforming}
          disablePrimaryButton={
            totalCredit && totalCredit < price ? true : false
          }
        />
      </ModalWrapper>
    </div>
  );
};

export default WellnessBookingModal;
