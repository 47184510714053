/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcMailAdd = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-mail-add ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M18 8.15385V4H2V16H10.5"
        stroke={color}
        strokeWidth="2"
      />
      <path
        className="path"
        d="M5 7.5C5.57874 7.5 7.16231 9.17471 8.39378 10.5857C9.23656 11.5513 10.7634 11.5513 11.6062 10.5857C12.8377 9.17471 14.4213 7.5 15 7.5"
        stroke={color}
        strokeLinecap="square"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M16.5 11V18.3684M20 14.6842L13 14.6842"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
