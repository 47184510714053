/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcArrowsLineSortVertical = ({
  className,
  color = "white",
}) => {
  return (
    <svg
      className={`twenty-ic-arrows-line-sort-vertical ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12 18C12 15.6667 16 14 16 14M16 14C16 14 12 12.3333 12 10M16 14L4 14"
        stroke={color}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M8 10C8 7.66667 4 6 4 6M4 6C4 6 8 4.33333 8 2M4 6L16 6"
        stroke={color}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
