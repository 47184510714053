import { Logo } from "../../ui/components/Logo/Logo";
import { IconDoc } from "../../ui/components/icons/IconDoc";
import { IconTik } from "../../ui/components/icons/IconTik";
import { TwentyIcArrowLineLeft } from "../../ui/components/icons/TwentyIcArrowLineLeft";
import { t } from "i18next";
import { useState, useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import {
  MyOrderDetail,
  myOrderDetailSelector,
  getOrderAsync,
} from "../myorders/MyOrderDetailSlice";
import WellnessActivityTailComponent from "../../ui/components/ActivityTail/WellnessActivityTail";
import { ImageConfig } from "../../config/ImageConfig";
import { IconAddCalendar } from "../../ui/components/icons/IconAddCalendar";
import { WellnessNutritional } from "../Wellness/WellnessSlice/WellnessNutritionalSlice";

export const WellnessNutritionalPaymentSuccess = () => {
  const wellnessNutritionalRoute = useMatch(
    "wellness/nutritional/:nutritionalId/success/:bookingId"
  );

  const navigate = useNavigate();

  const [id, setId] = useState<number>(-1);
  const [wellnessNutritional, setWellnessNutritional] =
    useState<WellnessNutritional>();

  let myOrderDetail: MyOrderDetail = useAppSelector(
    (state) =>
      myOrderDetailSelector.selectById(state, id) ?? ({} as MyOrderDetail)
  );

  useEffect(() => {
    if (myOrderDetail.wellnessNutritional) {
      setWellnessNutritional(myOrderDetail.wellnessNutritional);
    }
  }, [myOrderDetail]);

  useEffect(() => {
    if (wellnessNutritionalRoute != null) {
      if (wellnessNutritionalRoute.params.bookingId) {
        setId(parseInt(wellnessNutritionalRoute.params.bookingId));
        store.dispatch(
          getOrderAsync(parseInt(wellnessNutritionalRoute.params.bookingId))
        );
        return;
      }
    }
  }, [wellnessNutritionalRoute]);

  return (
    <>
      <div className="paymentsuccess-wrapper">
        <div className="payment-everywhere-logo">
          <Logo width="186px" height="28px" />
        </div>
        <div className="paymentsuccess-content">
          <div className="paymentsuccess-frame">
            <div className="paymentsuccess-frame-firstsection">
              <div className="paymentsuccess-frame-firstsection-iconcontainer">
                <IconTik size={40} className={undefined}></IconTik>
              </div>
              <div className="paymentsuccess-frame-firstsection-text">
                <div className="paymentsuccess-frame-firstsection-firsttext">
                  {t("payments.success.firstText")}
                </div>
                <div className="paymentsuccess-frame-firstsection-subtitle">
                  {t("payments.success.thankYou")}
                </div>
              </div>
            </div>
            <div className="paymentsuccess-frame-secondsection">
              {wellnessNutritional && (
                <WellnessActivityTailComponent
                  activityTailType="NUTRITIONAL"
                  id={wellnessNutritional.id}
                  img={
                    wellnessNutritional.image
                      ? wellnessNutritional.image
                      : ImageConfig.BACKGROUND_DEFAULT_SERVICE
                  }
                  mode={wellnessNutritional.remotelyOrPresenceEnum}
                  title={wellnessNutritional.name}
                  typology={wellnessNutritional.typology}
                  isIndividual={wellnessNutritional.isIndividual}
                  arrow={false}
                />
              )}
            </div>
            <div className="paymentsuccess-frame-thirdsection">
              <div
                className="paymentsuccess-frame-thirdsection-firstbutton"
                onClick={() => {}}
              >
                <IconAddCalendar></IconAddCalendar>
                <div
                  className="paymentfailed-buttontext"
                  onClick={() => {
                    navigate("/wellness/nutritional/myOrders/" + id);
                  }}
                >
                  {t("payments.success.buttonCalendar")}
                </div>
              </div>
              <div
                className="paymentsuccess-frame-thirdsection-secondbutton"
                onClick={() => {
                  navigate("/wellness/nutritional/myOrders/" + id);
                }}
              >
                <IconDoc className={undefined}></IconDoc>
                <div className="paymentfailed-buttontext">
                  {t("payments.success.buttonOrder")}
                </div>
              </div>
              <div
                className="paymentsuccess-frame-thirdsection-thirdbutton"
                onClick={() => {
                  navigate("/wellness/nutritional");
                }}
              >
                <TwentyIcArrowLineLeft
                  className={undefined}
                ></TwentyIcArrowLineLeft>
                <div className="paymentfailed-buttontext">
                  {t("payments.success.buttonServices")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WellnessNutritionalPaymentSuccess;
