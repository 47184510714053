import { useEffect, useState } from "react";
import "../Admin/CreditRechargeEmployeeModal.css";
import {
  TeamUserInfo,
  getFilteredUsersInfoWithoutUser,
  teamUserInfoSelectors,
} from "../../MyTeam/TeamUsersInfoSlice";
import { store } from "../../../app/store";
import { TwentyIcCreditsCoinsAdd } from "../../../ui/components/icons/TwentyIcCreditsCoinsAdd/TwentyIcCreditsCoinsAdd";
import { TwentyIcCreditCoins } from "../../../ui/components/TwentyIcCreditCoins";
import { Button, InputNumber, Select, Space, Switch, Tooltip } from "antd";
import { useAppSelector } from "../../../app/hooks";
import { IconSearch } from "../../../ui/components/icons/IconSearch";
import { IconUserAdd } from "../../../ui/components/icons/IconUserAdd";
import { IconInfo } from "../../../ui/components/icons/IconInfo";
import { UsersTable } from "../../../ui/components/UsersTable/UsersTable";
import { t } from "i18next";
import { IconUser } from "../../../ui/components/icons/IconUser";
import { TwentyIcTik } from "../../../ui/components/icons/TwentyIcTik";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";
import {
  UserSendMapWrapper,
  getMyEmployeeRechargeAsync,
  rechargeEmployeeAsync,
} from "../CreditHistorySlice";
import {
  Wallet,
  getWalletAsync,
  getWalletsAsync,
  walletSelector,
} from "../../Wallet/WalletSlice";
import { ToastNotification } from "../../../ui/components/utils/ToastNotification";
import { getTransactionsAsync } from "../TransactionSlice/TransactionSlice";
import { TwentyIcClose } from "../../../ui/components/TwentyIcClose";

interface CreditRechargeEmployeeModalProps {
  onClose: () => void;
}

export const CreditRechargeEmployeeModal: React.FC<
  CreditRechargeEmployeeModalProps
> = ({ onClose }) => {
  const userId = localStorage.getItem("customerId");
  const [summarySection, setSummarySection] = useState(false);
  const [isSamePrice, setIsSamePrice] = useState(false);
  const [samePrice, setSamePrice] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [selectedUsersList, setSelectedUsersList] = useState<TeamUserInfo[]>(
    []
  );
  let creditThreeshold = 50;
  const teamUsersInfo: TeamUserInfo[] = useAppSelector(
    teamUserInfoSelectors.selectAll
  ).filter((e) => e.city !== null);
  const [userSendMapArray, setUserSendMapArray] = useState<
    UserSendMapWrapper[]
  >([]);
  const [wallet, setWallet] = useState<Wallet>();
  const wallets: Wallet[] = useAppSelector(walletSelector.selectAll);

  useEffect(() => {
    isSamePrice && samePrice >= creditThreeshold && allSamePrice();
  }, [samePrice, isSamePrice, selectedUsersList]);

  useEffect(() => {
    let total = 0;
    for (let i = 0; i < userSendMapArray.length; i++) {
      total = total + userSendMapArray[i].balance;
    }
    setTotalCredits(total);
  }, [userSendMapArray, samePrice, isSamePrice]);

  useEffect(() => {
    store.dispatch(getFilteredUsersInfoWithoutUser());
  }, []);

  useEffect(() => {
    setWallet(wallets.filter((e) => e.userId === parseInt(userId ?? "0"))[0]);
  }, [wallets]);

  function addOrRemoveUsersToList(e: any) {
    const updatedList = [...selectedUsersList];

    const elementToAdd = teamUsersInfo.find((user) => user.id === e);

    if (updatedList.find((user) => user.id === e)) {
      const filteredList = updatedList.filter((user) => user.id !== e);
      const updatedWrapper = userSendMapArray.filter(
        (recharge) => recharge.userId !== e
      );
      setUserSendMapArray(updatedWrapper);
      setSelectedUsersList(filteredList);
    } else {
      if (elementToAdd) {
        const newUserSendMap: UserSendMapWrapper = {
          userId: e,
          balance: isSamePrice ? samePrice : creditThreeshold,
        };
        setUserSendMapArray([...userSendMapArray, newUserSendMap]);
        updatedList.push(elementToAdd);
        setSelectedUsersList(updatedList);
      }
    }
  }

  function addAllToList() {
    setSelectedUsersList(teamUsersInfo);
  }

  function allSamePrice() {
    let temporaryList = [];
    for (let i = 0; i < selectedUsersList.length; i++) {
      const newUserSendMap: UserSendMapWrapper = {
        userId: selectedUsersList[i].id,
        balance: isSamePrice ? samePrice : creditThreeshold,
      };
      temporaryList.push(newUserSendMap);
    }
    setUserSendMapArray(temporaryList);
  }

  const dropdownRender = (menu: any) => (
    <div>
      {menu}
      <div style={{ padding: "8px", borderTop: "1px solid #e8e8e8" }}>
        <div
          className="recharge-employee-modal-content-creditstosend-selectall"
          onClick={addAllToList}
        >
          <IconUser className={undefined} />
          Inserisci tutti gli utenti della lista
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="mn-modal-backdrop"></div>
      <div className="recharge-employee-modal-wrapper">
        <div className="recharge-employee-modal-header">
          <div className="recharge-employee-modal-header-text">
            <div className="icon-recharge-employee">
              <TwentyIcCreditsCoinsAdd
                className={undefined}
                color="#ffffff80"
                colorC="#ffffff80"
              />
            </div>
            Invia crediti
          </div>
          <div className="recharge-employee-modal-header-icon">
            <Button
              onClick={onClose}
              style={{
                borderColor: "transparent",
                backgroundColor: "transparent",
                boxShadow: "none",
                alignItems: "center",
                display: "flex",
                height: "40px",
              }}
            >
              <TwentyIcClose className={undefined} color="white" />
            </Button>
          </div>
        </div>
        {!summarySection ? (
          <>
            <div className="recharge-employee-modal-content">
              <div className="recharge-employee-modal-content-firstsection">
                <div className="recharge-employee-modal-content-firstsection-section">
                  <div className="recharge-employee-modal-content-firstsection-section-title">
                    CREDITI DISPONIBILI
                  </div>
                  <div className="recharge-employee-modal-content-firstsection-section-title-credits">
                    <TwentyIcCreditCoins
                      className={undefined}
                      color="#36b39e"
                      colorC="#36b39e"
                    />
                    {wallet?.currentCredits}
                  </div>
                </div>
                <div className="recharge-employee-modal-content-firstsection-section">
                  <div className="recharge-employee-modal-content-firstsection-section-title">
                    SALDO MINIMO PER PERS.
                  </div>
                  <Tooltip
                    title={
                      "Il saldo minimo consigliato si riferisce ai prezzi minimi dei servizi sulla piattaforma"
                    }
                  >
                    <div className="recharge-employee-modal-content-firstsection-section-title-minus-credits">
                      <TwentyIcCreditsCoinsAdd
                        className={undefined}
                        color="grey"
                        colorC="grey"
                      />
                      {creditThreeshold}

                      <IconInfo className={undefined} color="#14ADCC" />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="recharge-employee-modal-content-secondsection">
                <Select
                  mode="multiple"
                  placeholder={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <IconSearch color="#7a7d7fb0" className={undefined} />
                        Ricerca utenti
                      </div>
                    </>
                  }
                  onChange={(e: any) => {
                    addOrRemoveUsersToList(e[0]);
                  }}
                  dropdownRender={dropdownRender}
                  style={{ width: "100%", height: "40px" }}
                  value={[]}
                  options={teamUsersInfo.map((item: TeamUserInfo) => ({
                    value: item.id,
                    label: item.fullName,
                  }))}
                />
                {selectedUsersList.length > 0 ? (
                  <>
                    <div className="recharge-employee-modal-content-secondsection-switch">
                      <div className="recharge-employee-modal-content-secondsection-switch-section">
                        <Switch
                          size="default"
                          checked={isSamePrice}
                          onChange={(e) => setIsSamePrice(!isSamePrice)}
                        />
                        <div>Applica lo stesso prezzo a tutti</div>
                      </div>
                      {isSamePrice && (
                        <InputNumber
                          style={{ width: "130px" }}
                          value={samePrice}
                          onChange={(e) => {
                            if (e !== null) {
                              setSamePrice(e);
                            } else {
                              setSamePrice(0);
                            }
                          }}
                          prefix={
                            <TwentyIcCreditCoins
                              className={undefined}
                              color="grey"
                              colorC="grey"
                            />
                          }
                        />
                      )}
                    </div>
                    <UsersTable
                      creditThreeShold={creditThreeshold}
                      summaryCompleted={userSendMapArray}
                      staticPrice={samePrice}
                      summary={false}
                      usersToRecharge={(el) => setUserSendMapArray(el)}
                      inputNumberDisabled={isSamePrice}
                      users={selectedUsersList}
                      remove={(e) => {
                        addOrRemoveUsersToList(e);
                      }}
                    />
                    <div className="recharge-employee-modal-content-creditstosend-section">
                      <div className="recharge-employee-modal-content-creditstosend-section-text-one">
                        Crediti totali da inviare
                      </div>
                      {wallet !== undefined &&
                      wallet?.currentCredits - totalCredits >= 0 ? (
                        <div className="recharge-employee-modal-content-creditstosend-section-text-two">
                          {totalCredits}
                        </div>
                      ) : (
                        <Tooltip
                          title={
                            "Hai superato il massimo di crediti a disposizione. Ricarica ulteriori crediti o modifica la somma."
                          }
                        >
                          <div
                            className="recharge-employee-modal-content-creditstosend-section-text-two-wrong"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            {totalCredits}

                            <IconInfo color="#f26549" className={undefined} />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="recharge-employee-modal-content-empty-selection">
                    <div className="recharge-employee-modal-content-empty-selection-titles">
                      <IconUserAdd
                        className={undefined}
                        color="#d7d7d7"
                        size="25"
                      />
                      <div className="recharge-employee-modal-content-empty-selection-titles-first">
                        Nessun utente selezionato
                      </div>
                      <div className="recharge-employee-modal-content-empty-selection-titles-second">
                        Seleziona un utente a cui assegnare i crediti da inviare
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="recharge-employee-modal-content-buttons-2">
              <Button
                onClick={onClose}
                style={{
                  borderColor: "transparent",
                  boxShadow: "none",
                  height: "40px",
                }}
              >
                <Space>
                  <div className="recharge-employee-modal-footer-text">
                    {t("smartPlan.common.cancel")}
                  </div>
                </Space>
              </Button>
              <Button
                style={{
                  borderColor: "#36B39E",
                  backgroundColor: "#36B39E",
                  boxShadow: "none",
                  height: "40px",
                }}
                onClick={() => {
                  wallet !== undefined &&
                    wallet?.currentCredits - totalCredits >= 0 &&
                    userSendMapArray.length > 0 &&
                    totalCredits > 0 &&
                    setSummarySection(!summarySection);
                }}
              >
                <Space>
                  <TwentyIcTik
                    className={"recharge-employee-modal-content-vector-confirm"}
                  />
                  <div className="recharge-employee-modal-text-white">
                    {t("smartPlan.common.confirm")}
                  </div>
                </Space>
              </Button>
            </div>
          </>
        ) : (
          //summarySection
          <>
            <div className="recharge-employee-modal-content">
              <div className="recharge-employee-modal-content-creditstosend-summary-text">
                Riepilogo invio crediti
              </div>
              <UsersTable
                summaryCompleted={userSendMapArray}
                summary={true}
                usersToRecharge={(el) => setUserSendMapArray(el)}
                inputNumberDisabled={isSamePrice}
                users={selectedUsersList}
                remove={(e) => {
                  addOrRemoveUsersToList(e);
                }}
              />
              <div className="recharge-employee-modal-content-creditstosend-summary-container">
                <div className="recharge-employee-modal-content-creditstosend-summary-card">
                  <div className="recharge-employee-modal-content-creditstosend-summary-card-textone">
                    Crediti Disponibili
                  </div>
                  <div className="recharge-employee-modal-content-creditstosend-summary-card-textone">
                    {wallet?.currentCredits}
                  </div>
                </div>
                <div className="divider" />
                <div className="recharge-employee-modal-content-creditstosend-summary-card">
                  <div className="recharge-employee-modal-content-creditstosend-summary-card-textone">
                    Subtotale dei crediti da inviare
                  </div>
                  <div className="recharge-employee-modal-content-creditstosend-summary-card-textone">
                    {"- " + totalCredits}
                  </div>
                </div>
                <div className="divider" />
                <div className="recharge-employee-modal-content-creditstosend-summary-card">
                  <div className="recharge-employee-modal-content-creditstosend-summary-card-texttwo">
                    Crediti restanti
                  </div>
                  <div className="recharge-employee-modal-content-creditstosend-summary-card-texttwo">
                    {wallet && wallet?.currentCredits - totalCredits}
                  </div>
                </div>
              </div>
            </div>
            <div className="recharge-employee-modal-content-buttons">
              <Button
                onClick={() => setSummarySection(!summarySection)}
                style={{
                  borderColor: "transparent",
                  boxShadow: "none",
                  height: "40px",
                }}
              >
                <Space>
                  <div className="recharge-employee-modal-footer-text">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <TwentyIcArrowLineLeft className={undefined} />
                      Indietro
                    </div>
                  </div>
                </Space>
              </Button>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  onClick={onClose}
                  style={{
                    borderColor: "transparent",
                    boxShadow: "none",
                    height: "40px",
                  }}
                >
                  <Space>
                    <div className="recharge-employee-modal-footer-text">
                      {t("smartPlan.common.cancel")}
                    </div>
                  </Space>
                </Button>
                <Button
                  style={{
                    borderColor: "#36B39E",
                    backgroundColor: "#36B39E",
                    boxShadow: "none",
                    height: "40px",
                  }}
                  onClick={() => {
                    userId &&
                      store
                        .dispatch(
                          rechargeEmployeeAsync({
                            userId: Number.parseInt(userId) ?? 0,
                            usersToRecharge: userSendMapArray,
                          })
                        )
                        .then(() => {
                          ToastNotification({
                            status: "success",
                            description: "",
                            toastId: "Credits sended",
                            title: "Crediti inviati con successo !",
                          });
                          store.dispatch(
                            getMyEmployeeRechargeAsync(
                              Number.parseInt(userId ?? "0")
                            )
                          );
                          store.dispatch(getWalletAsync());
                          store.dispatch(getTransactionsAsync());
                          onClose();
                        });
                  }}
                >
                  <Space>
                    <TwentyIcTik
                      className={
                        "recharge-employee-modal-content-vector-confirm"
                      }
                    />
                    <div className="recharge-employee-modal-text-white">
                      {t("smartPlan.common.confirm")}
                    </div>
                  </Space>
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
