import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import PublicAccountRepository from "./PublicAccountRepository";
import { Config } from "../../../config/Config";
import _ from "lodash";
import { RootState } from "../../../app/store";

export interface PublicAccountView {
  id: number;
  firstName: string;
  lastName: string;
  personalPhotography: string;
  typologies: any[];
}

export const getPublicAccountViewAsync = createAsyncThunk(
  "publicAccountView/getPublicAccountViewAsync",
  async (data: { queryString?: string; providerType: string }) => {
    const publicAccountRepository = new PublicAccountRepository();
    const response = await publicAccountRepository.getPublicAccountView(
      data.queryString,
      data.providerType
    );
    const publicAccountView = _.get(
      response,
      Config.PUBLIC_ACCOUNT_VIEW_RESPONSE
    );
    return publicAccountView;
  }
);

const publicAccountViewAdapter = createEntityAdapter<PublicAccountView>({
  selectId: (publicAccountView) => publicAccountView.id,
});

export const publicAccountViewSlice = createSlice({
  name: "publicAccountView",
  initialState: publicAccountViewAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    publicAccountViewEmptyState: (state) => {
      publicAccountViewAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getPublicAccountViewAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          publicAccountViewAdapter.setAll(state, action.payload ?? []);
          state.status = "idle";
        }
      )
      .addCase(getPublicAccountViewAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getPublicAccountViewAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const publicAccountViewSelector =
  publicAccountViewAdapter.getSelectors<RootState>(
    (state) => state.publicAccountView
  );

export const { publicAccountViewEmptyState } = publicAccountViewSlice.actions;
export const selectPublicAccountViewSliceStatus = (state: any) =>
  state.publicAccountView.status;
export const selectPublicAccountViewSliceReasonCode = (state: any) =>
  state.publicAccountView.reasonCode;

//#endRegion Status

export default publicAccountViewSlice.reducer;
