/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconUser = ({ className, color = "black" }) => {
  return (
    <svg
      className={`icon-user ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16 14.9535C16 13.3611 15.1111 11.3333 10 11.3333C4.88889 11.3333 4 13.3611 4 14.9535V16H16V14.9535Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M12.6673 5.66667C12.6673 7.13943 11.4734 8.33333 10.0007 8.33333C8.52789 8.33333 7.33398 7.13943 7.33398 5.66667C7.33398 4.19391 8.52789 3 10.0007 3C11.4734 3 12.6673 4.19391 12.6673 5.66667Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
