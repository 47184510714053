/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconClose = ({ className, onClick, color }) => {
  return (
    <svg
      className={`icon-close ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        className="path"
        d="M16 15.9995L4.0009 4.00041"
        stroke={color !== undefined ? color : "#38342F"}
        strokeWidth="2"
      />
      <path
        className="path"
        d="M16 4.00049L4.00089 15.9996"
        stroke={color !== undefined ? color : "#38342F"}
        strokeWidth="2"
      />
    </svg>
  );
};
