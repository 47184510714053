import { t } from "i18next";
import { AssistanceBoxComponent } from "../../../ui/components/AssistanceBoxComponent";
import { Descriptions } from "../../../ui/components/Descriptions";
import { useMatch, useNavigate } from "react-router-dom";
import HeaderMenu from "../../../layout/HeaderMenu";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { Footer } from "../../../screens/Footer/Footer";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";
import _ from "lodash";
import { ImageConfig } from "../../../config/ImageConfig";
import { PublicAccountSummaryComponent } from "../../../ui/components/PublicAccountSummaryComponent";

import { useEffect, useState } from "react";
import { getPublicAccountAsync } from "../PublicAccount/PublicAccountSlice";
import { store } from "../../../app/store";
import "../ProviderAccountDetails/ProviderAccountDetails.css";
import { BookNow } from "../../../ui/components/ComponentBookNow";
import {
  ServiceViewSlice,
  getServicesByProviderAsync,
} from "../../Wellness/WellnessSlice/ServiceViewSlice";

export const ProviderAccountDetails = () => {
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const businessName = localStorage.getItem("businessName");

  const [publicAccount, setPublicAccount] = useState<any>();
  const [servicesView, setServicesView] = useState<any>();
  const navigate = useNavigate();
  const accountPublicRoute = useMatch("account/:id/details/public");

  let refresh = false;

  useEffect(() => {
    const fetchData = async () => {
      if (accountPublicRoute !== null && accountPublicRoute.params.id) {
        if (!refresh) {
          const id = parseInt(accountPublicRoute.params.id);

          const publicAccountResponse = await store.dispatch(
            getPublicAccountAsync({ accountDetailId: id })
          );

          setPublicAccount(publicAccountResponse.payload);

          const serviceView = await store.dispatch(
            getServicesByProviderAsync({
              tenantId: publicAccountResponse.payload?.tenant?.id,
              userId: publicAccountResponse.payload?.user?.id,
              providerType: publicAccountResponse.payload?.providerType,
            })
          );

          setServicesView(serviceView.payload);

          refresh = true;
        }
      }
    };

    fetchData();
  }, [accountPublicRoute]);

  const handleButtonClick = (elementId: number) => {
    switch (publicAccount?.providerType) {
      case "NUTRITIONIST":
        navigate("/Wellness/Nutritional/" + elementId + "/details");
        break;
      case "PSYCHOLOGIST":
        navigate("/Wellness/Psycho/" + elementId + "/details");
        break;
      case "COACH":
        navigate("/Wellness/Body/" + elementId + "/details");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <HeaderMenu>
        <TopBar
          title={publicAccount?.firstName + " " + publicAccount?.lastName}
          hasEdit={false}
          con-instance-node-3
          hasDate={false}
          breadcrumbText={"Account" + " > " + " Profilo Pubblico"}
          icon={<TwentyIcArrowLineLeft className="i" />}
          onClick={() => navigate(-1)}
        />
      </HeaderMenu>
      <div className="provider-details-container">
        <div className="wellness-details-image-container-provider">
          <img
            className="rectangle-8"
            alt=""
            src={ImageConfig.IMAGE_DEFAULT_SERVICE}
          />
          {publicAccount?.personalPhotography && (
            <img
              className="wellness-image2-user-provider"
              alt=""
              src={publicAccount?.personalPhotography}
            />
          )}
        </div>

        <div className="overlap-2">
          <div className="provider-bady-page">
            <div className="service-info-title">Informazioni</div>
            {publicAccount?.providerType !== "COACH" && (
              <div className="service-wrapper-user-specialization">
                <div className="service-title">Campi di specializzazione</div>
                <div className="specialization-chips">
                  {publicAccount?.typologies?.map(
                    (typology: { id: number; name: string }, index: number) => (
                      <div className="specialization-chip" key={index}>
                        <span>{typology.name}</span>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
            <div className="service-container-public-account">
              <div className="service-wrapper-public-account">
                <div className="service-title">Anni di esperienza</div>
                <div className="service-subtitle">
                  {publicAccount?.yearsOfExperience + " " + "Anni"}
                </div>
              </div>
              <div className="service-wrapper-public-account">
                <div className="service-title">Dove vive</div>
                <div className="service-subtitle">
                  {publicAccount?.residence}
                </div>
              </div>
            </div>
            <div className="service-public-account-skills">
              <div className="service-title">Formazione e Competenze</div>
              <div className="service-subtitle">
                {publicAccount?.trainingAndSkills.charAt(0).toUpperCase() +
                  publicAccount?.trainingAndSkills.slice(1)}
              </div>
            </div>
            <div className="text-wrapper-21-description">
              {t("smartPlanDetail.description.description")}
            </div>
            <Descriptions
              initialText={
                publicAccount?.profileDescription
                  ? publicAccount?.profileDescription
                  : ""
              }
              maxLength={300}
            />
            {!_.isEmpty(servicesView) && (
              <div className="select-activity">
                <div className="select-activity-text">Scegli attività</div>
                <div className="book-now-provider">
                  {servicesView.map(
                    (element: ServiceViewSlice, index: number) => (
                      <div key={index}>
                        <BookNow
                          label={"Prenota ora"}
                          price={element.price}
                          month={element.name}
                          startDay={""}
                          endDay={""}
                          onClick={() => handleButtonClick(element.id)}
                        ></BookNow>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="container-summary">
            <div className="summary-wrapper">
            <PublicAccountSummaryComponent
              email={publicAccount?.publicEmail}
              telephone={publicAccount?.publicTelephone}
            />
            <AssistanceBoxComponent
              assistance={t("smartPlanDetail.componentAssistantBox.assistance")}
              whatsApp={t("smartPlanDetail.componentAssistantBox.whatsApp")}
              date={t("smartPlanDetail.componentAssistantBox.date")}
              whatsAppNumber={3773604135}
              whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."
                .replace("firstName*", firstName ?? "")
                .replace("lastName*", lastName ?? "")
                .replace("companyName*", businessName ?? "")}
              disabled={undefined}
            />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
