import _ from "lodash";
import OfficeCoworkingRepository from "./OfficeCoworkingRepository";
import { Config } from "../../config/Config";
import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface OfficeCoworkingActivityTail {
  id: number;
  image: string;
  name: string;
  city: string;
  region: string;
}

export const getOfficeCoworkingActivityTailAsync = createAsyncThunk(
  "officeCoworking/getActivityTails",
  async (data: { queryString?: string }) => {
    const officeCoworkingRepository = new OfficeCoworkingRepository();
    const response =
      await officeCoworkingRepository.getOfficeCoworkingActivityTail(
        data?.queryString
      );
    const activityTails = _.get(
      response,
      Config.OFFICE_COWORKING_ACTIVITY_TAILS
    );
    return activityTails;
  }
);

const officeCoworkingActivityTailAdapter =
  createEntityAdapter<OfficeCoworkingActivityTail>({
    selectId: (activityTail) => activityTail.id,
  });

export const officeCoworkingActivityTailSlice = createSlice({
  name: "officeCoworkingActivityTails",
  initialState: officeCoworkingActivityTailAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    activityTailsEmptyState: (state) => {
      officeCoworkingActivityTailAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getOfficeCoworkingActivityTailAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          officeCoworkingActivityTailAdapter.setAll(
            state,
            action.payload ?? []
          );
          state.status = "idle";
        }
      )
      .addCase(getOfficeCoworkingActivityTailAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getOfficeCoworkingActivityTailAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const officeCoworkingActivityTailSelector =
  officeCoworkingActivityTailAdapter.getSelectors<RootState>(
    (state) => state.officeCoworkingActivityTails
  );

export const { activityTailsEmptyState } =
  officeCoworkingActivityTailSlice.actions;
export const selectOfficeCoworkingActivityTailSliceStatus = (state: any) =>
  state.activityTails.status;
export const selectOfficeCoworkingActivityTailSliceReasonCode = (state: any) =>
  state.activityTails.reasonCode;

export default officeCoworkingActivityTailSlice.reducer;
