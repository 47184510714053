/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcLaptop = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-laptop ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="4"
        width="16"
        height="11"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="1"
        y="15"
        width="18"
        height="1"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
