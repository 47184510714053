import HttpClient from "../../utils/HttpClient";

export default class IncludedServiceRepository extends HttpClient {
  constructor() {
    super(true);
  }
  public getSmartPlanIncludedServices(smartPlanId: number) {
    const path = "/smartPlans/" + smartPlanId + "/includedServices";
    return this.instance.get(path);
  }

  public getMasterClassIncludedServices(masterClassId: number) {
    const path = "/masterClasses/" + masterClassId + "/includedServices";
    return this.instance.get(path);
  }

  public getWellnessPsychoIncludedServices(wellnessId: number) {
    const path = "/wellnessPsycho/" + wellnessId + "/includedServices";
    return this.instance.get(path);
  }

  public getWellnessBodyIncludedServices(wellnessId: number) {
    const path = "/wellnessBody/" + wellnessId + "/includedServices";
    return this.instance.get(path);
  }

  public getWellnessNutritionalIncludedServices(wellnessId: number) {
    const path = "/wellnessNutritional/" + wellnessId + "/includedServices";
    return this.instance.get(path);
  }
}
