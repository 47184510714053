import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import SmartPlanRepository from "./SmartPlanRepository";
import { Config } from "../../config/Config";
import { normalize } from "normalizr";
import { activityTailsSchema } from "./SmartPlanNormalizationActivityTail";
import _ from "lodash";
import { RootState } from "../../app/store";
import { TypeOfLandformValues } from "./SmartPlanSlice";

//#region Type

export interface SmartPlanActivityTail {
  id: number;
  image: string;
  startDate: Date;
  endDate: Date;
  city: string;
  region: string;
  previewDescription: string;
  landform: TypeOfLandformValues;
  minPeople: number;
  maxPeople: number;
  placesLeft: string;
  isFavorite: boolean;
}

//#endRegion Type

//#region API

export const getSmartPlanActivityTailAsync = createAsyncThunk(
  "smartPlan/getActivityTails",
  async (data: { queryString?: string }) => {
    const smartPlanRepository = new SmartPlanRepository();
    const response = await smartPlanRepository.getSmartPlanActivityTail(
      data?.queryString
    );
    const activityTails = _.get(
      response,
      Config.SMART_PLAN_ACTIVITY_TAIL_RESPONSE
    );
    const normalizedData = normalize(activityTails, activityTailsSchema);
    return normalizedData.entities;
  }
);

//#endRegion API

//#region Slice

const smartPlanActivityTailAdapter = createEntityAdapter<SmartPlanActivityTail>(
  {
    selectId: (activityTail) => activityTail.id,
  }
);

export const smartPlanActivityTailSlice = createSlice({
  name: "smartPlanActivityTails",
  initialState: smartPlanActivityTailAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    activityTailsEmptyState: (state) => {
      smartPlanActivityTailAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getSmartPlanActivityTailAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          smartPlanActivityTailAdapter.setAll(
            state,
            action.payload.activityTails ?? []
          );
          state.status = "idle";
        }
      )
      .addCase(getSmartPlanActivityTailAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getSmartPlanActivityTailAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

//#endRegion Slice

//#region Status

export const smartPlanActivityTailSelector =
  smartPlanActivityTailAdapter.getSelectors<RootState>(
    (state) => state.smartPlanActivityTails
  );

export const { activityTailsEmptyState } = smartPlanActivityTailSlice.actions;
export const selectSmartPlanActivityTailSliceStatus = (state: any) =>
  state.activityTails.status;
export const selectSmartPlanActivityTailSliceReasonCode = (state: any) =>
  state.activityTails.reasonCode;

//#endRegion Status

export default smartPlanActivityTailSlice.reducer;
