//#region Type

import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import MasterClassRepository from "./MasterClassRepository";
import _ from "lodash";
import { Config } from "../../config/Config";
import { RootState } from "../../app/store";
import { normalize } from "normalizr";
import { masterClasssSchema } from "./MasterClassNormalization";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";

export interface MasterClassDetail {
  id: number;
  insertionDate: Date;
  startDate: Date;
  endDate: Date;
  city: string;
  province: string;
  region: string;
  minPeople: number;
  maxPeople: number;
  price: number;
  title: string;
  description: string;
  isFavorite: boolean;
  image: string;
  duration: number;
  placesLeft: number;
  video: string;
  includedServices: number[];
  faqs: number[];
  galleries: number[];
  location: TypeOfLocationValues;
  inPromotion: boolean;
  deposit: number;
}

export const typeOfLocationValues = {
  NORD: "NORD",
  SUD: "SUD",
  CENTRO: "CENTRO",
  ISOLE: "ISOLE",
};

export type TypeOfLocationValues = keyof typeof typeOfLocationValues;
//#endRegion Type

//#region API

export const getMasterClassAsyc = createAsyncThunk(
  "masterClass/getMasterClass",
  async (data: { masterClassId?: number }) => {
    const masterClassRepository = new MasterClassRepository();
    const response = await masterClassRepository.getMasterClass(
      data?.masterClassId
    );
    const masterClass = _.get(response, Config.MASTER_CLASS_RESPONSE);
    return masterClass;
  }
);

export const getAllMasterClassAsyc = createAsyncThunk(
  "masterClass/getAllMasterClass",
  async () => {
    const masterClassRepository = new MasterClassRepository();
    const response = await masterClassRepository.getAllMasterClasses();
    const masterClass = _.get(response, Config.MASTER_CLASSES_RESPONSE);
    const normalizeData = normalize(masterClass, masterClasssSchema);
    return normalizeData.entities;
  }
);
//#endRegion API

//#region Slice

const masterClassAdapter = createEntityAdapter<MasterClassDetail>({
  selectId: (masterClass) => masterClass.id,
});

export const masterClassSlice = createSlice({
  name: "masterClass",
  initialState: masterClassAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    masterClassEmptyState: (state: any) => {
      masterClassAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getMasterClassAsyc.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          masterClassAdapter.upsertOne(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getMasterClassAsyc.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getMasterClassAsyc.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getAllMasterClassAsyc.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          masterClassAdapter.setAll(state, action.payload.masterClasses ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getAllMasterClassAsyc.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getAllMasterClassAsyc.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const masterClassSelector = masterClassAdapter.getSelectors<RootState>(
  (state) => state.masterClass
);

export const { masterClassEmptyState } = masterClassSlice.actions;
export const selectMasterClassSliceStatus = (state: any) =>
  state.masterClass.status;
export const selectMasterClassSliceReasonCode = (state: any) =>
  state.masterClass.reasonCode;

//#endRegion Status

export default masterClassSlice.reducer;
