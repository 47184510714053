import { Button, Calendar, ConfigProvider, Form, Input } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { ModalWrapper } from "../../ui/components/Modals/ModalWrapper";
import { ModalBody } from "../../ui/components/Modals/ModalBody";
import { IconInfo } from "../../ui/components/icons/IconInfo";
import "../MyDiary/MyDiary.css";
import EmotionGraph from "../../ui/components/EmotionGraph/EmotionGraph";
import { ModalFooter } from "../../ui/components/Modals/ModalFooter";
import { store } from "../../app/store";
import {
  MyDiaryEmotions,
  myDiarySelector,
  retriveEmotionsAsync,
  updateEmotionsAsync,
} from "./MyDiarySlice";
import { useAppSelector } from "../../app/hooks";
import { IconTik } from "../../ui/components/icons/IconTik";
import dayjs, { Dayjs } from "dayjs";
import { IconHappiness } from "../../ui/components/icons/IcEmoji/Emoji/Happiness/Happiness";
import itIT from "antd/lib/locale/it_IT";
import { IconAngry } from "../../ui/components/icons/IcEmoji/Emoji/Angry/Angry";
import { IconDisgust } from "../../ui/components/icons/IcEmoji/Emoji/Disgust/Disgust";
import { IconFear } from "../../ui/components/icons/IcEmoji/Emoji/Fear/Fear";
import { IconSad } from "../../ui/components/icons/IcEmoji/Emoji/Sad/Sad";
import { IconStress } from "../../ui/components/icons/IcEmoji/Emoji/Stress/Stress";
import { TwentyIcArrowLeft } from "../../ui/components/icons/TwentyIcArrowLeft";
import { TwentyIcArrowRight } from "../../ui/components/icons/TwentyIcArrowRight";
import { monthTranslator } from "../../utils/utils";

interface MyDiaryProps {
  userFirstName: string;
  refreshed: (e: any) => any;
  completed: boolean;
}

export const MyDiary: React.FC<MyDiaryProps> = ({
  userFirstName,
  refreshed,
  completed,
}) => {
  const [infoModal, setInfoModal] = useState(false);
  const [daySelected, setDaySelected] = useState<Dayjs>(dayjs());
  const [loader, setLoader] = useState(true);
  const [skipModal, setSkipModal] = useState(false);
  const myEmotions: MyDiaryEmotions[] = useAppSelector(
    myDiarySelector.selectAll
  );
  const [text, setText] = useState("");

  useEffect(() => {
    const startDate = daySelected.startOf("day");
    const endDate = daySelected.endOf("day");

    store.dispatch(
      retriveEmotionsAsync({
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        userId: localStorage.getItem("customerId") ?? 0,
      })
    );
  }, [daySelected]);

  function compareDates() {
    let date = new Date(myEmotions[0].timestamp);
    if (
      daySelected.date() === date.getDate() &&
      daySelected.month() === date.getMonth() &&
      daySelected.year() === date.getFullYear()
    ) {
      return true;
    } else {
      return false;
    }
  }

  const headerRender = ({
    value,
    type,
    onChange,
  }: {
    value: dayjs.Dayjs;
    type: "year" | "month" | "date";
    onChange: (value: dayjs.Dayjs, type: "year" | "month" | "date") => void;
  }) => {
    const year = value.year();
    const month = value.format("MMMM");

    const handleChange = (newMonth: number) => {
      onChange(value.month(newMonth), "month");
    };

    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
        <TwentyIcArrowLeft
          onClick={() => handleChange(value.month() - 1)}
          className={undefined}
        />
        <div className="mydiary-calendar-viewer">{`${monthTranslator(
          month
        )} ${year}`}</div>
        <TwentyIcArrowRight
          onClick={() => handleChange(value.month() + 1)}
          className={undefined}
        />
        {(daySelected.date() !== new Date().getDate() ||
          daySelected.month() !== new Date().getMonth() ||
          daySelected.year() !== new Date().getFullYear()) && (
          <div
            style={{ cursor: "pointer" }}
            className="mydiary-calendar-todaydate"
            onClick={() => setDaySelected(dayjs(new Date()))}
          >
            {t("home.mindoor.today")}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {!completed && myEmotions.length === 0 ? (
        <>
          <div className="frame-16">
            <div className="mydiary-title">
              <div className="text-wrapper-6">
                {t("home.mindoor.writeYourDiary")}
              </div>
              <div
                className="mindoor-button-infomodal"
                onClick={() => {
                  setInfoModal(!infoModal);
                }}
              >
                <IconInfo className={undefined} color="#36B39E" />
              </div>
            </div>
            <div className="home-mindoor-form">
              <div className="home-mindoor-form-header">
                <div className="home-mindoor-form-header-text-one">
                  <IconHappiness className={undefined} color="#36B39E" />
                  {t("home.mindoor.howDoYouFeel")}
                </div>
                <div
                  className="home-mindoor-form-header-text-two"
                  onClick={() => setSkipModal(!skipModal)}
                >
                  Skip
                </div>
              </div>
              <Form.Item
                style={{ width: "100%" }}
                name="mindoorForm"
                rules={[{ required: true }]}
              >
                <Input.TextArea
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder={t("home.mindoor.infoWriteYourDiary")}
                />
              </Form.Item>
              <Button
                style={{
                  background: "var(--Corp-150, #C3E8E2)",
                  width: "110px",
                  height: "40px",
                }}
                onClick={() => {
                  text.length > 1 &&
                    store
                      .dispatch(
                        updateEmotionsAsync({
                          text: text,
                          userId: localStorage.getItem("customerId") ?? 0,
                        })
                      )
                      .then((response) => {
                        if (response.meta.requestStatus === "fulfilled") {
                          refreshed(true);
                          setLoader(false);
                          setTimeout(() => {
                            setLoader(true);
                          }, 6000);
                        }
                      });
                }}
              >
                <div className="home-mindoor-form-button-text">
                  {t("home.mindoor.send")}
                </div>
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="frame-16">
            <div className="mydiary-title">
              <div className="text-wrapper-6">
                {t("home.mindoor.summaryDiary")}
              </div>
            </div>
            <div className="home-mindoor-form">
              <div className="home-mindoor-form-content-results">
                {!loader && (
                  <div className="mindoor-ordersuccess-container">
                    <div className="mindoor-ordersuccess-textcontainer">
                      <IconTik className={undefined}></IconTik>
                      <div className="mindoor-ordersuccess-text">
                        {t("home.mindoor.answerFeedback")}
                      </div>
                    </div>
                  </div>
                )}
                {loader && (
                  <>
                    <div className="mydiary-result-container">
                      <div className="mydiary-graph">
                        <div className="home-mindoor-form-header-text-one">
                          {t("home.mindoor.summaryEmotionDiary")}
                        </div>

                        <EmotionGraph
                          width="100%"
                          height="85%"
                          barWidth={35}
                          categories={[
                            t("home.mindoor.categories.happiness"),
                            t("home.mindoor.categories.anger"),
                            t("home.mindoor.categories.fear"),
                            t("home.mindoor.categories.sadness"),
                            t("home.mindoor.categories.disgust"),
                            t("home.mindoor.categories.stress"),
                          ]}
                          data={[
                            compareDates() ? myEmotions[0].emotion.joy : 0.0,
                            compareDates() ? myEmotions[0].emotion.anger : 0.0,
                            compareDates() ? myEmotions[0].emotion.fear : 0.0,
                            compareDates()
                              ? myEmotions[0].emotion.sadness
                              : 0.0,
                            compareDates()
                              ? myEmotions[0].emotion.disgust
                              : 0.0,
                            compareDates() ? myEmotions[0].stress : 0.0,
                          ]}
                        ></EmotionGraph>
                        <div className="graph-icons-container">
                          <IconHappiness className={undefined} />
                          <IconAngry className={undefined} color={undefined} />
                          <IconFear className={undefined} color={undefined} />
                          <IconSad className={undefined} color={undefined} />
                          <IconDisgust
                            className={undefined}
                            color={undefined}
                          />
                          <IconStress className={undefined} color={undefined} />
                        </div>
                      </div>
                      <div className="mydiary-calendar">
                        <ConfigProvider locale={itIT}>
                          <Calendar
                            style={{ width: "100%", height: "100%" }}
                            onChange={(e) => {
                              setDaySelected(dayjs(e));
                            }}
                            value={daySelected}
                            fullscreen={false}
                            headerRender={headerRender}
                          />
                        </ConfigProvider>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {/* INFO MODAL REGION */}
      <ModalWrapper
        open={infoModal}
        closeAction={() => {
          setInfoModal(!infoModal);
        }}
      >
        <ModalBody title={t("home.mindoor.writeYourDiary")}>
          <br />
          <div className="mydiary-text">
            {t("home.mindoor.infoModalfirstText").replace(
              "*userFirstName",
              userFirstName
            )}
          </div>
          <br />
          <br />
          <div className="mydiary-text-title">
            {t("home.mindoor.infoModalFirstTitle")}
          </div>
          <br />
          <div className="mydiary-text">
            {t("home.mindoor.infoModalSecondText")}
          </div>
          <br />
          <br />
          <div className="mydiary-text-title">
            {t("home.mindoor.infoModalSecondTitle")}
          </div>
          <br />
          <div className="mydiary-text">
            {t("home.mindoor.infoModalThirdText")}
          </div>
          <br />
          <br />
          <div className="mydiary-text-title">
            {t("home.mindoor.infoModalThirdTitle")}
          </div>
          <br />
          <div className="mydiary-text">
            {t("home.mindoor.infoModalForthText")}
          </div>
          <EmotionGraph
            height={"60%"}
            barWidth="40%"
            data={[0.4, 0.1, 0.3, 0.22, 0.89, 0.2]}
            categories={[
              t("home.mindoor.categories.anger"),
              t("home.mindoor.categories.happiness"),
              t("home.mindoor.categories.fear"),
              t("home.mindoor.categories.sadness"),
              t("home.mindoor.categories.disgust"),
              t("home.mindoor.categories.stress"),
            ]}
          />
          <div className="graph-icons-container">
            <IconHappiness className={undefined} />
            <IconAngry className={undefined} color={undefined} />
            <IconFear className={undefined} color={undefined} />
            <IconSad className={undefined} color={undefined} />
            <IconDisgust className={undefined} color={undefined} />
            <IconStress className={undefined} color={undefined} />
          </div>
          <br />
          <div className="mydiary-text-title">
            {t("home.mindoor.infoModalForthTitle")}
          </div>
          <br />
          <div className="mydiary-text">
            {t("home.mindoor.infoModalFifthText")}
          </div>
          <br />
        </ModalBody>
        <ModalFooter
          primaryLabel={"Ok"}
          secondaryLabel={t("account.modalChangePassowrd.buttonCancel")}
          primaryAction={() => {
            setInfoModal(!infoModal);
          }}
          secondaryAction={() => {
            setInfoModal(!infoModal);
          }}
          disablePrimaryButton={false}
        />
      </ModalWrapper>
      {/* INFO MODAL END REGION */}

      {/* SKIP MODAL REGION */}
      <ModalWrapper
        open={skipModal}
        closeAction={() => {
          setSkipModal(!skipModal);
        }}
      >
        <ModalBody
          customHeader="skipmodal"
          title={t("home.mindoor.skipYourDiary")}
        >
          <br />
          <div className="mydiary-text">
            {t("home.mindoor.skipModalFirstText").replace(
              "*userFirstName",
              userFirstName
            )}
          </div>
          <br />
          <div className="mydiary-text-titletwo">
            {t("home.mindoor.skipModalFirstTitle")}
          </div>
          <br />
          <div className="mydiary-text-title">
            {t("home.mindoor.skipModalSecondTitle")}
          </div>
          <div className="mydiary-text">
            {t("home.mindoor.skipModalSecondText")}
          </div>
          <br />
          <div className="mydiary-text-title">
            {t("home.mindoor.skipModalThirdTitle")}
          </div>
          <div className="mydiary-text">
            {t("home.mindoor.skipModalThirdText")}
          </div>
          <br />
          <div className="mydiary-text-title">
            {t("home.mindoor.skipModalFourthTitle")}
          </div>
          <div className="mydiary-text">
            {t("home.mindoor.skipModalFourthText")}
          </div>
          <br />
          <div className="mydiary-text-title">
            {t("home.mindoor.skipModalFifthTitle")}
          </div>
          <div className="mydiary-text">
            {t("home.mindoor.skipModalFifthText")}
          </div>
          <br />
          <div className="divider" />
          <br />
        </ModalBody>
        <ModalFooter
          primaryLabel={"Skip"}
          secondaryLabel={t("account.modalChangePassowrd.buttonCancel")}
          primaryAction={() => {
            store.dispatch(
              updateEmotionsAsync({
                text: " ",
                userId: localStorage.getItem("customerId") ?? 0,
              })
            );
            refreshed(true);
            setSkipModal(!skipModal);
          }}
          secondaryAction={() => {
            setSkipModal(!skipModal);
          }}
          skip={true}
          disablePrimaryButton={false}
        />
      </ModalWrapper>
      {/* SKIP MODAL END REGION */}
    </>
  );
};
