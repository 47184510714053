/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import { useReducer, useRef } from "react";
import { TwentyIcDashboard } from "../icons/TwentyIcDashboard";
import "./style.css";

export const Option = ({
  icon = true,
  notify = true,
  style,
  className,
  isActive,
  override = (
    <TwentyIcDashboard className="element-ic-dashboard" color="white" />
  ),
  text = "Home",
  onClick,
  ...props
}) => {
  const [state, dispatch] = useReducer(reducer, {
    style: style || "default",
  });

  let classname = isActive
    ? "mn-menu__nav-list__item--active"
    : "mn-menu__nav-list__item";

  return (
    <div className={classname}>
      <div
        className={`option ${state.style} ${className}`}
        onMouseEnter={() => {
          dispatch("mouse_enter");
        }}
        onMouseLeave={() => {
          dispatch("mouse_leave");
        }}
        onClick={onClick && onClick}
      >
        {icon && <div className="icon">{override}</div>}

        <div className="home">{text}</div>
        {notify && (
          <div className="frame">
            <div className="element"></div>
          </div>
        )}
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        style: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        style: "default",
      };
  }

  return state;
}

Option.propTypes = {
  icon: PropTypes.bool,
  notify: PropTypes.bool,
  style: PropTypes.oneOf(["hover", "selected", "default"]),
  text: PropTypes.string,
};

Option.defaultProps = {
  isActive: false,
};
