import { useEffect, useState } from "react";
import "./style.css";

interface BaseTabProps {
  select?: boolean;
  isButton?: boolean;
  text?: string;
  icon?: any;
  children?: any;
  onClick?: (e: any) => any;
  onChange?: (e: any) => any;
}

export const BaseTab: React.FC<BaseTabProps> = ({
  select,
  isButton,
  icon,
  text,
  children,
  onClick,
  onChange,
}) => {
  const [style, setStyle] = useState("");

  useEffect(() => {
    if (select) setStyle("base-tab-element-selected");
    else return setStyle("base-tab-element");
  }, [select]);

  return (
    <div
      className={isButton ? "base-tab-element-container" : style}
      onClick={onClick}
      onChange={onChange}
    >
      {icon && icon}
      {text}
      {children && children}
    </div>
  );
};
