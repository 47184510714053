import { t } from "i18next";
import { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import SmartPlanActivityTailComponent from "../../ui/components/ActivityTail/SmartPlanActivityTail";
import { Logo } from "../../ui/components/Logo/Logo";
import { IconDoc } from "../../ui/components/icons/IconDoc";
import { IconTik } from "../../ui/components/icons/IconTik";
import { TwentyIcArrowLineLeft } from "../../ui/components/icons/TwentyIcArrowLineLeft";
import {
  MyOrderDetail,
  getOrderAsync,
  myOrderDetailSelector,
} from "../myorders/MyOrderDetailSlice";
import { SmartPlan } from "../Smartplan/SmartPlanSlice";
import { SmartDatePlan } from "../Smartplan/SmartDatePlan/SmartDatePlanSlice";

export const SmartplanPaymentSuccess = () => {
  const smartPlanSuccessRoute = useMatch(
    "smartplan/:smartplanId/success/:bookingId"
  );

  const navigate = useNavigate();

  const [id, setId] = useState<number>(-1);
  const [smartPlan, setSmartPlan] = useState<SmartPlan>();
  const [smartPlanDate, setSmartPlanDate] = useState<SmartDatePlan>();

  let myOrderDetail: MyOrderDetail = useAppSelector(
    (state) =>
      myOrderDetailSelector.selectById(state, id) ?? ({} as MyOrderDetail)
  );

  useEffect(() => {
    if (myOrderDetail.smartPlan && myOrderDetail.smartPlanDate) {
      setSmartPlan(myOrderDetail.smartPlan);
      setSmartPlanDate(myOrderDetail.smartPlanDate);
    }
  }, [myOrderDetail]);

  useEffect(() => {
    if (smartPlanSuccessRoute != null) {
      if (smartPlanSuccessRoute.params.bookingId) {
        setId(parseInt(smartPlanSuccessRoute.params.bookingId));
        store.dispatch(
          getOrderAsync(parseInt(smartPlanSuccessRoute.params.bookingId))
        );
        return;
      }
    }
  }, [smartPlanSuccessRoute]);

  return (
    <>
      <div className="paymentsuccess-wrapper">
        <div className="payment-everywhere-logo">
          <Logo width="186px" height="28px" />
        </div>
        <div className="paymentsuccess-content">
          <div className="paymentsuccess-frame">
            <div className="paymentsuccess-frame-firstsection">
              <div className="paymentsuccess-frame-firstsection-iconcontainer">
                <IconTik size={40} className={undefined}></IconTik>
              </div>
              <div className="paymentsuccess-frame-firstsection-text">
                <div className="paymentsuccess-frame-firstsection-firsttext">
                  {t("payments.success.firstText")}
                </div>
                <div className="paymentsuccess-frame-firstsection-subtitle">
                  {t("payments.success.thankYou")}
                </div>
              </div>
            </div>
            <div className="paymentsuccess-frame-secondsection">
              {smartPlan && smartPlanDate && (
                <SmartPlanActivityTailComponent
                  id={smartPlan.id}
                  img={smartPlan.image}
                  startDate={new Date(smartPlanDate.startDay)}
                  endDate={new Date(smartPlanDate.endDay)}
                  city={smartPlan.city}
                  region={smartPlan.region}
                  previewDescription={smartPlan.previewDescription}
                  minPeople={smartPlan.minPeople}
                  maxPeople={smartPlan.maxPeople}
                  placesLeft={smartPlan.placesLeft}
                  isFavorite={smartPlan.isFavorite}
                  arrow={false}
                />
              )}
            </div>
            <div className="paymentsuccess-frame-thirdsection">
              <div
                className="paymentsuccess-frame-thirdsection-secondbutton"
                onClick={() => {
                  navigate("/smartplan/myOrders/" + id);
                }}
              >
                <IconDoc className={undefined}></IconDoc>
                <div className="paymentfailed-buttontext">
                  {t("payments.success.buttonOrder")}
                </div>
              </div>
              <div
                className="paymentsuccess-frame-thirdsection-thirdbutton"
                onClick={() => {
                  navigate("/smartplan");
                }}
              >
                <TwentyIcArrowLineLeft
                  className={undefined}
                ></TwentyIcArrowLineLeft>
                <div className="paymentfailed-buttontext">
                  {t("payments.success.buttonServices")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SmartplanPaymentSuccess;
