//#region Type

import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import SmartDatePlanRepository from "./SmartDatePlanRepository";
import _ from "lodash";
import { Config } from "../../../config/Config";
import { normalize } from "normalizr";
import { smartDatePlansSchema } from "./SmartDatePlaneNormalization";
import { RootState } from "../../../app/store";
import { EverywhereSuccessCodes } from "../../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../../config/EverywhereErrorCodes";

export interface SmartDatePlan {
  id: number;
  month: string;
  startDay: string;
  endDay: string;
  deposit: number;
  price: number;
  smartPlanId: number;
}

//#endRegion Type

//#region API

export const getSmartDatePlanAsync = createAsyncThunk(
  "smartPlan/getSmartDatePlan",
  async (data: { smartPlanId: number }) => {
    const smartDatePlanRepository = new SmartDatePlanRepository();
    const response = await smartDatePlanRepository.getSmartDatePlane(
      data.smartPlanId
    );
    const smartDatePlane = _.get(response, Config.SMART_DATE_PLAN_RESPONSE);
    const normalizeData = normalize(smartDatePlane, smartDatePlansSchema);
    return normalizeData.entities;
  }
);

export const getAllSmartDatePlansAsync = createAsyncThunk(
  "smartPlan/getSmartDatePlan",
  async () => {
    const smartDatePlanRepository = new SmartDatePlanRepository();
    const response = await smartDatePlanRepository.getAllSmartDatePlanes();
    const smartDatePlane = _.get(response, Config.SMART_DATE_PLAN_RESPONSE);
    const normalizeData = normalize(smartDatePlane, smartDatePlansSchema);
    return normalizeData.entities;
  }
);

//#endRegion API

//#region Slice

const smartDatePlanAdapter = createEntityAdapter<SmartDatePlan>({
  selectId: (smartDatePlan) => smartDatePlan.id,
});

export const smartDatePlanSlice = createSlice({
  name: "smartDatePlan",
  initialState: smartDatePlanAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    smartDatePlanEmptyState: (state) => {
      smartDatePlanAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getSmartDatePlanAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          smartDatePlanAdapter.setAll(
            state,
            action.payload.smartDatePlan ?? []
          );
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getSmartDatePlanAsync.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(getSmartDatePlanAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const smartDatePlanSelector =
  smartDatePlanAdapter.getSelectors<RootState>((state) => state.smartDatePlan);

export const { smartDatePlanEmptyState } = smartDatePlanSlice.actions;
export const selectSmartDatePlanSliceStatus = (state: any) =>
  state.smartDatePlan.status;
export const selectSmartDatePlanSliceReasonCode = (state: any) =>
  state.smartDatePlan.reasonCode;

//#endRegion Status

export default smartDatePlanSlice.reducer;
