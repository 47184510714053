import { t } from "i18next";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { AssistanceBoxComponent } from "../../../ui/components/AssistanceBoxComponent";
import { Descriptions } from "../../../ui/components/Descriptions";
import QuestionsAndAnswers from "../../../ui/components/QuestionsAndAnswersComponent/QuestionsAndAnswers";
import { getWellnessNutritionalIncludedServices } from "../../IncludedServices/IncludedServiceSlice";
import "./WellnessNutritionalDetails.css";

import {
  Faq,
  faqSelector,
  getWellnessNutritionalFaqsAsync,
} from "../../Faq/FaqSlice";

import _ from "lodash";
import { useMatch, useNavigate } from "react-router-dom";
import { store } from "../../../app/store";
import HeaderMenu from "../../../layout/HeaderMenu";
import { Footer } from "../../../screens/Footer/Footer";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";

import { ImageConfig } from "../../../config/ImageConfig";
import { WellnessSummaryComponent } from "../../../ui/components/WellnessSummaryComponent";
import { ToastNotification } from "../../../ui/components/utils/ToastNotification";
import { getPublicAccountByTenantIdAndUserIdAsync } from "../../Account/PublicAccount/PublicAccountSlice";
import {
  Wallet,
  getWalletAsync,
  walletSelector,
} from "../../Wallet/WalletSlice";
import {
  MyOrder,
  createWellnessOrderAsync,
  selectMyOrdersSliceStatus,
} from "../../myorders/MyOrdersSlice";
import WellnessBookingModal from "../WellnessBookingModal/WellnessBookingModal";
import {
  WellnessNutritional,
  getWellnessNutritionalAsync,
  wellnessNutritionalSelector,
} from "../WellnessSlice/WellnessNutritionalSlice";
import { wellnessFaqs } from "../wellness_faqs";

export const WellnessNutritionalDetails = () => {
  const [id, setId] = useState<number>(-1);
  const [bookNowModalId, setBookNowModalId] = useState<number>(-1);

  let firstLoad = false;

  const wellnessNutritionalDetailsRoute = useMatch(
    "Wellness/Nutritional/:id/details"
  );

  const [walletId, setWalletId] = useState(-1);

  const wallet: Wallet = useAppSelector(
    (state) => walletSelector.selectById(state, walletId) ?? ({} as Wallet)
  );

  const bookingStatus = useAppSelector(selectMyOrdersSliceStatus);

  useEffect(() => {
    if (!firstLoad) {
      if (wellnessNutritionalDetailsRoute !== null) {
        if (wellnessNutritionalDetailsRoute.params.id) {
          setId(parseInt(wellnessNutritionalDetailsRoute.params.id));
          store.dispatch(
            getWellnessNutritionalAsync(
              parseInt(wellnessNutritionalDetailsRoute.params.id)
            )
          );
          store.dispatch(
            getWellnessNutritionalFaqsAsync({
              wellnessBodyId: parseInt(
                wellnessNutritionalDetailsRoute.params.id
              ),
            })
          );
          store.dispatch(
            getWellnessNutritionalIncludedServices({
              wellnessId: parseInt(wellnessNutritionalDetailsRoute.params.id),
            })
          );
          store
            .dispatch(getWalletAsync())
            .then((response) => setWalletId(response?.payload?.id));
          firstLoad = true;
          return;
        }
      }
    }
  }, [wellnessNutritionalDetailsRoute]);

  let wellNessNutritional: WellnessNutritional = useAppSelector(
    (state) =>
      wellnessNutritionalSelector.selectById(state, id) ??
      ({} as WellnessNutritional)
  );

  let faq = wellnessFaqs;

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const businessName = localStorage.getItem("businessName");

  const date = new Date();
  const hoursItalian = date.setHours(date.getHours() + 2);
  const userInfo = localStorage.getItem("customerId");
  const userInfoId = userInfo ? parseInt(userInfo, 10) : 0;
  const tenant = localStorage.getItem("tenantId");
  const tenantId = tenant ? parseInt(tenant, 10) : 0;
  const order: MyOrder = {
    numberOfPeople: 1,
    id: 0,
    tenantId: tenantId,
    bookingDate: new Date(hoursItalian),
    status: 0,
    purchasedBy: userInfoId,
    wellnessNutritionalId: wellNessNutritional.id,
  };

  const [publicAccount, setPublicAccount] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      if (!_.isEmpty(wellNessNutritional)) {
        const accountDetails = await store.dispatch(
          getPublicAccountByTenantIdAndUserIdAsync({
            tenantId: wellNessNutritional?.tenant?.id,
            userId: wellNessNutritional.userInfo.id,
          })
        );
        setPublicAccount(accountDetails.payload);
      }
    };
    fetchData();
  }, [wellNessNutritional]);

  const navigate = useNavigate();

  return (
    <>
      <HeaderMenu>
        <TopBar
          title={wellNessNutritional.name}
          hasEdit={false}
          hasDate={false}
          breadcrumbText={
            t("wellness.wellness") +
            " > " +
            t("wellness.nutritional.networkNutritional") +
            " > " +
            wellNessNutritional.name
          }
          icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
          onClick={() => navigate(-1)}
        />
      </HeaderMenu>
      <div className="wellnessnutritionaldetail-container">
        <div className="wellness-details-image-container-provider">
          <img
            className="rectangle-8"
            alt=""
            src={ImageConfig.IMAGE_DEFAULT_SERVICE}
          />
          {publicAccount?.personalPhotography && (
            <img
              className="wellness-image2-user-provider-nutrition"
              alt=""
              src={publicAccount?.personalPhotography}
            />
          )}
        </div>
        <div className="overlap-2">
          <div className="wellnessnutritionaldetail-bady-page">
            <div className="text-wrapper-21-service">
              {t("smartPlanDetail.smartPlanServiceComponent.service")}
            </div>

            <div className="service-container">
              <div>
                <div className="service-title">
                  {t("wellness.services.mode")}
                </div>
                <div className="service-subtitle">
                  {wellNessNutritional.remotelyOrPresenceEnum ===
                  "REMOTEANDPRESENCE"
                    ? t("stringBuilder.remoteAndPresence")
                    : wellNessNutritional.remotelyOrPresenceEnum === "REMOTE"
                    ? t("stringBuilder.remote")
                    : t("stringBuilder.presence")}
                </div>
              </div>
              <div>
                <div className="service-title">
                  {t("wellness.services.duration")}
                </div>
                <div className="service-subtitle">
                  {wellNessNutritional.durationMeeting}
                </div>
              </div>
              <div>
                <div className="service-title">
                  {t("wellness.services.sessions")}
                </div>
                <div className="service-subtitle">
                  {wellNessNutritional.numberMeeting > 1 &&
                    wellNessNutritional.numberMeeting +
                      " " +
                      t("stringBuilder.sessions")}
                  {wellNessNutritional.numberMeeting === 1 &&
                    wellNessNutritional.numberMeeting +
                      " " +
                      t("stringBuilder.session")}
                </div>
              </div>
              <div>
                <div className="service-title">
                  {t("wellness.services.assistance")}
                </div>
                <div className="service-subtitle">
                  {t("wellness.services.included")}
                </div>
              </div>
              {wellNessNutritional.city && (
                <div>
                  <div className="service-title">
                    {t("wellness.services.location")}
                  </div>
                  <div className="service-subtitle">
                    {wellNessNutritional.city}
                  </div>
                </div>
              )}
            </div>
            <div className="text-wrapper-21-description">
              {t("smartPlanDetail.description.description")}
            </div>
            <div className="description-wellness-nutritional-details">
              <Descriptions
                initialText={
                  wellNessNutritional.description
                    ? wellNessNutritional.description
                    : ""
                }
                maxLength={300}
              />
            </div>
            <div className="frame-36">
              <div className="frame-22">
                <div className="text-wrapper-37">
                  {t("smartPlanDetail.questionsAndAnswers.questionsAndAnswers")}
                </div>
                <div className="contattaci-su-wrapper">
                  <p className="contattaci-su">
                    <span className="text-wrapper-39">
                      {t("smartPlanDetail.questionsAndAnswers.contactUs")}
                    </span>
                    <span className="text-wrapper-38">WhatsApp</span>
                    <span className="text-wrapper-39">
                      {t("smartPlanDetail.questionsAndAnswers.callUs")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="container-answer">
              <div className="line-bookNow-initial" />
              {faq.map((el) => (
                <div>
                  <QuestionsAndAnswers title={el.question} desc={el.answer} />
                </div>
              ))}
            </div>
          </div>
          <div className="container-summary">
            <div className="summary-wrapper">
            <WellnessSummaryComponent
              type="NUTRITIONAL"
              email={wellNessNutritional?.userInfo?.email}
              voucherPrice={wellNessNutritional.price}
              telephone={wellNessNutritional?.userInfo?.telephone}
              onClick={() => {
                setBookNowModalId(wellNessNutritional?.id);
              }}
            />
            <AssistanceBoxComponent
              assistance={t("smartPlanDetail.componentAssistantBox.assistance")}
              whatsApp={t("smartPlanDetail.componentAssistantBox.whatsApp")}
              date={t("smartPlanDetail.componentAssistantBox.date")}
              whatsAppNumber={3773604135}
              whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."
                .replace("firstName*", firstName ?? "")
                .replace("lastName*", lastName ?? "")
                .replace("companyName*", businessName ?? "")}
              disabled={undefined}
            />
            </div>
          </div>
          {!_.isEmpty(wellNessNutritional) && bookNowModalId > 0 && (
            <WellnessBookingModal
              onClose={() => {
                navigate(
                  "/Wellness/Nutritional/" + wellNessNutritional.id + "/details"
                );
                setBookNowModalId(-1);
              }}
              onClick={() => {
                store
                  .dispatch(createWellnessOrderAsync({ myOrder: order }))
                  .then((result) => {
                    if (result.meta.requestStatus === "fulfilled") {
                      navigate(
                        "/wellness/nutritional/" +
                          id +
                          "/success/" +
                          result.payload.id
                      );
                    }
                  })
                  .catch(() => {
                    ToastNotification({
                      status: "error",
                      description: "",
                      toastId: "Booking not created",
                      title: t("myOrders.orderFailed"),
                    });
                  });
              }}
              totalCredit={wallet && wallet.currentCredits}
              price={wellNessNutritional?.price}
              isActionPerforming={bookingStatus === "loading" ? true : false}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
