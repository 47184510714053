/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const TwentyIcEverywhere2 = ({
  color = "#36B39E",
  className,
  dimension,
}) => {
  return (
    <svg
      className={`twenty-ic-everywhere-2 ${className}`}
      fill="none"
      height={dimension ? dimension : "20"}
      viewBox="0 0 20 20"
      width={dimension ? dimension : "20"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M19 10.0013L5.65567 2.11505C5.02811 1.74417 4.26998 2.32849 4.46398 3.03352L5.72576 7.61903M19 10.0013L5.66005 17.8849C5.03189 18.2562 4.27332 17.6704 4.46875 16.9651L5.72576 12.4281M19 10.0013L5.72576 7.61903M19 10.0013L5.72576 12.4281M19 10.0013H3M5.72576 7.61903L3 10.0013M3 10.0013L5.72576 12.4281"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

TwentyIcEverywhere2.propTypes = {
  color: PropTypes.string,
  dimension: PropTypes.number,
};
