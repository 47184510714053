import HttpClient from "../../utils/HttpClient";

export default class ActivityRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getSmartPlanActivity(smartPlanId: number) {
    const path = "/smartPlans/" + smartPlanId + "/activities";
    return this.instance.get(path);
  }
}
