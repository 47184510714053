import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Label } from "../Label";
import { Default } from "../icons/Default";
import InputNumber from "../InputNmber";

interface FormModalProps {
  description?: boolean;
  getPersons?: (num: number) => void;
  label?: boolean;
  auxiliarText?: boolean;
  link?: boolean;
  title?: boolean;
  type:
    | "upload-file"
    | "line-separator"
    | "textarea"
    | "check-vertical"
    | "check-horizontal"
    | "title-description"
    | "number"
    | "slider"
    | "select"
    | "text-result"
    | "input";
  className?: string;
  labelText?: string;
  text?: string;
  textDisplayer?: boolean;
}

const FormModal: React.FC<FormModalProps> = ({
  description = true,
  getPersons,
  label = true,
  auxiliarText = true,
  link = true,
  title = true,
  type,
  className,
  labelText = "Label",
  text = "Text",
  textDisplayer = false,
}) => {
  const [value, setValue] = useState(1);

  useEffect(() => {
    if (type === "number") {
      // Chiamo la funzione getPersons solo se il tipo è "number"
      if (typeof getPersons === "function") {
        getPersons(value);
      }
    }
  }, [type, value, getPersons]);

  const handleInputChange = (newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className={`form ${type} ${className}`}>
      {type === "title-description" && (
        <>{title && <div className="text-wrapper-2">Title</div>}</>
      )}

      {type === "line-separator" && <div className="rectangle" />}

      {(type === "check-horizontal" ||
        type === "check-vertical" ||
        type === "input" ||
        type === "number" ||
        type === "select" ||
        type === "text-result" ||
        type === "textarea" ||
        type === "upload-file") && (
        <>
          <>
            {label && (
              <Label
                className="label-instance"
                help={false}
                property1="default"
                request={false}
                rightOptions={false}
                text={labelText}
              />
            )}
          </>
        </>
      )}

      {(type === "check-horizontal" ||
        type === "check-vertical" ||
        type === "input" ||
        type === "select" ||
        type === "text-result" ||
        type === "textarea" ||
        type === "upload-file") && (
        <div className="input-2" style={{ marginTop: "17px" }}>
          {["input", "select", "text-result", "textarea"].includes(type) && (
            <div className={textDisplayer ? "base-displayer" : "base-input"}>
              {["input", "select", "textarea"].includes(type) && (
                <div className="label-3">
                  <div className="label-4">
                    {type === "select" && <>Select</>}

                    {["input", "textarea"].includes(type) && (
                      <>Ricerca membri</>
                    )}
                  </div>
                </div>
              )}

              {type === "select" && (
                <div className="arrow-down">
                  <img
                    className="img"
                    alt="Rectangle"
                    src="https://c.animaapp.com/RbPF2qKd/img/rectangle-101.svg"
                  />
                </div>
              )}

              {type === "text-result" && <>{text}</>}
            </div>
          )}

          {["check-horizontal", "check-vertical"].includes(type) && (
            <>
              <>
                {1 && (
                  <div className="check-label">
                    <div className="check">
                      <div className="rectangle-2" />
                    </div>
                    <div className="label-text-wrapper">
                      <div className="label-text">
                        {type === "check-vertical" && <>Label text</>}

                        {type === "check-horizontal" && <>Label</>}
                      </div>
                    </div>
                  </div>
                )}
              </>
              <>
                {2 && (
                  <div className="check-label">
                    <div className="check">
                      <div className="rectangle-2" />
                    </div>
                    <div className="label-text-wrapper">
                      <div className="label-text">
                        {type === "check-vertical" && <>Label text</>}

                        {type === "check-horizontal" && <>Label</>}
                      </div>
                    </div>
                  </div>
                )}
              </>
              <>
                {3 && (
                  <div className="check-label">
                    <div className="check">
                      <div className="rectangle-2" />
                    </div>
                    <div className="label-text-wrapper">
                      <div className="label-text">
                        {type === "check-vertical" && <>Label text</>}

                        {type === "check-horizontal" && <>Label</>}
                      </div>
                    </div>
                  </div>
                )}
              </>
            </>
          )}

          {type === "upload-file" && (
            <>
              <div className="text-wrapper-3">Drag here your file</div>
              <div className="text-wrapper-4">– or –</div>
              <div className="link">
                <div className="text-wrapper-5">Upload</div>
              </div>
            </>
          )}
        </div>
      )}

      {(type === "check-horizontal" ||
        type === "check-vertical" ||
        type === "input" ||
        type === "select" ||
        type === "text-result" ||
        type === "textarea" ||
        type === "title-description" ||
        type === "upload-file") && (
        <>
          <>
            {description && (
              <div className="description">
                {type === "title-description" && <>Description</>}

                {(type === "check-horizontal" ||
                  type === "check-vertical" ||
                  type === "input" ||
                  type === "select" ||
                  type === "text-result" ||
                  type === "textarea" ||
                  type === "upload-file") && (
                  <div className="text-wrapper-6">Descrizione</div>
                )}
              </div>
            )}
          </>
        </>
      )}

      {type === "number" && (
        <>
          <InputNumber getValue={(value: number) => handleInputChange(value)} />
          <>
            {description && (
              <div className="description-form">
                <div className="text-wrapper-6">Descrizione</div>
              </div>
            )}
          </>
        </>
      )}

      {type === "slider" && (
        <>
          <div className="div-2">
            {label && (
              <Label
                className="label-instance"
                help={false}
                property1="default"
                request={false}
                rightOptions={false}
                text="Label"
              />
            )}

            <div className="div-2">
              <div className="overlap-group-wrapper">
                <div className="overlap-group">
                  <div className="rectangle-3" />
                  <div className="rectangle-4" />
                  <div className="ellipse" />
                  <div className="ellipse-2" />
                </div>
              </div>
              <div className="frame-2">
                <div className="text-wrapper-7">100€</div>
                <div className="text-wrapper-8">8.000€</div>
              </div>
            </div>
          </div>
          <>
            {description && (
              <div className="description-form">
                <div className="text-wrapper-6">Descrizione</div>
              </div>
            )}
          </>
        </>
      )}

      {type === "title-description" && (
        <>
          <>
            {auxiliarText && (
              <div className="div-wrapper">
                <div className="text-wrapper-9">Description</div>
              </div>
            )}
          </>
          <>
            {link && (
              <div className="link-wrapper">
                <div className="link-2">
                  <Default className="arrow-down" />
                  <div className="frame-3">
                    <div className="text-wrapper-5">Link</div>
                  </div>
                </div>
              </div>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default FormModal;
