import HttpClient from "../../utils/HttpClient";

export default class FaqRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getSmartPlanFaqs(smartPlanId: number) {
    const path = "/smartPlans/" + smartPlanId + "/faqs";
    return this.instance.get(path);
  }

  public getMasterClassFaqs(masterClassId: number) {
    const path = "/masterClasses/" + masterClassId + "/faqs";
    return this.instance.get(path);
  }

  public getWellnessPsychoFaqs(wellnessPsychoId: number) {
    const path = "/wellnessPsycho/" + wellnessPsychoId + "/faqs";
    return this.instance.get(path);
  }

  public getWellnessBodyFaqs(wellnessBodyId: number) {
    const path = "/wellnessBody/" + wellnessBodyId + "/faqs";
    return this.instance.get(path);
  }

  public getWellnessNutritionalFaqs(wellnessBodyId: number) {
    const path = "/wellnessNutritional/" + wellnessBodyId + "/faqs";
    return this.instance.get(path);
  }
  public getOfficeCoworkingFaqs(officeCoworkingId: number) {
    const path = "/officeCoworkings/" + officeCoworkingId + "/faqs";
    return this.instance.get(path);
  }
}
