import React from "react";

import "./ServiceCard.css";

interface ServiceCardProps {
  title: string;
  subtitle: string;
  color: string;
  icon: any;
  triggered: (e: any) => any;
}

export const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  subtitle,
  triggered,
  color,
  icon,
}) => {
  return (
    <>
      <div
        className="service-card-container"
        style={{ background: color }}
        onClick={triggered}
      >
        <div className="service-card-icon-layout">{icon}</div>
        <div className="service-card-title">{title}</div>
        <div className="service-card-subtitle">{subtitle}</div>
      </div>
    </>
  );
};
