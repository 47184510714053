import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { Button } from "../../ui/components/Button/Button";
import "./VirtualCoworking.css";
import {
  VirtualCoworkingSlice,
  getLinkGatherAsync,
  virtualCoworkingSelector,
} from "./VirtualCoworkingSlice";
import { t } from "i18next";
import { IconDownload } from "../../ui/components/icons/IconDownload";
import { TwentyIcLaptop } from "../../ui/components/icons/TwentyIcLaptop";
import _ from "lodash";
export const VirtualCoworking = () => {
  const [id, setId] = useState<number>(-1);

  let virtualCoworking: VirtualCoworkingSlice = useAppSelector(
    (state) =>
      virtualCoworkingSelector.selectById(state, id) ??
      ({} as VirtualCoworkingSlice)
  );
  const tenantId = localStorage.getItem("tenantId");

  const securityProfile = localStorage.getItem("securityProfile");

  useEffect(() => {
    if (tenantId !== null) {
      store
        .dispatch(getLinkGatherAsync(parseInt(tenantId)))
        .then((response) => {
          setId(response?.payload?.id);
        });
      return;
    }
  }, [tenantId]);

  return (
    <>
      <div className="home-empty">
        <div className="div-3">
          <div className="frame-10">
            <div className="element-ic-lesson-wrapper">
              <TwentyIcLaptop className="element-ic-lesson" color="#36B39E" />
            </div>
            <div className="text-wrapper-6">
              {securityProfile === "PROVIDER"
                ? t("virtualCoworking.headerProvider")
                : t("virtualCoworking.header")}
            </div>
          </div>
          <div className="overlap">
            <div className="frame-11">
              <img
                className="rectangle-5"
                alt="Rectangle"
                src="https://everywheresb.s3.eu-central-1.amazonaws.com/images/Coworkings/VirtualCoworking.png"
              />
              <div className="title-card">Coworking virtuale</div>
              <div className="frame-wrapper">
                <div className="ti-presentiamo">
                  {t("virtualCoworking.description2")}
                </div>
              </div>
              <div className="button-instance">
                <Button
                  type="primary"
                  text="Inizia"
                  size="XL"
                  state={undefined}
                  onClick={() => {
                    window.open(virtualCoworking.link);
                  }}
                  disabled={undefined}
                  iconRight={undefined}
                />
              </div>
              <a
                href={
                  "https://host.everywheretew.it/hubfs/guidacoworkingvirtuale2023.pdf"
                }
                target="_blank"
                rel="noopener noreferrer"
                className="button-download"
              >
                <div
                  className="coworking-downloadpdf-button"
                  onClick={() => {}}
                >
                  <div className="coworking-downloadpdf-button-text">
                    {t("virtualCoworking.downloadGuide")}
                  </div>
                  <IconDownload className={""} color="#36B39E"></IconDownload>
                </div>
              </a>
            </div>

            {_.isEqual(securityProfile, "ADMIN") ||
            _.isEqual(securityProfile, "EMPLOYEE") ? (
              <div className="frame-11">
                <img
                  className="rectangle-5"
                  alt="Rectangle"
                  src="https://everywheresb.s3.eu-central-1.amazonaws.com/images/Coworkings/VirtualCoworkingMeta.png"
                />
                <div className="title-card">Coworking nel Metaverso</div>
                <div className="frame-wrapper">
                  <div className="ti-presentiamo">
                    {t("virtualCoworking.descriptionMeta")}
                  </div>
                </div>
                <div className="button-instance">
                  <Button
                    type="primary"
                    text="Inizia"
                    size="XL"
                    state={undefined}
                    onClick={() => {
                      window.open(
                        "https://game.coderblock.com/#id=Metaverse1&x=40&z=29"
                      );
                    }}
                    disabled={undefined}
                    iconRight={undefined}
                  />
                </div>
                <a
                  href={"https://bit.ly/Metaverso-Everywhere"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button-download"
                >
                  <div
                    className="coworking-downloadpdf-button"
                    onClick={() => {}}
                  >
                    <div className="coworking-downloadpdf-button-text">
                      {t("virtualCoworking.downloadGuide")}
                    </div>
                    <IconDownload className={""} color="#36B39E"></IconDownload>
                  </div>
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
