import React from "react";
import "./BaseInfo.css";

export const BaseInfo = (): JSX.Element => {
  return (
    <>
      <div className="base-info">
        Gli annullamenti devono essere effettuati entro 1 mese prima della
        partenza. Si prega di leggere la nostra{" "}
        <a
          href="https://everywheretew.it/privacy-policy-piattaforma"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="privacy-policy">Privacy Policy</div>
        </a>
      </div>
    </>
  );
};
