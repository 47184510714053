//#region Type

import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import TenantRepository from "./TenantRepository";
import _ from "lodash";
import { Config } from "../../config/Config";
import { RootState } from "../../app/store";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";

export interface TenantAdditionalInfo {
  id: number;
  name: string;
  email: string;
  link: string;
  linkMeta: string;
}

//#endRegion API

//#region Slice

export const getTenantAdditionalInfoAsync = createAsyncThunk(
  "tenant/getTenantAdditionalInfo",
  async (tenantId: any) => {
    const tenantRepository = new TenantRepository();
    const response = await tenantRepository.getTenantAdditionalInfo(tenantId);
    const tenant = _.get(response, Config.TENANT_RESPONSE);
    return tenant;
  }
);

//#endRegion API

//#region Slice

const tenantAdditionalInfoAdapter = createEntityAdapter<TenantAdditionalInfo>({
  selectId: (tenantAdditionalInfo) => tenantAdditionalInfo.id,
});

export const tenantAdditionalInfoSlice = createSlice({
  name: "tenantAdditionalInfo",
  initialState: tenantAdditionalInfoAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    tenantAdditionalInfoEmptyState: (state) => {
      tenantAdditionalInfoAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getTenantAdditionalInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          tenantAdditionalInfoAdapter.upsertOne(state, action.payload);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getTenantAdditionalInfoAsync.pending, (state: any) => {
        state.statys = "loading";
        state.reasonCode = "";
      })
      .addCase(getTenantAdditionalInfoAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const tenantAdditionalInfoSelector =
  tenantAdditionalInfoAdapter.getSelectors<RootState>(
    (state) => state.tenantAdditionalInfo
  );

export const { tenantAdditionalInfoEmptyState } =
  tenantAdditionalInfoSlice.actions;
export const selectTenantAdditionalInfoSliceStatus = (state: any) =>
  state.tenant.status;
export const selectTenantAdditionalInfoSliceReasonCode = (state: any) =>
  state.tenant.reasonCode;

//#endRegion Status

export default tenantAdditionalInfoSlice.reducer;
