import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import TransactionRepository from "./TransactionRepository";
import { EverywhereSuccessCodes } from "../../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../../config/EverywhereErrorCodes";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import _ from "lodash";

export interface TransactionSlice {
  id: number;
  uuid: string;
  type: string;
  date: Date;
  balance: number;
  totalCreditSent: number;
  user: number;
  tenant: number;
}

//#region API

export const getTransactionsAsync = createAsyncThunk(
  "getTransactionsAsync/getTransactions",
  async () => {
    const transactionRepository = new TransactionRepository();
    const response = await transactionRepository.getTransactions();
    const transaction = _.get(response, Config.USER_INFO_RESPONSE);
    return transaction;
  }
);

//#endRegion API

//#region Slice

const transactionAdapter = createEntityAdapter<TransactionSlice>({
  selectId: (transaction) => transaction.uuid,
});

export const transactionSlice = createSlice({
  name: "transactionSlice",
  initialState: transactionAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    transactionEmptyState: (state) => {
      transactionAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getTransactionsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          transactionAdapter.setAll(state, action.payload ?? []);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getTransactionsAsync.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(getTransactionsAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const transactionSelector = transactionAdapter.getSelectors<RootState>(
  (state) => state.transaction
);

export const { transactionEmptyState } = transactionSlice.actions;
export const selectTransactionSliceStatus = (state: any) =>
  state.transaction.status;
export const selectTransactionSliceReasonCode = (state: any) =>
  state.transaction.reasonCode;

//#endRegion Status

export default transactionSlice.reducer;
