/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const IconCalendario = ({ color = "#161938", className }) => {
  return (
    <svg
      className={`icon-calendario ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rect"
        height="16"
        rx="2"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
        width="16"
        x="2"
        y="2"
      />
      <path className="path" d="M17 6L3 6" stroke={color} strokeLinejoin="round" strokeWidth="2" />
      <rect className="rect" fill={color} height="2" width="2" x="5" y="9" />
      <rect className="rect" fill={color} height="2" width="2" x="5" y="13" />
      <rect className="rect" fill={color} height="2" width="2" x="9" y="9" />
      <rect className="rect" fill={color} height="2" width="2" x="9" y="13" />
      <rect className="rect" fill={color} height="2" width="2" x="13" y="9" />
      <rect className="rect" fill={color} height="2" width="2" x="13" y="13" />
    </svg>
  );
};

IconCalendario.propTypes = {
  color: PropTypes.string,
};
