//#Region Type

import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import ChangePasswordRepository from "./ChangePasswordRepository";
import _ from "lodash";
import { Config } from "../../config/Config";

import { RootState } from "../../app/store";

export interface ChangePassword {
  id: number;
  newPassword: string;
  oldPassword?: string;
  userId: number;
  lastUpdate: Date;
}

//#endRegion Type

//#region API

export const updatePasswordAsync = createAsyncThunk(
  "changePassword/updatePassword",
  async (data: { changePassword: ChangePassword }) => {
    const changePasswordRepository = new ChangePasswordRepository();
    const response = await changePasswordRepository.changePassword(
      data.changePassword
    );
    const changePassword = _.get(response, Config.CHANGE_PASSWORD_RESPONSE);
    return changePassword;
  }
);

export const updatePasswordOnFirstAccessAsync = createAsyncThunk(
  "changePassword/updatePasswordOnFirstAccess",
  async (data: { changePassword: ChangePassword }) => {
    const changePasswordRepository = new ChangePasswordRepository();
    const response = await changePasswordRepository.changePasswordOnFirstAccess(
      data.changePassword
    );
    const changePassword = _.get(response, Config.CHANGE_PASSWORD_RESPONSE);
    return changePassword;
  }
);

//#endRegion API

//#region Slice

const changePasswordAdapter = createEntityAdapter<ChangePassword>({
  selectId: (changePassword) => changePassword.id,
});

export const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState: changePasswordAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    elementOrdered: 0,
  }),
  reducers: {
    changePasswordEmptyState: (state) => {
      changePasswordAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
      state.elementOrdered = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        updatePasswordAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          changePasswordAdapter.upsertOne(state, action.payload.lastUpdate);

          state.status = "idle";
          state.reasonCode = "";
        }
      )
      .addCase(updatePasswordAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(updatePasswordAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        updatePasswordOnFirstAccessAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          changePasswordAdapter.upsertOne(state, action.payload.lastUpdate);

          state.status = "idle";
          state.reasonCode = "";
        }
      )
      .addCase(updatePasswordOnFirstAccessAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(updatePasswordOnFirstAccessAsync.pending, (state: any) => {
        state.status = "loading";
      });
  },
});

//#endRegion Slice

//#region Status

export const changePasswordSelector =
  changePasswordAdapter.getSelectors<RootState>(
    (state) => state.changePassword
  );

export const { changePasswordEmptyState } = changePasswordSlice.actions;
export const selectChangePaswordSliceStatus = (state: any) =>
  state.changePassword.status;
export const selectChangePaswordSliceReasonCode = (state: any) =>
  state.changePassword.reasonCode;
//#endRegion Status

export default changePasswordSlice.reducer;
