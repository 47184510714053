import React, { useEffect, useState } from "react";
import "./SmartDatePlanModal.css";
import { ModalWrapper } from "../../../../ui/components/Modals/ModalWrapper";
import { ModalBody } from "../../../../ui/components/Modals/ModalBody";
import { ModalFooter } from "../../../../ui/components/Modals/ModalFooter";
import { t } from "i18next";
import { IconCalendario } from "../../../../ui/components/icons/IconCalendario";
import FormModal from "../../../../ui/components/Form-Modal/FormModal";
import { formatNumber } from "../../../../utils/utils";

interface SmartDatePlanModalProps {
  onClose: () => void;
  onClick: (data?: number) => void;
  title: string;
  month: string;
  startDay: string;
  endDay: string;
  deposit: number;
  price: number;
  isActionPerforming: boolean;
}

export const SmartDatePlanModal: React.FC<SmartDatePlanModalProps> = ({
  onClose,
  onClick,
  title,
  endDay,
  startDay,
  month,
  deposit,
  isActionPerforming,
}) => {
  const percentIva = (deposit / 100) * 22;
  const [ivaMolt, setIvaMolt] = useState(0);
  const [despositMolt, setDepositMolt] = useState(0);
  const [total, setTotal] = useState(0);
  const [numPerson, setNumPerson] = useState(0);

  function moltiplicatePrice(value: any, persons: any) {
    const result = value * persons;
    return result;
  }

  useEffect(() => {
    const newIvaMolt = moltiplicatePrice(percentIva, numPerson);
    const newDespositMolt = moltiplicatePrice(deposit, numPerson);
    const newTotal = ivaMolt + despositMolt;

    setIvaMolt(newIvaMolt);
    setDepositMolt(newDespositMolt);
    setTotal(newTotal);
  }, [numPerson, total, despositMolt, ivaMolt]);

  return (
    <div className="">
      <ModalWrapper open={true} closeAction={onClose}>
        <ModalBody title={title}>
          <div className="description-modal">
            {t("smartPlanDetail.bookNow.bookNowModal.description")}
          </div>
          <div className="rectangle-5" />
          <div className="string-date-modal">
            {" "}
            {t("smartPlanDetail.bookNow.bookNowModal.book")}
          </div>
          <div className="frame-45">
            <div className="frame-46">
              <IconCalendario
                className="icon-instance-node-3"
                color="#36B39E"
              />
              <div className="text-wrapper-30">{month}</div>
            </div>
            <div className="text-wrapper-31">•</div>
            <div className="text-wrapper-32">
              {t("smartPlanDetail.bookNow.from")} {startDay}
              {t("smartPlanDetail.bookNow.to")} {endDay}
            </div>
          </div>
          <div className="rectangle-5" />
          <div className="buy-frame">
            <FormModal
              className="form-instance"
              description={false}
              labelText={t("smartPlanDetail.bookNow.bookNowModal.persons")}
              type="number"
              getPersons={setNumPerson}
            />
            <div className="div-2">
              <FormModal
                className="form-3"
                description={false}
                labelText={t("smartPlanDetail.bookNow.bookNowModal.deposit")}
                text={formatNumber(despositMolt) + "€"}
                type="text-result"
                textDisplayer={true}
              />
              <FormModal
                className="form-3"
                description={false}
                labelText="IVA (22%)"
                text={"+" + formatNumber(ivaMolt) + "€"}
                type="text-result"
                textDisplayer={true}
              />
              <FormModal
                className="form-3"
                description={false}
                labelText={t("smartPlanDetail.bookNow.bookNowModal.total")}
                text={formatNumber(total) + "€"}
                type="text-result"
                textDisplayer={true}
              />
            </div>
          </div>
          <div className="rectangle-5" />
          <div className="desc-modal">
            {t("smartPlanDetail.bookNow.bookNowModal.descModal")}
          </div>
        </ModalBody>
        <ModalFooter
          primaryLabel={t("smartPlanDetail.bookNow.bookNowModal.ok")}
          secondaryLabel={t("smartPlanDetail.bookNow.bookNowModal.cancel")}
          primaryAction={() => onClick(numPerson)}
          secondaryAction={onClose}
          isActionPerforming={isActionPerforming}
        />
      </ModalWrapper>
    </div>
  );
};

export default SmartDatePlanModal;
