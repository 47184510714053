import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import HeaderMenu from "../../layout/HeaderMenu";
import { Footer } from "../../screens/Footer/Footer";
import { AssistanceBoxComponent } from "../../ui/components/AssistanceBoxComponent";
import AssignedPeople from "../../ui/components/MyOrders/AssignedPeople";
import OrderDetailCard from "../../ui/components/MyOrders/OrderDetailCard";
import { OrderSummary } from "../../ui/components/MyOrders/OrderSummary";
import { TopBar } from "../../ui/components/TopBar/TopBar";
import { TwentyIcArrowLineLeft } from "../../ui/components/icons/TwentyIcArrowLineLeft";
import {
  TeamUserInfo,
  getFilteredUsersInfoPaginationAsync,
  teamUserInfoSelectors,
} from "../MyTeam/TeamUsersInfoSlice";
import { SmartDatePlan } from "../Smartplan/SmartDatePlan/SmartDatePlanSlice";
import { SmartPlan } from "../Smartplan/SmartPlanSlice";
import {
  MyOrderDetail,
  getOrderAsync,
  myOrderDetailSelector,
  myOrdersEmptyState,
} from "./MyOrderDetailSlice";
import "./OrderDetail.css";
import { userInfoEmptyState } from "../UserInfo/UserSlice";

export interface SmartPlanOrderDetailProps {}

const SmartPlanOrderDetail: React.FC<SmartPlanOrderDetailProps> = () => {
  const navigate = useNavigate();

  const [id, setId] = useState<number>(-1);
  const [smartPlan, setSmartPlan] = useState<SmartPlan>();
  const [smartPlanDate, setSmartPlanDate] = useState<SmartDatePlan>();
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const businessName = localStorage.getItem("businessName");
  const admin = localStorage.getItem("securityProfile") === "ADMIN";

  const orderRoute = useMatch("smartPlan/myOrders/:id");

  const teamUsersInfo: TeamUserInfo[] = useAppSelector(
    teamUserInfoSelectors.selectAll
  );

  let order: MyOrderDetail = useAppSelector(
    (state) =>
      myOrderDetailSelector.selectById(state, id) ?? ({} as MyOrderDetail)
  );

  let firstLoad: boolean = false;

  useEffect(() => {
    if (smartPlanDate) {
      let startDay = new Date(Date.parse(smartPlanDate.startDay));
      let endDay = new Date(Date.parse(smartPlanDate.endDay));
      let startDate = startDay.toLocaleDateString("it-IT", {
        weekday: "long",
        month: "short",
        day: "numeric",
      });
      let endDate = endDay.toLocaleDateString("it-IT", {
        weekday: "long",
        month: "short",
        day: "numeric",
      });
      setStartDate(startDate);
      setEndDate(endDate);
    }
  }, [smartPlanDate]);

  useEffect(() => {
    if (!firstLoad) {
      if (orderRoute !== null) {
        if (orderRoute.params.id) {
          setId(parseInt(orderRoute.params.id));
          store.dispatch(getOrderAsync(parseInt(orderRoute.params.id)));
          store.dispatch(getFilteredUsersInfoPaginationAsync());
          firstLoad = true;
          return;
        }
      }
    }
  }, [orderRoute]);

  useEffect(() => {
    if (order.smartPlan) {
      setSmartPlan(order.smartPlan);
      setSmartPlanDate(order.smartPlanDate);
    }
  }, [order]);

  useEffect(() => {
    store.dispatch(userInfoEmptyState);
    store.dispatch(myOrdersEmptyState);
  }, []);

  return (
    <>
      {smartPlan && smartPlanDate && (
        <>
          <HeaderMenu>
            <TopBar
              title={smartPlan.city + " • " + smartPlan.region}
              hasEdit={false}
              hasDate={false}
              breadcrumbText={"SmartPlan > Dettaglio ordine"}
              icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
              onClick={() => navigate("/smartplan/myOrders")}
            />
          </HeaderMenu>
          <div className="order-detail">
            <div className="order-detail-left-side">
              <OrderDetailCard
                id={order.id}
                date={order.bookingDate}
                price={smartPlanDate.price}
                deposit={smartPlanDate.deposit.toString()}
                image={smartPlan.image}
                status={order.status}
                personsNumber={order.numberOfPeople}
              />
              {admin ? (
                <>
                  <AssignedPeople
                    bookingId={order.id}
                    currentUsers={order.userInfo}
                    users={teamUsersInfo}
                    numberOfPeople={order.numberOfPeople}
                  />
                </>
              ) : null}
            </div>
            <div className="order-detail-right-side">
              <OrderSummary
                departure={startDate}
                comeBack={endDate}
                price={smartPlanDate.price}
                numberOfPeople={order.numberOfPeople}
                bookingStatus={order.status}
              />
              <div className="summary-column">
                <AssistanceBoxComponent
                  assistance={t(
                    "smartPlanDetail.componentAssistantBox.assistance"
                  )}
                  whatsApp={t("smartPlanDetail.componentAssistantBox.whatsApp")}
                  date={t("smartPlanDetail.componentAssistantBox.date")}
                  whatsAppNumber={3773604135}
                  whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."
                    .replace("firstName*", firstName ?? "")
                    .replace("lastName*", lastName ?? "")
                    .replace("companyName*", businessName ?? "")}
                  disabled={undefined}
                />
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default SmartPlanOrderDetail;
