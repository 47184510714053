import { useNavigate } from "react-router-dom";
import HeaderMenu from "../../layout/HeaderMenu";
import { TwentyIcEverywhere } from "../../ui/components/icons/TwentyIcEverywhere";
import { TwentyIcLaptop } from "../../ui/components/icons/TwentyIcLaptop";
import { TwentyIcLesson } from "../../ui/components/icons/TwentyIcLesson";
import { TopBar } from "../../ui/components/TopBar/TopBar";
import { Footer } from "../Footer/Footer";
import "./Home.css";
import { useAppSelector } from "../../app/hooks";

import { useEffect, useState } from "react";
import { store } from "../../app/store";
import {
  SmartPlanActivityTail,
  getSmartPlanActivityTailAsync,
  smartPlanActivityTailSelector,
} from "../../feature/Smartplan/SmartPlanActivityTailSlice";
import ActivityTailComponent from "../../ui/components/ActivityTail/SmartPlanActivityTail";
import { TwentyIcHandServices1 } from "../../ui/components/TwentyIcHandServices1";
import Card from "antd/es/card/Card";
import {
  PublicAccount,
  getPublicAccountsAsync,
  publicAccountSelector,
} from "../../feature/Account/PublicAccount/PublicAccountSlice";
import { Vector } from "../../ui/components/icons/Vector/Vector";
import TableTag from "../../ui/components/Table/TableTag";
import { TwentyIcZen } from "../../ui/components/icons/TwentyIcZen";
import {
  MyDiaryEmotions,
  myDiaryEmptyState,
  myDiarySelector,
  retriveEmotionsAsync,
} from "../../feature/MyDiary/MyDiarySlice";
import { MyDiary } from "../../feature/MyDiary/MyDiary";
import {
  UserInfo,
  getUserInfoAsync,
  userInfoSelector,
} from "../../feature/UserInfo/UserSlice";
import {
  WellNessTableSlice,
  getWellnessTableAsync,
  wellnessTableSelector,
} from "../../feature/Wellness/WellnessSlice/WellNessTableSlice";
import {
  Tenant,
  getTenantAsync,
  tenantSelector,
} from "../../feature/Tenant/TenantSlice";
import _ from "lodash";

export const Home = () => {
  const name = localStorage.getItem("firstName");
  const tenantId = localStorage.getItem("tenantId");

  const navigate = useNavigate();

  const profile = localStorage.getItem("securityProfile");
  const userId = localStorage.getItem("customerId");

  const [diaryActivated, setDiaryActivated] = useState(false);
  const [diaryCompleted, setDiaryCompleted] = useState(false);

  let activityTail: SmartPlanActivityTail[] = useAppSelector(
    smartPlanActivityTailSelector.selectAll
  );

  let tenant: Tenant = useAppSelector(
    (state) =>
      tenantSelector.selectById(state, tenantId ?? "") ?? ({} as Tenant)
  );

  let userInfo: UserInfo = useAppSelector(
    (state) =>
      userInfoSelector.selectById(state, parseInt(userId ?? "")) ??
      ({} as UserInfo)
  );

  const publicAccount: PublicAccount[] = useAppSelector(
    publicAccountSelector.selectAll
  );

  const myEmotions: MyDiaryEmotions[] = useAppSelector(
    myDiarySelector.selectAll
  );

  const wellnessTable: WellNessTableSlice[] = useAppSelector(
    wellnessTableSelector.selectAll
  );

  const isEmpty: boolean =
    _.isEmpty(userInfo.telephone) ||
    _.isEmpty(userInfo.fiscalCode) ||
    _.isEmpty(userInfo.city) ||
    _.isEmpty(tenant.name) ||
    _.isEmpty(tenant.vatNumber) ||
    _.isEmpty(tenant.zip) ||
    _.isEmpty(tenant.address) ||
    _.isEmpty(tenant.telephone);

  let refresh = false;

  function mydiaryShowed() {
    if (myEmotions.length === 0) {
      setDiaryActivated(true);
      setDiaryCompleted(false);
    } else {
      setDiaryActivated(true);
      setDiaryCompleted(true);
    }
  }

  useEffect(() => {
    mydiaryShowed();
  }, [myEmotions]);

  useEffect(() => {
    store.dispatch(myDiaryEmptyState());
    if (!refresh) {
      store.dispatch(getSmartPlanActivityTailAsync({}));

      refresh = true;
    }
    store.dispatch(getPublicAccountsAsync());
    mydiaryShowed();
    const today = new Date();
    const startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59,
      999
    );

    store.dispatch(
      retriveEmotionsAsync({ startDate: startDate, endDate: endDate, userId })
    );
    store.dispatch(
      getUserInfoAsync({
        userInfoId: userId,
        tenantId: tenantId,
      })
    );
    store.dispatch(getTenantAsync(tenantId));
    store.dispatch(getWellnessTableAsync());
    localStorage.setItem("isLoggedIn", "true");
  }, []);

  return (
    <>
      <HeaderMenu>
        <TopBar
          title={"Ciao " + name}
          hasEdit={false}
          hasDate={true}
          breadcrumbText={""}
        />
      </HeaderMenu>
      <div className="home-content">
        <div className="welcome-image">
          <span className="welcome-text">
            Siamo lieti di darti il benvenuto sulla piattaforma Everywhere!
          </span>
        </div>
        {profile === "PROVIDER" && (
          <div className="advice">
            <div className="advice-text">Alcuni consigli</div>
            <div className="advice-card">
              <div className="first-card">
                <Card>
                  <div className="row-card">
                    <div className="initial-icon-row">
                      {publicAccount.length === 0 ? (
                        <div className="number-initial-icon-row">1</div>
                      ) : (
                        <div className="icon-initial-icon-row">
                          <Vector />
                        </div>
                      )}
                    </div>
                    <div className="text-initial-row">
                      Completa i tuoi dati pubblici
                    </div>
                    {publicAccount.length === 0 && (
                      <div className="tag-row">
                        <TableTag text="Necessario" />
                      </div>
                    )}
                    <div
                      className="navigate-row"
                      onClick={() =>
                        navigate(`/account/${userId}/details/public-account`)
                      }
                    >
                      Il mio account pubblico &gt;
                    </div>
                  </div>
                </Card>
              </div>
              <div className="second-card">
                <Card>
                  <div className="row-card">
                    <div className="initial-icon-row">
                      {isEmpty ? (
                        <div className="number-initial-icon-row">2</div>
                      ) : (
                        <div className="icon-initial-icon-row">
                          <Vector />
                        </div>
                      )}
                    </div>
                    <div className="text-initial-row">
                      Completa i tuoi dati personali e aziendali
                    </div>

                    <div
                      className="navigate-row"
                      onClick={() => navigate(`/account/${userId}/details`)}
                    >
                      Il mio account &gt;
                    </div>
                  </div>
                </Card>
              </div>
              <div className="third-card">
                <Card>
                  <div className="row-card">
                    <div className="initial-icon-row">
                      {wellnessTable.length === 0 ? (
                        <div className="number-initial-icon-row">3</div>
                      ) : (
                        <div className="icon-initial-icon-row">
                          <Vector />
                        </div>
                      )}
                    </div>
                    <div className="text-initial-row">Crea un servizio</div>

                    <div
                      className="navigate-row"
                      onClick={() => navigate(`/myService`)}
                    >
                      I miei servizi &gt;
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        )}
        {diaryActivated && profile !== "PROVIDER" && (
          <MyDiary
            userFirstName={name ?? ""}
            completed={diaryCompleted}
            refreshed={() => {
              mydiaryShowed();
            }}
          />
        )}
        {profile !== "SUPER_ADMIN" && (
          <div className="frame-16">
            <div className="text-wrapper-6">Accessi rapidi</div>
            <div className="frame-26">
              {profile === "SUPER_ADMIN" ||
              profile === "ADMIN" ||
              profile === "EMPLOYEE" ? (
                <div className="frame-27">
                  <div
                    className="fram-28"
                    onClick={() => {
                      navigate("/masterclass");
                    }}
                  >
                    <div className="frame-29">
                      <TwentyIcLesson color="#36B39E" className={undefined} />
                    </div>
                    <div className="text-wrapper-12">Masterclass</div>
                    <div className="text-wrapper-13">La formazione 2.0</div>
                  </div>

                  <div
                    className="frame-32"
                    onClick={() => {
                      navigate("/smartPlan");
                    }}
                  >
                    <div className="frame-29">
                      <TwentyIcEverywhere
                        color="#36B39E"
                        className={undefined}
                      />
                    </div>
                    <div className="text-wrapper-12">Smart Plan</div>
                    <div className="text-wrapper-13">
                      I migliori piani produttivi
                    </div>
                  </div>
                  <div
                    className="frame-30"
                    onClick={() => {
                      navigate("/virtualCoworking");
                    }}
                  >
                    <div className="frame-29">
                      <TwentyIcLaptop color="#36B39E" className={undefined} />
                    </div>
                    <div
                      className="text-wrapper-12"
                      onClick={() => {
                        navigate("/virtualCoworking");
                      }}
                    >
                      Coworking Virtuale
                    </div>
                    <div className="text-wrapper-13">
                      Collegati ovunque tu sia
                    </div>
                  </div>

                  <div
                    className="frame-380"
                    onClick={() => {
                      navigate("/Wellness");
                    }}
                  >
                    <div className="frame-29">
                      <TwentyIcZen color="#36B39E" className={undefined} />
                    </div>
                    <div className="text-wrapper-12">
                      Servizi per il benessere
                    </div>
                    <div className="text-wrapper-13">
                      Siamo vicini alle tue esigenze
                    </div>
                  </div>
                </div>
              ) : null}
              {profile === "PROVIDER" ? (
                <div
                  className="frame-28-myService"
                  onClick={() => {
                    navigate("/myService");
                  }}
                >
                  <div className="frame-29">
                    <TwentyIcHandServices1
                      color="#36B39E"
                      className={undefined}
                    />
                  </div>
                  <div className="text-wrapper-12">I miei Servizi</div>
                </div>
              ) : null}
            </div>
          </div>
        )}
        {profile === "SUPER_ADMIN" ||
        profile === "ADMIN" ||
        profile === "EMPLOYEE" ? (
          <div className="frame-36">
            <div className="frame-17">
              <div className="text-wrapper-6">Top 3 · Smart Plan</div>
              <div
                className="text-wrapper-16"
                onClick={() => {
                  navigate("/smartPlan");
                }}
              >
                Vedi tutti Smart Plan
              </div>
            </div>
            <div className="frame-37">
              {activityTail.slice(0, 3).map((el) => (
                <ActivityTailComponent
                  id={el.id}
                  img={el.image}
                  startDate={el.startDate}
                  endDate={el.endDate}
                  city={el.city}
                  region={el.region}
                  previewDescription={el.previewDescription}
                  minPeople={el.minPeople}
                  maxPeople={el.maxPeople}
                  placesLeft={el.placesLeft}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className="footer-bottom">
        <Footer />
      </div>
    </>
  );
};
