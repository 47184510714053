/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcDelete = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-delete ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4 8C4 10.8794 4.39796 13.7588 4.73553 15.6833C4.97519 17.0496 6.17803 18 7.56519 18H12.4348C13.822 18 15.0248 17.0496 15.2645 15.6833C15.602 13.7588 16 10.8794 16 8"
        stroke={color}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M17 5H3"
        stroke={color}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M11 2H9"
        stroke={color}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
