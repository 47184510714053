/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcNotify = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-notify ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4 6C4 3.79086 5.79086 2 8 2H12C14.2091 2 16 3.79086 16 6V9L17 16H3L4 9V6Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M8 16C8 16.2626 8.05173 16.5227 8.15224 16.7654C8.25275 17.008 8.40007 17.2285 8.58579 17.4142C8.7715 17.5999 8.99198 17.7472 9.23463 17.8478C9.47728 17.9483 9.73736 18 10 18C10.2626 18 10.5227 17.9483 10.7654 17.8478C11.008 17.7472 11.2285 17.5999 11.4142 17.4142C11.5999 17.2285 11.7472 17.008 11.8478 16.7654C11.9483 16.5227 12 16.2626 12 16L8 16Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
