import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Space, Switch } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { t } from "i18next";
import _, { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { ImageConfig } from "../../config/ImageConfig";
import { EmptyState } from "../../ui/components/EmptyState";
import TableTag from "../../ui/components/Table/TableTag";
import { TwentyIcDelete } from "../../ui/components/icons/TwentyIcDelete";
import { TwentyIcDoc } from "../../ui/components/icons/TwentyIcDoc";
import { TwentyIcEdit } from "../../ui/components/icons/TwentyIcEdit";
import "../MyService/MyService.css";
import {
  WellNessTableSlice,
  getWellnessTableAsync,
  wellnessTableSelector,
} from "../Wellness/WellnessSlice/WellNessTableSlice";
import {
  createWellnessBodyAsync,
  deleteWellnessBodyAsync,
  getWellnessBodyDetailsAsync,
  updateWellnessApprovedAsync,
  updateWellnessBodyAsync,
} from "../Wellness/WellnessSlice/WellnessBodySlice";
import {
  createWellnessNutritionalAsync,
  deleteWellnessNutritionalAsync,
  getWellnessNutritionalDetailsAsync,
  updateWellnessNutritionalAsync,
} from "../Wellness/WellnessSlice/WellnessNutritionalSlice";
import {
  createWellnessPsychoAsync,
  deleteWellnessPsychoAsync,
  getWellnessPsychoDetailsAsync,
  updateWellnessPsychoAsync,
} from "../Wellness/WellnessSlice/WellnessPsychoSlice";
import { MyServiceFilterBar } from "./MyServiceFilterBar";
import { AddServiceModal } from "./MyServiceModal/AddServiceModal";
import { DeleteServiceModal } from "./MyServiceModal/DeleteServiceModal";

import { Footer } from "../../screens/Footer/Footer";
import {
  PublicAccount,
  getPublicAccountByTenantIdAndUserIdAsync,
  getPublicAccountsAsync,
} from "../Account/PublicAccount/PublicAccountSlice";

import { TwentyIcEye2 } from "../../ui/components/icons/TwentyIcEye2";
import { ToastNotification } from "../../ui/components/utils/ToastNotification";
import { itemsEmptyState } from "../Gallery/GalleryPreviewSlice";
import { formatNumber } from "../../utils/utils";
import { IconTik } from "../../ui/components/icons/IconTik";
import { TwentyIcClose } from "../../ui/components/TwentyIcClose";

export const MyService = () => {
  const [addServiceModalOpened, setAddServiceModalOpened] = useState(false);
  const [deleteServiceModalOpened, setDeleteServiceModalOpened] =
    useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const [serviceType, setServiceType] = useState("");

  const wellnessTable: WellNessTableSlice[] = useAppSelector(
    wellnessTableSelector.selectAll
  );

  const [publicAccount, setPublicAccount] = useState<PublicAccount>();
  const secutiryProfile = localStorage.getItem("securityProfile");

  const navigate = useNavigate();
  const tenantId = localStorage.getItem("tenantId");
  const userId = localStorage.getItem("customerId");

  const handleChangeApproval = (
    tenantId: number,
    providerId: number,
    id: number,
    approved: boolean,
    serviceType: string
  ) => {
    if (tenantId && providerId && id && serviceType) {
      store
        .dispatch(
          updateWellnessApprovedAsync({
            tenantId: tenantId,
            userId: providerId,
            wellnessId: id,
            request: {
              typeService: serviceType,
              isApproved: approved,
            },
          })
        )
        .then((response) => {
          store.dispatch(getWellnessTableAsync());
        });
    }
  };

  useEffect(() => {
    store.dispatch(itemsEmptyState());
    localStorage.removeItem("backgroundImageRemoved");
    localStorage.removeItem("imageMyServiceBackgroundPreview");
    store.dispatch(getWellnessTableAsync());
    store
      .dispatch(
        getPublicAccountByTenantIdAndUserIdAsync({
          userId: parseInt(userId ?? ""),
          tenantId: parseInt(tenantId ?? ""),
        })
      )
      .then((response) => {
        if (response && response.payload) {
          setPublicAccount(response.payload);
        }
      });
    store.dispatch(getPublicAccountsAsync());
  }, []);

  const handleSwitchChange = (
    typology: string,
    id: number,
    prevActive: boolean
  ) => {
    let action: any;
    let updateData: any;

    switch (typology) {
      case "BODY":
        action = updateWellnessBodyAsync;
        updateData = {
          wellnessBody: id,
          wellnessData: { active: !prevActive },
        };
        break;

      case "NUTRITIONAL":
        action = updateWellnessNutritionalAsync;
        updateData = {
          wellnessNutritional: id,
          wellnessData: { active: !prevActive },
        };
        break;

      case "PSYCHO":
        action = updateWellnessPsychoAsync;
        updateData = {
          wellnessPsycho: id,
          wellnessData: { active: !prevActive },
        };
        break;

      default:
        return;
    }

    store
      .dispatch(action(updateData))
      .then(() => store.dispatch(getWellnessTableAsync()));
  };

  const debouncedHandleSwitchChange = debounce(handleSwitchChange, 500);

  const columns: ColumnsType<WellNessTableSlice> = [
    {
      title: "Titolo",
      dataIndex: "image",
      key: "image",
      render: (image, record) => (
        <>
          <img
            src={
              record.serviceType === "BODY"
                ? record.image || ImageConfig.IMAGE_TABLE_SERVICE
                : publicAccount?.personalPhotography ||
                  ImageConfig.IMAGE_TABLE_SERVICE
            }
            alt=""
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              top: "10px",
              borderRadius: "4px",
            }}
          />
          <div className="title-table">
            {record.name}
            <div className="insertion-date-table">
              {record.insertionDate.toString()}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Ruolo",
      dataIndex: "role",
      key: "role",
      render: (image, record) => (
        <>
          <div className="role-table">
            {record.serviceType === "BODY"
              ? "Coach/Istruttore"
              : record.serviceType === "NUTRITIONAL"
              ? "Nutrizionista"
              : record.serviceType === "PSYCHO"
              ? "Psicologo"
              : null}
          </div>
        </>
      ),
    },
    {
      title: "Modalità",
      dataIndex: "remotelyOrPresenceEnum",
      key: "remotelyOrPresenceEnum",
      render: (value) => {
        return (
          <>
            <div className="presence-text">Remoto</div>
          </>
        );
      },
    },
    {
      title: "Stato",
      dataIndex: "published",
      key: "published",
      render: (value) => {
        return (
          <>
            {!value ? (
              <div className="color-tag-bozza">
                <TableTag text={t("Bozza")} />
              </div>
            ) : (
              <div className="color-tag-pubblico">
                <TableTag text={t("Pubblico")} />
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Attivo",
      dataIndex: "approved",
      key: "approved",

      render: (id, value) => {
        return (
          <>
            {value.published ? (
              value.approved ? (
                <Switch
                  checked={value.active}
                  style={
                    value.active
                      ? { backgroundColor: "#36B39E" }
                      : { backgroundColor: "grey" }
                  }
                  onChange={() =>
                    debouncedHandleSwitchChange(
                      value.serviceType,
                      value.id,
                      value.active
                    )
                  }
                  checkedChildren={<CheckOutlined rev={undefined} />}
                  unCheckedChildren={<CloseOutlined rev={undefined} />}
                />
              ) : (
                <div className="color-tag-approvazione">
                  <TableTag text="In approvazione" />
                </div>
              )
            ) : (
              <div className="price-text-table">-</div>
            )}
          </>
        );
      },
    },
    {
      title: "(€) Seduta",
      dataIndex: "price",
      key: "price",
      render: (value) => {
        if (value === null) {
          return <div className="price-text-table">-</div>;
        } else {
          return (
            <div className="price-text-table">{formatNumber(value) + "€"}</div>
          );
        }
      },
    },
    {
      title: "",
      dataIndex: "",
      render: (id, value) => {
        let typologyParam = "";

        switch (value.serviceType) {
          case "BODY":
            typologyParam = "BODY";
            break;
          case "NUTRITIONAL":
            typologyParam = "NUTRITIONAL";
            break;
          case "PSYCHO":
            typologyParam = "PSYCHO";
            break;
          default:
            break;
        }

        return (
          <>
            <Space
              size="middle"
              style={{ marginLeft: "15px", marginRight: "0px" }}
            >
              <a
                onClick={() => {
                  navigate(
                    `/myService/previewService/${value.id}?typology=${typologyParam}&accountId=${value.providerId}&tenantId=${value.tenantId}`
                  );
                }}
              >
                <TwentyIcEye2 className={undefined} color="#222729" />
              </a>
            </Space>
            {secutiryProfile !== "SUPER_ADMIN" && (
              <Space
                size="middle"
                style={{ marginLeft: "15px", marginRight: "0px" }}
              >
                <a
                  onClick={() => {
                    navigate(
                      `/myService/${value.id}/edit?typology=${typologyParam}`
                    );
                  }}
                >
                  <TwentyIcEdit className={undefined} color="#222729" />
                </a>
              </Space>
            )}
            {
              <Space
                size="middle"
                style={{ marginLeft: "15px", marginRight: "0px" }}
              >
                <a
                  onClick={() => {
                    setDeleteId(value.id);
                    setServiceType(value.serviceType);
                    setDeleteServiceModalOpened(true);
                  }}
                >
                  <TwentyIcDelete className={undefined} color="#222729" />
                </a>
              </Space>
            }
          </>
        );
      },
    },
  ];

  if (secutiryProfile === "SUPER_ADMIN") {
    columns.splice(1, 0, {
      title: "Fornitore",
      dataIndex: "fornitore",
      key: "fornitore",
      render: (image, record) => (
        <>
          <div className="title-table-super-admin">
            {record?.providerName ? record?.providerName : null}
          </div>{" "}
        </>
      ),
    });

    const index = columns.length - 1;
    columns.splice(index, 0, {
      title: "Approva / Disapprova",
      dataIndex: "Approva",
      key: "approved",
      render: (id, record) => (
        <>
          <div className="button-table">
            <Button
              className={
                !record.approved
                  ? "button-approved-primary"
                  : "button-disapproved-primary"
              }
              icon={
                !record.approved ? (
                  <IconTik color="#030330" className={undefined}></IconTik>
                ) : (
                  <TwentyIcClose
                    className={undefined}
                    color="#030330"
                  ></TwentyIcClose>
                )
              }
              onClick={() =>
                handleChangeApproval(
                  record.tenantId,
                  record.providerId,
                  record.id,
                  !record.approved,
                  record.serviceType
                )
              }
            >
              <div
                className={
                  !record.approved
                    ? "button-approved-service"
                    : "button-disapproved-service"
                }
              >
                {!record.approved ? "Approva" : "Disapprova"}
              </div>
            </Button>
          </div>
        </>
      ),
    });
  }

  function filterServiceTable(
    wellNessTableSlice: WellNessTableSlice[],
    searchKey: string
  ) {
    let data: any;

    if (secutiryProfile === "SUPER_ADMIN") {
      data = wellNessTableSlice.filter((el) => el.published === true);
    } else {
      data = wellNessTableSlice;
    }
    const sortedServiceTable = _.sortBy(data, (x) => x.name);
    return sortedServiceTable.filter((x) => {
      const nameMatches = x.name
        ?.toString()
        .toLocaleLowerCase()
        .includes(searchKey?.toLocaleLowerCase());

      return nameMatches;
    });
  }

  return (
    <>
      <div className="myService-content">
        <div className="frame-16">
          <div className="frame-17">
            <div className="myService-topbar">
              <div className="myService-topbar-title-iconplace">
                <TwentyIcDoc className="myService-topbar-title-iconplace-icon" />
              </div>
              <div className="myService-topbar-title">
                {secutiryProfile === "SUPER_ADMIN"
                  ? t("myService.common.superAdminService")
                  : t("myService.common.myService")}
              </div>
            </div>
          </div>
        </div>
        {publicAccount &&
        _.isEmpty(publicAccount) &&
        localStorage.getItem("securityProfile") === "PROVIDER" ? (
          <EmptyState
            icon={true}
            description={
              "Prima di creare un servizio, compila i tuoi dati pubblici"
            }
            title={"Non puoi ancora creare servizi"}
            setValue={(v: any) => {
              setAddServiceModalOpened(v);
            }}
            textButton={"Compila dati pubblici"}
            isPublicAccountEmpty={_.isEmpty(publicAccount)}
            isWellnessTableEmpty={wellnessTable.length === 0}
          />
        ) : wellnessTable.length === 0 ? (
          <EmptyState
            icon={true}
            description={
              "Che aspetti? Qui puoi aggiungere i tuoi servizi che vorresti offrire!"
            }
            title={"Non ci sono servizi creati"}
            setValue={(v: any) => {
              setAddServiceModalOpened(v);
            }}
            textButton={"Aggiungi nuovo servizio"}
            isPublicAccountEmpty={_.isEmpty(publicAccount)}
            isWellnessTableEmpty={wellnessTable.length === 0}
          />
        ) : (
          <>
            <MyServiceFilterBar
              searched={(e) => {
                setSearchKey(e);
              }}
              wellnessTableLength={
                filterServiceTable(wellnessTable, searchKey).length
              }
              setModalStatus={(v) => {
                setAddServiceModalOpened(v);
              }}
            />

            <div className="users-table">
              <Table
                columns={columns}
                dataSource={filterServiceTable(wellnessTable, searchKey)}
                pagination={{
                  pageSize: 12,
                }}
              />
            </div>
          </>
        )}

        {addServiceModalOpened && (
          <div>
            <AddServiceModal
              closeModal={(v) => {
                setAddServiceModalOpened(v);
              }}
              onClick={(title: string) => {
                let wellnessId: string = "";
                switch (publicAccount?.providerType) {
                  case "COACH":
                    store
                      .dispatch(
                        createWellnessBodyAsync({
                          wellnessBody: { name: title, userId: userId },
                        })
                      )
                      .then((response) => {
                        if (response.meta.requestStatus === "fulfilled") {
                          wellnessId = response.payload.id;
                          store.dispatch(
                            getWellnessBodyDetailsAsync(parseInt(wellnessId))
                          );
                          navigate(
                            `/myService/create?title=${title}&service=${wellnessId}`
                          );
                        } else {
                          ToastNotification({
                            status: "error",
                            description: "",
                            toastId: "Nome servizio gia esistente",
                            title: "Nome servizio gia esistente",
                          });
                        }
                      });
                    break;
                  case "NUTRITIONIST":
                    store
                      .dispatch(
                        createWellnessNutritionalAsync({
                          wellnessNutritional: { name: title, userId: userId },
                        })
                      )
                      .then((response) => {
                        if (response.meta.requestStatus === "fulfilled") {
                          wellnessId = response.payload.id;
                          store.dispatch(
                            getWellnessNutritionalDetailsAsync(
                              parseInt(wellnessId)
                            )
                          );
                          navigate(
                            `/myService/create?title=${title}&service=${wellnessId}`
                          );
                        } else {
                          ToastNotification({
                            status: "error",
                            description: "",
                            toastId: "Nome servizio gia esistente",
                            title: "Nome servizio gia esistente",
                          });
                        }
                      });
                    break;
                  case "PSYCHOLOGIST":
                    store
                      .dispatch(
                        createWellnessPsychoAsync({
                          wellnessPsycho: { name: title, userId: userId },
                        })
                      )
                      .then((response) => {
                        if (response.meta.requestStatus === "fulfilled") {
                          wellnessId = response.payload.id;
                          store.dispatch(
                            getWellnessPsychoDetailsAsync(parseInt(wellnessId))
                          );
                          navigate(
                            `/myService/create?title=${title}&service=${wellnessId}`
                          );
                        } else {
                          ToastNotification({
                            status: "error",
                            description: "",
                            toastId: "Nome servizio gia esistente",
                            title: "Nome servizio gia esistente",
                          });
                        }
                      });

                    break;
                  default:
                    break;
                }
              }}
            />
          </div>
        )}
        {deleteServiceModalOpened && (
          <div>
            <DeleteServiceModal
              closeModal={(v) => {
                setDeleteServiceModalOpened(v);
              }}
              statusModal={deleteServiceModalOpened}
              onClick={() => {
                switch (serviceType) {
                  case "BODY":
                    store
                      .dispatch(deleteWellnessBodyAsync(deleteId ?? 0))
                      .then(() => store.dispatch(getWellnessTableAsync()));
                    break;
                  case "NUTRITIONAL":
                    store
                      .dispatch(deleteWellnessNutritionalAsync(deleteId ?? 0))
                      .then(() => store.dispatch(getWellnessTableAsync()));
                    break;
                  case "PSYCHO":
                    store
                      .dispatch(deleteWellnessPsychoAsync(deleteId ?? 0))
                      .then(() => store.dispatch(getWellnessTableAsync()));
                    break;
                  default:
                    break;
                }
                setDeleteServiceModalOpened(false);
              }}
            />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};
