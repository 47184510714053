import { Input } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { UploadFile } from "antd/lib";
import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { ModalBody } from "../../../ui/components/Modals/ModalBody";
import { ModalFooter } from "../../../ui/components/Modals/ModalFooter";
import { ModalWrapper } from "../../../ui/components/Modals/ModalWrapper";
import { Required } from "../../../ui/components/Required";
import { convertBase64 } from "../../../utils/utils";
import "../AccountModal/EditPersonalDataModal.css";

interface EditPersonalDataModalProps {
  onClose: () => void;
  onClick: (
    firstName: string,
    lastName: string,
    email: string,
    telephone: string,
    profilePhoto: string,
    fiscalCode: string,
    city: string,
    virtualCooworking: string,
    personalOfficeURL: string
  ) => void;
  title: string;
  isActionPerforming?: boolean | undefined;
  initialData: {
    firstName: string;
    lastName: string;
    email: string;
    telephone: string;
    profilePhoto: string;
    fiscalCode: string;
    city: string;
    virtualCooworking: string;
    personalOfficeURL: string;
  };
}
export const EditPersonalDataModal: React.FC<EditPersonalDataModalProps> = ({
  onClose,
  onClick,
  title,
  isActionPerforming,
  initialData,
}) => {
  const [firstName, setFirstName] = useState(initialData.firstName);
  const [lastName, setLastName] = useState(initialData.lastName);
  const [email, setEmail] = useState(initialData.email);
  const [telephone, setTelephone] = useState(initialData.telephone);
  const [virtualCooworking, setVirtualCooworking] = useState(
    initialData.virtualCooworking
  );
  const [personalOfficeURL, setPersonalOfficeURL] = useState(
    initialData.personalOfficeURL
  );
  const [profilePhoto, setProfilePhoto] = useState(initialData.profilePhoto);
  const [fiscalCode, setFiscalCode] = useState(initialData.fiscalCode);
  const [city, setCity] = useState(initialData.city);
  const profile = localStorage.getItem("securityProfile");

  const checkingDifferences = (
    firstName: string,
    lastName: string,
    email: string,
    telephone: string,
    profilePhoto: string,
    fiscalCode: string,
    city: string,
    virtualCooworking: string,
    personalOfficeURL: string,
    initialData: {
      firstName: string;
      lastName: string;
      email: string;
      telephone: string;
      profilePhoto: string;
      fiscalCode: string;
      city: string;
      virtualCooworking?: string;
      personalOfficeURL?: string;
    }
  ) => {
    // Michele ti tolgo la patente cristoforo colombo
    if (
      firstName !== initialData.firstName ||
      lastName !== initialData.lastName ||
      email !== initialData.email ||
      telephone !== initialData.telephone ||
      fiscalCode !== initialData.fiscalCode ||
      city !== initialData.city ||
      virtualCooworking !== initialData.virtualCooworking ||
      profilePhoto !== initialData.profilePhoto ||
      personalOfficeURL !== initialData.personalOfficeURL
    ) {
      return false;
    } else {
      return true;
    }
  };

  const uploadFile = (file: File) => {
    convertBase64(file).then((response) => {
      setProfilePhoto(response);
    });
  };

  const [getImageService, setGetImageService] = useState<UploadFile<any>[]>([]);

  useEffect(() => {
    if (!!initialData && !_.isEmpty(initialData.profilePhoto)) {
      const uploadFile: UploadFile = {
        uid: "",
        name: "Immagine caricata",
        status: "done",
        url: initialData?.profilePhoto,
      };
      if (_.isEmpty(getImageService)) {
        getImageService.push(uploadFile);
      }
    }
  }, [initialData]);

  return (
    <div className="modal-wrapper-edit-personal-data">
      <ModalWrapper open={true} closeAction={onClose}>
        <div className="modal-body-edit-personal-data">
          <ModalBody title={title}>
            <br />
            <div className="modal-body">
              <div className="input-first-and-last-name">
                <div className="input-first-name">
                  <div className="input-title-first-name">Nome</div>
                  <div className="input-text-first-name">
                    <Input
                      className="input-text-style"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="input-last-name">
                  <div className="input-title-last-name">Cognome</div>
                  <div className="input-text-last-name">
                    <Input
                      className="input-text-style"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="input-email-and-telephone">
                <div className="input-email">
                  <div className="input-title-email">Email</div>
                  <div className="input-text-email">
                    <Input
                      className="input-text-style"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="input-telephone">
                  <div className="input-title-telephone">Telefono</div>
                  <div className="input-text-telephone">
                    <Input
                      className="input-text-style"
                      value={telephone}
                      onChange={(e) => setTelephone(e.target.value)}
                    />
                  </div>
                  <div className="info-telephone">
                    Questo dato non sarà pubblico
                  </div>
                </div>
              </div>
              {profile === "SUPER_ADMIN" && (
                <div>
                  <div className="personal-data-virtual-cooworking">
                    Coworking virtuale per appuntamenti
                  </div>
                  <div>
                    <Input
                      className="input-text-style"
                      value={virtualCooworking}
                      onChange={(e) => {
                        setVirtualCooworking(e.target.value);
                      }}
                    />
                  </div>
                  <div className="personal-data-virtual-cooworking">
                    Coworking virtuale personale
                  </div>
                  <div>
                    <Input
                      className="input-text-style"
                      value={personalOfficeURL}
                      onChange={(e) => {
                        setPersonalOfficeURL(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="input-photo">
                <div className="input-title-photo">
                  <div>
                    <Required
                      className={undefined}
                      ellipseClassName={undefined}
                    />
                  </div>
                  <div className="input-title-photo-text">
                    Carica una foto tua
                  </div>
                  <div className="input-title-photo-advise">
                    Consigliato 200x200px
                  </div>
                </div>
                <div className="input-dragger">
                  <Dragger
                    defaultFileList={getImageService}
                    customRequest={({ file, onSuccess }) => {
                      if (onSuccess !== undefined) {
                        uploadFile(file as File);
                        onSuccess("ok");
                      }
                    }}
                    action={undefined}
                    listType="picture"
                    maxCount={1}
                  >
                    <div className="dragger-text">Carica una foto</div>
                  </Dragger>
                </div>
                <div className="input-fiscal-code-and-role">
                  <div className="input-fiscal-code">
                    <div className="input-title-fiscal-code">
                      Codice Fiscale
                    </div>
                    <div className="input-text-fiscal-code">
                      <Input
                        className="input-text-style"
                        value={fiscalCode}
                        onChange={(e) => setFiscalCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="input-role">
                    <div className="input-title-role">Città</div>
                    <div className="input-text-role">
                      <Input
                        className="input-text-style"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter
            primaryLabel={"Salva dati"}
            secondaryLabel={t("account.modalChangePassowrd.buttonCancel")}
            primaryAction={() => {
              onClick(
                firstName,
                lastName,
                email,
                telephone,
                profilePhoto,
                fiscalCode,
                city,
                virtualCooworking,
                personalOfficeURL
              );
            }}
            secondaryAction={onClose}
            disablePrimaryButton={checkingDifferences(
              firstName,
              lastName,
              email,
              telephone,
              profilePhoto,
              fiscalCode,
              city,
              virtualCooworking,
              personalOfficeURL,
              initialData
            )}
            isActionPerforming={isActionPerforming}
          />
        </div>
      </ModalWrapper>
    </div>
  );
};
