import { useEffect, useRef, useState } from "react";
import { Button } from "../../ui/components/Button/Button";
import PropTypes from "prop-types";
import { Logo } from "../../ui/components/Logo/Logo";
import "./LoginPage.css";
import AuthenticationService from "../../utils/AuthenticationService";
import Form from "../../ui/components/Form/Form";
import { ToastNotification } from "../../ui/components/utils/ToastNotification";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import { t } from "i18next";
import { Input } from "antd";
import { IconMail } from "../../ui/components/icons/IconMail";
import { IconLock } from "../../ui/components/icons/IconLock";
import { usePreviewContext } from "../../Context/PrewiewContext";
import { TwentyIcEye2 } from "../../ui/components/icons/TwentyIcEye2";
import { TwentyIcEyeHide2 } from "../../ui/components/icons/TwentyIcEyeHide2";

interface LoginPageProps {
  loginDone: () => void;
  isLogged: () => void;
}

function LoginPage(Props: LoginPageProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [key, setKey] = useState("");

  const navigate = useNavigate();

  const authenticationService = new AuthenticationService();

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);

    const passwordField = document.getElementById("password-field");
    if (passwordField) {
      if (showPassword) {
        passwordField.setAttribute("type", "password");
      } else {
        passwordField.setAttribute("type", "text");
      }
    }
    
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (reminder) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    setIsLoading(true);
    authenticationService.login(
      email,
      password,
      (result: any) => {
        Props.loginDone();
        if (result.isFirstAccess) {
          navigate("/privacyPolicy");
        } else {
          localStorage.setItem("isLoggedIn", "true");
          navigate("/home");
          Props.isLogged();
        }
        setIsLoading(false);
      },
      (error) => {
        console.log("LOGIN ERROR: " + error);
        if (error.response === undefined || error.response.status === 500) {
          ToastNotification({
            status: "error",
            description: "",
            toastId: "loginFailed",
            title: i18next.t("loginPage.toastNotification.networkError"),
          });
        } else if (error.response.status === 401) {
          ToastNotification({
            status: "error",
            description: "",
            toastId: "loginFailed",
            title: i18next.t("loginPage.toastNotification.unauthorizedError"),
          });
        } else if (error.response.status === 409) {
          ToastNotification({
            status: "error",
            description: "",
            toastId: "loginFailed",
            title: i18next.t(
              "loginPage.toastNotification.alertExpiredDisabled"
            ),
          });
        } else {
          ToastNotification({
            status: "error",
            description: "",
            toastId: "loginFailed",
            title: i18next.t("loginPage.toastNotification.alert"),
          });
        }
        setIsLoading(false);
      }
    );
  };

  const loginFromHS = (key: any) => {
    setIsLoading(true);

    authenticationService.loginFromEmail(
      key,
      (result: any) => {
        setIsLoading(false);
        Props.loginDone();
        navigate("/privacyPolicy");
      },
      (error: any) => {
        console.log("LOGIN ERROR: " + error);
        if (error.response === undefined || error.response.status === 500) {
          ToastNotification({
            status: "error",
            description: "",
            toastId: "loginFailed",
            title: i18next.t("loginPage.toastNotification.networkError"),
          });
        } else if (error.response.status === 401) {
          ToastNotification({
            status: "error",
            description: "",
            toastId: "loginFailed",
            title: i18next.t("loginPage.toastNotification.unauthorizedError"),
          });
        } else if (error.response.status === 409) {
          ToastNotification({
            status: "error",
            description: "",
            toastId: "loginFailed",
            title: i18next.t(
              "loginPage.toastNotification.alertExpiredDisabled"
            ),
          });
        } else {
          ToastNotification({
            status: "error",
            description: "",
            toastId: "loginFailed",
            title: i18next.t("loginPage.toastNotification.alert"),
          });
        }
        setIsLoading(false);
      }
    );
  };

  const location = window.location;
  let queryParams = new URLSearchParams(location.search);
  let keyParam = queryParams.get("key");

  const submitRef = useRef<any>();
  useEffect(() => {
    if (keyParam !== null && keyParam !== "") {
      setKey(keyParam);
    } else {
      const keyUp = (event: any) => {
        if (event.keyCode === 13 && password !== "" && email !== "") {
          handleSubmit(event);
        }
      };

      if (submitRef && submitRef.current) {
        submitRef.current.addEventListener("keydown", keyUp);
        let parentRef = submitRef;
        return () => {
          parentRef.current &&
            parentRef.current.removeEventListener("keydown", keyUp);
        };
      }
    }
  });

  useEffect(() => {
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");
    if (keyParam !== null && keyParam !== "") {
      loginFromHS(keyParam);
    } else {
      if (
        email !== "" &&
        email !== null &&
        password !== "" &&
        password !== null
      ) {
        // Popola i campi di input con le credenziali salvate
        setEmail(email);
        setPassword(password);
        setReminder(true);
      }
    }
  }, []);

  return (
    <div className="login" ref={submitRef}>
      <div className="login-container">
        <div className="login-logo">
          <Logo width="186px" height="28px" />
        </div>
        <div className="login-content">
          <div className="login-form-container">
            <span className="login-up-span">{t("loginPage.loginText")}</span>
            <span className="login-desc-span">{t("loginPage.insertData")}</span>
            <div className="login-form">
              <div className="login-input-container">
                <div className="login-input-group">
                  <Form>
                    <span className="email-span">{t("loginPage.email")}</span>
                    <Input
                      style={{ width: "360px", height: "50px" }}
                      className="input-text-style"
                      id="username-field"
                      value={email}
                      type="email"
                      size="large"
                      placeholder="Email"
                      prefix={
                        <IconMail color={"#BDBEBF"} className={undefined} />
                      }
                      onChange={handleEmailChange}
                    />
                  </Form>
                  <Form>
                    <span className="password-span">
                      {t("loginPage.password")}
                    </span>
                    <Input
                      id="password-field"
                      value={password}
                      type="password"
                      style={{ width: "360px", height: "50px" }}
                      size="large"
                      placeholder="Password"
                      prefix={
                        <IconLock color={"#BDBEBF"} className={undefined} />
                      }
                      suffix={
                        <button className="show-password" onClick={togglePasswordVisibility}>
                          {!showPassword ? <TwentyIcEye2 className={undefined} color="#BDBEBF" /> : <TwentyIcEyeHide2 className={undefined} color="#BDBEBF"/>}
                        </button>
                      }
                      onChange={handlePasswordChange}
                    />
                  </Form>
                </div>
                <div className="login-reminder-container">
                  <Switch
                    checked={reminder}
                    style={
                      reminder
                        ? { backgroundColor: "#36B39E" }
                        : { backgroundColor: "#BDBEBF" }
                    }
                    checkedChildren={<CheckOutlined rev={undefined} />}
                    onChange={() => setReminder(!reminder)}
                    defaultChecked={reminder}
                  />
                  <div className="login-reminder-text">
                    {t("loginPage.remindCredentials")}
                  </div>
                </div>
              </div>
            </div>
            <div className="login-button-wrapper">
              <Button
                textColor={"white"}
                size="XL"
                onClick={handleSubmit}
                disabled={email === "" || password === ""}
                text={isLoading ? "" : t("loginPage.login")}
                type="primary"
                isActionPerforming={isLoading}
                state={undefined}
                icon={undefined}
                iconRight={undefined}
              />
            </div>
          </div>

          <p className="login-forgot-password">
            <span className="login-span">
              {t("loginPage.lostCredentials") + " "}
            </span>
            <span
              className="login-text-3"
              onClick={() => {
                navigate("/recover-password");
              }}
            >
              {t("loginPage.findCredentials")}
            </span>
          </p>
        </div>
        <div className="login-footer-bottom">
          <div className="login-text-4">© EverywhereSB, s.r.l</div>
          <div className="login-text-5">
            <div className="login-text-6">contact@everywheretew.it</div>
            <a
              href="https://everywheretew.it/privacy-policy-piattaforma"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="footer-label">Privacy Policy</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;

LoginPage.propTypes = {
  loginDone: PropTypes.func.isRequired,
};

LoginPage.defaultProps = {
  loginDone: () => {},
};
