/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcLink = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-link ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.24694 13.7535L13.7538 6.24655M9.24931 16.7562C7.59093 18.4146 4.90216 18.4146 3.24378 16.7562C1.58541 15.0978 1.58541 12.4091 3.24378 10.7507C4.90216 9.09231 7.59093 9.09231 9.24931 10.7507C10.9077 12.4091 10.9077 15.0978 9.24931 16.7562ZM16.7562 9.24931C15.0978 10.9077 12.4091 10.9077 10.7507 9.24931C9.09231 7.59093 9.09231 4.90216 10.7507 3.24378C12.4091 1.58541 15.0978 1.58541 16.7562 3.24378C18.4146 4.90216 18.4146 7.59093 16.7562 9.24931Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
