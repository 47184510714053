import HttpClient from "../../utils/HttpClient";
import {
  MeetingCreateRequest,
  MeetingFilters,
  MeetingRequest,
} from "./MeetingSlice";

export default class MeetingsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public createMeeting(meeting: MeetingCreateRequest) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    const path = "/tenants/" + tenantId + "/users/" + userId + "/meetings";
    return this.instance.post(path, meeting);
  }

  public updateMeeting(requests: MeetingRequest[]) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    const path =
      "/tenants/" + tenantId + "/user/" + userId + "/meetings/status";
    return this.instance.post(path, requests);
  }

  public getMeetingsReport(year: number) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    const path =
      "/tenants/" +
      tenantId +
      "/user/" +
      userId +
      "/" +
      year +
      "/meetingsReport";
    return this.instance.get(path);
  }

  public getMeetings(filters?: MeetingFilters) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    const path = "/tenants/" + tenantId + "/user/" + userId + "/meetings/view";
    return this.instance.post(path, filters);
  }
}
