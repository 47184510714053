import HttpClient from "../../utils/HttpClient";

export default class GalleryRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getSmartPlanGalleyImages(smartPlanId: number) {
    const path = "/smartPlans/" + smartPlanId + "/galleries";
    return this.instance.get(path);
  }

  public getMasterClassGalleyImages(masterClassId: number) {
    const path = "/masterClasses/" + masterClassId + "/galleries";
    return this.instance.get(path);
  }

  public getWellnessBodyGalleyImages(wellnessId: number) {
    const path = "/wellnessBody/" + wellnessId + "/galleries";
    return this.instance.get(path);
  }

  public getOfficeCoworkingGalleryImages(officeCoworkingId: number) {
    const path = "/officeCoworkings/" + officeCoworkingId + "/galleries";
    return this.instance.get(path);
  }
}
