import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import MasterClassRepository from "./MasterClassRepository";
import { Config } from "../../config/Config";
import { normalize } from "normalizr";
import { activityTailsSchema } from "./MasterClassNormalization";
import _ from "lodash";
import { RootState } from "../../app/store";

//#region Type

export interface MasterClassActivityTail {
  id: number;
  placesLeft: number;
  startDate: Date;
  title: string;
  endDate: Date;
  city: string;
  image: string;
  price?: number;
  deposit?: number;
}

//#endRegion Type

//#region API

export const getMasterClassActivityTailAsync = createAsyncThunk(
  "masterClass/getActivityTails",
  async (data: { queryString?: string }) => {
    const masterClassRepository = new MasterClassRepository();
    const response = await masterClassRepository.getMasterClassActivityTail(
      data?.queryString
    );
    const activityTails = _.get(
      response,
      Config.MASTER_CLASS_ACTIVITY_TAIL_RESPONSE
    );
    const normalizedData = normalize(activityTails, activityTailsSchema);
    return normalizedData.entities;
  }
);

//#endRegion API

//#region Slice

const masterClassActivityTailAdapter =
  createEntityAdapter<MasterClassActivityTail>({
    selectId: (activityTail) => activityTail.id,
  });

export const masterClassActivityTailSlice = createSlice({
  name: "masterClassActivityTails",
  initialState: masterClassActivityTailAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    activityTailsEmptyState: (state) => {
      masterClassActivityTailAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getMasterClassActivityTailAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          masterClassActivityTailAdapter.setAll(
            state,
            action.payload.activityTails ?? []
          );
          state.status = "idle";
        }
      )
      .addCase(getMasterClassActivityTailAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getMasterClassActivityTailAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

//#endRegion Slice

//#region Status

export const masterClassActivityTailSelector =
  masterClassActivityTailAdapter.getSelectors<RootState>(
    (state) => state.masterClassActivityTails
  );

export const { activityTailsEmptyState } = masterClassActivityTailSlice.actions;
export const selectMasterClassActivityTailSliceStatus = (state: any) =>
  state.activityTails.status;
export const selectMasterClassActivityTailSliceReasonCode = (state: any) =>
  state.activityTails.reasonCode;

//#endRegion Status

export default masterClassActivityTailSlice.reducer;
