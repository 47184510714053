export const IconAddCalendar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M17.5 9.5V3.5C17.5 2.39543 16.6046 1.5 15.5 1.5H3.5C2.39543 1.5 1.5 2.39543 1.5 3.5V15.5C1.5 16.6046 2.39543 17.5 3.5 17.5H9.5"
        stroke="#222729"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M14.5 10.5L14.5 18.5"
        stroke="#222729"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M18.5 14.5H10.5"
        stroke="#222729"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <circle cx="5.5" cy="4.5" r="0.5" fill="white" stroke="#222729" />
      <circle cx="13.5" cy="4.5" r="0.5" fill="white" stroke="#222729" />
    </svg>
  );
};
