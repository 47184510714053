/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcStatBox = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-stat-box ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rect"
        height="13"
        rx="3"
        stroke={color}
        strokeWidth="2"
        width="16"
        x="2"
        y="3.5"
      />
      <path
        className="path"
        d="M6 12.5L8.51429 9.83333L11.9429 10.8333L14 7.5"
        stroke={color}
        strokeLinecap="square"
        strokeWidth="2"
      />
    </svg>
  );
};
