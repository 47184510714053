/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconLink = ({ className, color = "black" }) => {
  return (
    <svg
      className={`icon-link ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rect"
        height="8"
        rx="4"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="rotate(-45 1 14)"
        width="8"
        x="1"
        y="14"
      />

      <rect
        className="rect"
        height="8"
        rx="4"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="rotate(-45 8.07108 6.92896)"
        width="8"
        x="8.07108"
        y="6.92896"
      />

      <path
        className="path"
        d="M6.65723 14L13.7283 6.92893"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
