import React, { useEffect, useState } from "react";
import { QueryParams } from "../../../feature/Smartplan/SmartPlan";
import _ from "lodash";
import { t } from "i18next";
import "./WellnessBodyFilterModal.css";
import { Dropdown, Button, Space, Select, Slider, MenuProps } from "antd";
import { TwentyIcArrowDown } from "../icons/TwentyIcArrowDown";
import { TwentyIcCloseSmall } from "../icons/TwentyIcCloseSmall";
import { TwentyIcTik } from "../icons/TwentyIcTik";
import { BaseOptionType } from "antd/es/select";
import Tag from "../Tag/Tag";
import { TwentyIcCreditCoins } from "../TwentyIcCreditCoins";

interface WellnessBodyFilterModalProps {
  modalChange: (e: any) => any;
  setParam: (e: any) => any;
  isModalOpened: boolean;
  queryParams: QueryParams;
}

export const WellnessBodyFilterModal: React.FC<
  WellnessBodyFilterModalProps
> = ({ modalChange, setParam, isModalOpened, queryParams }) => {
  const [modalOpened, setModalOpened] = useState(isModalOpened);

  const [isOnline, setIsOnline] = useState(false);
  const [isPresence, setIsPresence] = useState(false);

  const [oldQueryParams, setOldQueryParams] = useState({} as QueryParams);

  useEffect(() => {
    if (!_.isEqual(oldQueryParams, queryParams) && !!queryParams) {
      // recupera dai query params  e setta gli hooks
      queryParams["minPrice"] &&
        queryParams["maxPrice"] &&
        setPriceSelected([queryParams["minPrice"], queryParams["maxPrice"]]);

      if (queryParams["isIndividual"] === true) {
        setModeSelected(t("wellness.body.filters.single"));
      } else if (queryParams["isIndividual"] === false) {
        setModeSelected(t("wellness.body.filters.group"));
      }

      if (queryParams["location"]) {
        if (Array.isArray(queryParams["location"])) {
          let locTemp = [] as string[];
          queryParams["location"].map((element: any) => {
            if (element === "NORD") {
              locTemp.push(t("smartPlan.components.location.nord"));
            } else if (element === "SUD") {
              locTemp.push(t("smartPlan.components.location.sud"));
            } else if (element === "CENTRO") {
              locTemp.push(t("smartPlan.components.location.center"));
            } else {
              locTemp.push(t("smartPlan.components.location.islands"));
            }
          });
          setLocationsSelected(locTemp);
        }
      }

      if (queryParams["typology"]) {
        if (Array.isArray(queryParams["typology"])) {
          let locTemp = [] as string[];
          queryParams["typology"].map((element: any) => {
            let tmp = element.charAt(0).toUpperCase() + element.slice(1);
            locTemp.push(tmp);
          });
          setTypeSelected(locTemp);
        }
      }

      if (queryParams["remotelyOrPresenceEnum"] === "REMOTEANDPRESENCE") {
        setIsOnline(true);
        setIsPresence(true);
      } else {
        if (queryParams["remotelyOrPresenceEnum"] === "REMOTE") {
          setIsOnline(true);
        }
        if (queryParams["remotelyOrPresenceEnum"] === "PRESENCE") {
          setIsPresence(true);
        }
      }
    }
    setOldQueryParams(queryParams);
  }, [isModalOpened]);

  function paramBuilder() {
    let map = new Map();
    let optSelected = [] as string[];
    if (typeSelected.length > 0) {
      typeSelected.map((element) => {
        optSelected.push(element);
      });
      map.set("typology", optSelected);
      optSelected = [];
    }

    if (modeSelected !== "") {
      if (modeSelected === t("wellness.body.filters.single")) {
        map.set("isIndividual", true);
      } else if (modeSelected === t("wellness.body.filters.group")) {
        map.set("isIndividual", false);
      }
    }

    if (locationsSelected.length > 0) {
      locationsSelected.map((element) => {
        switch (element) {
          case t("smartPlan.components.location.nord"):
            optSelected.push("NORD");
            break;
          case t("smartPlan.components.location.sud"):
            optSelected.push("SUD");
            break;
          case t("smartPlan.components.location.center"):
            optSelected.push("CENTRO");
            break;
          case t("smartPlan.components.location.islands"):
            optSelected.push("ISOLE");
            break;
        }
      });
      map.set("location", optSelected);
      optSelected = [];
    }

    if (isOnline && isPresence) {
      map.set("remotelyOrPresenceEnum", "REMOTEANDPRESENCE");
    } else {
      if (isOnline) {
        map.set("remotelyOrPresenceEnum", "REMOTE");
      }
      if (isPresence) {
        map.set("remotelyOrPresenceEnum", "PRESENCE");
      }
    }

    if (priceSelected) {
      let temp = priceSelected as [number, number];
      temp.sort((a, b) => a - b);

      if (temp[0] !== minLimit || temp[1] !== maxLimit) {
        map.set("minPrice", temp[0].toString());
        map.set("maxPrice", temp[1].toString());
      }
    }

    return map;
  }

  function restoreParams() {
    setLocationsSelected([]);
    setTypeSelected([]);
    setIsOnline(false);
    setIsPresence(false);
    setModeSelected("");
    setPriceSelected([minLimit, maxLimit]);
  }

  //START REGION TYPE
  const [typeSelected, setTypeSelected] = useState<string[]>([]);
  const typeItems: BaseOptionType["types"] = [
    {
      label: t("wellness.body.filters.yoga"),
      value: t("wellness.body.filters.yoga"),
    },
    {
      label: t("wellness.body.filters.pilates"),
      value: t("wellness.body.filters.pilates"),
    },
    {
      label: t("wellness.body.filters.aerobic"),
      value: t("wellness.body.filters.aerobic"),
    },
    {
      label: t("wellness.body.filters.lifeCoaching"),
      value: t("wellness.body.filters.lifeCoaching"),
    },
    {
      label: t("wellness.body.filters.counseling"),
      value: t("wellness.body.filters.counseling"),
    },
  ];

  const typeHandleChange = (value: string[]) => {
    setTypeSelected(value);
  };

  //END REGION TYPE
  //START REGION PRICE
  let minLimit = 20;
  let maxLimit = 500;
  const [priceSelected, setPriceSelected] = useState<number | [number, number]>(
    [minLimit, maxLimit]
  );
  const priceHandleChange = (value: number | [number, number]) => {
    if (Array.isArray(value)) {
      setPriceSelected(value);
    }
  };

  //END REGION PRICE
  //START REGION MODE
  const [modeSelected, setModeSelected] = useState("");

  const modeItems: BaseOptionType["items"] = [
    {
      label: t("wellness.body.filters.single"),
      key: t("wellness.body.filters.single"),
    },
    {
      label: t("wellness.body.filters.group"),
      key: t("wellness.body.filters.group"),
    },
  ];

  const onModeClick: MenuProps["onClick"] = ({ key }) => {
    setModeSelected(key);
  };

  //END REGION MODE
  //START REGION LOCATIONS
  const [locationsSelected, setLocationsSelected] = useState<string[]>([]);

  const locationItems: BaseOptionType["items"] = [
    {
      label: t("smartPlan.components.location.nord"),
      value: t("smartPlan.components.location.nord"),
    },
    {
      label: t("smartPlan.components.location.sud"),
      value: t("smartPlan.components.location.sud"),
    },
    {
      label: t("smartPlan.components.location.center"),
      value: t("smartPlan.components.location.center"),
    },
    {
      label: t("smartPlan.components.location.islands"),
      value: t("smartPlan.components.location.islands"),
    },
  ];

  const locationsHandleChange = (value: string[]) => {
    setLocationsSelected(value);
  };

  //END REGION LOCATION

  function tagRender(props: any) {
    const { label, value, closable, onClose } = props;

    return (
      <div style={{ paddingTop: "2px", paddingRight: "4px" }}>
        <Tag isRemoved={onClose} textOne={label} />
      </div>
    );
  }

  useEffect(() => {
    modalChange(modalOpened);
  }, [modalOpened]);

  return (
    <>
      <div className="mn-modal-backdrop"></div>
      {
        <div className="smartplan-filtermodal">
          <div className="smartplan-filtermodal-header">
            <div className="smartplan-filtermodal-header-text">
              {t("smartPlan.common.filters")}
            </div>
            <div
              className="smartplan-filtermodal-header-iconclose"
              onClick={() => {
                setModalOpened(!modalOpened);
              }}
            >
              <TwentyIcCloseSmall className={undefined}></TwentyIcCloseSmall>
            </div>
          </div>
          <div className="smartplan-filtermodal-content">
            <div className="wellness-filtermodal-content-dropdown">
              <div className="smartplan-filtermodal-text">
                {t("wellness.body.filters.typeTitle")}
              </div>
              <Select
                suffixIcon={
                  typeSelected.length === 0 && (
                    <TwentyIcArrowDown className={undefined} color="#36b39e" />
                  )
                }
                value={typeSelected}
                tagRender={tagRender}
                placeholder={t("smartPlan.filterModal.select")}
                bordered={false}
                mode="multiple"
                allowClear
                style={{
                  paddingTop: "2px",
                  paddingBottom: "4px",
                  gap: "5px",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "40px",
                  height: "100%",
                  border: "1px solid #d9d9d9",
                  borderRadius: "5px",
                }}
                onChange={typeHandleChange}
                options={typeItems}
              />
            </div>
            <div className="wellness-filtermodal-content-dropdown">
              <div className="smartplan-filtermodal-text">
                {t("wellness.body.filters.modeTitle")}
              </div>
              <Dropdown menu={{ items: modeItems, onClick: onModeClick }}>
                <Button
                  style={{
                    width: "100%",
                    height: "40px",
                  }}
                >
                  <Space
                    style={{ width: "100%", placeContent: "space-between" }}
                  >
                    <div
                      className={
                        modeSelected
                          ? "smartplan-filtermodal-text"
                          : "smartplan-filtermodal-text-select"
                      }
                      style={{
                        width: "130px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlignLast: "left",
                      }}
                    >
                      {modeSelected
                        ? modeSelected
                        : t("smartPlan.filterModal.select")}
                    </div>
                    <TwentyIcArrowDown color="#36B39E" className={undefined} />
                  </Space>
                </Button>
              </Dropdown>
            </div>
            {/* <div className="smartplan-filtermodal-content-form">
              <div className="smartplan-filtermodal-text">
                {t("smartPlan.common.location")}
              </div>
              <Select
                suffixIcon={
                  locationsSelected.length === 0 && (
                    <TwentyIcArrowDown className={undefined} color="#36b39e" />
                  )
                }
                value={locationsSelected}
                tagRender={tagRender}
                placeholder={t("smartPlan.filterModal.select")}
                bordered={false}
                mode="multiple"
                allowClear
                style={{
                  paddingTop: "2px",
                  paddingBottom: "4px",
                  gap: "5px",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "40px",
                  height: "100%",
                  border: "1px solid #d9d9d9",
                  borderRadius: "5px",
                }}
                onChange={locationsHandleChange}
                options={locationItems}
              />
            </div>
            <div className="wellness-body-filtermodal-switchsection">
              <div className="smartplan-filtermodal-content-toggle-button">
                <Switch
                  checked={isOnline}
                  style={
                    isOnline
                      ? { backgroundColor: "#36B39E" }
                      : { backgroundColor: "grey" }
                  }
                  checkedChildren={<CheckOutlined rev={undefined} />}
                  unCheckedChildren={<CloseOutlined rev={undefined} />}
                  onChange={() => setIsOnline(!isOnline)}
                  defaultChecked={isOnline}
                />
                <div className="smartplan-filtermodal-text">
                  {t("wellness.body.filters.distance")}
                </div>
              </div>
              <div className="smartplan-filtermodal-content-toggle-button">
                <Switch
                  checked={isPresence}
                  style={
                    isPresence
                      ? { backgroundColor: "#36B39E" }
                      : { backgroundColor: "grey" }
                  }
                  checkedChildren={<CheckOutlined rev={undefined} />}
                  unCheckedChildren={<CloseOutlined rev={undefined} />}
                  onChange={() => setIsPresence(!isPresence)}
                  defaultChecked={isPresence}
                />
                <div className="smartplan-filtermodal-text">
                  {t("wellness.body.filters.presence")}
                </div>
              </div>
            </div> */}
            <div className="smartplan-filtermodal-content-range-slider">
              <div className="smartplan-filtermodal-text">
                {t("smartPlan.components.rangeBoundPrice.price")}
              </div>
              <Slider
                value={priceSelected as [number, number]}
                onChange={priceHandleChange}
                defaultValue={[maxLimit, minLimit]}
                min={minLimit}
                max={maxLimit}
                style={{ width: "100%" }}
                range={{ draggableTrack: true }}
                trackStyle={[{ backgroundColor: "#36B39E" }]}
                handleStyle={[
                  { boxShadow: "0 0 0 0 #36B39E", borderRadius: "50%" },
                ]}
              />
              <div className="smartPlan-filtermodal-content-range-slider-text">
                <div
                  className="smartplan-filtermodal-text"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {Array.isArray(priceSelected) && priceSelected[0]}
                  <TwentyIcCreditCoins
                    className={undefined}
                    color={"#35b39e"}
                    colorC={"#35b39e"}
                    size="20"
                  ></TwentyIcCreditCoins>
                </div>
                <div
                  className="smartplan-filtermodal-text"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {Array.isArray(priceSelected) && priceSelected[1]}
                  <TwentyIcCreditCoins
                    className={undefined}
                    color={"#35b39e"}
                    colorC={"#35b39e"}
                    size="20"
                  ></TwentyIcCreditCoins>
                </div>
              </div>
            </div>
            <div className="smartPlan-filtermodal-content-buttons">
              <Button
                onClick={restoreParams}
                style={{
                  borderColor: "transparent",
                  boxShadow: "none",
                  height: "40px",
                }}
              >
                <Space>
                  <div className="smartplan-filtermodal-text">
                    {t("smartPlan.common.cancel")}
                  </div>
                </Space>
              </Button>
              <Button
                style={{
                  borderColor: "#36B39E",
                  backgroundColor: "#36B39E",
                  boxShadow: "none",
                  height: "40px",
                }}
                onClick={() => {
                  let param = paramBuilder();
                  setParam(param);
                  setModalOpened(!modalOpened);
                }}
              >
                <Space>
                  <TwentyIcTik
                    className={"smartPlan-filtermodal-content-vector-confirm"}
                  />
                  <div className="smartplan-filtermodal-text-white">
                    {t("smartPlan.common.confirm")}
                  </div>
                </Space>
              </Button>
            </div>
          </div>
          <div className="smartplan-filtermodal-footer"></div>
        </div>
      }
    </>
  );
};
