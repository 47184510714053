import { store } from "../app/store";
import { getTenantAsync } from "../feature/Tenant/TenantSlice";
import HttpClient from "./HttpClient";
export default class AuthenticationService extends HttpClient {
  constructor() {
    super(false);
  }

  login(
    myEmail: string,
    myPassword: string,
    successCallbackFn: (a: any) => any,
    errorCallbackFn: (b: any) => any
  ) {
    this.instance
      .post("/auth/login", {
        email: myEmail,
        password: myPassword,
      })
      .then((response) => {
        const userData = response.data;
        localStorage.removeItem("imagePublicAccountPreview");
        localStorage.setItem("jwtToken", userData.token);
        localStorage.setItem("refreshToken", userData.refreshToken);
        localStorage.setItem("customerId", userData.customerId);
        localStorage.setItem("tenantId", userData.tenantId);
        localStorage.setItem("firstName", userData.firstName);
        localStorage.setItem("lastName", userData.lastName);
        localStorage.setItem("chatToken", userData.chatToken);
        localStorage.setItem("email", userData.email);
        localStorage.setItem("i18nextLng", userData.i18nextLng);
        localStorage.setItem("profilePic", userData.profilePicture);
        localStorage.setItem("securityProfile", userData.securityProfile);
        localStorage.setItem("admin", userData.admin);
        localStorage.setItem("securityProfile", userData.securityProfile);
        store.dispatch(getTenantAsync(userData.tenantId)).then((response) => {
          !!response.payload.businessName &&
            localStorage.setItem("businessName", response.payload.businessName);
        });

        successCallbackFn(userData);
      })
      .catch((error) => {
        errorCallbackFn(error);
      });
  }

  refreshToken: any = super.refreshToken;

  logout() {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("customerId");
    localStorage.removeItem("tenantId");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("chatToken");
    localStorage.removeItem("i18nextLng");
    localStorage.removeItem("profilePic");
    localStorage.removeItem("securityProfile");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("admin");
    localStorage.removeItem("securityProfile");
    localStorage.removeItem("imagePublicAccountPreview");
    localStorage.removeItem("businessName");
    return super.logout();
  }

  generateEmail(
    email: string,
    successCallbackFn: (a: any) => any,
    errorCallbackFn: (b: any) => any
  ) {
    this.instance
      .post("/auth/generateLinkCode", {
        email: email,
      })
      .then((response) => {
        const generatedCode = response.data;
        successCallbackFn(generatedCode);
      })
      .catch((error) => {
        errorCallbackFn(error);
      });
  }

  validateCode(generatedCode: string) {
    return this.instance.get("/auth/validateCode/" + generatedCode);
  }

  resetPassword(
    newPassword: string,
    generatedCode: string,
    email: string,
    successCallbackFn: (a: any) => any,
    errorCallbackFn: (b: any) => any
  ) {
    this.instance
      .put("/auth/resetPassword", {
        code: generatedCode,
        newPassword: newPassword,
        email: email,
      })
      .then((response) => {
        const reserPassword = response.data;
        successCallbackFn(reserPassword);
      })
      .catch((error) => {
        errorCallbackFn(error);
      });
  }

  loginFromEmail(
    key: any,
    successCallbackFn: (a: any) => any,
    errorCallbackFn: (b: any) => any
  ) {
    this.instance
      .post("/auth/loginFromEmail", {
        key: key,
      })
      .then((response) => {
        const userData = response.data;
        localStorage.setItem("jwtToken", userData.token);
        localStorage.setItem("refreshToken", userData.refreshToken);
        localStorage.setItem("customerId", userData.customerId);
        localStorage.setItem("tenantId", userData.tenantId);
        localStorage.setItem("firstName", userData.firstName);
        localStorage.setItem("lastName", userData.lastName);
        localStorage.setItem("chatToken", userData.chatToken);
        localStorage.setItem("email", userData.email);
        localStorage.setItem("i18nextLng", userData.i18nextLng);
        localStorage.setItem("profilePic", userData.profilePicture);
        localStorage.setItem("securityProfile", userData.securityProfile);
        localStorage.setItem("admin", userData.admin);
        localStorage.setItem("securityProfile", userData.securityProfile);
        store.dispatch(getTenantAsync(userData.tenantId)).then((response) => {
          !!response.payload.businessName &&
            localStorage.setItem("businessName", response.payload.businessName);
        });
        successCallbackFn(userData);
      })
      .catch((error) => {
        errorCallbackFn(error);
        localStorage.removeItem("firebaseToken");
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("tenantId");
        localStorage.removeItem("customerId");
        localStorage.removeItem("username");
        localStorage.removeItem("email");
        localStorage.removeItem("tenantEmail");
        localStorage.removeItem("chatToken");
        localStorage.removeItem("fullName");
        localStorage.removeItem("preferencesId");
        localStorage.removeItem("profilePic");
        errorCallbackFn(error);
      });
  }

  confirmMeeting(meetingId: number, code: string) {
    this.instance
      .post(
        "/auth/confirmMeeting?meetingId=" +
          meetingId +
          "&validationCode=" +
          code
      )
      .catch((error) => {
        console.log(error);
      });
  }
}
