/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcComment = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-notify ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 17H11V16H10V17ZM10 17H9V18H10V17ZM16 17V18H17V17H16ZM16 13.6076L15.1435 13.0915L15 13.3296V13.6076H16ZM4 10C4 6.68629 6.68629 4 10 4V2C5.58172 2 2 5.58172 2 10H4ZM10 16C6.68629 16 4 13.3137 4 10H2C2 14.4183 5.58172 18 10 18V16ZM11 17V17H9V17H11ZM16 16H10V18H16V16ZM15 13.6076V17H17V13.6076H15ZM16 10C16 11.1327 15.6871 12.1894 15.1435 13.0915L16.8565 14.1237C17.5826 12.9187 18 11.5066 18 10H16ZM10 4C13.3137 4 16 6.68629 16 10H18C18 5.58172 14.4183 2 10 2V4Z"
        fill={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
