import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import OfficeCoworkingRepository from "./OfficeCoworkingRepository";
import { Config } from "../../config/Config";
import _ from "lodash";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { RootState } from "../../app/store";

export interface OfficeCoworking {
  id: number;
  name: string;
  image: string;
  telephone: string;
  city: string;
  region: string;
  dailyPrice: number;
  description: string;
  email: string;
  includedServices: number[];
  galleries: number[];
  faqs: number[];
  activities: number[];
}

export const getOfficeCoworkingAsync = createAsyncThunk(
  "officeCoworking/getOfficeCoworking",
  async (officeCoworkingId: number) => {
    const officeCoworkingRepository = new OfficeCoworkingRepository();
    const response = await officeCoworkingRepository.getOfficeCoworking(
      officeCoworkingId
    );
    const officeCoworking = _.get(response, Config.OFFICE_COWORKING_RESPONSE);
    return officeCoworking;
  }
);

const officeCoworkingAdapter = createEntityAdapter<OfficeCoworking>({
  selectId: (officeCoworking) => officeCoworking.id,
});

export const officeCoworkingSlice = createSlice({
  name: "officeCoworking",
  initialState: officeCoworkingAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    officeCoworkingEmptyState: (state) => {
      officeCoworkingAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(
        getOfficeCoworkingAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          officeCoworkingAdapter.upsertOne(state, action.payload);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getOfficeCoworkingAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getOfficeCoworkingAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

export const officeCoworkingSelector =
  officeCoworkingAdapter.getSelectors<RootState>(
    (state) => state.officeCoworking
  );
export const { officeCoworkingEmptyState } = officeCoworkingSlice.actions;
export const selectOfficeCoworkingSliceStatus = (state: any) =>
  state.officeCoworking.status;
export const selectOfficeCoworkingSliceReasonCode = (state: any) =>
  state.officeCoworking.reasonCode;

export default officeCoworkingSlice.reducer;
