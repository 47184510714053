/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcLock = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-lock ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rect"
        height="7"
        stroke={color}
        strokeWidth="2"
        width="12"
        x="4"
        y="10.5"
      />
      <path
        className="path"
        d="M7 5.5C7 3.84315 8.34315 2.5 10 2.5V2.5C11.6569 2.5 13 3.84315 13 5.5V10.5H7V5.5Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
