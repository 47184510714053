//#region Type

import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import GalleryRepository from "./GalleryRepository";
import _ from "lodash";
import { Config } from "../../config/Config";
import { normalize } from "normalizr";
import { galleriesSchema } from "./GalleryNormalization";
import { RootState } from "../../app/store";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";

export interface Gallery {
  id: any;
  image: string;
}

//#endRegion Type

//#region API

export const getSmartPlanGalleyAsync = createAsyncThunk(
  "smartPlan/gallery",
  async (data: { smartPlanId: number }) => {
    const galleryRepository = new GalleryRepository();
    const response = await galleryRepository.getSmartPlanGalleyImages(
      data.smartPlanId
    );
    const gallery = _.get(response, Config.GALLERY_RESPONSE);
    const normalizeData = normalize(gallery, galleriesSchema);
    return normalizeData.entities;
  }
);

export const getMasterClassGalleyAsync = createAsyncThunk(
  "masterclass/gallery",
  async (data: { masterClassId: number }) => {
    const galleryRepository = new GalleryRepository();
    const response = await galleryRepository.getMasterClassGalleyImages(
      data.masterClassId
    );
    const gallery = _.get(response, Config.GALLERY_RESPONSE);
    const normalizeData = normalize(gallery, galleriesSchema);
    return normalizeData.entities;
  }
);

export const getWellnessBodyGalleyAsync = createAsyncThunk(
  "wellnessBody/gallery",
  async (data: { wellnessId: number }) => {
    const galleryRepository = new GalleryRepository();
    const response = await galleryRepository.getWellnessBodyGalleyImages(
      data.wellnessId
    );
    const gallery = _.get(response, Config.GALLERY_RESPONSE);
    const normalizeData = normalize(gallery, galleriesSchema);
    return normalizeData.entities;
  }
);

export const getOfficeCoworkingGalleryAsync = createAsyncThunk(
  "officeCoworking/gallery",
  async (data: { officeCoworkingId: number }) => {
    const galleryRepository = new GalleryRepository();
    const response = await galleryRepository.getOfficeCoworkingGalleryImages(
      data.officeCoworkingId
    );
    const gallery = _.get(response, Config.GALLERY_RESPONSE);
    const normalizeData = normalize(gallery, galleriesSchema);
    return normalizeData.entities;
  }
);

//#endRegion API

//#region Slice

const galleryAdapter = createEntityAdapter<Gallery>({
  selectId: (smartDatePlan) => smartDatePlan.id,
});

export const gallerySlice = createSlice({
  name: "gallery",
  initialState: galleryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    galleryEmptyState: (state) => {
      galleryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getSmartPlanGalleyAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          galleryAdapter.setAll(state, action.payload.gallery ?? []);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getSmartPlanGalleyAsync.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(getSmartPlanGalleyAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getMasterClassGalleyAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          galleryAdapter.setAll(state, action.payload.gallery ?? []);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getMasterClassGalleyAsync.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(getMasterClassGalleyAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getWellnessBodyGalleyAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          galleryAdapter.setAll(state, action.payload.gallery ?? []);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessBodyGalleyAsync.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(getWellnessBodyGalleyAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getOfficeCoworkingGalleryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          galleryAdapter.setAll(state, action.payload.gallery ?? []);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getOfficeCoworkingGalleryAsync.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(getOfficeCoworkingGalleryAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const gallerySelector = galleryAdapter.getSelectors<RootState>(
  (state) => state.gallery
);

export const { galleryEmptyState } = gallerySlice.actions;
export const selectGallerySliceStatus = (state: any) => state.gallery.status;
export const selectGallerySliceReasonCode = (state: any) =>
  state.gallery.reasonCode;

//#endRegion Status

export default gallerySlice.reducer;
