import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { EverywhereErrorCodes } from "../../../config/EverywhereErrorCodes";
import { EverywhereSuccessCodes } from "../../../config/EverywhereSuccessCodes";
import { UserInfo } from "../../UserInfo/UserSlice";
import WellnessRepository from "../WellnessRepository";
import { Tenant } from "../../Tenant/TenantSlice";

export interface WellnessNutritional {
  id: number;
  approved: boolean;
  active: boolean;
  published: boolean;
  name: string;
  description: string;
  typology: string;
  numberMeeting: number;
  durationMeeting: string;
  price: number;
  isIndividual: boolean;
  sessionsAvailable: number;
  remotelyOrPresenceEnum: string;
  city: string;
  province: string;
  location: string | undefined;
  address: string;
  zip: string;
  link: string;
  galleries: string[];
  deletedPhotoGallery: number[];
  image: string;
  insertionDate: Date;
  tenant: Tenant;
  userInfo: UserInfo;
}

export const getWellnessNutritionalAsync = createAsyncThunk(
  "wellnessNutritional/getWellnessNutritional",
  async (wellnessNutritionalId: number) => {
    const wellnessRepository = new WellnessRepository();
    const response = await wellnessRepository.getWellnessNutritional(
      wellnessNutritionalId
    );
    const wellnessNutritional = _.get(
      response,
      Config.WELLNESS_NUTRITIONAL_RESPONSE
    );
    return wellnessNutritional;
  }
);
export const getWellnessNutritionalDetailsAsync = createAsyncThunk(
  "wellnessNutritional/getWellnessNutritionalDetails",
  async (wellnessNutritionalId: number) => {
    const wellnessRepository = new WellnessRepository();
    const response = await wellnessRepository.getWellnessNutritionalDetails(
      wellnessNutritionalId
    );
    const wellnessNutritional = _.get(
      response,
      Config.WELLNESS_NUTRITIONAL_RESPONSE
    );
    return wellnessNutritional;
  }
);

export const createWellnessNutritionalAsync = createAsyncThunk(
  "wellnessNutritional",
  async (data: { wellnessNutritional: any }) => {
    const wellnessRepository = new WellnessRepository();
    const response = await wellnessRepository.createWellnessNutritional(
      data.wellnessNutritional
    );
    const wellnessNutritional = _.get(
      response,
      Config.CREATE_WELLNESS_NUTRITIONAL_RESPONSE
    );
    return wellnessNutritional;
  }
);

export const deleteWellnessNutritionalAsync = createAsyncThunk(
  "WellnessNutritional/deleteWellnessNutritional",
  async (wellnessNutritionalId: number, { rejectWithValue }) => {
    try {
      const wellnessRepository = new WellnessRepository();
      await wellnessRepository.deleteWellnessNutritional(wellnessNutritionalId);
      return wellnessNutritionalId;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const updateWellnessNutritionalAsync = createAsyncThunk(
  "wellnessNutritional/updateWellnessNutritional",
  async (data: { wellnessNutritional: number; wellnessData: any }) => {
    const wellnessRepository = new WellnessRepository();
    const response =
      await wellnessRepository.partiallyUpdateWellNessNutritional(
        data.wellnessNutritional,
        data.wellnessData
      );
    const wellnessNutritional = _.get(
      response,
      Config.UPDATE_WELLNESS_NUTRITIONAL_RESPONSE
    );
    return wellnessNutritional;
  }
);

const wellnessNutritionalAdapter = createEntityAdapter<WellnessNutritional>({
  selectId: (wellnessNutritional) => wellnessNutritional.id,
});

export const wellnessNutritionalSlice = createSlice({
  name: "wellnessNutritional",
  initialState: wellnessNutritionalAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    wellnessNutritionalEmptyState: (state) => {
      wellnessNutritionalAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getWellnessNutritionalAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          wellnessNutritionalAdapter.setOne(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessNutritionalAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getWellnessNutritionalAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getWellnessNutritionalDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          wellnessNutritionalAdapter.setOne(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessNutritionalDetailsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getWellnessNutritionalDetailsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        createWellnessNutritionalAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          wellnessNutritionalAdapter.upsertOne(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.POST;
        }
      )
      .addCase(createWellnessNutritionalAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(createWellnessNutritionalAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(deleteWellnessNutritionalAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        deleteWellnessNutritionalAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          wellnessNutritionalAdapter.upsertMany(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = "";
        }
      )
      .addCase(
        deleteWellnessNutritionalAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = EverywhereSuccessCodes.DELETE;
        }
      )
      .addCase(
        updateWellnessNutritionalAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload) {
            wellnessNutritionalAdapter.upsertOne(state, action.payload);
          }
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.PATCH;
        }
      )
      .addCase(updateWellnessNutritionalAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(updateWellnessNutritionalAsync.pending, (state: any) => {
        state.status = "loading";
      });
  },
});

export const wellnessNutritionalSelector =
  wellnessNutritionalAdapter.getSelectors<RootState>(
    (state) => state.wellnessNutritional
  );

export const { wellnessNutritionalEmptyState } =
  wellnessNutritionalSlice.actions;
export const selectWellnessNutritionalSliceReasonCOde = (state: any) =>
  state.wellnessNutritional.reasonCode;

export const selectWellnessNutritionalSliceStatus = (state: any) =>
  state?.wellnessNutritional?.status;

export default wellnessNutritionalSlice.reducer;
