/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcHelp = ({ className, color = "#36B39E" }) => {
  return (
    <svg
      className={`twenty-ic-help ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rect"
        height="14"
        rx="7"
        stroke={color}
        strokeWidth="2"
        width="14"
        x="3"
        y="3"
      />
      <path
        className="path"
        d="M10.6813 11.457H9.1596C9.1596 11.1416 9.18081 10.8638 9.22322 10.6237C9.26918 10.3799 9.34694 10.1613 9.45652 9.96774C9.5661 9.77419 9.71456 9.59498 9.90191 9.43011C10.068 9.29032 10.2112 9.15591 10.3314 9.02688C10.4516 8.89785 10.5435 8.76703 10.6071 8.63441C10.6707 8.50179 10.7025 8.36022 10.7025 8.20968C10.7025 8.0233 10.676 7.87097 10.623 7.75269C10.5735 7.63441 10.4975 7.54659 10.395 7.48925C10.296 7.42832 10.1706 7.39785 10.0186 7.39785C9.89484 7.39785 9.77819 7.42832 9.66861 7.48925C9.56257 7.55018 9.47596 7.64516 9.4088 7.77419C9.34164 7.89964 9.30452 8.06631 9.29745 8.27419H7.5C7.5106 7.74014 7.62725 7.30645 7.84995 6.97312C8.07618 6.6362 8.37663 6.39068 8.75133 6.23656C9.12955 6.07885 9.55196 6 10.0186 6C10.5346 6 10.9783 6.08065 11.3494 6.24194C11.7206 6.40323 12.0051 6.64337 12.2031 6.96237C12.401 7.27778 12.5 7.66667 12.5 8.12903C12.5 8.43728 12.4417 8.70609 12.325 8.93548C12.2119 9.16129 12.0581 9.37276 11.8637 9.56989C11.6729 9.76344 11.459 9.97133 11.2222 10.1935C11.0171 10.3763 10.8775 10.5627 10.8033 10.7527C10.7291 10.9391 10.6884 11.1738 10.6813 11.457ZM8.94221 13.0914C8.94221 12.8333 9.03411 12.6183 9.21792 12.4462C9.40173 12.2706 9.64033 12.1828 9.93372 12.1828C10.2271 12.1828 10.4657 12.2706 10.6495 12.4462C10.8333 12.6183 10.9252 12.8333 10.9252 13.0914C10.9252 13.3495 10.8333 13.5663 10.6495 13.7419C10.4657 13.914 10.2271 14 9.93372 14C9.64033 14 9.40173 13.914 9.21792 13.7419C9.03411 13.5663 8.94221 13.3495 8.94221 13.0914Z"
        fill="#36B39E"
      />
    </svg>
  );
};
