/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcCloseSmall = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-close-small ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M16 16L4 4" stroke={color} strokeWidth="2" />
      <path className="path" d="M4 16L16 4" stroke={color} strokeWidth="2" />
    </svg>
  );
};
