import { t } from "i18next";
import { TwentyIcArrowDown1 } from "../TwentyIcArrowDown1";
import { TwentyIcArrowUp } from "../icons/TwentyIcArrowUp";
import { useState } from "react";

interface QuestionsAndAnswerProps {
  title: string;
  desc: string;
}

export const QuestionsAndAnswers: React.FC<QuestionsAndAnswerProps> = ({
  title,
  desc,
}) => {
  const [isQuestionOpen, setIsQuestionOpen] = useState(false);

  const toggleQuestion = () => {
    setIsQuestionOpen(!isQuestionOpen);
  };
  return (
    <>
      <div className="box-answer" onClick={toggleQuestion}>
        <div className="faq-header-title">
          <div className="text-wrapper-27">{title}</div>
        </div>
        <div className="faq-arrow">
          {isQuestionOpen ? (
            <TwentyIcArrowUp className="icon-arrow-down" />
          ) : (
            <TwentyIcArrowDown1 className="icon-arrow-up" />
          )}
        </div>
      </div>
      {isQuestionOpen && (
        <div className="box-response">
          <div className="faq-header-desc">{desc}</div>
        </div>
      )}
      <div className="rectangle-9" />
    </>
  );
};

export default QuestionsAndAnswers;
