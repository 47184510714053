import {
  Button,
  Dropdown,
  Input,
  MenuProps,
  Space,
  Tooltip,
  Upload,
  UploadFile,
} from "antd";
import Card from "antd/es/card/Card";
import Dragger from "antd/es/upload/Dragger";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import HeaderMenu from "../../../layout/HeaderMenu";
import { Footer } from "../../../screens/Footer/Footer";
import { BaseTab } from "../../../ui/components/BaseTab";
import Form from "../../../ui/components/Form/Form";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { TwentyIcArrowDown } from "../../../ui/components/icons/TwentyIcArrowDown";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";
import { TwentyIcLink } from "../../../ui/components/icons/TwentyIcLink";
import { ToastNotification } from "../../../ui/components/utils/ToastNotification";
import { convertBase64, isURL } from "../../../utils/utils";
import {
  PublicAccount,
  getPublicAccountsAsync,
  publicAccountSelector,
} from "../../Account/PublicAccount/PublicAccountSlice";
import {
  UserInfo,
  getUserInfoAsync,
  userInfoSelector,
} from "../../UserInfo/UserSlice";
import { getWellnessTableAsync } from "../../Wellness/WellnessSlice/WellNessTableSlice";
import { updateWellnessBodyAsync } from "../../Wellness/WellnessSlice/WellnessBodySlice";
import { updateWellnessNutritionalAsync } from "../../Wellness/WellnessSlice/WellnessNutritionalSlice";
import { updateWellnessPsychoAsync } from "../../Wellness/WellnessSlice/WellnessPsychoSlice";
import "../CreateService/CreateService.css";
import {
  addItem,
  itemsEmptyState,
  removeItem,
  selectGalleryItems,
} from "../../Gallery/GalleryPreviewSlice";
import { Gallery } from "../../Gallery/GallerySlice";
import CopyLinkButton from "../../../ui/components/Button/CopyLinkButton";

export const CreateService = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const publicAccount: PublicAccount[] = useAppSelector(
    publicAccountSelector.selectAll
  );
  const { TextArea } = Input;

  const selectProviederForService = (providerType: string) => {
    if (providerType === "COACH") {
      return "BODY";
    } else if (providerType === "PSYCHOLOGIST") {
      return "PSYCHO";
    } else if (providerType === "NUTRITIONIST") {
      return "NUTRITIONAL";
    } else {
      return "";
    }
  };
  let defaultListImageBackgroundPreview: UploadFile | any = [];
  let defaultListGalleryPreview: UploadFile | any = [];
  const title = queryParams.get("title");
  const serviceId = queryParams.get("service");
  const providerType: string = selectProviederForService(
    publicAccount[0]?.providerType
  );

  const [titleString, setTitleString] = useState(title || "");
  const [description, setDescription] = useState("");
  const [typologyDropdown, setTypologyDropdown] = useState<string | undefined>(
    ""
  );
  const [isLinkValid, setIsLinkValid] = useState(false);
  const [positionDropdown, setPositionDropdown] = useState("");
  const [draftDropdown, setDraftDropdown] = useState("Pubblica");
  const [numbersessions, setNumberSessions] = useState<string | undefined>("");
  const [duration, setDuration] = useState<string | undefined>("");
  const [presenceOrRemotely, setPresenceOrRemotely] = useState<
    string | undefined
  >("");
  const [price, setPrice] = useState<number>(0);
  let [isIndividual, setIsIndividual] = useState(false);
  const [seats, setSeats] = useState<number | undefined>(2);
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [address, setAddress] = useState("");
  const [cap, setCap] = useState("");
  const [link, setLink] = useState("");
  const [photo, setPhoto] = useState<any | undefined>();
  const [gallery, setGallery] = useState<string[]>([]);

  let previewGallery: Gallery[] = useAppSelector(selectGalleryItems);

  const tenantId = localStorage.getItem("tenantId");
  const userInfoId = localStorage.getItem("customerId");

  useEffect(() => {
    store.dispatch(
      getUserInfoAsync({
        userInfoId: parseInt(userInfoId ?? ""),
        tenantId: tenantId,
      })
    );
    store.dispatch(getPublicAccountsAsync()).then(() => {
      if (providerType === "NUTRITIONAL") {
        setIsIndividual(true);
        setSeats(1);
      }
    });

    let imageLoaded = localStorage.getItem("imageMyServiceBackgroundPreview");
    if (imageLoaded && defaultListImageBackgroundPreview.length === 0) {
      const uploadFile: UploadFile = {
        uid: "-1",
        name: "ImmagineCaricata",
        status: "done",
        url: imageLoaded,
      };
      defaultListImageBackgroundPreview?.push(uploadFile);
      setPhoto(imageLoaded);
    }
  }, []);

  const userInfo: UserInfo = useAppSelector(
    (state) =>
      userInfoSelector.selectById(state, userInfoId ?? "") ?? ({} as UserInfo)
  );

  function numMeeting(numbersessions: string | undefined) {
    switch (numbersessions) {
      case "Seduta individuale":
        return 1;
      case "5 sedute":
        return 5;
      case "8 sedute":
        return 8;
      default:
        return "Seduta individuale";
    }
  }

  function durationMeeting(duration: string) {
    switch (duration) {
      case "1 ora":
        return "1";
      case "2 ore":
        return "2";
      case "3 ore":
        return "3";
      case "4 ore":
        return "4";
      case "5 ore":
        return "5";
      default:
        return undefined;
    }
  }

  const functionTitle = (typology: string | undefined) => {
    let title = "";
    switch (typology) {
      case "BODY":
        title = "Servizio - Attività psico-fisiche";
        break;
      case "NUTRITIONAL":
        title = "Servizio - Sedute nutrizionistiche";
        break;
      case "PSYCHO":
        title = "Servizio - Sedute psicologiche";
        break;
      default:
        break;
    }
    return title;
  };

  const functionTypology = (typology: string) => {
    let typologyItems: MenuProps["items"] = [];

    switch (typology) {
      case "BODY":
        typologyItems = [
          {
            label: t("myService.dropdownCreate.typologyBody1"),
            key: t("myService.dropdownCreate.typologyBody1"),
          },
          {
            label: t("myService.dropdownCreate.typologyBody2"),
            key: t("myService.dropdownCreate.typologyBody2"),
          },
          {
            label: t("myService.dropdownCreate.typologyBody3"),
            key: t("myService.dropdownCreate.typologyBody3"),
          },
          {
            label: t("myService.dropdownCreate.typologyBody5"),
            key: t("myService.dropdownCreate.typologyBody5"),
          },
          {
            label: t("myService.dropdownCreate.typologyBody6"),
            key: t("myService.dropdownCreate.typologyBody6"),
          },
        ];
        break;

      case "NUTRITIONAL":
        typologyItems = publicAccount[0].typologies.map((el) => ({
          label: el.name,
          key: el.name,
        }));
        break;

      case "PSYCHO":
        typologyItems = publicAccount[0].typologies.map((el) => ({
          label: el.name,
          key: el.name,
        }));
        break;

      default:
        break;
    }

    return typologyItems;
  };

  const itemPrice: MenuProps["items"] = [
    {
      label: t("myService.dropdownSessions.oneSession"),
      key: t("myService.dropdownSessions.oneSession"),
    },
    {
      label: t("myService.dropdownSessions.fiveSession"),
      key: t("myService.dropdownSessions.fiveSession"),
    },
    {
      label: t("myService.dropdownSessions.eightSession"),
      key: t("myService.dropdownSessions.eightSession"),
    },
  ];

  const itemServiceDuration: MenuProps["items"] = [
    {
      label: t("myService.dropdownDurations.one"),
      key: t("myService.dropdownDurations.one"),
    },
    {
      label: t("myService.dropdownDurations.two"),
      key: t("myService.dropdownDurations.two"),
    },
    {
      label: t("myService.dropdownDurations.three"),
      key: t("myService.dropdownDurations.three"),
    },
    {
      label: t("myService.dropdownDurations.four"),
      key: t("myService.dropdownDurations.four"),
    },
    {
      label: t("myService.dropdownDurations.five"),
      key: t("myService.dropdownDurations.five"),
    },
  ];

  const draftItems: MenuProps["items"] = [
    {
      label: t("myService.dropdownDraft.public"),
      key: t("myService.dropdownDraft.public"),
    },
    {
      label: t("myService.dropdownDraft.draft"),
      key: t("myService.dropdownDraft.draft"),
    },
  ];

  const onDraftClick: MenuProps["onClick"] = ({ key }) => {
    setDraftDropdown(key);
  };

  const handleSave = (
    title: string,
    description: string,
    seats: number,
    draftDropdown: string,
    numbersessions: string,
    price: number,
    timeSessions: string
  ) => {
    if (
      title !== "" &&
      description !== "" &&
      seats !== null &&
      draftDropdown !== "" &&
      numbersessions !== "" &&
      price.toString() !== "" &&
      price.toString() !== "0" &&
      timeSessions !== "" &&
      isLinkValid &&
      (providerType === "BODY" ? previewGallery.length > 0 && !!photo : true)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleUpdateAction = async (
    providerType: string,
    serviceId: string | null,
    wellnessData: object
  ) => {
    let updateAction: any;

    switch (providerType) {
      case "BODY":
        updateAction = await store.dispatch(
          updateWellnessBodyAsync({
            wellnessBody: parseInt(serviceId ?? ""),
            wellnessData,
          })
        );
        break;
      case "NUTRITIONAL":
        updateAction = await store.dispatch(
          updateWellnessNutritionalAsync({
            wellnessNutritional: parseInt(serviceId ?? ""),
            wellnessData,
          })
        );
        break;
      case "PSYCHO":
        updateAction = await store.dispatch(
          updateWellnessPsychoAsync({
            wellnessPsycho: parseInt(serviceId ?? ""),
            wellnessData,
          })
        );
        break;
      default:
        break;
    }

    if (updateAction) {
      store.dispatch(updateAction);
    }
  };

  const handleTitle = (e: any, providerType: string) => {
    setTitleString(e.target.value);
    handleUpdateAction(providerType, serviceId, {
      name: e.target.value,
    });
  };

  const handleDescription = (e: any, providerType: string) => {
    setDescription(e.target.value);
    handleUpdateAction(providerType, serviceId, {
      description: e.target.value,
    });
  };

  const onTypologyClick: MenuProps["onClick"] = ({ key }) => {
    setTypologyDropdown(key);
    handleUpdateAction(providerType, serviceId, {
      typology: key,
    });
  };

  const onSessionsClick: MenuProps["onClick"] = ({ key }) => {
    setNumberSessions(key);

    handleUpdateAction(providerType, serviceId, {
      numberMeeting: numMeeting(key),
    });
  };

  const onDurationClick: MenuProps["onClick"] = ({ key }) => {
    setDuration(key);

    handleUpdateAction(providerType, serviceId, {
      durationMeeting: durationMeeting(key),
    });
  };

  const handlePriceChange = (e: any) => {
    if (parseInt(e.target.value) >= 0 || e.target.value === "") {
      setPrice(e.target.value);
    }

    handleUpdateAction(providerType, serviceId, {
      price: e.target.value,
    });
  };

  const handleIsIndividual = (newIsIndividual: boolean, newSeats: number) => {
    if (newIsIndividual !== isIndividual) {
      setIsIndividual(newIsIndividual);
      setSeats(newSeats);

      if (newIsIndividual) {
        handleUpdateAction(providerType, serviceId, {
          isIndividual: newIsIndividual,
          sessionsAvailable: 2,
        });
      } else {
        handleUpdateAction(providerType, serviceId, {
          isIndividual: newIsIndividual,
        });
      }
    }
  };

  const handleIsIndividualChange = (e: any, seats: number) => {
    if (isIndividual === true && parseInt(e.target.value) >= seats) {
      setSeats(e.target.value);
    } else if (e.target.value === "") {
      setSeats(undefined);
    } else {
      setSeats(2);
    }

    handleUpdateAction(providerType, serviceId, {
      sessionsAvailable: e.target.value,
    });
  };

  const updateWellnessOnClick = async () => {
    try {
      const getCurrentWellness = (typology: string) => {
        const wellnessTypes: Record<
          string,
          { update: Function; type: string }
        > = {
          BODY: { update: updateWellnessBodyAsync, type: "wellnessBody" },
          NUTRITIONAL: {
            update: updateWellnessNutritionalAsync,
            type: "wellnessNutritional",
          },
          PSYCHO: {
            update: updateWellnessPsychoAsync,
            type: "wellnessPsycho",
          },
        };

        return wellnessTypes[typology] || null;
      };

      const getWellnessData = () => {
        const baseData = {
          name: titleString ?? "",
          description: description,
          typology: typologyDropdown,
          numberMeeting: numMeeting(numbersessions ?? ""),
          durationMeeting: duration,
          price: price,
          isIndividual: isIndividual,
          link: link,
          sessionsAvailable: isIndividual === false ? 1 : seats,
          remotelyOrPresenceEnum: "REMOTE",
          image: photo,
          approved: false,
          userId: userInfoId,
          active: true,
          published: draftDropdown === "Pubblica" ? true : false,
        };

        if (presenceOrRemotely && presenceOrRemotely !== "A distanza") {
          const wellnessBodyDatas = {
            ...baseData,
            city: city,
            province: province ?? "",
            address: address,
            zip: cap,
            location: positionDropdown ? positionDropdown?.toUpperCase() : null,
          };
          return wellnessBodyDatas;
        }

        if (providerType === "BODY") {
          const wellnessBodyData = {
            ...baseData,
            deletedPhotoGallery: [],
            gallery: gallery ?? [],
          };
          return wellnessBodyData;
        }

        return baseData;
      };

      const wellnessData = getWellnessData();
      const wellnessConfig = getCurrentWellness(providerType ?? "");

      if (wellnessConfig) {
        const result = await store.dispatch(
          wellnessConfig.update({
            [wellnessConfig.type]: parseInt(serviceId ?? ""),
            wellnessData,
          })
        );

        localStorage.removeItem("imageMyServiceBackgroundPreview");

        if (result.meta.requestStatus === "fulfilled") {
          navigate("/myService");
          store.dispatch(getWellnessTableAsync());
          ToastNotification({
            status: "success",
            description: "",
            toastId: "Service created",
            title: t("toastNotification.successfully"),
          });
        } else {
          ToastNotification({
            status: "error",
            description: "",
            toastId: "Service not created",
            title: t("toastNotification.networkError"),
          });
        }
      }
    } catch (error) {
      console.error("Error creating wellness service:", error);
      ToastNotification({
        status: "error",
        description: "",
        toastId: "Service not created",
        title: t("toastNotification.networkError"),
      });
    }
  };
  // FINE | GESTIONE SALVATAGGIO BOZZA

  const uploadFilePhotoService = (file: File) => {
    convertBase64(file).then((response) => {
      setPhoto(response);
    });
  };

  const uploadFileGalleryService = (file: any) => {
    convertBase64(file).then((response) => {
      setGallery((prevGallery) => [...prevGallery, response]);
      let object: Gallery = {
        id: file.uid,
        image: response,
      };
      store.dispatch(addItem(object));
    });
  };

  useEffect(() => {
    if (photo !== undefined && photo !== "") {
      localStorage.setItem("imageMyServiceBackgroundPreview", photo);
    }
  }, [photo]);

  useEffect(() => {
    if (previewGallery && defaultListGalleryPreview.length === 0) {
      previewGallery.map((e, index) => {
        const uploadFile: UploadFile = {
          uid: e.id,
          name: "ImmagineCaricata",
          status: "done",
          url: e.image,
        };
        defaultListGalleryPreview.push(uploadFile);
      });
    }
  }, [previewGallery.length]);

  return (
    <>
      <div className="create-service-filterbar">
        <div className="draft-dropdown">
          <Dropdown
            menu={{
              items: draftItems,
              onClick: onDraftClick,
            }}
          >
            <div className="button-dropdown-draft">
              <Button>
                <div className="space-draft">
                  <Space>
                    <div
                      className={
                        draftDropdown
                          ? "myService-filtermodal-text"
                          : "myService-filtermodal-text-select"
                      }
                    >
                      {draftDropdown ? draftDropdown : "Salva come"}
                    </div>
                    <TwentyIcArrowDown color="#36B39E" className={undefined} />
                  </Space>
                </div>
              </Button>
            </div>
          </Dropdown>
        </div>
        {draftDropdown === "Pubblica" &&
        handleSave(
          titleString,
          description,
          seats ?? 0,
          draftDropdown,
          numbersessions ?? "",
          price ?? 0,
          duration ?? ""
        ) ? (
          <div className="save-button">
            <Tooltip
              placement="left"
              title={
                "Prima di salvare il servizio, è necessaria la compilazione di tutti i campi."
              }
            >
              <Button
                disabled={
                  draftDropdown === "Pubblica" &&
                  handleSave(
                    titleString,
                    description,
                    seats ?? 0,
                    draftDropdown,
                    numbersessions ?? "",
                    price ?? 0,
                    duration ?? ""
                  )
                }
                onClick={() => {
                  updateWellnessOnClick();
                }}
              >
                <div className="space-save">
                  <Space>
                    <div className="smartplan-filtermodal-text-white">
                      {t("myService.components.filterBar.save")}
                    </div>
                  </Space>
                </div>
              </Button>
            </Tooltip>
          </div>
        ) : (
          <div className="save-button">
            <Button
              disabled={
                draftDropdown === "Pubblica" &&
                handleSave(
                  titleString,
                  description,
                  seats ?? 0,
                  draftDropdown,
                  numbersessions ?? "",
                  price ?? 0,
                  duration ?? ""
                )
              }
              onClick={() => {
                updateWellnessOnClick();
                store.dispatch(itemsEmptyState);
              }}
            >
              <div className="space-save">
                <Space>
                  <div className="smartplan-filtermodal-text-white">
                    {t("myService.components.filterBar.save")}
                  </div>
                </Space>
              </div>
            </Button>
          </div>
        )}
      </div>

      <div className="header-menu">
        <HeaderMenu>
          <TopBar
            title={titleString}
            breadcrumbText={"Servizi > " + titleString}
            icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
            onClick={() => {
              navigate(-1);
            }}
            hasEdit={false}
            hasDate={false}
          />
          <div className="line-create-service " />
        </HeaderMenu>
      </div>
      <div className="page-content">
        <div className="title-card-title">
          {functionTitle(providerType ?? "")}
          <div className="card-title">
            <Card className="card-font">
              <div className="title-text">
                Titolo e descrizione del servizio
              </div>
              <div className="title-text-up">Titolo</div>
              <div className="input-title">
                <Form>
                  <Input
                    id="username-field"
                    value={titleString}
                    style={{ width: "100%", height: "50px" }}
                    size="large"
                    placeholder="Inserisci titolo"
                    onChange={(e) => handleTitle(e, providerType ?? "")}
                  />
                </Form>
              </div>
              <div className="title-text-up">Descrizione</div>
              <div className="input-desc">
                <TextArea
                  rows={10}
                  value={description}
                  onChange={(e) => handleDescription(e, providerType ?? "")}
                  placeholder="Descrizione dell'evento"
                />
              </div>
            </Card>
          </div>
        </div>
        <div className="title-card-typology">
          Informazione base
          <div className="card-typology">
            <Card className="card-font">
              <div className="title-text">Tipologia</div>
              {providerType === "BODY" && (
                <>
                  <div className="title-text-up">Tipologia di servizio</div>
                  <div className="dropdown-typology">
                    <Dropdown
                      menu={{
                        items: functionTypology(providerType ?? ""),
                        onClick: onTypologyClick,
                      }}
                    >
                      <div className="button-dropdown-typology">
                        <Button>
                          <div className="space-dropdown-typology">
                            <Space>
                              <div
                                className={
                                  typologyDropdown
                                    ? "myService-filtermodal-text"
                                    : "myService-filtermodal-text-select"
                                }
                              >
                                {typologyDropdown
                                  ? typologyDropdown
                                  : t("smartPlan.filterModal.select")}
                              </div>
                              <TwentyIcArrowDown
                                color="#36B39E"
                                className={undefined}
                              />
                            </Space>
                          </div>
                        </Button>
                      </div>
                    </Dropdown>
                  </div>
                </>
              )}
              <div className="price-duration-text-up">Prezzo e durata</div>

              <div className="line-price">
                <div className="time-picker">
                  <div className="session-text-up">
                    Durata della seduta
                    <Dropdown
                      menu={{
                        items: itemServiceDuration,
                        onClick: onDurationClick,
                      }}
                    >
                      <div className="button-dropdown-seats">
                        <Button>
                          <div className="space-dropdown-seats">
                            <Space>
                              <div
                                className={
                                  duration
                                    ? "myService-filtermodal-text"
                                    : "myService-filtermodal-text-select"
                                }
                              >
                                {duration
                                  ? duration
                                  : t("smartPlan.filterModal.select")}
                              </div>
                              <TwentyIcArrowDown
                                color="#36B39E"
                                className={undefined}
                              />
                            </Space>
                          </div>
                        </Button>
                      </div>
                    </Dropdown>
                  </div>
                </div>

                <div className="input-price">
                  <div className="price-text-up">
                    Prezzo desiderato del servizio
                    <Input
                      addonAfter={"€"}
                      size="large"
                      type="number"
                      onWheel={(e) => e.currentTarget.blur()}
                      placeholder="Inserisci prezzo"
                      value={price}
                      onChange={(e) => handlePriceChange(e)}
                    />
                  </div>
                </div>
              </div>

              {providerType !== "NUTRITIONAL" && (
                <>
                  <div className="person-text-up">Posti e modalità</div>

                  <div className="line-sessions">
                    <div className="dropdown-typology">
                      <div className="session-text-up">
                        Sessione
                        <div className="myService-tabs">
                          <BaseTab
                            select={!isIndividual}
                            text={"Singola"}
                            onClick={() => {
                              if (!isIndividual) // Cristo santo
                                handleIsIndividual(!isIndividual, 1);
                            }}
                          ></BaseTab>
                          <BaseTab
                            select={isIndividual}
                            text={"Gruppi"}
                            onClick={() => {
                              if (!isIndividual)
                                handleIsIndividual(!isIndividual, 2);
                            }}
                          ></BaseTab>
                        </div>
                      </div>
                    </div>
                    <div className="input-persons">
                      Posti
                      <div className="input-person">
                        <Input
                          addonAfter={"Pers."}
                          type="number"
                          size="large"
                          placeholder="Inserisci n. posti"
                          value={isIndividual === false ? 1 : seats}
                          onWheel={(e) => e.currentTarget.blur()}
                          onChange={(e) =>
                            handleIsIndividualChange(e, seats ?? 0)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
        <div className="title-card-hourly-availability">
          Disponibilità oraria
          <div className="card-hourly-availability">
            <Card className="card-font">
              <div className="title-text-union">
                <div className="title-text-2">
                  1. Imposta la tua disponibilità oraria
                </div>
                <div className="title-text-p1">
                  Clicca il link e crea il tuo calendario personalizzato&nbsp;
                  <a
                    className="title-text-p2"
                    href="https://calendly.com/signup"
                    target="_blank"
                    rel="noreferrer"
                  >
                    calendly.com
                  </a>
                </div>
              </div>
              <div className="title-text-union">
                <div className="title-text-2">2. Inserisci il link</div>
                <div className="title-text-p1">
                  Inserendo il link, i clienti visualizzeranno le tue
                  disponibilità e potranno scegliere la più adatta alle loro
                  esigenze.
                </div>
              </div>
              <div className="input-citys">
                <Form>
                  <Input
                    style={!isLinkValid ? { borderColor: "red" } : undefined}
                    prefix={<TwentyIcLink className={undefined} color="gray" />}
                    id="field"
                    value={link}
                    size="large"
                    placeholder="Link"
                    onChange={(e) => {
                      setLink(e.target.value);
                      setIsLinkValid(isURL(e.target.value));
                    }}
                  />
                </Form>
                {!isLinkValid && (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "500",
                      position: "absolute",
                    }}
                  >
                    Inserire un link valido
                  </div>
                )}
              </div>
            </Card>
          </div>
        </div>
        {providerType === "BODY" && (
          <div className="title-card-photo">
            Caricamento foto
            <div className="card-photo">
              <Card className="card-font">
                <div className="title-text">Copertina</div>
                <div className="service-photo">
                  <Space
                    direction="vertical"
                    style={{ width: "100%" }}
                    size="large"
                  >
                    <div className="service-photo">
                      <Upload
                        defaultFileList={defaultListImageBackgroundPreview}
                        onChange={(e: any) => {
                          if (e.fileList.length === 0) {
                            localStorage.removeItem(
                              "imageMyServiceBackgroundPreview"
                            );
                            setPhoto(null);
                          }
                        }}
                        customRequest={({ file, onSuccess }) => {
                          if (onSuccess !== undefined) {
                            uploadFilePhotoService(file as File);
                            onSuccess("ok");
                          }
                        }}
                        action={undefined}
                        listType="picture"
                        maxCount={1}
                      >
                        <div className="button-upload">
                          <Button>
                            <div className="text-update">Upload</div>
                          </Button>
                        </div>
                      </Upload>
                    </div>
                  </Space>
                </div>
                <div className="text-photo-m">
                  Misura consigliata:
                  <div className="text-photo-desc"> 1200 x 250px </div>
                </div>
                <div className="text-photo-p">
                  Peso massimo per file:
                  <div className="text-photo-desc"> 1 mb</div>
                </div>

                <>
                  <div className="gallery-text">Galleria</div>
                  <div className="font-gallery"></div>
                  <Dragger
                    defaultFileList={defaultListGalleryPreview}
                    customRequest={({ file, onSuccess }) => {
                      if (onSuccess !== undefined) {
                        uploadFileGalleryService(file as File);
                        onSuccess("ok");
                      }
                    }}
                    action={undefined}
                    onRemove={(e) => {
                      store.dispatch(removeItem(e.uid));
                    }}
                    listType="picture"
                    maxCount={6}
                  >
                    <div className="text-t">
                      Trascina qui i tuoi file &nbsp;
                      <div className="text-o">
                        - oppure - &nbsp;
                        <div className="text-c"> Carica manualmente</div>
                      </div>
                    </div>
                  </Dragger>

                  <div className="text-photo-m">
                    Misura consigliata:
                    <div className="text-photo-desc"> 800 x 450px </div>
                  </div>
                  <div className="text-photo-p">
                    Peso massimo per file:
                    <div className="text-photo-desc"> 1 mb</div>
                  </div>
                </>
              </Card>
            </div>
          </div>
        )}
      </div>
      <div className="background-footer">
        <Footer />
      </div>
    </>
  );
};
