/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcDashboard = ({ size, color = "white" }) => {
  return (
    <svg
      fill="none"
      className="twenty-ic-dashboard"
      height={size}
      viewBox="0 0 20 20"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3 9.62429V3C10.732 3 17 9.26801 17 17H9.70808"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M12 8L6.26208 16.2325C5.69619 17.0444 4.57925 17.2439 3.76733 16.678C3.68068 16.6176 3.59954 16.5497 3.52485 16.475C2.82505 15.7752 2.82505 14.6406 3.52485 13.9408C3.59954 13.8661 3.68068 13.7982 3.76733 13.7378L12 8Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
