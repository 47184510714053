/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcArrowLineRight = ({
  className,
  color = "white",
  onClick,
}) => {
  return (
    <svg
      onClick={onClick}
      className={`twenty-ic-arrow-line-right ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M13 4C13 7.5 19 10 19 10M19 10C19 10 13 12.5 13 16M19 10H1"
        stroke={color}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
