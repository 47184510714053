/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcPeople = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-people ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_662_18685)">
        <path
          className="path"
          d="M9 11C8.33333 13.3333 6.8 18.3 6 19.5"
          stroke={color}
          strokeWidth="2"
        />

        <path
          className="path"
          d="M11 11C11.6667 13.3333 13.2 18.3 14 19.5"
          stroke={color}
          strokeWidth="2"
        />

        <path className="path" d="M8 14V6H12V14H8Z" fill={color} />

        <path
          className="path"
          d="M2 6C2 6 5.2 7 9.85455 7C14.5091 7 18 6 18 6"
          stroke={color}
          strokeWidth="2"
        />

        <circle
          className="circle"
          cx="10"
          cy="2.5"
          fill={color}
          r="2"
          stroke={color}
        />
      </g>

      <defs className="defs">
        <clipPath className="clip_path" id="clip0_662_18685">
          <rect className="rect" fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
