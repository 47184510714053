import "./style.css";
import { TwentyIcSearch } from "../icons/TwentyIcSearch";
import { BaseInput } from "../BaseInput/BaseInput";

enum InputSize {
  l,
  m,
  xl,
}

interface InputProps {
  size?: InputSize;
  baseInputText?: string;
  baseInputIconLeft?: boolean;
  className?: string;
  inputSearched: (e: any) => any;
  icon?: any;
}

export const SearchInput: React.FC<InputProps> = ({
  size,
  className,
  baseInputText = "Ricerca per nome",
  baseInputIconLeft,
  icon,
  inputSearched,
}) => {
  return (
    <div className={`input ${size} ${className}`}>
      <BaseInput
        icon={icon ? icon : <TwentyIcSearch color="grey" className={""} />}
        iconLeft={baseInputIconLeft}
        iconRight={false}
        style="default"
        text={baseInputText}
        className={undefined}
        frameClassName={undefined}
        onChange={(searchKey) => inputSearched(searchKey)}
      />
    </div>
  );
};
