import { schema } from "normalizr";
import { smartPlanSchema } from "../Smartplan/SmartPlanNormalization";
import { smartDatePlanSchema } from "../Smartplan/SmartDatePlan/SmartDatePlaneNormalization";

export const myOrderSchema = new schema.Entity("myOrders", {});

export const myOrdersSchema = new schema.Array(myOrderSchema);

export const myOrderDetailSchema = new schema.Entity("orderDetail", {
  smartPlan: smartPlanSchema,
  smartPlanDate: smartDatePlanSchema,
});
