/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconHappiness = ({ className, color = "#FFD685" }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10.5"
        cy="10"
        r="8"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="8" cy="7.5" r="1" fill={color} stroke={color} />
      <circle cx="13" cy="7.5" r="1" fill={color} stroke={color} />
      <path
        d="M14.5 11C14.5 10.4477 14.0523 10 13.5 10C12.9477 10 12.5 10.4477 12.5 11H14.5ZM13.2716 12.1481L14.1955 12.5307L13.2716 12.1481ZM12.6213 13.1213L13.3284 13.8284V13.8284L12.6213 13.1213ZM11.6481 13.7716L11.2654 12.8478L11.2654 12.8478L11.6481 13.7716ZM8.37868 13.1213L7.67157 13.8284H7.67157L8.37868 13.1213ZM7.72836 12.1481L6.80448 12.5307L6.80448 12.5307L7.72836 12.1481ZM8.5 11C8.5 10.4477 8.05228 10 7.5 10C6.94772 10 6.5 10.4477 6.5 11H8.5ZM12.5 11C12.5 11.2626 12.4483 11.5227 12.3478 11.7654L14.1955 12.5307C14.3965 12.0454 14.5 11.5253 14.5 11H12.5ZM12.3478 11.7654C12.2472 12.008 12.0999 12.2285 11.9142 12.4142L13.3284 13.8284C13.6999 13.457 13.9945 13.016 14.1955 12.5307L12.3478 11.7654ZM11.9142 12.4142C11.7285 12.5999 11.508 12.7473 11.2654 12.8478L12.0307 14.6955C12.516 14.4945 12.957 14.1999 13.3284 13.8284L11.9142 12.4142ZM11.2654 12.8478C11.0227 12.9483 10.7626 13 10.5 13V15C11.0253 15 11.5454 14.8965 12.0307 14.6955L11.2654 12.8478ZM10.5 13C10.2374 13 9.97728 12.9483 9.73463 12.8478L8.96927 14.6955C9.45457 14.8965 9.97471 15 10.5 15V13ZM9.73463 12.8478C9.49198 12.7472 9.2715 12.5999 9.08579 12.4142L7.67157 13.8284C8.04301 14.1999 8.48396 14.4945 8.96927 14.6955L9.73463 12.8478ZM9.08579 12.4142C8.90007 12.2285 8.75275 12.008 8.65224 11.7654L6.80448 12.5307C7.0055 13.016 7.30014 13.457 7.67157 13.8284L9.08579 12.4142ZM8.65224 11.7654C8.55173 11.5227 8.5 11.2626 8.5 11H6.5C6.5 11.5253 6.60346 12.0454 6.80448 12.5307L8.65224 11.7654Z"
        fill={color}
      />
    </svg>
  );
};
