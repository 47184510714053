/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcFavorite = ({ className, color = "black" }) => {
  return (
    <svg
      className={`twenty-ic-favorite ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M2.07182 5.75297C2.88535 1.05549 8.25461 0.887766 10.2071 4.57865C14.112 -0.454393 18.8997 4.50858 17.8542 8.10175C16.3899 13.1348 11.8341 16.1546 8.58003 18C7.60381 13.9736 1.2583 10.4505 2.07182 5.75297Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
