import HttpClient from "../../utils/HttpClient";
import { Tenant } from "./TenantSlice";

export default class TenantRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getTenant(tenantId: number) {
    return this.instance.get("/tenants/" + tenantId);
  }

  public getTenantAdditionalInfo(tenantId: number) {
    return this.instance.get("/tenants/" + tenantId + "/additionalInfo");
  }

  public updateTenant(tenant: Tenant) {
    return this.instance.patch("/tenants/" + tenant.id, tenant);
  }
}
