import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseTab } from "../BaseTab/BaseTab";
import { SearchInput } from "../Input/SearchInput";
import Tag from "../Tag/Tag";
import { TwentyIcFiltersOrder } from "../icons/TwentyIcFiltersOrder";
import "./SmartPlanFilterBar.css";

interface WellnessNutritionalFilterBarProps {
  filterOpened?: boolean;
  setSwitchSelected: (e: any) => any;
  setModalOpened: (e: any) => any;
  filters: Map<string, string>;
  searched: (e: any) => any;
  setFilters: (e: any) => any;
  switchStatus: number;
}

export const WellnessNutritionalFilterBar: React.FC<
  WellnessNutritionalFilterBarProps
> = ({
  filterOpened,
  setSwitchSelected,
  setModalOpened,
  filters,
  searched,
  setFilters,
  switchStatus,
}) => {
  const [elementSelected, setElementSelected] = useState(switchStatus);
  const [refreshed, setRefreshed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setElementSelected(switchStatus);
  }, [switchStatus]);

  useEffect(() => {
    setSwitchSelected(elementSelected);
  }, [elementSelected]);

  function removeFilter(map: Map<string, string>, value: any) {
    map.forEach(function (valore, key) {
      if (valore === value) {
        map.delete(key);
      }
    });
    setFilters(map);
    setRefreshed(!refreshed);
  }

  function resetFilters(map: Map<string, string>) {
    map.clear();
    setFilters(map);
    setRefreshed(!refreshed);
  }

  function FirsTextTagReader(element: string) {
    switch (element) {
      case "typology":
        return t("wellness.body.filterBar.activity");
      case "location":
        return t("smartPlan.components.tagElements.location");
      case "remotelyOrPresenceEnum":
        return t("wellness.body.filterBar.mode");
      case "minPrice":
        return t("smartPlan.components.tagElements.minPrice");
      case "maxPrice":
        return t("smartPlan.components.tagElements.maxPrice");
      default:
        return element;
    }
  }

  function SecondTextTagReader(e1: string, e2: string | string[]) {
    if (e1 == "typology") {
      if (Array.isArray(e2)) {
        var newEl = [] as string[];
        e2.map((el) => {
          newEl.push(el);
        });
        return newEl;
      } else {
        return e2;
      }
    }
  }

  useEffect(() => {
    setModalOpened(showModal);
  }, [showModal]);

  return (
    <>
      <div className="smartplan-filterbar">
        {/* BUTTON ZONE */}
        <div className="smartplan-filterBar-tabs">
          <BaseTab
            select={elementSelected === 0}
            text={t("switchBar.allServices")}
            onClick={() => {
              setElementSelected(0);
            }}
          ></BaseTab>
          <BaseTab
            select={elementSelected === 1}
            text={t("switchBar.myOrders")}
            onClick={() => {
              setElementSelected(1);
            }}
          ></BaseTab>
        </div>
        {/* END BUTTON ZONE */}
        {/* SEARCH ZONE */}
        {!elementSelected && (
          <div className="smartplan-filterBar-search">
            <SearchInput
              baseInputText={t("smartPlan.components.filterBar.searchByName")}
              inputSearched={(e) => searched(e)}
            />
            <div className="smartplan-filterBar-filterbutton">
              <BaseTab
                icon={
                  <TwentyIcFiltersOrder color="grey" className={undefined} />
                }
                select={!filterOpened}
                isButton={true}
                text={t("smartPlan.common.filters")}
                children={
                  !!filters &&
                  filters?.size > 0 && (
                    <div className="smartplan-filterBar-filterbutton-counter">
                      <div className="smartplan-filterbar-filterbutton-counter-text">
                        {filters.size}
                      </div>
                    </div>
                  )
                }
                onClick={() => {
                  !elementSelected && setShowModal(!showModal);
                }}
              />
            </div>
          </div>
        )}
        {/* END SEARCH ZONE */}
      </div>
      {/* TAG ZONE */}

      {!elementSelected && filters.size > 0 && (
        <React.Fragment>
          <div className="smartplan-filterBar-tags">
            <div className={"smartplan-filterBar-tags-section"}>
              {Array.from(filters.entries()).map(
                ([textOne, textTwo], index) => (
                  <Tag
                    key={index}
                    textOne={FirsTextTagReader(textOne)}
                    textTwo={SecondTextTagReader(textOne, textTwo)}
                    isRemoved={() => {
                      removeFilter(filters, textTwo);
                    }}
                  />
                )
              )}
            </div>
            {filters.size > 1 && (
              <div className="smartplan-filterBar-tags-reset">
                <Tag
                  resetAllFilterButton={true}
                  isRemoved={() => {
                    resetFilters(filters);
                  }}
                />
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      {/* END TAG ZONE */}
    </>
  );
};
