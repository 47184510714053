export const TwentyIcCreditCoins = ({
  className,
  color,
  colorC,
  size = "24",
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="5"
        width="14"
        height="14"
        rx="7"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M11.9108 16C11.3561 16 10.8395 15.9001 10.3611 15.7004C9.88955 15.4933 9.47697 15.2085 9.12333 14.846C8.76969 14.4836 8.49232 14.062 8.29123 13.5811C8.09708 13.0929 8 12.5677 8 12.0055C8 11.436 8.09708 10.9108 8.29123 10.43C8.49232 9.94175 8.76969 9.51641 9.12333 9.15395C9.4839 8.79149 9.89995 8.5104 10.3715 8.31068C10.8499 8.10356 11.3631 8 11.9108 8C12.313 8 12.7083 8.06288 13.0966 8.18863C13.4849 8.31438 13.842 8.49191 14.1679 8.72122C14.5007 8.94313 14.7781 9.20943 15 9.52011L13.7935 10.785C13.5369 10.4374 13.2491 10.1785 12.9302 10.0083C12.6181 9.83819 12.2784 9.75312 11.9108 9.75312C11.6196 9.75312 11.3457 9.8123 11.0892 9.93065C10.8395 10.0416 10.6211 10.1969 10.4339 10.3967C10.2467 10.5964 10.101 10.8331 9.99703 11.1068C9.89302 11.3805 9.84101 11.6801 9.84101 12.0055C9.84101 12.3236 9.89302 12.6195 9.99703 12.8932C10.108 13.1595 10.2571 13.3962 10.4443 13.6033C10.6384 13.8031 10.8638 13.9584 11.1204 14.0693C11.3839 14.1803 11.6682 14.2358 11.9733 14.2358C12.3269 14.2358 12.6528 14.1544 12.951 13.9917C13.2561 13.8289 13.53 13.5885 13.7727 13.2705L14.948 14.5021C14.7261 14.8054 14.4522 15.0717 14.1263 15.301C13.8004 15.5229 13.4468 15.6967 13.0654 15.8225C12.684 15.9408 12.2992 16 11.9108 16Z"
        fill={colorC}
      />
    </svg>
  );
};
