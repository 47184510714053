import { t } from "i18next";
import { TwentyIcCloseSmall } from "../icons/TwentyIcCloseSmall";
import "./Tag.css";

export interface TagProps {
  textOne?: string;
  textTwo?: string | string[];
  color?: string;
  resetAllFilterButton?: boolean;
  isRemoved?: (e: any) => any;
  isRem?: boolean;
}

const Tag: React.FC<TagProps> = ({
  textOne,
  textTwo,
  color,
  isRemoved,
  resetAllFilterButton,
  isRem = true,
}) => {
  return (
    <>
      {!resetAllFilterButton ? (
        textOne !== "" && textTwo !== "" ? (
          <div
            className="tag"
            style={{ backgroundColor: color ? color : "#ebf7f5" }}
          >
            <div className="tag-textone">{textOne && textOne} </div>
            <div className="tag-texttwo">
              {textTwo && Array.isArray(textTwo)
                ? textTwo.map((e, index) =>
                    index === textTwo.length - 1
                      ? e.toString() + " "
                      : e.toString() + ", "
                  )
                : textTwo}
            </div>
            {isRem && (
              <>
                <span className="tag-divider" />
                <div onClick={isRemoved}>
                  <TwentyIcCloseSmall
                    color="#36b39e"
                    className="tag-icon"
                  ></TwentyIcCloseSmall>
                </div>
              </>
            )}
          </div>
        ) : null
      ) : (
        <div className="tag-reset" onClick={isRemoved}>
          <span className="tag-divider" />
          <div className="tag-text-reset">{t("smartPlan.common.resetAll")}</div>
          <span className="tag-divider" />
        </div>
      )}
    </>
  );
};

export default Tag;
