import { t } from "i18next";
import { ModalFooter } from "../../../ui/components/Modals/ModalFooter";
import "../AccountModal/ChangeBusinessDataModal.css";
import { ModalBody } from "../../../ui/components/Modals/ModalBody";
import { ModalWrapper } from "../../../ui/components/Modals/ModalWrapper";
import { Input } from "antd";
import { useEffect, useState } from "react";
import { Tenant } from "../../Tenant/TenantSlice";
import { validateNumericInput } from "../../../utils/utils";

interface ChangeBusinessDataModalProps {
  onClose: () => void;
  onClick: (tenant: Tenant) => void;
  title: string;
  isActionPerforming?: boolean | undefined;
  initialData: {
    name: string;
    vatNumber: string;
    email: string;
    telephone: string;
    address: string;
    city: string;
    province: string;
    businessName: string;
    prefix: string;
    country: string;
    zip: string;
  };
}
export const ChangeBusinessDataModal: React.FC<
  ChangeBusinessDataModalProps
> = ({ onClose, onClick, title, isActionPerforming, initialData }) => {
  const tenantIdString: string | null = localStorage.getItem("tenantId");
  const tenantId: number = parseInt(tenantIdString ?? "0", 10);
  const [vatNumber, setVatNumber] = useState(initialData.vatNumber);
  const [email, setEmail] = useState(initialData.email);
  const [telephone, setTelephone] = useState(initialData.telephone ?? "");
  const [address, setAddress] = useState(initialData.address);
  const [city, setCity] = useState(initialData.city);
  const [province, setProvince] = useState(initialData.province);
  const [name, setName] = useState(initialData.name);
  const [businessName, setBusienssName] = useState(initialData.businessName);
  const [zip, setZip] = useState(initialData.zip);
  const [prefix, setPrefix] = useState(initialData.prefix);
  const [hasChanged, setHasChanged] = useState(false);

  const [newTenant, setNewTenant] = useState<Tenant>({
    id: tenantId,
    enabled: true,
    name: "",
    businessName: "",
    email: "",
    address: "",
    city: "",
    country: initialData.country,
    province: "",
    zip: initialData.zip,
    prefix: "+39",
    telephone: "",
    vatNumber: "",
  });

  useEffect(() => {
    if (vatNumber !== "") {
      setNewTenant((prevTenant) => ({
        ...prevTenant,
        vatNumber: vatNumber,
      }));
    }
    if (email !== "") {
      setNewTenant((prevTenant) => ({
        ...prevTenant,
        email: email,
      }));
    }
    if (address !== "") {
      setNewTenant((prevTenant) => ({
        ...prevTenant,
        address: address,
      }));
    }
    if (telephone !== "") {
      setNewTenant((prevTenant) => ({
        ...prevTenant,
        telephone: telephone,
      }));
    }
    if (city !== "") {
      setNewTenant((prevTenant) => ({
        ...prevTenant,
        city: city,
      }));
    }
    if (province !== "") {
      setNewTenant((prevTenant) => ({
        ...prevTenant,
        province: province,
      }));
    }
    if (businessName !== "") {
      setNewTenant((prevTenant) => ({
        ...prevTenant,
        businessName: businessName,
      }));
      if (zip !== "") {
        setNewTenant((prevTenant) => ({
          ...prevTenant,
          zip: zip,
        }));
      }
      if (name !== "") {
        setNewTenant((prevTenant) => ({
          ...prevTenant,
          name: name,
        }));
      }
    }

    if (
      (vatNumber !== initialData.vatNumber ||
        email !== initialData.email ||
        telephone !== initialData.telephone ||
        address !== initialData.address ||
        city !== initialData.city ||
        province !== initialData.province ||
        businessName !== initialData.businessName ||
        zip !== initialData.zip ||
        name !== initialData.name) &&
      vatNumber !== "" &&
      email !== "" &&
      telephone !== "" &&
      address !== "" &&
      city !== "" &&
      province !== "" &&
      businessName !== "" &&
      zip !== "" &&
      name !== ""
    ) {
      setHasChanged(false);
    } else {
      setHasChanged(true);
    }
  }, [
    vatNumber,
    email,
    telephone,
    address,
    city,
    province,
    businessName,
    zip,
    name,
  ]);

  return (
    <div className="modal-wrapper-edit-business-data">
      <ModalWrapper open={true} closeAction={onClose}>
        <div className="modal-body-edit-business-data">
          <ModalBody title={title}>
            <div className="modal-body-dynamic">
              <div className="modal-body-input-field">
                <div className="input-field">
                  <div className="input-title-first-title">
                    {t("account.modalChangeBusinessData.name")}
                  </div>
                  <div className="input-text-field">
                    <Input
                      className="input-text-style"
                      style={{ width: "100%", height: "40px" }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-body-input-row">
                {/* <div className="modal-body-input-field">
                  <div className="input-field">
                    <div className="input-title-first-title">
                      {t("account.modalChangeBusinessData.companyName")}
                    </div>
                    <div className="input-text-field">
                      <Input
                        className="input-text-style"
                        style={{ width: "100%", height: "40px" }}
                        value={businessName}
                        onChange={(e) => setBusienssName(e.target.value)}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="modal-body-input-field">
                  <div className="input-field">
                    <div className="input-title-first-title">
                      {t("account.modalChangeBusinessData.vatNumber")}
                    </div>
                    <div className="input-text-field">
                      <Input
                        className="input-text-style"
                        style={{ width: "100%", height: "40px" }}
                        value={vatNumber}
                        onChange={(e) => setVatNumber(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body-input-row">
                <div className="modal-body-input-field">
                  <div className="input-field">
                    <div className="input-title-first-title">
                      {t("account.modalChangeBusinessData.email")}
                    </div>
                    <div className="input-text-field">
                      <Input
                        className="input-text-style"
                        style={{ width: "100%", height: "40px" }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-body-input-field">
                  <div className="input-field">
                    <div className="input-title-first-title">
                      {t("account.modalChangeBusinessData.telephone")}
                    </div>
                    <div className="input-text-field">
                      <Input
                        className="input-text-style"
                        style={{ width: "100%", height: "40px" }}
                        value={prefix + telephone}
                        onChange={(e) =>
                          setTelephone(
                            validateNumericInput(
                              e.target.value.replace(prefix, "")
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body-input-field">
                <div className="input-field">
                  <div className="input-title-first-title">
                    {t("account.modalChangeBusinessData.address")}
                  </div>
                  <div className="input-text-field">
                    <Input
                      className="input-text-style"
                      style={{ width: "100%", height: "40px" }}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-body-input-row">
                <div className="modal-body-input-field">
                  <div className="input-field">
                    <div className="input-title-first-title">
                      {t("account.modalChangeBusinessData.city")}
                    </div>
                    <div className="input-text-field">
                      <Input
                        className="input-text-style"
                        style={{ width: "100%", height: "40px" }}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-body-input-field">
                  <div className="input-field">
                    <div className="input-title-first-title">CAP</div>
                    <div className="input-text-field">
                      <Input
                        className="input-text-style"
                        style={{ width: "100%", height: "40px" }}
                        value={zip}
                        onChange={(e) => setZip(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </div>
        <ModalFooter
          primaryLabel={"Salva dati"}
          secondaryLabel={t("account.modalChangePassowrd.buttonCancel")}
          primaryAction={() => onClick(newTenant)}
          secondaryAction={onClose}
          disablePrimaryButton={hasChanged}
          isActionPerforming={isActionPerforming}
        />
      </ModalWrapper>
    </div>
  );
};
