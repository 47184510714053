/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const LeftText = ({ size, link, className, maxCarClassName }) => {
  return (
    <div className={`left-text ${className}`}>
      <div className={`max-car link-${link} ${size} ${maxCarClassName}`}>
        {link === "off" && <>Max. 50 car.</>}

        {link === "on" && <>Link</>}
      </div>
    </div>
  );
};

LeftText.propTypes = {
  size: PropTypes.oneOf(["m", "XS"]),
  link: PropTypes.oneOf(["off", "on"]),
};
