import HttpClient from "../../utils/HttpClient";

export default class OfficeCoworkingRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getOfficeCoworkingActivityTail(queryString?: string) {
    return this.instance.get("officeCoworking" + (queryString ?? ""));
  }

  public getOfficeCoworking(officeCoworkingId: number) {
    const path = "/officeCoworkings/" + officeCoworkingId;
    return this.instance.get(path);
  }

  public getOfficeCoworkingFilters() {
    const path = "/officeCoworking/filters";
    return this.instance.get(path);
  }

  public getOfficeCoworkingIncludedServices(officeCoworkingId: number) {
    const path = "officeCoworkings/" + officeCoworkingId + "/includedServices";
    return this.instance.get(path);
  }
}
