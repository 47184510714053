/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcSupport = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-support ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3 11V7.25C3 3.79822 6.13401 1 10 1C13.866 1 17 3.79822 17 7.25V11"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M12 18C15.8889 18 17 15.6788 17 11"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M4.40625 8.9375H4.125C3.50368 8.9375 3 9.35723 3 9.875V12.2188C3 12.7365 3.50368 13.1562 4.125 13.1562H4.40625C5.02757 13.1562 5.53125 12.7365 5.53125 12.2188V9.875C5.53125 9.35723 5.02757 8.9375 4.40625 8.9375Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M15.6667 9H15.3333C14.597 9 14 9.39797 14 9.88889V12.1111C14 12.602 14.597 13 15.3333 13H15.6667C16.403 13 17 12.602 17 12.1111V9.88889C17 9.39797 16.403 9 15.6667 9Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M11 17H10C9.44772 17 9 17.4477 9 18C9 18.5523 9.44772 19 10 19H11C11.5523 19 12 18.5523 12 18C12 17.4477 11.5523 17 11 17Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
