import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { EverywhereErrorCodes } from "../../../config/EverywhereErrorCodes";
import { EverywhereSuccessCodes } from "../../../config/EverywhereSuccessCodes";
import { UserInfo } from "../../UserInfo/UserSlice";
import WellnessRepository from "../WellnessRepository";
import { Tenant } from "../../Tenant/TenantSlice";

export interface WellnessPsycho {
  id: number;
  name: string;
  description: string;
  numberMeeting: number;
  durationMeeting: string;
  price: number;
  isIndividual: boolean;
  sessionsAvailable: number;
  remotelyOrPresenceEnum: string;
  city: string;
  address: string;
  province: string;
  zip: string;
  location: string | undefined;
  image: string;
  link: string;
  approved: boolean;
  active: boolean;
  published: boolean;
  insertionDate: Date;
  typology: string;
  faqs: number[];
  galleries: string[];
  deletedPhotoGallery: number[];
  tenant: Tenant;
  userInfo: UserInfo;
}

export const getWellnessPsychoAsync = createAsyncThunk(
  "wellnessPsycho/getWellnessPsycho",
  async (wellnessPsychoId: number) => {
    const wellNessRepository = new WellnessRepository();
    const response = await wellNessRepository.getWellnessPsycho(
      wellnessPsychoId
    );
    const WellnessPsycho = _.get(response, Config.WELLNESS_PSYCHO_DETAILS);
    return WellnessPsycho;
  }
);

export const getWellnessPsychoDetailsAsync = createAsyncThunk(
  "wellnessPsycho/getWellnessPsychoDetails",
  async (wellnessPsychoId: number) => {
    const wellNessRepository = new WellnessRepository();
    const response = await wellNessRepository.getWellnessPsychoDetails(
      wellnessPsychoId
    );
    const WellnessPsycho = _.get(response, Config.WELLNESS_PSYCHO_DETAILS);
    return WellnessPsycho;
  }
);

export const createWellnessPsychoAsync = createAsyncThunk(
  "wellnessPsycho/createWellnessPsycho",
  async (data: { wellnessPsycho: any }) => {
    const wellnessRepository = new WellnessRepository();
    const response = await wellnessRepository.createWellnessPsycho(
      data.wellnessPsycho
    );
    const wellnessPsycho = _.get(
      response,
      Config.CREATE_WELLNESS_PSYCHO_RESPONSE
    );
    return wellnessPsycho;
  }
);

export const deleteWellnessPsychoAsync = createAsyncThunk(
  "WellnessPsycho/deleteWellnessPsycho",
  async (wellnessPsychoId: number, { rejectWithValue }) => {
    try {
      const wellnessRepository = new WellnessRepository();
      await wellnessRepository.deleteWellnessPsycho(wellnessPsychoId);
      return wellnessPsychoId;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const updateWellnessPsychoAsync = createAsyncThunk(
  "wellnessPsycho/updateWellnessPsycho",
  async (data: { wellnessPsycho: number; wellnessData: any }) => {
    const wellnessRepository = new WellnessRepository();
    const response = await wellnessRepository.partiallyUpdateWellnessPsycho(
      data.wellnessPsycho,
      data.wellnessData
    );
    const wellnessPsycho = _.get(
      response,
      Config.UPDATE_WELLNESS_PSYCHO_RESPONSE
    );
    return wellnessPsycho;
  }
);

const wellNessPsychoAdapter = createEntityAdapter<WellnessPsycho>({
  selectId: (WellnessPsycho) => WellnessPsycho.id,
});

export const WellnessPsychoSlice = createSlice({
  name: "WellnessPsycho",
  initialState: wellNessPsychoAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    WellnessPsychoEmptyState: (state: any) => {
      wellNessPsychoAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getWellnessPsychoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          wellNessPsychoAdapter.setOne(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessPsychoAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getWellnessPsychoAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getWellnessPsychoDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          wellNessPsychoAdapter.setOne(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessPsychoDetailsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getWellnessPsychoDetailsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        createWellnessPsychoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          const wellnessPsychoData = action.payload; // Access payload directly

          wellNessPsychoAdapter.upsertOne(state, wellnessPsychoData ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.POST;
        }
      )
      .addCase(createWellnessPsychoAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(createWellnessPsychoAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(deleteWellnessPsychoAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        deleteWellnessPsychoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          wellNessPsychoAdapter.upsertMany(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = "";
        }
      )
      .addCase(
        deleteWellnessPsychoAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = EverywhereSuccessCodes.DELETE;
        }
      )
      .addCase(
        updateWellnessPsychoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload) {
            wellNessPsychoAdapter.upsertOne(state, action.payload);
          }
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.PATCH;
        }
      )
      .addCase(updateWellnessPsychoAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(updateWellnessPsychoAsync.pending, (state: any) => {
        state.status = "loading";
      });
  },
});

export const wellnessPsychoSelector =
  wellNessPsychoAdapter.getSelectors<RootState>(
    (state) => state.wellnessPsycho
  );

export const { WellnessPsychoEmptyState } = WellnessPsychoSlice.actions;

export const selectWellnessPsychoSliceStatus = (state: any) =>
  state.WellnessPsycho.status;
export const selectWellnessPsychoSliceReasonCode = (state: any) =>
  state.WellnessPsycho.reasonCode;

export default WellnessPsychoSlice.reducer;
