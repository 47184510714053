import HttpClient from "../../../utils/HttpClient";

export default class SmartDatePlanRepository extends HttpClient {
  constructor() {
    super(true);
  }
  public getSmartDatePlane(smartPlanId: number) {
    const path = "/smartPlans/" + smartPlanId + "/smartPlanDates";
    return this.instance.get(path);
  }

  public getAllSmartDatePlanes() {
    const path = "/smartPlanDates";
    return this.instance.get(path);
  }
}
