import React, { useState } from "react";

const Descriptions = ({ initialText, maxLength }) => {
  const [expanded, setExpanded] = useState(true);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="frame-31-description">
      <p className="lorem-ipsum-dolor">
        <span className="text-wrapper-24" style={{ whiteSpace: "pre-wrap" }}>
          {expanded ? initialText : initialText.slice(0, maxLength)}
          {initialText.length > maxLength && (
            <span onClick={toggleExpand} className="text-wrapper-25"></span>
          )}
        </span>
      </p>
    </div>
  );
};

export default Descriptions;
