// EmotionGraph.tsx
import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { IconHappiness } from "../icons/IcEmoji/Emoji/Happiness/Happiness";
import { IconAngry } from "../icons/IcEmoji/Emoji/Angry/Angry";
import { IconFear } from "../icons/IcEmoji/Emoji/Fear/Fear";
import { IconSad } from "../icons/IcEmoji/Emoji/Sad/Sad";
import { IconDisgust } from "../icons/IcEmoji/Emoji/Disgust/Disgust";
import { IconStress } from "../icons/IcEmoji/Emoji/Stress/Stress";

interface EmotionGraphProps {
  data: number[];
  categories: any[];
  barWidth: number | string;
  width?: number | string;
  height?: number | string;
}

const EmotionGraph: React.FC<EmotionGraphProps> = ({
  data,
  categories,
  barWidth,
  width,
  height,
}) => {
  const colors = [
    "#FFD685",
    "#F5846D",
    "#C3E8E2",
    "#A1DEEB",
    "#86D1C5",
    "#F7A392",
  ];

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      type: "bar",
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: barWidth ?? undefined,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      categories: categories,
      labels: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    colors: colors,
    tooltip: {
      enabled: false,
    },
  };

  const series = [
    {
      data: data.map((barra, index) => ({
        x: categories[index],
        y: barra,
        fillColor: colors[data.indexOf(barra) % colors.length],
      })),
    },
  ];

  return (
    <>
      <ReactApexChart
        width={width ?? undefined}
        height={height ?? undefined}
        options={options}
        series={series}
        type="bar"
      />
    </>
  );
};

export default EmotionGraph;
