// GalleryPreviewSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Gallery, getWellnessBodyGalleyAsync } from "./GallerySlice";

// Sostituisci './path/to/your/GalleryInterface' con il percorso corretto

interface GalleryState {
  items: Gallery[];
}

const initialState: GalleryState = {
  items: [],
};

const galleryPreviewSlice = createSlice({
  name: "galleryPreview",
  initialState,
  reducers: {
    itemsEmptyState: (state) => {
      state.items = [];
    },

    addItem: (state, action: PayloadAction<Gallery>) => {
      state.items.push(action.payload);
    },
    removeItem: (state, action: PayloadAction<any>) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    setItems: (state, action: PayloadAction<Gallery[]>) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getWellnessBodyGalleyAsync.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        const retrievedItems = action.payload.gallery;
        if (retrievedItems !== null && retrievedItems !== undefined) {
          let newElements: Gallery[] = [];
          Object.keys(retrievedItems).forEach((key) => {
            const el: Gallery = {
              id: retrievedItems[key].id,
              image: retrievedItems[key].image,
            };
            newElements.push(el);
          });

          state.items = newElements;
        }
      }
    );
  },
});

// Actions esportate per l'uso nei componenti
export const { addItem, removeItem, setItems, itemsEmptyState } =
  galleryPreviewSlice.actions;

// Selettore per accedere agli items dello stato
export const selectGalleryItems = (state: { galleryPreview: GalleryState }) =>
  state.galleryPreview.items;

export default galleryPreviewSlice.reducer;
