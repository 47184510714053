import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { Config } from "../../config/Config";
import OfficeCoworkingRepository from "./OfficeCoworkingRepository";
import { RootState } from "../../app/store";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";

export interface OfficeCoworkingIncludedServices {
  id: number;
  wifi: boolean;
  fiber: boolean;
  printer: boolean;
  copyPrinter: boolean;
  airConditioning: boolean;
  park: boolean;
  bicyclePark: boolean;
  videoProjector: boolean;
  secretarian: boolean;
  kitchen: boolean;
  coffeMachine: boolean;
  refrigerator: boolean;
}

export const getOfficeCoworkingIncludedServicesAsync = createAsyncThunk(
  "officeCoworkingIncludedServices/getOfficeCoworkingIncludedServices",
  async (officeCoworkingId: number) => {
    const officeCoworkingRepository = new OfficeCoworkingRepository();
    const response =
      await officeCoworkingRepository.getOfficeCoworkingIncludedServices(
        officeCoworkingId
      );
    const officeCoworking = _.get(response, Config.OFFICE_COWORKING_RESPONSE);
    return officeCoworking;
  }
);

const officeCoworkingIncludedServicesAdapter =
  createEntityAdapter<OfficeCoworkingIncludedServices>({
    selectId: (officeCoworkingIncludedServices) =>
      officeCoworkingIncludedServices.id,
  });

export const officeCoworkingIncludedServicesSlice = createSlice({
  name: "officeCoworkingIncludedServices",
  initialState: officeCoworkingIncludedServicesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    officeCoworkingIncludedServicesEmptyState: (state) => {
      officeCoworkingIncludedServicesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(
        getOfficeCoworkingIncludedServicesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          officeCoworkingIncludedServicesAdapter.removeAll(state);
          officeCoworkingIncludedServicesAdapter.setOne(state, action.payload);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(
        getOfficeCoworkingIncludedServicesAsync.pending,
        (state: any) => {
          state.status = "loading";
        }
      )
      .addCase(
        getOfficeCoworkingIncludedServicesAsync.rejected,
        (state: any) => {
          state.status = "failed";
          state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
        }
      );
  },
});

export const officeCoworkingIncludedServicesSelector =
  officeCoworkingIncludedServicesAdapter.getSelectors<RootState>(
    (state) => state.officeCoworkingIncludedServices
  );
export const { officeCoworkingIncludedServicesEmptyState } =
  officeCoworkingIncludedServicesSlice.actions;
export const selectOfficeCoworkingSliceStatus = (state: any) =>
  state.officeCoworking.status;
export const selectOfficeCoworkingSliceReasonCode = (state: any) =>
  state.officeCoworking.reasonCode;

export default officeCoworkingIncludedServicesSlice.reducer;
