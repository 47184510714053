/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcSearch = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-search ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="circle"
        cx="8.28571"
        cy="8.28571"
        r="4.28571"
        stroke={color}
        strokeWidth="2"
      />
      <path
        className="path"
        d="M11.7143 11.7142L16 15.9999"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
