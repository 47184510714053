import { createContext, Component } from "react";

import validations from "../utils/validation";

export const FormCtx = createContext({
  fields: {},
  errors: {},
});

export default class Form extends Component {
  state = {
    fields: {},
    errors: {},
  };

  render() {
    const { fields, errors } = this.state;
    const formCtx = {
      fields,
      errors,
      addField: (data) => {
        this.addField(data);
      },
      setFields: this.setFields,
      validateField: this.validateField,
    };

    return (
      <form
        onSubmit={(e) => e.preventDefault()}
        name={this.props.name}
        className={this.props.className}
      >
        <FormCtx.Provider value={formCtx}>
          {this.props.children}
        </FormCtx.Provider>
      </form>
    );
  }

  setFields = (event, { id }) => {
    const { fields } = this.state;
    let field = fields[id];

    if (field.hasOwnProperty("selected")) {
      field = {
        ...field,
        selected: event,
        value: event,
      };
    } else {
      field = {
        ...field,
        value: event.currentTarget.value,
      };
    }

    this.addField({
      field: field,
    });
  };

  addField = ({ field }) => {
    const { id } = field;

    field = {
      value: "",
      ...field,
    };

    if (id) {
      this.setState((prevState) => {
        return {
          ...prevState,
          fields: {
            ...prevState.fields,
            [id]: field,
          },
        };
      });

      return;
    }

    throw new Error(`please add 'id' field to the input: ${field}`);
  };

  validateField = (id) => {
    let error = "";

    const {
      value: fieldValue,
      validate,
      name,
      customRules = {},
    } = this.state.fields[id];
    const rules = validate ? validate.split("|") : "";
    let actualValue;
    if (fieldValue !== null) {
      actualValue = fieldValue?.hasOwnProperty("value")
        ? fieldValue.value
        : fieldValue;
    } else {
      actualValue = null;
    }

    if (rules.length) {
      for (const rule in rules) {
        const ruleName = rules[rule];
        const validation =
          validations[ruleName.split(".")[0]] ||
          customRules[ruleName.split(".")[0]];
        const isRuleSatisfied =
          ruleName !== "required" && !actualValue
            ? true
            : ruleName.split(".")[0] === "minLength"
            ? validation
                .rule(ruleName.split(".")[1])
                .test(actualValue.toString())
            : ruleName.split(".")[0] === "maxLength"
            ? validation
                .rule(ruleName.split(".")[1])
                .test(actualValue.toString())
            : validation.rule().test(actualValue.toString());

        const isRuleSatisfiedEmpty =
          ruleName === "isNotEmpty" && actualValue !== null
            ? validation
                .rule(ruleName.split(".")[1])
                .test(actualValue.toString())
            : true;

        if (!isRuleSatisfied || !isRuleSatisfiedEmpty) {
          error = validation.formatter.apply(null, [""]);
        }

        if (error !== "") {
          break;
        }
      }

      this.setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [id]: error,
        },
      }));
    }
  };
}
