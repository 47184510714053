import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { Footer } from "../../screens/Footer/Footer";
import { BaseTab } from "../../ui/components/BaseTab";
import MyOrders from "../../ui/components/MyOrders/MyOrders";
import { ServiceCard } from "../../ui/components/ServiceCard/ServiceCard";
import { IconClose } from "../../ui/components/icons/IconClose";
import { IconTik } from "../../ui/components/icons/IconTik";
import { TwentyIcPeople } from "../../ui/components/icons/TwentyIcPeople";
import { TwentyIcRelax } from "../../ui/components/icons/TwentyIcRelax";
import { TwentyIcZen } from "../../ui/components/icons/TwentyIcZen";
import {
  MyOrder,
  getAssociatedOrdersAsync,
  getMyOrdersAsync,
  myOrdersEmptyState,
  myOrdersSelector,
  selectMyOrdersSliceStatus,
} from "../myorders/MyOrdersSlice";
import "./Wellness.css";
import {
  WellnessBodyView,
  WellnessBodyViewSelector,
  getWellnessBodyViewAsync,
} from "./WellnessSlice/WellnessBodyViewSlice";
import {
  WellnessNutritionalView,
  WellnessNutritionalViewSelector,
  getWellnessNutritionalViewAsync,
} from "./WellnessSlice/WellnessNutritionalViewSlice";
import {
  WellnessPsychoView,
  WellnessPsychoViewSelector,
  getWellnessPsychoViewAsync,
} from "./WellnessSlice/WellnessPsychoViewSlice";
import { Spin } from "antd";

export const Wellness = () => {
  const navigate = useNavigate();
  const [elementSelected, setElementSelected] = useState(0);
  const [successModalOpened, setSuccessModalOpened] = useState(true);

  const myOrders: MyOrder[] = useAppSelector(myOrdersSelector.selectAll);

  const myOrdersStatus: any = useAppSelector(selectMyOrdersSliceStatus);

  const psychoActivityTails: WellnessPsychoView[] = useAppSelector(
    WellnessPsychoViewSelector.selectAll
  );

  const nutritionalActivityTails: WellnessNutritionalView[] = useAppSelector(
    WellnessNutritionalViewSelector.selectAll
  );

  const bodyActivityTails: WellnessBodyView[] = useAppSelector(
    WellnessBodyViewSelector.selectAll
  );

  const wellnessPsychoDetailsSuccessOrder = useMatch(
    "Wellness/MyOrders/Success"
  );

  useEffect(() => {
    if (wellnessPsychoDetailsSuccessOrder) {
      setElementSelected(1);
    }
  }, []);

  let loaded = false;

  function fillMyOrdersResults(): JSX.Element[] {
    var elements = [];
    if (!!myOrders) {
      for (let i = 0; i < myOrders.length; i++) {
        let temp = null;
        if (myOrders[i].wellnessBodyId) {
          temp = bodyActivityTails.find(
            (el) => el.id === myOrders[i].wellnessBodyId
          );
          elements.push(
            <React.Fragment>
              <MyOrders
                id={myOrders[i].id}
                date={myOrders[i].bookingDate}
                price={temp?.price?.toString()}
                wellnessBodyId={myOrders[i].wellnessBodyId}
                image={temp?.image}
                status={myOrders[i].status.toString()}
                titleOne={temp?.name}
                titleTwo={""}
                personsNumber={myOrders[i].numberOfPeople}
                type={"Wellness"}
                deposit={temp?.price?.toString()}
              />
            </React.Fragment>
          );
        }
        if (myOrders[i].wellnessNutritionalId) {
          temp = nutritionalActivityTails.find(
            (el) => el.id === myOrders[i].wellnessNutritionalId
          );
          elements.push(
            <React.Fragment>
              <MyOrders
                id={myOrders[i].id}
                date={myOrders[i].bookingDate}
                price={temp?.price?.toString()}
                wellnessNutritionalId={myOrders[i].wellnessNutritionalId}
                image={temp?.image}
                status={myOrders[i].status.toString()}
                titleOne={temp?.name}
                titleTwo={""}
                personsNumber={myOrders[i].numberOfPeople}
                type={"Wellness"}
                deposit={temp?.price?.toString()}
              />
            </React.Fragment>
          );
        }
        if (myOrders[i].wellnessPsychoId) {
          temp = psychoActivityTails.find(
            (el) => el.id === myOrders[i].wellnessPsychoId
          );
          elements.push(
            <React.Fragment>
              <MyOrders
                id={myOrders[i].id}
                date={myOrders[i].bookingDate}
                price={temp?.price?.toString()}
                wellnessPsychoId={myOrders[i].wellnessPsychoId}
                image={temp?.image}
                status={myOrders[i].status.toString()}
                titleOne={temp?.name}
                titleTwo={""}
                personsNumber={myOrders[i].numberOfPeople}
                type={"Wellness"}
                deposit={temp?.price?.toString()}
              />
            </React.Fragment>
          );
        }
      }
      return elements;
    } else {
      return [];
    }
  }

  useEffect(() => {
    store.dispatch(myOrdersEmptyState());
    if (!loaded) {
      store.dispatch(getWellnessBodyViewAsync({}));
      store.dispatch(getWellnessNutritionalViewAsync({}));
      store.dispatch(getWellnessPsychoViewAsync({}));
      loaded = true;
    }
  }, []);

  useEffect(() => {
    if (elementSelected === 1) {
      if (myOrders.length === 0) {
        store.dispatch(getMyOrdersAsync({ queryString: "?type=allWellness" }));
      }
    } else if (elementSelected === 2) {
      if (myOrders.length === 0) {
        store.dispatch(
          getAssociatedOrdersAsync({ queryString: "?type=allWellness" })
        );
      }
    }
  }, [elementSelected]);

  return (
    <>
      <div className="wellness-content">
        <div className="wellness-titlebar">
          <div className="wellness-titlebar-iconplace">
            <TwentyIcZen
              className="wellness-titlebar-iconplace-icon"
              color="#36B39E"
            ></TwentyIcZen>
          </div>
          <div className="wellness-titlebar-text">{t("wellness.wellness")}</div>
        </div>
        <div className="wellness-switchbar-tabs">
          <BaseTab
            select={elementSelected === 0}
            text={t("wellness.allServices")}
            onClick={() => {
              if (elementSelected !== 0) {
                setElementSelected(0);
                navigate("/Wellness");
              }
            }}
          ></BaseTab>
          <BaseTab
            select={elementSelected === 1}
            text={t("wellness.myPurchases")}
            onClick={() => {
              if (elementSelected !== 1) {
                store.dispatch(myOrdersEmptyState());
                setElementSelected(1);
                navigate("/Wellness/myOrders");
              }
            }}
          ></BaseTab>
        </div>
        {wellnessPsychoDetailsSuccessOrder && successModalOpened && (
          <div className="wellness-ordersuccess-container">
            <div className="wellness-ordersuccess-textcontainer">
              <IconTik className={undefined}></IconTik>
              <div className="wellness-ordersuccess-text">
                {t("myOrders.orderSuccess")}
              </div>
            </div>
            <div
              className="wellness-ordersuccess-icon"
              onClick={() => {
                setSuccessModalOpened(false);
              }}
            >
              <IconClose
                className={undefined}
                onClick={undefined}
                color={undefined}
              ></IconClose>
            </div>
          </div>
        )}
        <div className="wellness-container">
          {!elementSelected ? (
            <div className="wellnes-allservices-section">
              <div className="wellness-welcome-widget">
                <div className="wellness-welcome-widget-text">
                  {t("wellness.welcomeWidgetText")}
                </div>
              </div>
              <div className="wellness-servicearea">
                <div className="wellness-servicearea-text-selection">
                  {t("wellness.home.selectService")}
                </div>
                <div className="wellness-servicearea-row">
                  {false && (
                    <ServiceCard
                      title={t("wellness.home.emotionalService")}
                      subtitle={t("wellness.home.emotionalServiceSubtitle")}
                      color={"#FEF0ED"}
                      icon={
                        <TwentyIcRelax className={undefined} color="#36B39E" />
                      }
                      triggered={() => navigate("Psycho")}
                    />
                  )}
                  <ServiceCard
                    title={t("wellness.home.bodyService")}
                    subtitle={t("wellness.home.bodyServiceSubtitle")}
                    color={"#E7F7FA"}
                    icon={
                      <TwentyIcPeople className={undefined} color="#36B39E" />
                    }
                    triggered={() => navigate("Body")}
                  />
                  {/* <ServiceCard
                    title={t("wellness.home.dedicatedService")}
                    subtitle={t("wellness.home.dedicatedServiceSubtitle")}
                    color={"#EBF7F5"}
                    icon={
                      <TwentyIcDuplicate
                        className={undefined}
                        color="#36B39E"
                      />
                    }
                    triggered={function (e: any) {
                      throw new Error("Function not implemented.");
                    }}
                  /> */}
                  <ServiceCard
                    title={t("wellness.home.nutritionalService")}
                    subtitle={t("wellness.home.nutritionalServiceSubtitle")}
                    color={"#FFFAF0"}
                    icon={<TwentyIcZen className={undefined} color="#36B39E" />}
                    triggered={() => navigate("Nutritional")}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="smartplan-myorder-container">
                {myOrders.length > 0 ? (
                  fillMyOrdersResults()
                ) : myOrdersStatus === "loading" ? (
                  <>
                    <Spin size="large" />
                  </>
                ) : (
                  <div className="smartplan-myorderempty-container">
                    <div className="smartplan-myorderempty-element">
                      <TwentyIcZen
                        size="40"
                        color="#222729"
                        className={"smartplan-myorderempty-icon"}
                      />
                      <div className="smartplan-myorderempty-firsttext">
                        {t("wellness.noServicesAvabileFirstText")}
                      </div>
                      <div className="smartplan-myorderempty-secondtext">
                        {t("wellness.noServicesBuyed")}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
