import HttpClient from "../../utils/HttpClient";

export default class VirtualCoworkingRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getLinkGather(tenantId: number) {
    return this.instance.get("/tenants/" + tenantId + "/virtualCoworkings");
  }
}
