/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconUserAdd = ({ className, color, size = "20" }) => {
  return (
    <svg
      className={`icon-user-add ${className}`}
      fill="none"
      height={size}
      viewBox="0 0 20 20"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16 4V11"
        stroke={color ? color : "#38342F"}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M19.5 7.5L12.5 7.5"
        stroke={color ? color : "#38342F"}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M13 14.9535C13 13.3611 12.1111 11.3333 7 11.3333C1.88889 11.3333 1 13.3611 1 14.9535V16H13V14.9535Z"
        stroke={color ? color : "#38342F"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M9.66634 5.66667C9.66634 7.13943 8.47243 8.33333 6.99967 8.33333C5.52692 8.33333 4.33301 7.13943 4.33301 5.66667C4.33301 4.19391 5.52692 3 6.99967 3C8.47243 3 9.66634 4.19391 9.66634 5.66667Z"
        stroke={color ? color : "#38342F"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
