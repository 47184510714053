/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcInfo = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-info ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rect"
        height="14"
        rx="7"
        stroke={color}
        strokeWidth="2"
        width="14"
        x="3"
        y="3"
      />
      <path
        className="path"
        d="M11.4678 15L11.122 9.15166H8.91823L8.57239 15H11.4678ZM8.5 6.46478C8.5 6.88081 8.63941 7.22751 8.91823 7.50486C9.19705 7.78799 9.55898 7.92956 10.004 7.92956C10.4491 7.92956 10.8083 7.78799 11.0818 7.50486C11.3606 7.22751 11.5 6.88081 11.5 6.46478C11.5 6.04875 11.3606 5.69917 11.0818 5.41603C10.8083 5.13868 10.4491 5 10.004 5C9.55898 5 9.19705 5.13868 8.91823 5.41603C8.63941 5.69917 8.5 6.04875 8.5 6.46478Z"
        fill="#222729"
      />
    </svg>
  );
};
