export const Map = ({ city }) => {
  return (
    <iframe
      width="100%"
      height="300"
      style={{ border: "0" }}
      src={`https://www.google.com/maps/embed/v1/place?q=${city}&key=AIzaSyCSQyqCZE2iMFMRbppTlx1rOs2hDFKxjQ8`}
      title={`Mappa di ${city}`}
    ></iframe>
  );
};
