/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const Default = ({ className, color = "white" }) => {
  return (
    <svg
      className={`default ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rect"
        height="16"
        rx="2"
        stroke={color}
        strokeWidth="2"
        width="16"
        x="2"
        y="2"
      />
      <rect
        className="rect"
        height="12"
        rx="6"
        stroke={color}
        strokeWidth="2"
        width="12"
        x="4"
        y="4"
      />
      <path className="path" d="M18 2L2 18" stroke={color} strokeWidth="2" />
      <path className="path" d="M2 2L18 18" stroke={color} strokeWidth="2" />
    </svg>
  );
};
