import { useState } from "react";
import { ModalBody } from "../../../ui/components/Modals/ModalBody";
import { ModalFooter } from "../../../ui/components/Modals/ModalFooter";
import { ModalWrapper } from "../../../ui/components/Modals/ModalWrapper";
import { t } from "i18next";
import "../MyServiceModal/AddServiceModal.css";
import Form from "../../../ui/components/Form/Form";
import { Input } from "antd";
import "../MyServiceModal/AddServiceModal.css";

interface AddServiceModalProps {
  closeModal: (e: any) => any;
  onClick: (title: string) => void;
}

export const AddServiceModal: React.FC<AddServiceModalProps> = ({
  closeModal,
  onClick,
}) => {
  const [title, setTitle] = useState("");

  const handleTitleChange = (e: any) => {
    setTitle(e.target.value);
  };

  return (
    <div className="modal-addService">
      <ModalWrapper open={true} closeAction={closeModal}>
        <ModalBody title="Nuovo servizio">
          <div className="description-modal-service">
            {t("myService.modal.title")}
          </div>
          <Form>
            <div className="input-title-modal">
              <Input
                value={title}
                type="text"
                size="large"
                placeholder="Inserisci titolo"
                onChange={handleTitleChange}
              />
            </div>
          </Form>
        </ModalBody>
        <ModalFooter
          primaryLabel="Crea"
          secondaryLabel="Annulla"
          primaryAction={() => onClick(title)}
          secondaryAction={closeModal}
          disablePrimaryButton={title === ""}
          isActionPerforming={undefined}
        />
      </ModalWrapper>
    </div>
  );
};
