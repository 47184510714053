import {
  Button,
  Card,
  Dropdown,
  Image,
  Input,
  MenuProps,
  Select,
  Space,
  Switch,
  Tooltip,
  UploadFile,
} from "antd";
import { t } from "i18next";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import HeaderMenu from "../../../layout/HeaderMenu";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";
import "../PublicAccount/PublicAccount.css";
import { Required } from "../../../ui/components/Required";
import { BaseTab } from "../../../ui/components/BaseTab";
import { useEffect, useState } from "react";
import Dragger from "antd/es/upload/Dragger";
import { TwentyIcArrowDown } from "../../../ui/components/icons/TwentyIcArrowDown";
import Tag from "../../../ui/components/Tag/Tag";
import { Footer } from "../../../screens/Footer/Footer";
import { useNavigate } from "react-router-dom";
import {
  PublicAccount,
  createPublicAccountAsync,
  getPublicAccountsAsync,
  partiallyUpdatePublicAccountAsync,
  publicAccountSelector,
} from "./PublicAccountSlice";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { ToastNotification } from "../../../ui/components/utils/ToastNotification";
import { TwentyIcEye2 } from "../../../ui/components/icons/TwentyIcEye2";
import { usePreviewContext } from "../../../Context/PrewiewContext";
import { convertBase64 } from "../../../utils/utils";
import { ImageConfig } from "../../../config/ImageConfig";
import TextArea from "antd/es/input/TextArea";
import _ from "lodash";

function PublicAccountFeature() {
  let refresh = false;

  useEffect(() => {
    if (!refresh) {
      if (userIdNumber !== undefined) {
        store.dispatch(getPublicAccountsAsync());
      }

      refresh = true;
    }

    let imageLoaded = localStorage.getItem("imagePublicAccountPreview");
    if (imageLoaded && defaultListImagePreview.length === 0) {
      const uploadFile: UploadFile = {
        uid: "-1",
        name: "ImmagineCaricata",
        status: "done",
        url: imageLoaded,
      };
      defaultListImagePreview?.push(uploadFile);
      setResponsePhoto(imageLoaded);
    }
  }, []);

  const publicAccountList: PublicAccount[] = useAppSelector(
    publicAccountSelector.selectAll
  );

  const publicAccount =
    publicAccountList.length !== 0 ? publicAccountList[0] : null;

  const [providerSelected, setProviderSelected] = useState(
    publicAccount?.providerType === "PSYCHOLOGIST"
      ? 0
      : publicAccount?.providerType === "NUTRITIONIST"
      ? 1
      : publicAccount?.providerType === "COACH"
      ? 2
      : -1
  );

  const [typologySelected, setTypologySelected] = useState<string[]>([]);

  useEffect(() => {
    setProviderSelected(
      publicAccountPreview?.providerType
        ? publicAccountPreview?.providerType
        : publicAccount?.providerType === "PSYCHOLOGIST"
        ? 0
        : publicAccount?.providerType === "NUTRITIONIST"
        ? 1
        : publicAccount?.providerType === "COACH"
        ? 2
        : 1
    );

    setTypologySelected(
      publicAccountPreview?.typologies === undefined
        ? publicAccount?.typologies?.map((el) => el.name) || []
        : publicAccountPreview?.typologies
    );

    setFirstName(
      publicAccountPreview?.firstName === undefined
        ? publicAccount?.firstName ?? ""
        : publicAccountPreview?.firstName
    );
    setLastName(
      publicAccountPreview?.lastName === undefined
        ? publicAccount?.lastName ?? ""
        : publicAccountPreview?.lastName
    );

    setProfileDescription(
      publicAccountPreview?.profileDescription === undefined
        ? publicAccount?.profileDescription ?? ""
        : publicAccountPreview?.profileDescription
    );

    setYearsOfExperience(
      publicAccountPreview?.yearsOfExperience === undefined
        ? publicAccount?.yearsOfExperience ?? 0
        : publicAccountPreview?.yearsOfExperience
    );

    setResidence(
      publicAccountPreview?.residence === undefined
        ? publicAccount?.residence ?? ""
        : publicAccountPreview?.residence
    );

    setTrainingAndCompetition(
      publicAccountPreview?.trainingAndCompetition === undefined
        ? publicAccount?.trainingAndSkills ?? ""
        : publicAccountPreview?.trainingAndCompetition
    );

    setEmail(
      publicAccountPreview?.email === undefined
        ? publicAccount?.publicEmail ?? ""
        : publicAccountPreview?.email
    );

    setTelephone(
      publicAccountPreview?.telephone === undefined
        ? publicAccount?.publicTelephone ?? ""
        : publicAccountPreview?.telephone
    );

    setPreferredShape(
      publicAccountPreview?.preferredShape === undefined
        ? publicAccount?.preferredShape ?? ""
        : publicAccountPreview?.preferredShape
    );

    setIsTelephone(
      publicAccount?.publicTelephone !== "" &&
        publicAccount?.publicTelephone !== null
        ? true
        : false
    );
  }, [publicAccount]);

  const [isTelephone, setIsTelephone] = useState(
    publicAccount?.publicTelephone !== "" ? true : false
  );

  const [preferredShape, setPreferredShape] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileDescription, setProfileDescription] = useState("");

  const [yearsOfExperience, setYearsOfExperience] = useState(0);

  const [residence, setResidence] = useState("");

  const [trainingAndCompetition, setTrainingAndCompetition] = useState("");

  const [email, setEmail] = useState("");

  const [telephone, setTelephone] = useState("");

  const [responsePhoto, setResponsePhoto] = useState("");

  const name = localStorage.getItem("firstName");

  const userId = localStorage.getItem("customerId");

  const userIdNumber: number | undefined = userId
    ? parseInt(userId)
    : undefined;

  const navigate = useNavigate();

  let defaultListImagePreview: UploadFile | any = [];

  const onPreferredShape: MenuProps["onClick"] = ({ key }) => {
    setPreferredShape(key);
  };

  const onWhoYouAre: MenuProps["onClick"] = ({ key }) => {
    setProviderSelected(
      key === "Nutrizionista" ? 1 : key === "Istruttore/Coach" ? 2 : 0
    );
    setTypologySelected(
      handelProviderTypeN(
        publicAccount?.providerType ?? "",

        publicAccount?.typologies?.map((el) => el.name) || []
      )
    );
  };

  const whoYouAreItems: MenuProps["items"] = [
    {
      label: <div className="input-text-style">{"Psicologo"}</div>,

      key: "Psicologo",
    },
    {
      label: <div className="input-text-style">{"Nutrizionista"}</div>,

      key: "Nutrizionista",
    },

    {
      label: (
        <div className="input-text-style">
          {" "}
          {
            "Istruttore/Coach (Istruttore, counselor, life coach, fitness coach)"
          }
        </div>
      ),

      key: "Istruttore/Coach",
    },
  ];

  const preferredShapeItems: MenuProps["items"] = [
    {
      label: (
        <div className="input-text-style">
          {t("publicAccount.preferredShapeDropdown.doctor")}
        </div>
      ),

      key: t("publicAccount.preferredShapeDropdown.doctor"),
    },

    {
      label: (
        <div className="input-text-style">
          {" "}
          {t("publicAccount.preferredShapeDropdown.doctor/a")}
        </div>
      ),

      key: t("publicAccount.preferredShapeDropdown.doctor/a"),
    },

    {
      label: <div className="input-text-style"> - </div> ?? "",

      key: name ?? "",
    },
  ];

  function tagRender(props: any) {
    const { label, onClose } = props;

    return (
      <div
        style={{ paddingTop: "2px", paddingBottom: "2px", paddingRight: "4px" }}
      >
        <Tag isRemoved={onClose} textOne={label} />
      </div>
    );
  }

  function handleTypologyOptions(providerSelected: number) {
    if (providerSelected === 0) {
      return [
        {
          label: (
            <div className="input-text-style">
              {" "}
              {t("publicAccount.psychologistTypologies.foodDisorders")}
            </div>
          ),

          value: t("publicAccount.psychologistTypologies.foodDisorders"),
        },

        {
          label: (
            <div className="input-text-style">
              {t("publicAccount.psychologistTypologies.sexology")}
            </div>
          ),

          value: t("publicAccount.psychologistTypologies.sexology"),
        },

        {
          label: (
            <div className="input-text-style">
              {" "}
              {t("publicAccount.psychologistTypologies.anxiety")}
            </div>
          ),

          value: t("publicAccount.psychologistTypologies.anxiety"),
        },

        {
          label: (
            <div className="input-text-style">
              {" "}
              {t("publicAccount.psychologistTypologies.addictions")}
            </div>
          ),

          value: t("publicAccount.psychologistTypologies.addictions"),
        },

        {
          label: (
            <div className="input-text-style">
              {" "}
              {t("publicAccount.psychologistTypologies.stressAndBurnout")}
            </div>
          ),

          value: t("publicAccount.psychologistTypologies.stressAndBurnout"),
        },

        {
          label: (
            <div className="input-text-style">
              {" "}
              {t("publicAccount.psychologistTypologies.parentingSupport")}
            </div>
          ),

          value: t("publicAccount.psychologistTypologies.parentingSupport"),
        },

        {
          label: (
            <div className="input-text-style">
              {" "}
              {t("publicAccount.psychologistTypologies.workAndCareerCoaching")}
            </div>
          ),

          value: t(
            "publicAccount.psychologistTypologies.workAndCareerCoaching"
          ),
        },

        {
          label: (
            <div className="input-text-style">
              {" "}
              {t("publicAccount.psychologistTypologies.familyMediation")}
            </div>
          ),

          value: t("publicAccount.psychologistTypologies.familyMediation"),
        },
      ];
    } else if (providerSelected === 1) {
      return [
        {
          label: (
            <div className="input-text-style">
              {t(
                "publicAccount.nutritionistTypes.weightLossOrGainInMuscleMass"
              )}
            </div>
          ),

          value: t(
            "publicAccount.nutritionistTypes.weightLossOrGainInMuscleMass"
          ),
        },

        {
          label: (
            <div className="input-text-style">
              {t("publicAccount.nutritionistTypes.improvedAthleticPerformance")}
            </div>
          ),

          value: t(
            "publicAccount.nutritionistTypes.improvedAthleticPerformance"
          ),
        },

        {
          label: (
            <div className="input-text-style">
              {t(
                "publicAccount.nutritionistTypes.managementOfSpecificMedicalConditions"
              )}
            </div>
          ),

          value: t(
            "publicAccount.nutritionistTypes.managementOfSpecificMedicalConditions"
          ),
        },

        {
          label: (
            <div className="input-text-style">
              {t("publicAccount.nutritionistTypes.managementOfEatingStress")}
            </div>
          ),

          value: t("publicAccount.nutritionistTypes.managementOfEatingStress"),
        },
      ];
    } else {
      return [];
    }
  }

  function handelProviderTypeN(type: string, typologies: string[]) {
    if (type === "NUTRITIONIST") {
      return typologies;
    } else {
      return [];
    }
  }

  const selectProvider = (providerSelected: number) => {
    if (providerSelected === 0) {
      return "PSYCHOLOGIST";
    }

    if (providerSelected === 1) {
      return "NUTRITIONIST";
    }

    if (providerSelected === 2) {
      return "COACH";
    } else {
      return "";
    }
  };

  const handleSave = async () => {
    const createAccountDetails = {
      providerType:
        providerSelected === 0
          ? "PSYCHOLOGIST"
          : providerSelected === 1
          ? "NUTRITIONIST"
          : providerSelected === 2
          ? "COACH"
          : "",

      personalPhotography: responsePhoto,

      preferredShape: preferredShape,

      firstName: firstName,
      lastName: lastName,
      profileDescription: profileDescription,

      yearsOfExperience: yearsOfExperience,

      residence: residence,

      trainingAndSkills: trainingAndCompetition,

      publicEmail: email,

      publicTelephone: isTelephone ? telephone : "",

      typology: providerSelected === 2 ? [] : typologySelected,

      userId: userIdNumber ?? 0,
    };

    if (createAccountDetails) {
      const result = await store.dispatch(
        createPublicAccountAsync({ publicAccount: createAccountDetails })
      );

      if (result.meta.requestStatus === "fulfilled") {
        navigate(-1);

        ToastNotification({
          status: "success",

          description: "",

          toastId: "Account pubblico salvato con successo",

          title: t("toastNotification.successfully"),
        });
      } else {
        navigate(-1);

        ToastNotification({
          status: "error",

          description: "",

          toastId: "Errore nella creazione dell'account pubblcio",

          title: t("toastNotification.networkError"),
        });
      }
    }
  };

  const handleUpdate = async () => {
    const updateAccountDetails = {
      providerType: selectProvider(providerSelected),

      personalPhotography: responsePhoto,

      preferredShape: preferredShape,

      firstName: firstName,
      lastName: lastName,
      profileDescription: profileDescription,

      yearsOfExperience: yearsOfExperience,

      residence: residence,

      trainingAndSkills: trainingAndCompetition,

      publicEmail: email,

      publicTelephone: isTelephone ? telephone : "",

      typology: providerSelected === 2 ? [] : typologySelected,

      userId: userIdNumber ?? 0,
    };

    if (updateAccountDetails) {
      const result = await store.dispatch(
        partiallyUpdatePublicAccountAsync({
          id: publicAccount?.id ?? 0,

          publicAccount: updateAccountDetails,
        })
      );

      if (result.meta.requestStatus === "fulfilled") {
        ToastNotification({
          status: "success",

          description: "",

          toastId: "Account pubblico aggiornato con successo",

          title: t("toastNotification.successfully"),
        });
      } else {
        ToastNotification({
          status: "error",

          description: "",

          toastId: "Errore nell'aggiornamento dell'account pubblcio",

          title: t("toastNotification.networkError"),
        });
      }
    }
  };

  let { value: publicAccountPreview, setValue: setPublicAccountPreview } =
    usePreviewContext();

  const handleGoBack = () => {
    setPublicAccountPreview({
      firstName: undefined,
      lastName: undefined,
      yearsOfExperience: undefined,
      profileDescription: undefined,
      typologies: undefined,
      trainingAndCompetition: undefined,
      email: undefined,
      telephone: undefined,
    });
  };

  const handleInputPreview = () => {
    setPublicAccountPreview({
      firstName: firstName,
      lastName: lastName,
      providerType: providerSelected,

      preferredShape: preferredShape,

      profilePhoto: ImageConfig.BACKGROUND_DEFAULT_SERVICE,

      yearsOfExperience: yearsOfExperience,

      residence: residence,

      profileDescription: profileDescription,

      typologies: typologySelected,

      trainingAndCompetition: trainingAndCompetition,

      email: email,

      telephone: telephone,

      isTelephone: isTelephone,
    });

    navigate("previewAccountDetails");
  };

  const controlSave = (
    firstName: string | undefined,
    lastName: string | undefined,
    profileDescription: string | undefined,
    residence: string | undefined,
    preferredShape: string | undefined,

    email: string | undefined,

    telephone: string | undefined,

    yearsOfExperience: number | undefined,
    trainingAndCompetition: string | undefined
  ) => {
    if (
      firstName &&
      lastName &&
      profileDescription &&
      residence &&
      preferredShape &&
      email &&
      (isTelephone ? telephone : true) &&
      yearsOfExperience &&
      trainingAndCompetition
    ) {
      return false;
    } else {
      return true;
    }
  };

  const uploadFile = (file: File) => {
    convertBase64(file).then((response) => {
      setResponsePhoto(response);
    });
  };

  useEffect(() => {
    localStorage.setItem("imagePublicAccountPreview", responsePhoto);
  }, [responsePhoto]);

  useEffect(() => {
    if (
      publicAccountList.length > 0 &&
      publicAccountList[0].personalPhotography !== undefined &&
      defaultListImagePreview.length === 0
    ) {
      const uploadFile: UploadFile = {
        uid: "-1",
        name: "ImmagineCaricata",
        status: "done",
        url: publicAccountList[0].personalPhotography,
      };
      defaultListImagePreview?.push(uploadFile);
      setResponsePhoto(publicAccountList[0].personalPhotography);
    }
  }, [publicAccountList.length]);

  return (
    <>
      <div className="public-account-filterbar">
        <div className="preview-button">
          <Button
            onClick={() => {
              handleInputPreview();
            }}
          >
            <div className="space-preview">
              <Space>
                <TwentyIcEye2 className={undefined} color="gray" />

                <div className="smartplan-filtermodal-text-white">
                  {"Anteprima"}
                </div>
              </Space>
            </div>
          </Button>
        </div>
        {controlSave(
          firstName,
          lastName,
          profileDescription,
          preferredShape,
          email,
          residence,
          telephone,
          yearsOfExperience,
          trainingAndCompetition
        ) ? (
          <div className="save-button">
            <Tooltip
              placement="bottom"
              title={
                "Prima di salvare i tuoi dati pubblici, è necessaria la compilazione di tutti i campi."
              }
            >
              <Button disabled={true}>
                <div className="space-save">
                  <Space>
                    <div className="smartplan-filtermodal-text-white">
                      {publicAccount === null
                        ? t("myService.components.filterBar.save")
                        : "Salva cambiamenti"}
                    </div>
                  </Space>
                </div>
              </Button>
            </Tooltip>
          </div>
        ) : (
          <div className="save-button">
            <Button
              onClick={() => {
                localStorage.removeItem("imagePublicAccountPreview");
                publicAccount === null ? handleSave() : handleUpdate();
              }}
            >
              <div className="space-save">
                <Space>
                  <div className="smartplan-filtermodal-text-white">
                    {publicAccount === null
                      ? t("myService.components.filterBar.save")
                      : "Salva cambiamenti"}
                  </div>
                </Space>
              </div>
            </Button>
          </div>
        )}
      </div>

      <div className="header-menu">
        <HeaderMenu>
          <TopBar
            title={"Account Pubblico"}
            breadcrumbText={"Account > " + "Profilo Pubblico"}
            icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
            onClick={() => {
              navigate(-1);

              handleGoBack();
            }}
            hasEdit={false}
            hasDate={false}
          />

          <div className="line-create-service " />
        </HeaderMenu>
      </div>

      <div className="page-cont">
        <div className="public-data-text-title">Dati pubblici</div>

        <div className="input-card">
          <Card>
            <div className="who-are-you">
              <div className="required">
                <Required className={undefined} ellipseClassName={undefined} />
              </div>

              <div className="who-are-you-text">Chi sei?</div>
            </div>
            <div className="preferred-shape-dropdown">
              <div className="text-dropdown-preferred-shape"></div>
              <Dropdown
                menu={{
                  items: whoYouAreItems,

                  onClick: onWhoYouAre,
                }}
              >
                <div className="button-dropdown-preferred-shape">
                  <Button>
                    <div className="space-preferred-shape">
                      <Space>
                        <div
                          className={
                            providerSelected !== -1
                              ? "myService-filtermodal-text"
                              : "myService-filtermodal-text-select"
                          }
                        >
                          {providerSelected === 1
                            ? "Nutrizionista"
                            : providerSelected === 2
                            ? "Istruttore/Coach"
                            : providerSelected === 0
                            ? "Psicologo"
                            : t("smartPlan.filterModal.selectRole")}
                        </div>

                        <TwentyIcArrowDown
                          color="#36B39E"
                          className={undefined}
                        />
                      </Space>
                    </div>
                  </Button>
                </div>
              </Dropdown>
            </div>

            <div className="personal-photo">
              <div className="required">
                <Required className={undefined} ellipseClassName={undefined} />
              </div>

              <div className="personal-photo-text">Fotografia personale</div>
            </div>

            <div className="input-dragger">
              <Dragger
                multiple={false}
                defaultFileList={defaultListImagePreview}
                customRequest={({ file, onSuccess }) => {
                  if (onSuccess !== undefined) {
                    uploadFile(file as File);

                    onSuccess("ok");
                  }
                }}
                onChange={(e: any) => {
                  if (e.fileList.length === 0) {
                    localStorage.removeItem("imagePublicAccountPreview");
                  }
                }}
                action={undefined}
                listType="picture"
                maxCount={1}
              >
                {responsePhoto ? (
                  <div className="dragger-text">Carica un'altra foto</div>
                ) : (
                  <div className="dragger-text">Carica una foto</div>
                )}
              </Dragger>
            </div>

            <div className="recommended-size">
              <div className="recommended-size-text">
                Misura consigliata:
                <div className="recommended-size-text-desc">300 x 300px</div>
              </div>

              <div className="recommended-size-text">
                Peso massimo per file:
                <div className="recommended-size-text-desc">1mb</div>
              </div>
            </div>

            <div className="personal-data-and-professional">
              <div className="personal-data-and-professional-text">
                Dati personali e professionali
              </div>
            </div>

            <div className="first-row-info">
              <div className="preferred-shape-dropdown">
                <div className="text-dropdown-preferred-shape">
                  Titolo professionale
                </div>
                <Dropdown
                  menu={{
                    items: preferredShapeItems,

                    onClick: onPreferredShape,
                  }}
                >
                  <div className="button-dropdown-preferred-shape">
                    <Button>
                      <div className="space-preferred-shape">
                        <Space>
                          <div
                            className={
                              preferredShape
                                ? "myService-filtermodal-text"
                                : "myService-filtermodal-text-select"
                            }
                          >
                            {preferredShape
                              ? preferredShape === name
                                ? "-"
                                : preferredShape
                              : t("smartPlan.filterModal.select")}
                          </div>

                          <TwentyIcArrowDown
                            color="#36B39E"
                            className={undefined}
                          />
                        </Space>
                      </div>
                    </Button>
                  </div>
                </Dropdown>
              </div>

              <div className="input-name">
                <div className="required-text">
                  <div className="required">
                    <Required
                      className={undefined}
                      ellipseClassName={undefined}
                    />
                  </div>

                  <div className="text-input-name">Nome</div>
                </div>

                <Input
                  className="input-text-style"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Inserisci nome"
                />
              </div>

              <div className="input-surname">
                <div className="required-text">
                  <div className="required">
                    <Required
                      className={undefined}
                      ellipseClassName={undefined}
                    />
                  </div>

                  <div className="text-input-surname">Cognome</div>
                </div>

                <Input
                  className="input-text-style"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Inserisci cognome"
                />
              </div>
            </div>

            <div className="second-row-info">
              <div className="personal-photo">
                <div className="required">
                  <Required
                    className={undefined}
                    ellipseClassName={undefined}
                  />
                </div>

                <div className="description-text">Descrizione profilo</div>
              </div>

              <div className="input-description">
                <TextArea
                  rows={2}
                  maxLength={2500}
                  className="input-text-style"
                  value={profileDescription}
                  placeholder="Inserisci descrizione profilo"
                  onChange={(e) => setProfileDescription(e.target.value)}
                />
              </div>
            </div>

            <div className="third-row-info">
              <div className="input-years">
                <div className="required-text">
                  <div className="required">
                    <Required
                      className={undefined}
                      ellipseClassName={undefined}
                    />
                  </div>

                  <div className="text-input-years">Anni di esperienza</div>
                </div>

                <Input
                  className="input-text-style"
                  value={yearsOfExperience}
                  type="number"
                  placeholder="Inserisci anni di esperienza"
                  onChange={(e) =>
                    setYearsOfExperience(parseInt(e.target.value))
                  }
                />
              </div>

              <div className="input-city-public-account">
                <div className="required-text">
                  <div className="required">
                    <Required
                      className={undefined}
                      ellipseClassName={undefined}
                    />
                  </div>

                  <div className="text-input-city">Dove vivi</div>
                </div>

                <Input
                  style={{ width: "100" }}
                  className="input-text-style"
                  value={residence}
                  placeholder="Inserisci la tua residenza"
                  onChange={(e) => setResidence(e.target.value)}
                />
              </div>
            </div>

            {providerSelected !== 2 && (
              <div className="input-specializations">
                <div className="required-text">
                  <div className="required">
                    <Required
                      className={undefined}
                      ellipseClassName={undefined}
                    />
                  </div>

                  <div className="text-input-specializations">
                    Aree di specializzazione
                  </div>
                </div>

                <Select
                  className="custom-select-public-account-specalizations"
                  value={typologySelected}
                  options={handleTypologyOptions(providerSelected)}
                  placeholder="Inserisci tipologie"
                  mode="multiple"
                  allowClear
                  optionLabelProp="label"
                  tagRender={tagRender}
                  onChange={(selectedValues) =>
                    setTypologySelected(selectedValues)
                  }
                />
              </div>
            )}

            <div className="fourth-row-info">
              <div className="personal-photo">
                <div className="required">
                  <Required
                    className={undefined}
                    ellipseClassName={undefined}
                  />
                </div>

                <div className="training-and-skills-text">
                  Formazione e competenze
                </div>
              </div>

              <div className="input-training-and-skills">
                <Input
                  className="input-text-style"
                  value={trainingAndCompetition}
                  placeholder="Inserisci le tue competenze"
                  onChange={(e) => setTrainingAndCompetition(e.target.value)}
                />
              </div>
            </div>

            <div className="fifth-row-info">
              <div className="input-email">
                <div className="required-text">
                  <div className="required">
                    <Required
                      className={undefined}
                      ellipseClassName={undefined}
                    />
                  </div>

                  <div className="text-input-email">Email Pubblica</div>
                </div>

                <div className="text-input-email-desc">Email</div>

                <Input
                  className="input-text-style"
                  value={email}
                  placeholder="Inserisci email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="input-telephone">
                <div className="switch-telephone">
                  <Switch
                    checked={isTelephone}
                    style={
                      isTelephone
                        ? { backgroundColor: "#36B39E" }
                        : { backgroundColor: "grey" }
                    }
                    onChange={(selected) => {
                      setIsTelephone(selected);

                      selected ? setTelephone(telephone) : setTelephone("");
                    }}
                    checkedChildren={<CheckOutlined rev={undefined} />}
                    unCheckedChildren={<CloseOutlined rev={undefined} />}
                  />

                  <div className="text-input-telephone">Telefono Pubblico</div>
                </div>

                <div className="text-input-telephone-desc">Telefono</div>

                <Input
                  className="input-text-style"
                  value={isTelephone ? telephone : ""}
                  disabled={!isTelephone}
                  placeholder="Inserisci telefono"
                  onChange={(e) => setTelephone(e.target.value)}
                />
              </div>
            </div>
          </Card>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default PublicAccountFeature;
