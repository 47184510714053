/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconRemove = ({ className, trigger }) => {
  return (
    <svg
      className={`icon-remove ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      onClick={trigger}
    >
      <g className="g" opacity="0" />
      <circle
        className="circle"
        cx="10"
        cy="10"
        r="8"
        stroke="#38342F"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M6.5 10H13.5"
        stroke="#38342F"
        strokeLinecap="square"
        strokeWidth="2"
      />
    </svg>
  );
};
