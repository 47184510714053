import { TwentyIcArrowLineRight } from "../icons/TwentyIcArrowLineRight";
import { useTranslation } from "react-i18next";
import "./WellnessActivityTail.css";
import { useNavigate } from "react-router-dom";
import { TwentyIcPinMap } from "../icons/TwentyIcPinMap/TwentyIcPinMap";
import { IconUser } from "../icons/IconUser/IconUser";
import { ImageConfig } from "../../../config/ImageConfig";
export interface WellnessActivityTailProps {
  id: number | undefined;
  img: any;
  title: string | undefined;
  status?: string;
  mode?: string;
  isIndividual?: boolean;
  placesLeft?: any;
  isFavorite?: boolean;
  typology?: string;
  activityTailType: string;
  reactionButton?: (e: any) => any;
  navigation?: (e: any) => any;
  arrow: boolean;
}

const WellnessActivityTailComponent: React.FC<WellnessActivityTailProps> = ({
  id,
  img,
  title,
  status,
  mode,
  isIndividual,
  activityTailType,
  arrow,
  navigation,
}) => {
  const { t } = useTranslation();

  let imageBuilder = null;
  let stringBuilder = "";
  let stringBuilder2 = "";

  if (activityTailType === "BODY") {
    imageBuilder = (
      <img className="activity-tail__container" alt="Rectangle" src={img} />
    );
  } else if (
    activityTailType === "NUTRITIONAL" ||
    activityTailType === "PSYCHO"
  ) {
    imageBuilder = (
      <>
        <div className="nutritional-image-container">
          <img
            className="activity-tail__container"
            alt="Rectangle"
            src={ImageConfig.BACKGROUND_DEFAULT_SERVICE}
          />
          {img && (
            <img
              className="activity-tail__container-nutritional"
              alt="Rectangle"
              src={img}
            />
          )}
        </div>
      </>
    );
  }
  if (mode) {
    switch (mode) {
      case "PRESENCE":
        stringBuilder = t("stringBuilder.presence");
        break;
      case "REMOTE":
        stringBuilder = t("stringBuilder.remote");
        break;
      case "REMOTEANDPRESENCE":
        stringBuilder = t("stringBuilder.remoteAndPresence");
        break;
    }
  }

  if (status) {
    stringBuilder2 = status;
  }

  if (isIndividual !== undefined) {
    if (activityTailType === "NUTRITIONAL") {
      stringBuilder2 = t("stringBuilder.single");
    } else {
      // Per qualche ragione che sa solo il buon dio, isIndividual è il contrario di quello che dovrebbe essere
      !isIndividual
        ? (stringBuilder2 = t("stringBuilder.individual"))
        : (stringBuilder2 = t("stringBuilder.group"));
    }
  }

  return (
    <>
      <div className="activity-tail__wrapper">
        <span className="activity-tail__image">
          {imageBuilder}
          {/* {isMostPopular && (
             <span className="activity-tail__most-popular">
               <div className="activity-tail__most-popular-text">
                 {t("Wellness.components.activityTail.mostPopular")}
               </div>
             </span>
           )} */}
          {/* <img
             className="activity-tail__frame"
             alt="Frame"
             src={!isFavorite ? favouritesImg : favouritesImgLiked}
             onClick={reactionButton}
           /> */}
        </span>
        <div className="activity-tail__frame-wrapper">
          <div className="activity-tail__sections">
            <div className="activity-tail__first-section">
              <div className="activity-tail__first-section-wrapper">
                {activityTailType === "BODY" ? (
                  <TwentyIcPinMap color="#86D1C5" className="" />
                ) : (
                  <IconUser color="#86D1C5" className="" />
                )}
                <div className="activity-tail__first-title">
                  {stringBuilder}
                </div>
              </div>
              <div className="activity-tail__alert-title"></div>
            </div>
            <div className="activity-tail__second-section">
              <div className="activity-tail__second-section-wrapper">
                <div className="activity-tail__second-title">{title}</div>
                <div className="activity-tail__third-title">
                  {stringBuilder2}
                </div>
              </div>
              {arrow && (
                <div className="activity-tail____twentyicarrowlineright ">
                  <TwentyIcArrowLineRight
                    color="#36B39E"
                    className="activity-tail____wentyicarrowlineright"
                    onClick={() => {
                      navigation && navigation(id);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WellnessActivityTailComponent;
