/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import { Switch } from "../Switch";
import "./style.css";

export const CheckLabel = ({
  help,
  icon,
  label = true,
  type,
  disabled,
  className,
  switchVector,
  switchStyle = "default",
  switchSelect = "off",
  switchEllipseClassName,
  text = "Label text",
}) => {
  return (
    <div className={`check-label type-${type} ${className}`}>
      {type === "switch" && (
        <Switch
          ellipseClassName={switchEllipseClassName}
          rectangleClassName={`${disabled === "off" && "class"}`}
          select={switchSelect}
          style={switchStyle}
          type="round"
          vector={switchVector}
        />
      )}

      {["check", "radio"].includes(type) && (
        <div className={`check-2 ${type} disabled-${disabled}`}>
          {(disabled === "off" || type === "radio") && (
            <div className="rectangle-6" />
          )}
        </div>
      )}

      {label && (
        <div className="label-text-wrapper">
          <div className={`label-text disabled-1-${disabled}`}>{text}</div>
        </div>
      )}
    </div>
  );
};

CheckLabel.propTypes = {
  help: PropTypes.bool,
  icon: PropTypes.bool,
  label: PropTypes.bool,
  type: PropTypes.oneOf(["switch", "check", "radio"]),
  disabled: PropTypes.oneOf(["off", "on"]),
  switchVector: PropTypes.string,
  switchStyle: PropTypes.string,
  switchSelect: PropTypes.string,
  text: PropTypes.string,
};
