import PropTypes from "prop-types";
import "./User.css";

const User = ({ name = "JD", size, imgUrl, round }) => {
  return (
    <div
      style={{ backgroundImage: `url(${imgUrl})` }}
      className={`user size-${size}${!imgUrl ? " no-image " : " "}${
        round && "round"
      }`}
    >
      {!imgUrl && <div className="user-name">{name}</div>}
    </div>
  );
};

User.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf(["l", "xs", "m", "s"]),
  imgUrl: PropTypes.string,
  round: PropTypes.bool,
};

export default User;
