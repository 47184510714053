import HttpClient from "../../utils/HttpClient";

export default class SmartPlanRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getSmartPlanActivityTail(queryString?: string) {
    return this.instance.get("smartPlans" + (queryString ?? ""));
  }

  public getSmartPlan(smartPlanId: number) {
    const path = "/smartPlans/" + smartPlanId;
    return this.instance.get(path);
  }
}
