import HttpClient from "../../../utils/HttpClient";

export default class TransactionRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getTransactions() {
    const tenantId = localStorage.getItem("tenantId");
    const user = localStorage.getItem("customerId");
    const path = "/tenants/" + tenantId + "/users/" + user + "/transactions";
    return this.instance.get(path);
  }

  public getTransactionDetails(transactionalSummaryId: number) {
    const path = "/transactionDetails/" + transactionalSummaryId;
    return this.instance.get(path);
  }
}
