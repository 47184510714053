import "../../../feature/Smartplan/SmartplanDetails/SmartPlanDetails.css";
import React, { useEffect, useState } from "react";
import { Button } from "../Button/Button";
import { t } from "i18next";
import { IconLink } from "../icons/IconLink";
import { TwentyIcPhone } from "../icons/TwentyIcPhone";
import { IconMail } from "../icons/IconMail";
import { TwentyIcCreditCoins } from "../TwentyIcCreditCoins";
import { useLocation } from "react-router-dom";

interface WellnessSummaryComponentsProps {
  type: string;
  website?: string;
  telephone?: string;
  sosTelephone?: string;
  email?: string;
  referral?: string;
  price?: number;
  voucherPrice?: number;
  disabled?: boolean;
  onClick?: () => any;
  goToBooks?: () => any;
}

export const WellnessSummaryComponent: React.FC<
  WellnessSummaryComponentsProps
> = ({
  website,
  telephone,
  sosTelephone,
  email,
  voucherPrice,
  disabled,
  onClick,
  goToBooks,
}) => {
  const urlLocation = useLocation();
  const isPreview = urlLocation.pathname.includes("previewService");
  const [disabledButton, setDisabledButton] = useState<boolean>();

  useEffect(() => {
    setDisabledButton(disabled);
  }, [disabled]);

  return (
    <div className="frame-17">
      <div className="text-wrapper-11">{t("wellness.summary")}</div>
      <div className="rectangle-9" />
      <div className="frame-18">
        {website && (
          <div className="frame-19">
            <div className="text-wrapper-14">{t("wellness.website")}</div>
            <div className="frame-20">
              <IconLink className={undefined} color="#36B39E" />
              <div className="text-wrapper-15">{website}</div>
            </div>
          </div>
        )}
      </div>
      {telephone && (
        <div className="frame-22-summary">
          <div className="text-wrapper-14">{t("wellness.telephone")}</div>
          <div className="frame-20">
            <TwentyIcPhone color="#36B39E" className="icon-instance-node-3" />
            <div className="text-wrapper-15">{telephone}</div>
          </div>
        </div>
      )}
      {sosTelephone && (
        <div className="frame-22-summary">
          <div className="text-wrapper-14">{t("wellness.sosTelephone")}</div>
          <div className="frame-20">
            <TwentyIcPhone color="#36B39E" className="icon-instance-node-3" />
            <div className="text-wrapper-15">{sosTelephone}</div>
          </div>
        </div>
      )}
      {email && (
        <div className="frame-22-summary">
          <div className="text-wrapper-14">{t("wellness.email")}</div>
          <div className="frame-20">
            <IconMail className="icon-instance-node-3" color="#36B39E" />
            <div className="text-wrapper-15">{email}</div>
          </div>
        </div>
      )}
      <div className="rectangle-9" />
      <div className="frame-23">
        <div className="text-wrapper-14">{"COSTO"}</div>
        <div className="frame-24">
          <div style={{ display: "flex" }}>
            <h1 className="h-1">
              {isPreview ? voucherPrice + "€" : voucherPrice}
            </h1>
            <div style={{ position: "relative", top: "4px" }}>
              {!isPreview ? (
                <TwentyIcCreditCoins
                  className={undefined}
                  color="#36B39E"
                  colorC="#36B39E"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Button
        type="primary"
        size="XL"
        state="default"
        onClick={() => {
          if (goToBooks) {
            goToBooks();
          }
          if (onClick) {
            onClick();
          }
        }}
        text={t("wellness.nextToOrder")}
        disabled={disabledButton}
        iconRight={undefined}
        icon={undefined}
      />
    </div>
  );
};
