import "../Tag/Tag.css";

export interface TagProps {
  text?: string;
  isAdmin?: boolean;
  pending?: boolean;
}

const TableTag: React.FC<TagProps> = ({ text, isAdmin, pending }) => {
  return (
    <>
      <div
        className={`table-tag-${
          pending ? "pending" : isAdmin ? "admin" : "active"
        }`}
      >
        <div className="table-tag-text">{text} </div>
      </div>
    </>
  );
};

export default TableTag;
