import { ReactNode } from "react";
import "./ContainerWrapper.css";

interface ContainerWrapperProps {
  children: ReactNode;
}

function ContainerWrapper({ children }: ContainerWrapperProps) {
  return <div className="container-wrapper">{children}</div>;
}

export default ContainerWrapper;
