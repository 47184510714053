import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { Config } from "../../../config/Config";
import _ from "lodash";
import { EverywhereSuccessCodes } from "../../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../../config/EverywhereErrorCodes";
import { RootState } from "../../../app/store";
import { UserInfo } from "../../UserInfo/UserSlice";
import WellnessRepository from "../WellnessRepository";
import { Tenant } from "../../Tenant/TenantSlice";

export interface WellnessBody {
  id: number;
  approved: boolean;
  active: boolean;
  published: boolean;
  name: string;
  description: string;
  typology: string;
  numberMeeting: number;
  durationMeeting: string;
  price: number;
  isIndividual: boolean | undefined;
  sessionsAvailable: number;
  remotelyOrPresenceEnum: string;
  city: string;
  province: string;
  location: string | undefined;
  address: string;
  zip: string;
  link: string;
  image: string;
  galleries: string[];
  deletedPhotoGallery: number[];
  faqs: number[];
  tenant: Tenant;
  userInfo: UserInfo;
}

export const getWellnessBodyAsync = createAsyncThunk(
  "wellnessBody/getWellnessBody",
  async (wellnessBodyId: number) => {
    const wellnessRepository = new WellnessRepository();
    const response = await wellnessRepository.getWellnessBody(wellnessBodyId);
    const wellnessBody = _.get(response, Config.WELLNESS_BODY_RESPONSE);
    return wellnessBody;
  }
);

export const getWellnessBodyDetailsAsync = createAsyncThunk(
  "wellnessBody/getWellnessBodyDetails",
  async (wellnessBodyId: number) => {
    const wellnessRepository = new WellnessRepository();
    const response = await wellnessRepository.getWellnessBodyDetails(
      wellnessBodyId
    );
    const wellnessBody = _.get(response, Config.WELLNESS_BODY_RESPONSE);
    return wellnessBody;
  }
);

export const createWellnessBodyAsync = createAsyncThunk(
  "wellnessBody/createWellnessBody",
  async (data: { wellnessBody: any }) => {
    const wellnessRepository = new WellnessRepository();
    const response = await wellnessRepository.createWellnessBody(
      data.wellnessBody
    );
    const wellnessBody = _.get(response, Config.CREATE_WELLNESS_BODY_RESPONSE);
    return wellnessBody;
  }
);

export const deleteWellnessBodyAsync = createAsyncThunk(
  "WellnessBody/deleteWellnessBody",
  async (wellnessBodyId: number, { rejectWithValue }) => {
    try {
      const wellnessRepository = new WellnessRepository();
      await wellnessRepository.deleteWellnessBody(wellnessBodyId);
      return wellnessBodyId;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const updateWellnessBodyAsync = createAsyncThunk(
  "wellnessBody/updateWellnessBody",
  async (data: { wellnessBody: number; wellnessData: any }) => {
    const wellnessRepository = new WellnessRepository();
    const response = await wellnessRepository.partiallyUpdateWellNessBody(
      data.wellnessBody,
      data.wellnessData
    );
    const wellnessBody = _.get(response, Config.UPDATE_WELLNESS_BODY_RESPONSE);
    return wellnessBody;
  }
);

export const updateWellnessApprovedAsync = createAsyncThunk(
  "wellness/updateWellness",
  async (data: {
    request: any;
    tenantId: number;
    wellnessId: number;
    userId: number;
  }) => {
    const wellnessRepository = new WellnessRepository();
    const response = await wellnessRepository.changeApproval(
      data.tenantId,
      data.userId,
      data.request,
      data.wellnessId
    );
    const wellnessBody = _.get(response, Config.UPDATE_WELLNESS_BODY_RESPONSE);
    return wellnessBody;
  }
);

const wellnessBodyAdapter = createEntityAdapter<WellnessBody>({
  selectId: (wellnessBody) => wellnessBody.id,
});

export const wellnessBodySlice = createSlice({
  name: "wellnessBody",
  initialState: wellnessBodyAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    wellnessBodyEmptyState: (state) => {
      wellnessBodyAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getWellnessBodyAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          wellnessBodyAdapter.setOne(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessBodyAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getWellnessBodyAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getWellnessBodyDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          wellnessBodyAdapter.setOne(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessBodyDetailsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getWellnessBodyDetailsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        createWellnessBodyAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          wellnessBodyAdapter.upsertOne(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.POST;
        }
      )
      .addCase(createWellnessBodyAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(createWellnessBodyAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(deleteWellnessBodyAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        deleteWellnessBodyAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          wellnessBodyAdapter.upsertMany(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = "";
        }
      )
      .addCase(
        deleteWellnessBodyAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = EverywhereSuccessCodes.DELETE;
        }
      )
      .addCase(
        updateWellnessBodyAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload) {
            wellnessBodyAdapter.upsertOne(state, action.payload);
          }
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.PATCH;
        }
      )
      .addCase(updateWellnessBodyAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(updateWellnessBodyAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        updateWellnessApprovedAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload) {
            wellnessBodyAdapter.upsertOne(state, action.payload);
          }
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.PATCH;
        }
      )
      .addCase(updateWellnessApprovedAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(updateWellnessApprovedAsync.pending, (state: any) => {
        state.status = "loading";
      });
  },
});

export const wellnessBodySelector = wellnessBodyAdapter.getSelectors<RootState>(
  (state) => state.wellnessBody
);

export const { wellnessBodyEmptyState } = wellnessBodySlice.actions;
export const selectWellnessBodySliceReasonCOde = (state: any) =>
  state.wellnessBody.reasonCode;

export const selectWellnessBodySliceStatus = (state: any) =>
  state?.wellnessBody?.status;

export default wellnessBodySlice.reducer;
