/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcEdit = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-edit ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M13.6064 4L15.7278 6.12132L5.93706 15.912L4.0605 15.6673L3.81574 13.7907L13.6064 4Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
