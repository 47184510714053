import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const fallbackLng = ["it"];
const availableLanguages = ["en", "it"];

const resources = {
  en: {
    translation: require("./locales/en/translations.json"),
  },
  it: {
    translation: require("./locales/it/translations.json"),
  },
};

i18n
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next
  .init({
    resources, //supported languages
    lng: "it", //    lng: localStorage.getItem("i18nextLng"), deactivated auto detection language and setted it as default language
    fallbackLng, // fallback language is english.
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    whitelist: availableLanguages,
  });

export default i18n;
