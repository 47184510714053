import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import smartPlanActivityTailSliceReducer from "../feature/Smartplan/SmartPlanActivityTailSlice";
import activitySliceReducer from "../feature/Activity/ActivitySlice";
import smartPlanReducer from "../feature/Smartplan/SmartPlanSlice";
import faqSliceReducer from "../feature/Faq/FaqSlice";
import includedServiceSliceReducer from "../feature/IncludedServices/IncludedServiceSlice";
import smartDatePlanSliceReducer from "../feature/Smartplan/SmartDatePlan/SmartDatePlanSlice";
import gallerySliceReducer from "../feature/Gallery/GallerySlice";
import teamUsersInfoSliceReducer from "../feature/MyTeam/TeamUsersInfoSlice";
import masterClassActivityTailSliceReducer from "../feature/masterclass/MasterClassActivityTailSlice";
import myOrdersSliceReducer from "../feature/myorders/MyOrdersSlice";
import virtualCoworkingSliceReducer from "../feature/VirtualCoworking/VirtualCoworkingSlice";
import masterClassSliceReducer from "../feature/masterclass/MasterClassSlice";
import tenantSliceReducer from "../feature/Tenant/TenantSlice";
import tenantAdditionalInfoSliceReducer from "../feature/Tenant/TenantAdditionalInfoSlice";
import userInfoSliceReducer from "../feature/UserInfo/UserSlice";
import ChangePasswordSliceReducer from "../feature/ChangePassword/ChangePasswordSlice";
import myOrderDetailsSliceReducer from "../feature/myorders/MyOrderDetailSlice";
import WalletSliceReducer from "../feature/Wallet/WalletSlice";
import WellNessBodySliceReducer from "../feature/Wellness/WellnessSlice/WellnessBodySlice";
import WellNessNutritionalSliceReducer from "../feature/Wellness/WellnessSlice/WellnessNutritionalSlice";
import WellNessTableSliceReducer from "../feature/Wellness/WellnessSlice/WellNessTableSlice";
import WellNessBodyViewsReducer from "../feature/Wellness/WellnessSlice/WellnessBodyViewSlice";
import WellNessNutritionalViewsReducer from "../feature/Wellness/WellnessSlice/WellnessNutritionalViewSlice";
import WellNessPsychoSlice from "../feature/Wellness/WellnessSlice/WellnessPsychoSlice";
import WellNessPsychoViewsReducer from "../feature/Wellness/WellnessSlice/WellnessPsychoViewSlice";
import PublicAccountReducer from "../feature/Account/PublicAccount/PublicAccountSlice";
import PublicAccountViewReducer from "../feature/Account/PublicAccount/PublicAccountViewSlice";
import MyDiaryReducer from "../feature/MyDiary/MyDiarySlice";
import ServiceViewSliceReducer from "../feature/Wellness/WellnessSlice/ServiceViewSlice";
import CreditHistoryReducer from "../feature/CreditManagement/CreditHistorySlice";
import TransactionSliceReducer from "../feature/CreditManagement/TransactionSlice/TransactionSlice";
import TransactionDetailsSliceReducer from "../feature/CreditManagement/TransactionSlice/TransactionDetailsSlice";
import GalleryPreviewReducer from "../feature/Gallery/GalleryPreviewSlice";
import MeetingsForReportSlice from "../feature/Meetings/MeetingsForReport/MeetingsForReportSlice";
import MeetingReducer from "../feature/Meetings/MeetingSlice";
import OfficeCoworkingReducer from "../feature/OfficeCoworking/OfficeCoworkingSlice";
import OfficeCoworkingActivityTailReducer from "../feature/OfficeCoworking/OfficeCoworkingActivityTailSlice";
import OfficeCoworkingIncludedServicesReducer from "../feature/OfficeCoworking/OfficeCoworkingIncludedServicesSlice";
import OfficeCoworkingFiltersReducer from "../feature/OfficeCoworking/OfficeCoworkingFiltersSlice";

export const store = configureStore({
  reducer: {
    smartPlanActivityTails: smartPlanActivityTailSliceReducer,
    activity: activitySliceReducer,
    smartPlan: smartPlanReducer,
    faq: faqSliceReducer,
    includedService: includedServiceSliceReducer,
    smartDatePlan: smartDatePlanSliceReducer,
    gallery: gallerySliceReducer,
    masterClassActivityTails: masterClassActivityTailSliceReducer,
    myOrders: myOrdersSliceReducer,
    myOrderDetail: myOrderDetailsSliceReducer,
    teamUsersInfo: teamUsersInfoSliceReducer,
    masterClass: masterClassSliceReducer,
    virtualCoworking: virtualCoworkingSliceReducer,
    tenant: tenantSliceReducer,
    tenantAdditionalInfo: tenantAdditionalInfoSliceReducer,
    userInfo: userInfoSliceReducer,
    changePassword: ChangePasswordSliceReducer,
    wallet: WalletSliceReducer,
    wellnessBody: WellNessBodySliceReducer,
    wellnessNutritional: WellNessNutritionalSliceReducer,
    wellnessTable: WellNessTableSliceReducer,
    wellnessBodyViews: WellNessBodyViewsReducer,
    wellnessNutritionalViews: WellNessNutritionalViewsReducer,
    wellnessPsycho: WellNessPsychoSlice,
    wellnessPsychoViews: WellNessPsychoViewsReducer,
    publicAccount: PublicAccountReducer,
    publicAccountView: PublicAccountViewReducer,
    myDiary: MyDiaryReducer,
    wellnessServicesView: ServiceViewSliceReducer,
    creditHistory: CreditHistoryReducer,
    transaction: TransactionSliceReducer,
    transactionDetails: TransactionDetailsSliceReducer,
    galleryPreview: GalleryPreviewReducer,
    meetingsReport: MeetingsForReportSlice,
    meetings: MeetingReducer,
    officeCoworking: OfficeCoworkingReducer,
    officeCoworkingActivityTails: OfficeCoworkingActivityTailReducer,
    officeCoworkingIncludedServices: OfficeCoworkingIncludedServicesReducer,
    officeCoworkingFilters: OfficeCoworkingFiltersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
