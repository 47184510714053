/* eslint-disable default-case */
/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import { useReducer, useState } from "react";
import { Default } from "../icons/Default";
import "./style.css";
import { TwentyIcSearch } from "../icons/TwentyIcSearch";

export const BaseInput = ({
  TAGS,
  tag3,
  tag2,
  tag1,
  iconRight = true,
  label = true,
  iconLeft = true,
  style,
  className,
  onChange,
  icon = <TwentyIcSearch color="grey" />,
  frameClassName,
  text = "Ricerca per nome",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    style: style || "default",
  });
  const [name, setName] = useState("");

  function handleChange(event) {
    setName(event.target.value);
    onChange && onChange(event.target.value);
  }

  return (
    <div
      className={`base-input ${state.style} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onClick={() => {}}
    >
      {iconLeft && (
        <>
          <>
            <div style={{ height: "10px", paddingBottom: "8px" }}>{icon}</div>
          </>
        </>
      )}

      <div
        className={`frame-4 ${
          state.style === "hover" ? frameClassName : undefined
        }`}
      >
        <div className="text-prev">
          {(state.style !== "hover" || state.style === "focus") &&
            name === "" &&
            text}
        </div>
        {label && (
          <input
            style={{ borderColor: "transparent", outlineColor: "transparent" }}
            type="text"
            name="name"
            onChange={(e) => {
              handleChange(e);
            }}
            value={name}
          />
        )}
      </div>
      {iconRight && (
        <>
          <>
            {["default", "focus-text", "focus", "hover", "text"].includes(
              state.style
            ) && <Default className="default-2" color="#36B39E" />}

            {state.style === "disabled" && (
              <Default className="default-2" color="#222729" opacity="0.2" />
            )}
          </>
        </>
      )}
    </div>
  );
};

function reducer(state, action) {
  if (state.style === "default") {
    switch (action) {
      case "mouse_enter":
        return {
          style: "hover",
        };
    }
  }

  if (state.style === "hover") {
    switch (action) {
      case "mouse_leave":
        return {
          style: "default",
        };

      case "click":
        return {
          style: "focus",
        };
    }
  }

  if (state.style === "focus") {
    switch (action) {
      case "click":
        return {
          style: "default",
        };
    }
  }

  return state;
}

BaseInput.propTypes = {
  TAGS: PropTypes.bool,
  tag3: PropTypes.bool,
  tag2: PropTypes.bool,
  tag1: PropTypes.bool,
  iconRight: PropTypes.bool,
  label: PropTypes.bool,
  iconLeft: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.oneOf([
    "focus-text",
    "default",
    "focus",
    "hover",
    "text",
    "disabled",
  ]),
  text: PropTypes.string,
};
