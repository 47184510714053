import React, { useEffect, useState } from "react";
import { TwentyIcArrowLeft3 } from "../TwentyIcArrowLeft3";
import { TwentyIcArrowRight1 } from "../TwentyIcArrowRight1";
import "./InputNumber.css";

const InputNumber = ({ getValue }) => {
  const [value, setValue] = useState(1);

  const handleIncrement = () => {
    if (value < 100) {
      setValue(value + 1);
    }
  };

  const handleDecrement = () => {
    if (value > 1) {
      setValue(value - 1);
    }
  };

  useEffect(() => {
    getValue(value);
  }, [value]);

  return (
    <div className="input-number-container">
      <button className="button-left" onClick={handleDecrement}>
        <TwentyIcArrowLeft3 className="icon-instance-node" />
      </button>
      <div className="input-number">
        <span className="number">{value}</span>
      </div>
      <button className="button-right" onClick={handleIncrement}>
        <TwentyIcArrowRight1 className="icon-instance-node" color="#36B39E" />
      </button>
    </div>
  );
};

export default InputNumber;
