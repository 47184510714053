import PropTypes from "prop-types";
import { TwentyIcCalendar } from "../icons/TwentyIcCalendar";
import { TwentyIcComment } from "../icons/TwentyIcComment";
import { TwentyIcDashboard } from "../icons/TwentyIcDashboard";
import { TwentyIcEverywhere } from "../icons/TwentyIcEverywhere";
import { TwentyIcFavorite } from "../icons/TwentyIcFavorite";
import { TwentyIcHamburguerNavClose } from "../icons/TwentyIcHamburguerNavClose";
import { TwentyIcLaptop } from "../icons/TwentyIcLaptop";
import { TwentyIcLesson } from "../icons/TwentyIcLesson";
import { TwentyIcNotify } from "../icons/TwentyIcNotify";
import { TwentyIcOrderBox } from "../icons/TwentyIcOrderBox/TwentyIcOrderBox";
import { TwentyIcPresentation } from "../icons/TwentyIcPresentation";
import { TwentyIcSupport } from "../icons/TwentyIcSupport";
import { TwentyIcTable } from "../icons/TwentyIcTable";
import { TwentyIcZen } from "../icons/TwentyIcZen";
import { Logo } from "../Logo/Logo";
import { Option } from "../Option";
import "./SidebarAdmin.css";
import { useMatch, useNavigate } from "react-router-dom";
import { TwentyIcUser } from "../icons/TwentyIcUser";
import { useEffect, useState } from "react";
import { TwentyIcHandServices } from "../TwentyIcHandServices";
import { TwentyIcCreditCoins } from "../TwentyIcCreditCoins";
import _ from "lodash";
import { Image } from "antd";
import { store } from "../../../app/store";
import {
  getUserInfoAsync,
  userInfoSelector,
} from "../../../feature/UserInfo/UserSlice";
import { useAppSelector } from "../../../app/hooks";
import { TwentyIcDoc } from "../icons/TwentyIcDoc";

export const SidebarAdmin = ({
  className,

  frameClassName,

  optionStyle = "selected",

  optionIcon1 = <TwentyIcDashboard className="admin-icon-node" color="white" />,

  optionIcon2 = (
    <TwentyIcFavorite className="sidebar-icon-node" color="white" />
  ),

  optionIcon3 = <TwentyIcNotify className="sidebar-icon-node" color="white" />,

  optionIcon4 = (
    <TwentyIcOrderBox className="sidebar-icon-node" color="white" />
  ),

  optionIcon5 = (
    <TwentyIcCalendar className="sidebar-icon-node" color="white" />
  ),

  optionIcon6 = <TwentyIcComment className="sidebar-icon-node" color="white" />,

  optionIcon7 = <TwentyIcLesson className="sidebar-icon-node" color="white" />,

  optionIcon8 = (
    <TwentyIcEverywhere className="sidebar-icon-node" color="white" />
  ),

  optionIcon9 = <TwentyIcLaptop className="sidebar-icon-node" color="white" />,

  optionIcon10 = <TwentyIcTable className="sidebar-icon-node" color="white" />,

  optionIcon11 = (
    <TwentyIcPresentation className="sidebar-icon-node" color="white" />
  ),

  optionIcon12 = <TwentyIcZen className="sidebar-icon-node" color="white" />,

  optionIcon13 = (
    <TwentyIcSupport className="sidebar-icon-node" color="white" />
  ),

  optionIcon14 = <TwentyIcUser className="sidebar-icon-node" color="white" />,

  optionIcon15 = (
    <TwentyIcHandServices className="sidebar-icon-node" color="white" />
  ),

  optionIcon16 = (
    <TwentyIcCreditCoins
      className="sidebar-icon-node"
      color="white"
      colorC="white"
    />
  ),

  optionIcon17 = <TwentyIcDoc color="white" />,

  headerClassName,

  frameClassNameOverride,
}) => {
  const navigate = useNavigate();

  const firstName = localStorage.getItem("firstName");

  const lastName = localStorage.getItem("lastName");

  const email = localStorage.getItem("email");

  const userId = localStorage.getItem("customerId");

  const tenantId = localStorage.getItem("tenantId");

  const [currentActive, setCurrentActive] = useState("");

  const pathHome = useMatch("/home");

  const pathMyTeam = useMatch("/myTeam");

  const pathMasterclass = useMatch("/masterclass");

  const pathSmartplan = useMatch("/smartplan");

  const pathVirtualCoworking = useMatch("/virtualCoworking");

  const pathOfficeCoworking = useMatch("/officeCoworking");

  const pathAssistance = useMatch("/assistance");

  const pathMyService = useMatch("/myService");

  const pathWellness = useMatch("/wellness");

  const pathCredits = useMatch("/credits");

  const pathMeetings = useMatch("/meetings");

  let userInfo = useAppSelector(
    (state) => userInfoSelector.selectById(state, userId) ?? {}
  );

  useEffect(() => {
    if (pathHome) {
      setCurrentActive("admin-home");
    }

    if (pathMyTeam) {
      setCurrentActive("admin-myTeam");
    }

    if (pathMasterclass) {
      setCurrentActive("admin-masterclass");
    }

    if (pathSmartplan) {
      setCurrentActive("admin-smartplan");
    }

    if (pathVirtualCoworking) {
      setCurrentActive("admin-virtualCoworking");
    }

    if (pathOfficeCoworking) {
      setCurrentActive("admin-officeCoworking");
    }

    if (pathAssistance) {
      setCurrentActive("admin-assistance");
    }

    if (pathMyService) {
      setCurrentActive("admin-myService");
    }

    if (pathWellness) {
      setCurrentActive("admin-wellness");
    }
    if (pathCredits) {
      setCurrentActive("admin-credits");
    }
    if (pathMeetings) {
      setCurrentActive("admin-meetings");
    }
  }, [
    pathHome,

    pathMyTeam,

    pathMasterclass,

    pathSmartplan,

    pathVirtualCoworking,

    pathAssistance,

    pathMyService,

    pathCredits,
    pathWellness,
    pathMeetings,
  ]);

  useEffect(() => {
    if (userId !== "" && userId !== undefined && userId !== null) {
      store.dispatch(
        getUserInfoAsync({
          userInfoId: parseInt(userId),

          tenantId: tenantId,
        })
      );
    }
  }, [userId]);

  const profile = localStorage.getItem("securityProfile");

  const w = window;

  const openHubSpotChat = () => {
    if (w.HubSpotConversations) {
      w.HubSpotConversations.widget.open() ||
        w.HubSpotConversations.widget.close();
    }
  };

  const showCredits = profile === "ADMIN" || profile === "EMPLOYEE";

  return (
    <div
      className={
        profile === "SUPER_ADMIN"
          ? `sidebar-super-admin ${className}`
          : `sidebar-admin ${className}`
      }
    >
      <div className={`admin-frame ${frameClassName}`}>
        <div className="admin-section">
          <Option
            className="admin-option"
            notify={false}
            override={optionIcon1}
            text="Home"
            style="default"
            onClick={() => {
              navigate("/home");

              setCurrentActive("admin-home");
            }}
            isActive={currentActive === "admin-home"}
          />

          {profile === "ADMIN" || profile === "SUPER_ADMIN" ? (
            <Option
              className="admin-option"
              notify={false}
              override={optionIcon9}
              style="default"
              text={profile === "ADMIN" ? "Il Mio Team" : "Utenti"}
              onClick={() => {
                navigate("/myTeam");
                setCurrentActive("admin-myTeam");
              }}
              isActive={currentActive === "admin-myTeam"}
            />
          ) : null}
          {showCredits ? (
            <Option
              className="admin-option"
              notify={false}
              override={optionIcon16}
              style="default"
              text={profile === "ADMIN" ? "Gestione crediti" : "I miei crediti"}
              onClick={() => {
                navigate("/credits");
                setCurrentActive("admin-credits");
              }}
              isActive={currentActive === "admin-credits"}
            />
          ) : null}
        </div>

        {profile === "ADMIN" || profile === "EMPLOYEE" ? (
          <div className="admin-divider">
            <div className="admin-text">SERVIZI</div>
          </div>
        ) : null}

        <div className="admin-section">
          {profile === "ADMIN" || profile === "EMPLOYEE" ? (
            <Option
              className="admin-option"
              notify={false}
              override={optionIcon12}
              style="default"
              text="Servizi per il benessere"
              onClick={() => {
                navigate("/wellness");
                setCurrentActive("admin-wellNess");
              }}
              isActive={currentActive === "admin-wellness"}
            />
          ) : null}

          {profile === "ADMIN" || profile === "EMPLOYEE" ? (
            <Option
              className="admin-option"
              notify={false}
              override={optionIcon7}
              style="default"
              text="Master Class"
              onClick={() => {
                navigate("/masterclass");
                setCurrentActive("admin-masterclass");
              }}
              isActive={currentActive === "admin-masterclass"}
            />
          ) : null}

          {profile === "ADMIN" || profile === "EMPLOYEE" ? (
            <Option
              className="admin-option"
              notify={false}
              override={optionIcon8}
              style="default"
              text="Smart Plan"
              onClick={() => {
                navigate("/smartplan");
                setCurrentActive("admin-smartplan");
              }}
              isActive={currentActive === "admin-smartplan"}
            />
          ) : null}

          {profile === "ADMIN" || profile === "EMPLOYEE" ? (
            <Option
              className="admin-option"
              notify={false}
              override={optionIcon10}
              style="default"
              text={"Coworking"}
              onClick={() => {
                navigate("/officeCoworking");
                setCurrentActive("admin-officeCoworking");
              }}
              isActive={currentActive === "admin-officeCoworking"}
            />
          ) : null}

          {profile === "ADMIN" ||
          profile === "EMPLOYEE" ||
          profile === "PROVIDER" ? (
            <Option
              className="admin-option"
              notify={false}
              override={optionIcon9}
              style="default"
              text={
                profile === "PROVIDER"
                  ? "Coworking virtuale"
                  : "Coworking virtuali"
              }
              onClick={() => {
                navigate("/virtualCoworking");
                setCurrentActive("admin-virtualCoworking");
              }}
              isActive={currentActive === "admin-virtualCoworking"}
            />
          ) : null}
        </div>

        {profile === "SUPER_ADMIN" ||
        profile === "ADMIN" ||
        profile === "EMPLOYEE" ? (
          <div className="admin-divider">
            <div className="admin-text">ALTRO</div>
          </div>
        ) : null}

        {profile === "PROVIDER" || profile === "SUPER_ADMIN" ? (
          <div className="admin-section">
            <Option
              className="admin-option"
              notify={false}
              override={optionIcon17}
              style="default"
              text={
                profile === "SUPER_ADMIN"
                  ? "Servizi dei Fornitori"
                  : "Miei servizi"
              }
              onClick={() => {
                navigate("/myService");
                setCurrentActive("admin-myService");
              }}
              isActive={currentActive === "admin-myService"}
            />
          </div>
        ) : null}

        {profile === "SUPER_ADMIN" || profile === "PROVIDER" ? (
          <div className="admin-section">
            <Option
              className="admin-option"
              notify={false}
              override={optionIcon15}
              style="default"
              text="Appuntamenti"
              onClick={() => {
                navigate("/meetings");
                setCurrentActive("admin-meetings");
              }}
              isActive={currentActive === "admin-meetings"}
            />
          </div>
        ) : null}

        <div
          className="admin-section"
          onClick={openHubSpotChat}
          href="#hs-chat-open"
        >
          <Option
            className="admin-option"
            notify={false}
            override={optionIcon13}
            style="default"
            text="Assistenza"
            onClick={() => {
              setCurrentActive("admin-assistance");
            }}
            isActive={currentActive === "admin-assistance"}
          />
        </div>
      </div>

      <div className={`admin-header ${headerClassName}`}>
        <div className="admin-img">
          <Logo width="173px" height="26px" />
        </div>

        <div className="admin-group-wrapper">
          <div className="admin-group-icon">
            {null && (
              <div className="admin-overlap-group">
                <TwentyIcHamburguerNavClose
                  className="admin-hamburger-nav"
                  color="white"
                />

                <TwentyIcHamburguerNavClose
                  className="admin-hamburger-nav"
                  color="white"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={`admin-frame-2 ${frameClassNameOverride}`}
        onClick={() => {
          setCurrentActive("");
          navigate("/account/" + userId + "/details");
        }}
      >
        <div className="admin-frame-3">
          {userInfo && userInfo.profilePhoto ? (
            <div className="admin-MR-wrapper">
              <div className="profile-photo">
                <Image
                  preview={false}
                  style={{
                    width: "100%",

                    height: "100%",

                    objectFit: "cover",

                    position: "absolute",

                    top: 0,

                    left: 0,
                  }}
                  width={50}
                  height={50}
                  src={userInfo.profilePhoto}
                  alt=""
                />
              </div>
            </div>
          ) : (
            <div className="admin-MR-wrapper">
              <div className="admin-MR">
                {firstName && lastName && firstName[0] + lastName[0]}
              </div>
            </div>
          )}

          <div className="admin-frame-4">
            <div className="admin-frame-5">
              <div className="admin-mario-rossi">
                {firstName + " " + lastName}
              </div>

              {profile === "SUPER_ADMIN" || profile === "ADMIN" ? (
                <div className="admin-admin-wrapper">
                  <div className="admin-admin">ADMIN</div>
                </div>
              ) : null}
            </div>

            <div className="admin-mario-rossi-mail-com">{email}</div>
          </div>
        </div>

        <div className="admin-rectangle" />
      </div>
    </div>
  );
};

SidebarAdmin.propTypes = {
  optionStyle: PropTypes.string,

  optionStyle1: PropTypes.string,
};
