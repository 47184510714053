import React, { useEffect, useState } from "react";
import { QueryParams } from "../../../feature/Smartplan/SmartPlan";
import _ from "lodash";
import { t } from "i18next";
import "./WellnessBodyFilterModal.css";
import { Button, Space, Select } from "antd";
import { TwentyIcArrowDown } from "../icons/TwentyIcArrowDown";
import { TwentyIcCloseSmall } from "../icons/TwentyIcCloseSmall";
import { TwentyIcTik } from "../icons/TwentyIcTik";
import { BaseOptionType } from "antd/es/select";
import Tag from "../Tag/Tag";

interface WellnessNutritionalFilterModalProps {
  modalChange: (e: any) => any;
  setParam: (e: any) => any;
  isModalOpened: boolean;
  queryParams: QueryParams;
}

export const WellnessNutritionalFilterModal: React.FC<
  WellnessNutritionalFilterModalProps
> = ({ modalChange, setParam, isModalOpened, queryParams }) => {
  const [modalOpened, setModalOpened] = useState(isModalOpened);

  const [oldQueryParams, setOldQueryParams] = useState({} as QueryParams);
  const [typeSelected, setTypeSelected] = useState<string[]>([]);

  useEffect(() => {
    if (!_.isEqual(oldQueryParams, queryParams) && !!queryParams) {
      if (queryParams["typology"]) {
        if (Array.isArray(queryParams["typology"])) {
          let locTemp = [] as string[];
          queryParams["typology"].map((element: any) => {
            let tmp = element.charAt(0).toUpperCase() + element.slice(1);
            locTemp.push(tmp);
          });
          setTypeSelected(locTemp);
        }
      }
    }
    setOldQueryParams(queryParams);
  }, [isModalOpened]);

  function paramBuilder() {
    let map = new Map();
    let optSelected = [] as string[];
    if (typeSelected.length > 0) {
      typeSelected.map((element) => {
        optSelected.push(element);
      });
      map.set("typology", optSelected);
      optSelected = [];
    }

    return map;
  }

  function restoreParams() {
    setTypeSelected([]);
  }

  //START REGION TYPE
  const typeItems: BaseOptionType["types"] = [
    {
      label: "Perdita di peso o aumento della massa muscolare",
      value: "Perdita di peso o aumento della massa muscolare",
    },
    {
      label: "Miglioramento delle prestazioni atletiche",
      value: "Miglioramento delle prestazioni atletiche",
    },
    {
      label: "Gestione di condizioni mediche specifiche",
      value: "Gestione di condizioni mediche specifiche",
    },
    {
      label: "Gestione dello stress alimentare",
      value: "Gestione dello stress alimentare",
    },
  ];

  const typeHandleChange = (value: string[]) => {
    setTypeSelected(value);
  };

  function tagRender(props: any) {
    const { label, onClose } = props;

    return (
      <div style={{ paddingTop: "2px", paddingRight: "4px" }}>
        <Tag isRemoved={onClose} textOne={label} />
      </div>
    );
  }

  useEffect(() => {
    modalChange(modalOpened);
  }, [modalOpened]);

  return (
    <>
      <div className="mn-modal-backdrop"></div>
      {
        <div className="smartplan-filtermodal">
          <div className="smartplan-filtermodal-header">
            <div className="smartplan-filtermodal-header-text">
              {t("smartPlan.common.filters")}
            </div>
            <div
              className="smartplan-filtermodal-header-iconclose"
              onClick={() => {
                setModalOpened(!modalOpened);
              }}
            >
              <TwentyIcCloseSmall className={undefined}></TwentyIcCloseSmall>
            </div>
          </div>
          <div className="smartplan-filtermodal-content">
            <div className="wellness-filtermodal-content-dropdown">
              <div className="smartplan-filtermodal-text">
                {t("wellness.nutritional.filters.goaltoReach")}
              </div>
              <Select
                suffixIcon={
                  typeSelected.length === 0 && (
                    <TwentyIcArrowDown className={undefined} color="#36b39e" />
                  )
                }
                value={typeSelected}
                tagRender={tagRender}
                placeholder={t("smartPlan.filterModal.select")}
                bordered={false}
                mode="multiple"
                allowClear
                style={{
                  paddingTop: "2px",
                  paddingBottom: "4px",
                  gap: "5px",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "40px",
                  height: "100%",
                  border: "1px solid #d9d9d9",
                  borderRadius: "5px",
                }}
                onChange={typeHandleChange}
                options={typeItems}
              />
            </div>

            <div className="smartPlan-filtermodal-content-buttons">
              <Button
                onClick={restoreParams}
                style={{
                  borderColor: "transparent",
                  boxShadow: "none",
                  height: "40px",
                }}
              >
                <Space>
                  <div className="smartplan-filtermodal-text">
                    {t("smartPlan.common.cancel")}
                  </div>
                </Space>
              </Button>
              <Button
                style={{
                  borderColor: "#36B39E",
                  backgroundColor: "#36B39E",
                  boxShadow: "none",
                  height: "40px",
                }}
                onClick={() => {
                  let param = paramBuilder();
                  setParam(param);
                  setModalOpened(!modalOpened);
                }}
              >
                <Space>
                  <TwentyIcTik
                    className={"smartPlan-filtermodal-content-vector-confirm"}
                  />
                  <div className="smartplan-filtermodal-text-white">
                    {t("smartPlan.common.confirm")}
                  </div>
                </Space>
              </Button>
            </div>
          </div>
          <div className="smartplan-filtermodal-footer"></div>
        </div>
      }
    </>
  );
};
