/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcDoc = ({ className, color = "#36B39E" }) => {
  return (
    <svg
      className={`twenty-ic-doc ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rect"
        height="14"
        stroke={color}
        strokeWidth="2"
        width="12"
        x="4"
        y="3"
      />
      <path
        className="path"
        d="M12 7V5.41421L13.5858 7H12Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
