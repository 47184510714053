/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcError = ({ className, color = "#36B39E" }) => {
  return (
    <svg
      className={`twenty-ic-error ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#36B39E"
    >
      <g className="g" opacity="0" />
      <path
        className="path"
        d="M10 2L17 6V14L10 18L3 14V6L10 2Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        className="circle"
        cx="10"
        cy="10"
        fill="#222729"
        r="1.5"
        stroke={color}
      />
    </svg>
  );
};
