import { format, parseISO } from "date-fns";
import { ModalBody } from "../../../ui/components/Modals/ModalBody";
import { ModalFooter } from "../../../ui/components/Modals/ModalFooter";
import { ModalWrapper } from "../../../ui/components/Modals/ModalWrapper";
import { TransactionSlice } from "../TransactionSlice/TransactionSlice";
import { it } from "date-fns/locale";
import "../TransactionDetailsModal/TransactionRechargeModal.css";
import {
  TransactionDetailsSlice,
  transactionDetailsSelector,
} from "../TransactionSlice/TransactionDetailsSlice";
import { useAppSelector } from "../../../app/hooks";

import _ from "lodash";
import { formatNumber } from "../../../utils/utils";

interface TransactionRechargeModalProps {
  onClose: (e: any) => any;
  onClick: (e: any) => any;
  transactionsDetails: TransactionSlice;
}

export const TransactionRechargeModal: React.FC<
  TransactionRechargeModalProps
> = ({ onClose, onClick, transactionsDetails }) => {
  const transactionDetails: TransactionDetailsSlice[] = useAppSelector(
    transactionDetailsSelector.selectAll
  );

  const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const convertDate = (inputDate: string) => {
    const parsedDate = parseISO(inputDate);

    const formattedDate = format(parsedDate, "EEE. dd MMM yyyy", {
      locale: it,
    }).replace(/(\b[a-z](?!\.))/g, (x) => capitalize(x));

    return formattedDate;
  };

  const convertTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");

    return `${hour}:${minute} `;
  };

  return (
    <div className="modal-wrapper-transaction-recharge-detail">
      <ModalWrapper open={true} closeAction={onClose}>
        <ModalBody title="Riepilogo ricarica">
          <div>
            <div className="summary-text">Riepilogo</div>
            <div>
              In data :{" "}
              {transactionsDetails &&
                convertDate(transactionsDetails?.date?.toString())}{" "}
              alle{" "}
              {transactionsDetails &&
                convertTimestamp(transactionsDetails?.date?.toString())}
            </div>
            <div className="summary-credit">
              <div className="container-credits">
                <div className="credit-recharge">Crediti ricaricati</div>
                <div className="credit-recharge-text">
                  {transactionsDetails?.totalCreditSent}
                </div>
              </div>
              <div className="rectangle-10" />
              <div className="container-price">
                <div className="price-recharge">Totale spesa</div>
                <div className="price-recharge-text">
                  {transactionDetails[0]?.price &&
                    formatNumber(transactionDetails[0]?.price) + "€"}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter
          primaryLabel={"Ok"}
          secondaryLabel={undefined}
          primaryAction={(e) => {
            onClick(e);
          }}
          secondaryAction={onClose}
          disablePrimaryButton={undefined}
        />
      </ModalWrapper>
    </div>
  );
};
