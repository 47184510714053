import { Spin } from "antd";
import { t } from "i18next";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { ImageConfig } from "../../../config/ImageConfig";
import HeaderMenu from "../../../layout/HeaderMenu";
import { Footer } from "../../../screens/Footer/Footer";
import WellnessActivityTailComponent from "../../../ui/components/ActivityTail/WellnessActivityTail";
import { WellnessBodyFilterBar } from "../../../ui/components/FilterBar/WellnessBodyFilterBar";
import { WellnessBodyFilterModal } from "../../../ui/components/Modals/WellnessBodyFilterModal";
import MyOrders from "../../../ui/components/MyOrders/MyOrders";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";
import { TwentyIcZen } from "../../../ui/components/icons/TwentyIcZen";
import { getQueryString } from "../../../utils/utils";
import {
  MyOrder,
  getAssociatedOrdersAsync,
  getMyOrdersAsync,
  myOrdersSelector,
  selectMyOrdersSliceStatus,
} from "../../myorders/MyOrdersSlice";
import {
  WellnessBodyView,
  WellnessBodyViewSelector,
  getWellnessBodyViewAsync,
} from "../WellnessSlice/WellnessBodyViewSlice";
import "./WellnessBody.css";

export interface QueryParams {
  [paramName: string]: any;
}

export const WellnessBody = () => {
  const navigate = useNavigate();
  const [elementSelected, setElementSelected] = useState(0);

  const myOrders: MyOrder[] = useAppSelector(myOrdersSelector.selectAll);
  const myOrdersStatus: any = useAppSelector(selectMyOrdersSliceStatus);

  const [isFiltered, setIsFiltered] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [refreshed, setRefreshed] = useState(false);

  const [modalParams, setQueryParams] = useState<QueryParams>({});
  const [stringifiedQueryParams, setStringifiedQueryParams] = useState("");
  const [prevStringifiedQueryParams, setPrevStringifiedQueryParams] =
    useState("");
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;
  const [filters, setFilters] = useState(new Map());

  const [searchKey, setSearchKey] = useState("");
  const elementsLimit = 3;

  const activityTails: WellnessBodyView[] = useAppSelector(
    WellnessBodyViewSelector.selectAll
  );

  const [activityTailsByCategories, setActivityTailsByCategories] = useState<{
    [key: string]: WellnessBodyView[];
  }>();
  const [activityTailsCategories, setActivityTailsCategories] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (searchKey) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [searchKey]);

  const refreshActivityTail = (
    params: Map<string, string[] | string>
  ): void => {
    const newQueryParams = { ...queryParams }; // Clona gli attuali query parameters

    if (params.size > 0) {
      params.forEach((value, key) => {
        // Gestisci i valori nulli o vuoti in modo appropriato
        if (
          value !== null &&
          value !== undefined &&
          (Array.isArray(value) ? value.length > 0 : value !== "")
        ) {
          newQueryParams[key] = value;
        } else {
          delete newQueryParams[key];
        }
      });
    } else {
      // Se la mappa dei parametri è vuota, azzeriamo i parametri esistenti
      Object.keys(newQueryParams).forEach((key) => {
        delete newQueryParams[key];
      });
    }
    setQueryParams(newQueryParams);
    setStringifiedQueryParams(getQueryString(newQueryParams));

    return;
  };

  function fillMyOrdersResults(): JSX.Element[] {
    var elements = [];
    if (!!myOrders) {
      for (let i = 0; i < myOrders.length; i++) {
        let temp = activityTails.find(
          (el) => el.id === myOrders[i].wellnessBodyId
        );
        elements.push(
          <React.Fragment>
            <MyOrders
              id={myOrders[i].id}
              date={myOrders[i].bookingDate}
              price={temp?.price?.toString()}
              image={temp?.image}
              wellnessBodyId={myOrders[i].wellnessBodyId}
              status={myOrders[i].status.toString()}
              titleOne={temp?.name}
              titleTwo={""}
              personsNumber={myOrders[i].numberOfPeople}
              type={"Wellness"}
              deposit={temp?.price?.toString()}
            />
          </React.Fragment>
        );
      }
      return elements;
    } else {
      return [];
    }
  }

  useEffect(() => {
    if (stringifiedQueryParams !== prevStringifiedQueryParams) {
      store.dispatch(
        getWellnessBodyViewAsync({ queryString: stringifiedQueryParams })
      );
    }
    setPrevStringifiedQueryParams(stringifiedQueryParams);
  }, [stringifiedQueryParams]);

  useEffect(() => {
    setIsFiltered(filters.size > 0);
    if (filters.get("typology") === "ZUMBA") {
      filters.set("typology", ["DANCE", "ZUMBA"]);
    }
    refreshActivityTail(filters);
  }, [filters, filters.size]);

  useEffect(() => {
    if (elementSelected === 1) {
      if (myOrders.length === 0) {
        store.dispatch(getMyOrdersAsync({ queryString: "?type=wellnessBody" }));
      }
    }
  }, [elementSelected]);

  useEffect(() => {
    if (elementSelected === 2) {
      if (myOrders.length === 0) {
        store.dispatch(
          getAssociatedOrdersAsync({ queryString: "?type=wellnessBody" })
        );
      }
    }
  }, [elementSelected]);

  useEffect(() => {
    const tempActivityTailsByCategoriesMinMax = activityTails.reduce(
      (group: any, activityTail: WellnessBodyView) => {
        const activityTailName = activityTail.typology;

        if (activityTailName) {
          group[activityTailName] = group[activityTailName] ?? [];
          group[activityTailName].push(activityTail);
        }
        return group;
      },
      {}
    );

    setActivityTailsByCategories(tempActivityTailsByCategoriesMinMax);
    setActivityTailsCategories(
      Object.keys(tempActivityTailsByCategoriesMinMax)
    );
  }, [activityTails]);

  function getCategory(type: any) {
    return type;
  }

  function fillEmptyActivityTailsResults(counter: number): JSX.Element[] {
    var elements = [];
    for (var i = 0; i < elementsLimit - counter; i++) {
      elements.push(
        <div className="wellnessbody-activitytail-transparent-container">
          <WellnessActivityTailComponent
            activityTailType="BODY"
            id={undefined}
            img={undefined}
            title={undefined}
            typology={undefined}
            navigation={() => {}}
            arrow={false}
          />
        </div>
      );
    }
    return elements;
  }

  function fillActivityTailsResults(): JSX.Element[] {
    var elements = [];
    var filtered = activityTails.filter(
      (x: WellnessBodyView) =>
        x?.city
          ?.toString()
          .toLocaleLowerCase()
          .includes(searchKey?.toLocaleLowerCase()) ||
        x?.name
          ?.toString()
          .toLocaleLowerCase()
          .includes(searchKey?.toLocaleLowerCase()) ||
        x?.typology
          ?.toLocaleLowerCase()
          .includes(searchKey?.toLocaleLowerCase())
    );
    if (filtered.length > 0) {
      for (let i = 0; i < Math.ceil(filtered.length / 3); i++) {
        var children = [];
        var columnCounter = 0;
        for (
          let j = i * elementsLimit;
          j < filtered.length && columnCounter < elementsLimit;
          j++
        ) {
          children.push(
            <React.Fragment>
              <WellnessActivityTailComponent
                activityTailType="BODY"
                id={filtered[j].id}
                img={
                  filtered[j].image
                    ? filtered[j].image
                    : ImageConfig.BACKGROUND_DEFAULT_SERVICE
                }
                mode={filtered[j].remotelyOrPresenceEnum}
                title={filtered[j].name}
                typology={filtered[j].typology}
                isIndividual={filtered[j].isIndividual}
                navigation={(e) => navigate("/Wellness/Body/" + e + "/details")}
                arrow={true}
              />
            </React.Fragment>
          );
          columnCounter++;
        }
        children.push(fillEmptyActivityTailsResults(columnCounter));
        elements.push(
          <div className="wellnessbody-activitytail-container-row">
            {children}
          </div>
        );
      }
    } else {
      elements.push(
        <React.Fragment>
          <div className="smartplan-myorderempty-container">
            <div className="smartplan-myorderempty-element">
              <TwentyIcZen
                size="40"
                color="#222729"
                className={"smartplan-myorderempty-icon"}
              />
              <div className="smartplan-myorderempty-firsttext">
                {t("wellness.noServicesAvabileFirstText")}
              </div>
              <div className="smartplan-myorderempty-secondtext">
                {t("wellness.noServicesAvabileSecondText")}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return elements;
  }

  return (
    <>
      <HeaderMenu>
        <TopBar
          title={t("wellness.body.bodyService")}
          hasEdit={false}
          hasDate={false}
          breadcrumbText={
            t("wellness.wellness") + " > " + t("wellness.body.bodyService")
          }
          icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
          onClick={() => {
            navigate("/Wellness");
          }}
        />
      </HeaderMenu>
      <div className="wellnessbody-content">
        <WellnessBodyFilterBar
          setSwitchSelected={(value) => setElementSelected(value)}
          setModalOpened={(e) => {
            elementSelected === 0 && setModalOpened(e);
          }}
          filters={filters}
          searched={(e) => {
            setSearchKey(e);
          }}
          setFilters={(e) => {
            setFilters(e);
            setRefreshed(!refreshed);
          }}
          switchStatus={elementSelected}
        />
        {elementSelected === 0 && isFiltered && <div className="divider" />}
        {(searchKey || filters.size > 0) && elementSelected === 0 && (
          <div className="wellnessbody-activitytail-results-bar">
            <div className="wellnessbody-activitytail-results-title">
              <div className="wellnessbody-activitytail-results-title-one">
                {t("smartPlan.common.results")}
              </div>
              <div className="wellnessbody-activitytail-results-title-two">
                (
                {
                  activityTails.filter(
                    (x: WellnessBodyView) =>
                      x.city
                        ?.toString()
                        .toLocaleLowerCase()
                        .includes(searchKey?.toLocaleLowerCase()) ||
                      x.name
                        ?.toString()
                        .toLocaleLowerCase()
                        .includes(searchKey?.toLocaleLowerCase()) ||
                      x.typology
                        ?.toLocaleLowerCase()
                        .includes(searchKey?.toLocaleLowerCase())
                  ).length
                }
                )
              </div>
            </div>
          </div>
        )}
        {elementSelected === 0 &&
          (!isFiltered && filters.size === 0 ? (
            <div className="wellnessbody-activitytail-container">
              {activityTailsCategories && activityTailsCategories.length > 0 ? (
                activityTailsCategories.map((activityTailCategory) => {
                  return (
                    <React.Fragment>
                      {}
                      <div className="wellnessbody-activitytail-container-title">
                        {activityTailsByCategories &&
                        activityTailsByCategories[activityTailCategory].filter(
                          (x: WellnessBodyView) =>
                            x.city
                              ?.toString()
                              .toLocaleLowerCase()
                              .includes(searchKey?.toLocaleLowerCase()) ||
                            x.name
                              ?.toString()
                              .toLocaleLowerCase()
                              .includes(searchKey?.toLocaleLowerCase()) ||
                            x.typology
                              ?.toLocaleLowerCase()
                              .includes(searchKey?.toLocaleLowerCase())
                        ).length
                          ? activityTailCategory
                          : null}
                      </div>
                      <div className="wellnessbody-activitytail-container-row">
                        {activityTailsByCategories &&
                          !_.isEmpty(activityTailsByCategories) &&
                          activityTailsByCategories[activityTailCategory]
                            .filter(
                              (x: WellnessBodyView) =>
                                x.city
                                  ?.toString()
                                  .toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase()) ||
                                x.name
                                  ?.toString()
                                  .toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase()) ||
                                x.typology
                                  ?.toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase())
                            )
                            .filter(
                              (activityTail, index) => index < elementsLimit
                            )
                            .map((activityTail) => {
                              return (
                                <React.Fragment>
                                  <WellnessActivityTailComponent
                                    activityTailType="BODY"
                                    id={activityTail.id}
                                    img={
                                      activityTail.image
                                        ? activityTail.image
                                        : ImageConfig.BACKGROUND_DEFAULT_SERVICE
                                    }
                                    mode={activityTail.remotelyOrPresenceEnum}
                                    title={activityTail.name}
                                    typology={activityTail.typology}
                                    isIndividual={activityTail.isIndividual}
                                    navigation={(e) =>
                                      navigate(
                                        "/Wellness/Body/" + e + "/details"
                                      )
                                    }
                                    arrow={true}
                                  />
                                </React.Fragment>
                              );
                            })}
                        {activityTailsByCategories &&
                          fillEmptyActivityTailsResults(
                            activityTailsByCategories[
                              activityTailCategory
                            ].filter(
                              (x: WellnessBodyView) =>
                                x.city
                                  ?.toString()
                                  .toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase()) ||
                                x.name
                                  ?.toString()
                                  .toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase()) ||
                                x.typology
                                  ?.toLocaleLowerCase()
                                  .includes(searchKey?.toLocaleLowerCase())
                            ).length
                          )}
                        {activityTails
                          .filter(
                            (x: WellnessBodyView) =>
                              x.city
                                ?.toString()
                                .toLocaleLowerCase()
                                .includes(searchKey?.toLocaleLowerCase()) ||
                              x.name
                                ?.toString()
                                .toLocaleLowerCase()
                                .includes(searchKey?.toLocaleLowerCase()) ||
                              x.typology
                                ?.toLocaleLowerCase()
                                .includes(searchKey?.toLocaleLowerCase())
                          )
                          .filter(
                            (activityTail) =>
                              getCategory(activityTail.typology) ===
                              activityTailCategory
                          ).length -
                          elementsLimit >
                          0 && (
                          <div
                            className="wellnessbody-activitytail-container-row-button"
                            onClick={() => {
                              filters.set(
                                "typology",
                                activityTails
                                  .filter(
                                    (activityTail) =>
                                      getCategory(activityTail.typology) ===
                                      activityTailCategory
                                  )
                                  .at(0)?.typology
                              );
                              setRefreshed(!refreshed);
                            }}
                          >
                            +
                            {activityTails.filter(
                              (activityTail) =>
                                getCategory(activityTail.typology) ===
                                activityTailCategory
                            ).length - elementsLimit}
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  );
                })
              ) : (
                <React.Fragment>
                  <div className="smartplan-myorderempty-container">
                    <div className="smartplan-myorderempty-element">
                      <TwentyIcZen
                        size="40"
                        color="#222729"
                        className={"smartplan-myorderempty-icon"}
                      />
                      <div className="smartplan-myorderempty-firsttext">
                        {t("wellness.noServicesAvabileFirstText")}
                      </div>
                      <div className="smartplan-myorderempty-secondtext">
                        {t("wellness.noServicesAvabileSecondText")}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          ) : (
            fillActivityTailsResults()
          ))}
        {elementSelected === 1 && (
          <>
            <div className="smartplan-myorder-container">
              {myOrders.length > 0 ? (
                fillMyOrdersResults()
              ) : myOrdersStatus === "loading" ? (
                <>
                  <Spin size="large" />
                </>
              ) : (
                <div className="smartplan-myorderempty-container">
                  <div className="smartplan-myorderempty-element">
                    <TwentyIcZen
                      size="40"
                      color="#222729"
                      className={"smartplan-myorderempty-icon"}
                    />
                    <div className="smartplan-myorderempty-firsttext">
                      {t("wellness.noServicesAvabileFirstText")}
                    </div>
                    <div className="smartplan-myorderempty-secondtext">
                      {t("wellness.noServicesBuyed")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {elementSelected === 2 && (
          <>
            <div className="smartplan-myorder-container">
              {myOrders.length > 0 ? (
                fillMyOrdersResults()
              ) : myOrdersStatus === "loading" ? (
                <>
                  <Spin size="large" />
                </>
              ) : (
                <div className="smartplan-myorderempty-container">
                  <div className="smartplan-myorderempty-element">
                    <TwentyIcZen
                      size="40"
                      color="#222729"
                      className={"smartplan-myorderempty-icon"}
                    />
                    <div className="smartplan-myorderempty-firsttext">
                      {t("wellness.noServicesAvabileFirstText")}
                    </div>
                    <div className="smartplan-myorderempty-secondtext">
                      {t("wellness.noServicesBuyed")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {modalOpened && elementSelected === 0 && (
        <WellnessBodyFilterModal
          modalChange={(value) => {
            setModalOpened(value);
          }}
          setParam={(e) => {
            setFilters(e);
          }}
          isModalOpened={modalOpened}
          queryParams={modalParams}
        />
      )}
      <Footer />
    </>
  );
};
