//#region Type

import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import VirtualCoworkingRepository from "./VirtualCoworkingRepository";
import { Config } from "../../config/Config";
import _ from "lodash";
import { RootState } from "../../app/store";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";

export interface VirtualCoworkingSlice {
  id: number;
  link: string;
  linkMeta: string;
}

//#endRegion Type

//#region API

export const getLinkGatherAsync = createAsyncThunk(
  "virtualCoworking/getLinkGather",
  async (tenantId: number) => {
    const virtualCoworkingRepository = new VirtualCoworkingRepository();
    const response = await virtualCoworkingRepository.getLinkGather(tenantId);
    const virtualCoworking = _.get(response, Config.VIRTUAL_COWORKING_RESPONSE);
    return virtualCoworking;
  }
);

//#endRegion API

//#region Slice

const virtualCoworkingAdapter = createEntityAdapter<VirtualCoworkingSlice>({
  selectId: (virtualCoworking) => virtualCoworking.id,
});

export const virtualCoworkingSlice = createSlice({
  name: "virtualCoworking",
  initialState: virtualCoworkingAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    virtualCoworkingEmptyState: (state) => {
      virtualCoworkingAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getLinkGatherAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          virtualCoworkingAdapter.setOne(state, action.payload);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getLinkGatherAsync.pending, (state: any) => {
        state.statys = "loading";
        state.reasonCode = "";
      })
      .addCase(getLinkGatherAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const virtualCoworkingSelector =
  virtualCoworkingAdapter.getSelectors<RootState>(
    (state) => state.virtualCoworking
  );

export const { virtualCoworkingEmptyState } = virtualCoworkingSlice.actions;
export const selectVirtualCoworkingSliceStatus = (state: any) =>
  state.virtualCoworking.status;
export const selectVirtualCoworkingeReasonCode = (state: any) =>
  state.virtualCoworking.reasonCode;
//#endRegion Status

export default virtualCoworkingSlice.reducer;
