export class EverywhereErrorCodes {
  //#region
  static INTERNAL_SERVER_ERROR = "500";
  //#endregion
  // AUTHENTICATION FEATURE ERRORS
  static NEW_PASSWORD_CONFLICT = "E0";
  static CODE_NOT_FOUND = "E1";
  static INVALID_PASSWORD = "E2";
  static UNABLE_REFRESH_TOKEN = "E3";
  static EMPTY_EMAIL = "E4";
  static EMPTY_PASSWORD = "E5";

  // USERS FEATURE ERRORS
  static USER_INFO_NOT_FOUND = "E10";

  static USERNAME_NOT_FOUND = "E10";
  static USER_UNAUTHORIZED = "E11";
  static USER_DISABLED = "E12";
  static USER_EXPIRED = "E13";
  static USERNAME_ALREADY_EXISTS = "E14";
  static EMAIL_ALREADY_EXISTS = "E15";
  static USER_ALREADY_CREATED = "E16";
  static EMAIL_NOT_ACCEPTABLE = "E17";

  // PROVIDER FEATURE ERRORS
  static DUPLICATE_TITLE = "E18";
  static NON_ADMIN_USER_ACCESS_DENIED = "E19";
  static SERVICE_NOT_FOUND = "E21";
  static ACCOUNT_NOT_FOUND = "E22";

  // S3 ERRORS
  static FILE_NOT_UPLOAD = "E23";

  // BOOKING FEATURE ERRORS
  static BOOKING_NOT_FOUND = "E30";
  static STRIPE_ERROR = "E31";
  static STRIPE_EXCEPTION_ERROR = "E32";

  // CONVERSION RATE ERRORS
  static CONVERSION_RATE_NOT_FOUND = "E40";

  // MEETINGS FEATURE ERRORS
  static MEETING_NOT_FOUND = "E50";
  static INVALID_MEETING_CODE = "E51";
}
