/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import { TwentyIcHelp } from "../icons/TwentyIcHelp";
import { LeftText } from "../LeftText";
import { Required } from "../Required";
import "./style.css";

export const Label = ({
  rightOptions = true,
  help = true,
  label = true,
  request = true,
  property1,
  className,
  text = "Label",
}) => {
  return (
    <div className={`label ${className}`}>
      {request && (
        <div className="required-wrapper">
          <Required
            className="required-instance"
            ellipseClassName="design-component-instance-node"
          />
        </div>
      )}

      <div className="frame-3">
        {label && <div className="text-wrapper-2">{text}</div>}

        {help && <TwentyIcHelp className="element-ic-help" color="#36B39E" />}
      </div>
      {rightOptions && (
        <LeftText
          className="left-text-instance"
          link="off"
          maxCarClassName="left-text-2"
          size="XS"
        />
      )}
    </div>
  );
};

Label.propTypes = {
  rightOptions: PropTypes.bool,
  help: PropTypes.bool,
  label: PropTypes.bool,
  request: PropTypes.bool,
  property1: PropTypes.oneOf(["default"]),
  text: PropTypes.string,
};
