import { useNavigate } from "react-router-dom";
import { Button } from "../../ui/components/Button/Button";
import { CheckLabel } from "../../ui/components/CheckLabel";
import { Logo } from "../../ui/components/Logo/Logo";
import "./SendEmail.css";
import AuthenticationService from "../../utils/AuthenticationService";
import { useState } from "react";
import { ElementIcTik } from "../../ui/components/ElementIcTik";
import { TwentyIcMailAdd } from "../../ui/components/icons/TwentyIcMailAdd";
import { ToastNotification } from "../../ui/components/utils/ToastNotification";
import i18next, { t } from "i18next";

export const SendEmail = () => {
  const [lastClickTime, setLastClickTime] = useState(0);
  const savedEmail = localStorage.getItem("email");
  const authenticationService = new AuthenticationService();

  const handleGenerateEmail = async (eMail: string) => {
    const currentTime = Date.now();
    if (currentTime - lastClickTime >= 60000) {
      setLastClickTime(currentTime);
      authenticationService.generateEmail(
        eMail,
        (result: any) => {},
        (error) => {
          console.log("LOGIN ERROR: " + error);
          if (error.response === undefined || error.response.status === 500) {
            ToastNotification({
              status: "error",
              description: "",
              toastId: "loginFailed",
              title: i18next.t("loginPage.toastNotification.networkError"),
            });
          }
        }
      );
    }
  };

  const navigate = useNavigate();

  return (
    <div className="sendEmail">
      <div className="sendEmail-container">
        <div className="sendEmail-logo">
          <Logo width="186px" height="28px" />
        </div>

        <div className="sendEmail-content">
          <div className="sendEmail-form-container">
            <span className="sendEmail-up-span">
              <div className="frame" style={{ alignItems: "center" }}>
                <ElementIcTik className="forty-ic-tik" />
              </div>
            </span>
            <span className="sendEmail-desc-span">
              {t("resetPassword.sendEmail.title")}
            </span>
            <span className="sendEmail-desc">
              {t("resetPassword.sendEmail.desc1")}
            </span>
            <div className="sendEmail-form">
              <div className="sendEmail-input-container">
                {false && (
                  <CheckLabel
                    disabled="off"
                    text="Ricorda le mie credenziali"
                    type="switch"
                    className={undefined}
                    switchEllipseClassName={undefined}
                  />
                )}
              </div>
              <span className="not-mail">
                {t("resetPassword.sendEmail.desc2")}
              </span>
            </div>
            <div className="sendEmail-button-wrapper">
              <Button
                size="XL"
                disabled={undefined}
                text={t("resetPassword.sendEmail.buttonLabel")}
                type="line"
                state="default"
                icon={<TwentyIcMailAdd className={undefined} />}
                iconRight={undefined}
                onClick={() => handleGenerateEmail(savedEmail ?? "")}
              />
            </div>
          </div>

          <p className="sendEmail-login">
            <span
              className="sendEmail-text-3"
              onClick={() => navigate("/recover-password")}
            >
              {t("resetPassword.sendEmail.footer")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
