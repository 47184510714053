/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconTik = ({ className, color = "#36B39E", size = 20 }) => {
  return (
    <svg
      className={`icon-tik ${className}`}
      fill="none"
      height={size}
      viewBox="0 0 20 20"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" opacity="0" />

      <path
        className="path"
        d="M4 9.61538L6.82353 15L16 5"
        stroke={color}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
