import { Navigate, Route, Routes } from "react-router-dom";
import AccountDetails from "../feature/Account/AccountDetails";
import { Assistance } from "../feature/Assistance/Assistance";
import { CreateService } from "../feature/MyService/CreateService/CreateService";
import { MyService } from "../feature/MyService/MyService";
import { MyTeam } from "../feature/MyTeam/MyTeam";
import { SmartPlan } from "../feature/Smartplan/SmartPlan";
import { SmartPlanDetails } from "../feature/Smartplan/SmartplanDetails/SmartPlanDetails";
import { VirtualCoworking } from "../feature/VirtualCoworking/VirtualCoworking";
import { Wellness } from "../feature/Wellness/Wellness";
import { WellnessBody } from "../feature/Wellness/WellnessFeatures/WellnessBody";
import { WellnessBodyDetails } from "../feature/Wellness/WellnessFeatures/WellnessBodyDetails";
import { WellnessNutritional } from "../feature/Wellness/WellnessFeatures/WellnessNutritional";
import { WellnessNutritionalDetails } from "../feature/Wellness/WellnessFeatures/WellnessNutritionalDetails";
import { WellnessPsycho } from "../feature/Wellness/WellnessFeatures/WellnessPsycho";
import { WellnessPsychoDetails } from "../feature/Wellness/WellnessFeatures/WellnessPsychoDetails";
import { MasterClass } from "../feature/masterclass/MasterClass";
import { MasterClassDetails } from "../feature/masterclass/MasterclassDetails/MasterClassDetails";
import MasterClassOrderDetail from "../feature/myorders/MasterClassOrderDetail";
import SmartPlanOrderDetail from "../feature/myorders/SmartPlanOrderDetail";
import WellnessBodyOrderDetail from "../feature/myorders/WellnessBodyOrderDetail";
import WellnessNutritionalOrderDetail from "../feature/myorders/WellnessNutritionalOrderDetail";
import WellnessPsychoOrderDetail from "../feature/myorders/WellnessPsychoOrderDetail";
import { Home } from "../screens/Home/Home";
import { SidebarAdmin } from "../ui/components/SidebarAdmin/SidebarAdmin";
import ContainerWrapper from "./ContainerWrapper";
import GridContainer from "./GridContainer";
import PageContainer from "./PageContainer";
import "./SidebarContainer.css";
import { EditService } from "../feature/MyService/EditService/EditService";
import { PreviewService } from "../feature/MyService/PreviewService/PreviewService";
import PublicAccountFeature from "../feature/Account/PublicAccount/PublicAccount";
import { PreviewAccountDetails } from "../feature/Account/PreviewProviderAccountDetails/PreviewProviderAccountDetails";
import { ProviderAccountDetails } from "../feature/Account/ProviderAccountDetails/ProviderAccountDetails";
import { CreditManagementAdmin } from "../feature/CreditManagement/Admin/CreditManagementAdmin";
import { CreditManagementEmployee } from "../feature/CreditManagement/Employee/CreditManagementEmployee";
import { Meetings } from "../feature/Meetings/Meetings";
import { OfficeCoworking } from "../feature/OfficeCoworking/OfficeCoworking";
import { OfficeCoworkingDetails } from "../feature/OfficeCoworking/OfficeCoworkingDetails";

interface SidebarPageProps {
  logoutDone: () => void;
}

function SidebarContainer(Props: SidebarPageProps) {
  const profile = localStorage.getItem("securityProfile");

  return (
    <ContainerWrapper>
      <Routes>
        <Route
          path="/*"
          element={
            <div className="sidebar-container">
              <div className="admin-wrapper">
                <SidebarAdmin
                  frameClassName=""
                  frameClassNameOverride=""
                  className="admin-instance"
                  headerClassName="admin-2"
                  optionStyle=""
                />
              </div>
            </div>
          }
        />
      </Routes>
      <GridContainer>
        <PageContainer>
          <Routes>
            <Route path="*" element={<Navigate replace to="/home" />} />
            <Route path="home/*" element={<Home />} />
            <Route
              path="/account/:id/details/*"
              element={<AccountDetails logoutDone={Props.logoutDone} />}
            />
            <Route path="virtualCoworking/*" element={<VirtualCoworking />} />
            <Route
              path="account/:id/details/public-account"
              element={<PublicAccountFeature />}
            />
            <Route
              path="/account/:id/details/public"
              element={<ProviderAccountDetails />}
            />
            {/* START REGION ONLY ADMIN */}
            {profile === "ADMIN" || profile === "SUPER_ADMIN" ? (
              <Route path="myTeam" element={<MyTeam />} />
            ) : (
              <Route path="home/*" element={<Home />} />
            )}
            {/* END REGION ONLY ADMIN */}
            {/* START REGION ONLY ADMIN AND EMPLOYEE */}
            {profile === "ADMIN" || profile === "EMPLOYEE" ? (
              <>
                <Route path="smartPlan/*" element={<SmartPlan />} />
                <Route path="masterclass/*" element={<MasterClass />} />
                <Route
                  path="smartPlan/:id/details/*"
                  element={<SmartPlanDetails />}
                />
                <Route
                  path="smartplan/myOrders/:id"
                  element={<SmartPlanOrderDetail />}
                />
                <Route
                  path="masterclass/myOrders/:id"
                  element={<MasterClassOrderDetail />}
                />
                <Route
                  path="masterclass/:id/details/*"
                  element={<MasterClassDetails />}
                />

                <Route path="wellness/*" element={<Wellness />} />
                <Route
                  path="wellness/MyOrders/Success"
                  element={<Wellness />}
                />
                <Route path="Wellness/Body/" element={<WellnessBody />} />
                <Route
                  path="Wellness/Body/:id/details/"
                  element={<WellnessBodyDetails />}
                />
                <Route
                  path="wellness/body/myOrders/:id"
                  element={<WellnessBodyOrderDetail />}
                />
                <Route
                  path="Wellness/Nutritional/"
                  element={<WellnessNutritional />}
                />
                <Route
                  path="Wellness/Nutritional/:id/details/"
                  element={<WellnessNutritionalDetails />}
                />
                <Route
                  path="wellness/nutritional/myOrders/:id"
                  element={<WellnessNutritionalOrderDetail />}
                />
                <Route
                  path="Wellness/Psycho/:id/details/"
                  element={<WellnessPsychoDetails />}
                />
                <Route
                  path="wellness/psycho/myOrders/:id"
                  element={<WellnessPsychoOrderDetail />}
                />
                <Route
                  path="officeCoworking/:id/details"
                  element={<OfficeCoworkingDetails />}
                />
                <Route path="Wellness/Psycho/" element={<WellnessPsycho />} />
                <Route path="officeCoworking/*" element={<OfficeCoworking />} />
                <Route
                  path="credits"
                  element={
                    profile === "ADMIN" ? (
                      <CreditManagementAdmin />
                    ) : (
                      <CreditManagementEmployee />
                    )
                  }
                />
              </>
            ) : (
              <Route path="home/*" element={<Home />} />
            )}
            {/* END REGION ONLY ADMIN AND EMPLOYEE */}

            {/* START REGION ONLY PROVIDER  */}
            {profile === "PROVIDER" || profile === "SUPER_ADMIN" ? (
              <>
                <Route
                  path="/account/:id/details/public-account/previewAccountDetails"
                  element={<PreviewAccountDetails />}
                />
                <Route path="/assistence/*" element={<Assistance />} />
                <Route path="/meetings/*" element={<Meetings />} />
                <Route path="/myService/*" element={<MyService />} />
                <Route path="/myService/create" element={<CreateService />} />
                <Route path="/myService/:id/edit" element={<EditService />} />
                <Route
                  path="/myService/previewService/:id"
                  element={<PreviewService />}
                />
              </>
            ) : (
              <Route path="home/*" element={<Home />} />
            )}
            {/* END REGION ONLY PROVIDER  */}
          </Routes>
        </PageContainer>
      </GridContainer>
    </ContainerWrapper>
  );
}

export default SidebarContainer;
