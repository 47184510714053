import { IconCalendario } from "../icons/IconCalendario";
import { TwentyIcArrowLineRight } from "../icons/TwentyIcArrowLineRight";
import { useTranslation } from "react-i18next";
import "./SmartPlanActivityTail.css";
import { useNavigate } from "react-router-dom";
export interface SmartPlanActivityTailProps {
  id: number | undefined;
  img: any;
  startDate: Date;
  endDate: Date;
  city: any;
  region: any;
  previewDescription: any;
  minPeople: number;
  maxPeople: number;
  placesLeft: any;
  isFavorite?: boolean;
  arrow?: boolean;
  reactionButton?: (e: any) => any;
}

const SmartPlanActivityTailComponent: React.FC<SmartPlanActivityTailProps> = ({
  id,
  img,
  startDate,
  endDate,
  city,
  region,
  previewDescription,
  arrow,
  minPeople,
  maxPeople,
  placesLeft,
  isFavorite,
  reactionButton,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const months = [
    t("timeFormats.monthsFirstThree.January"),
    t("timeFormats.monthsFirstThree.February"),
    t("timeFormats.monthsFirstThree.March"),
    t("timeFormats.monthsFirstThree.April"),
    t("timeFormats.monthsFirstThree.May"),
    t("timeFormats.monthsFirstThree.June"),
    t("timeFormats.monthsFirstThree.July"),
    t("timeFormats.monthsFirstThree.August"),
    t("timeFormats.monthsFirstThree.September"),
    t("timeFormats.monthsFirstThree.October"),
    t("timeFormats.monthsFirstThree.November"),
    t("timeFormats.monthsFirstThree.December"),
  ];

  const startDateNotFormatted = new Date(startDate);
  const endDateNotFormatted = new Date(endDate);

  const startDay = startDateNotFormatted.getDate();
  const startMonth = startDateNotFormatted.getMonth() + 1;
  const startYear = startDateNotFormatted.getFullYear();

  const endDay = endDateNotFormatted.getDate();
  const endMonth = endDateNotFormatted.getMonth() + 1;
  const endYear = endDateNotFormatted.getFullYear();

  function compareDates() {
    const isSameDay = startDay === endDay;
    const isSameMonth = startMonth === endMonth;
    const isSameYear = startYear === endYear;

    if (isSameDay && isSameMonth && isSameYear) {
      return endDay + " " + endMonth + " " + endYear;
    } else if (!isSameDay && isSameMonth && isSameYear) {
      return (
        t("smartPlan.components.activityTail.fromDate") +
        " " +
        startDay +
        " " +
        t("smartPlan.components.activityTail.toDate") +
        " " +
        endDay +
        " " +
        months[endMonth - 1] +
        " " +
        endYear
      );
    } else if (!isSameYear) {
      return (
        t("smartPlan.components.activityTail.fromDate") +
        startDay +
        " " +
        startMonth +
        " " +
        startYear +
        t("smartPlan.components.activityTail.toDate") +
        endDay +
        " " +
        months[endMonth - 1] +
        " " +
        endYear
      );
    } else if (!isSameMonth && isSameYear) {
      return (
        t("smartPlan.components.activityTail.fromDate") +
        " " +
        startDay +
        " " +
        months[startMonth - 1] +
        " " +
        t("smartPlan.components.activityTail.toDate") +
        " " +
        endDay +
        " " +
        months[endMonth - 1] +
        " " +
        endYear
      );
    } else {
      return null;
    }
  }

  return (
    <>
      <div className="activity-tail__wrapper">
        <span className="activity-tail__image">
          <img className="activity-tail__container" alt="Rectangle" src={img} />
          {/* {isMostPopular && (
            <span className="activity-tail__most-popular">
              <div className="activity-tail__most-popular-text">
                {t("smartPlan.components.activityTail.mostPopular")}
              </div>
            </span>
          )} */}
          {/* <img
            className="activity-tail__frame"
            alt="Frame"
            src={!isFavorite ? favouritesImg : favouritesImgLiked}
            onClick={reactionButton}
          /> */}
        </span>
        <div className="activity-tail__frame-wrapper">
          <div className="activity-tail__sections">
            <div className="activity-tail__first-section">
              <div className="activity-tail__first-section-wrapper">
                {/* {firstTitleIsADate ? (
                  <IconCalendario color="#86D1C5" className="" />
                ) : (
                  <TwentyIcPinMap color="#86D1C5" className="" />
                )} */}
              </div>
              <div className="activity-tail__alert-title"></div>
            </div>
            <div className="activity-tail__second-section">
              <div className="activity-tail__second-section-wrapper">
                <div className="activity-tail__second-title">
                  {city} {region && <div className="name-plate-pointer" />}{" "}
                  {region}
                </div>
                <div className="activity-tail__third-title">
                  {previewDescription}
                </div>
              </div>
              {arrow && (
                <div className="activity-tail____twentyicarrowlineright ">
                  <TwentyIcArrowLineRight
                    color="#36B39E"
                    className="activity-tail____wentyicarrowlineright"
                    onClick={() => {
                      navigate("/smartPlan/" + id + "/details");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartPlanActivityTailComponent;
