import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import WellnessRepository from "../WellnessRepository";
import _ from "lodash";
import { Config } from "../../../config/Config";
import { EverywhereSuccessCodes } from "../../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../../config/EverywhereErrorCodes";
import { RootState } from "../../../app/store";
import { setNumberOfElements } from "../../MyTeam/TeamUsersInfoSlice";

export type WellNessTableSlice = {
  id: number;
  serviceType: string;
  name: string;
  insertionDate: Date;
  remotelyOrPresenceEnum: string;
  image: string;
  approved: boolean;
  active: boolean;
  published: boolean;
  price: number;
  providerName: string;
  providerId: number;
  tenantId: number;
};

export const getWellnessTableAsync = createAsyncThunk(
  "wellnessTable/getWellnessTable",
  async (data, thunkAPI) => {
    const wellnessRepository = new WellnessRepository();
    const response = await wellnessRepository.getWellnessTable();
    const wellnessTable = _.get(response, Config.WELLNESS_TABLE_RESPONSE);
    if (response.data) {
      thunkAPI.dispatch(setNumberOfElements(response.data.length));
    }
    if (wellnessTable) {
      return wellnessTable;
    } else {
      return [];
    }
  }
);

const wellnessTableAdapter = createEntityAdapter<WellNessTableSlice>({
  selectId: (wellnessTable) => wellnessTable.name,
});

export const wellnessTableSlice = createSlice({
  name: "wellnessTable",
  initialState: wellnessTableAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    wellnessTableEmptyState: (state) => {
      wellnessTableAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getWellnessTableAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          wellnessTableAdapter.setAll(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWellnessTableAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getWellnessTableAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

export const wellnessTableSelector =
  wellnessTableAdapter.getSelectors<RootState>((state) => state.wellnessTable);

export const { wellnessTableEmptyState } = wellnessTableSlice.actions;
export const selectWellnessTableSliceReasonCOde = (state: any) =>
  state.wellnessTable.reasonCode;

export const selectWellnessTableSliceStatus = (state: any) =>
  state?.wellnessTable?.status;

export default wellnessTableSlice.reducer;
