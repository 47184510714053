//#region Type

import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import TenantRepository from "./TenantRepository";
import _ from "lodash";
import { Config } from "../../config/Config";
import { RootState } from "../../app/store";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";

export interface Tenant {
  id: number;
  enabled: boolean | null;
  name: string;
  businessName: string;
  email: string;
  address: string;
  city: string;
  province: string;
  country: string;
  zip: string;
  prefix: string;
  telephone: string;
  vatNumber: string;
}

//#endRegion API

//#region Slice

export const getTenantAsync = createAsyncThunk(
  "tenant/getTenant",
  async (tenantId: any) => {
    const tenantRepository = new TenantRepository();
    const response = await tenantRepository.getTenant(tenantId);
    const tenant = _.get(response, Config.TENANT_RESPONSE);
    return tenant;
  }
);

export const updateTenantAsync = createAsyncThunk(
  "tenant/updateTenant",
  async (tenant: Tenant) => {
    const tenantRepository = new TenantRepository();
    const response = await tenantRepository.updateTenant(tenant);
    const newTenant = _.get(response, Config.TENANT_RESPONSE);
    return newTenant;
  }
);

//#endRegion API

//#region Slice

const tenantAdapter = createEntityAdapter<Tenant>({
  selectId: (tenant) => tenant.id,
});

export const tenantSlice = createSlice({
  name: "tenant",
  initialState: tenantAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    tenantEmptyState: (state) => {
      tenantAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getTenantAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          tenantAdapter.upsertOne(state, action.payload);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getTenantAsync.pending, (state: any) => {
        state.statys = "loading";
        state.reasonCode = "";
      })
      .addCase(getTenantAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        updateTenantAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          tenantAdapter.upsertOne(state, action.payload);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(updateTenantAsync.pending, (state: any) => {
        state.statys = "loading";
        state.reasonCode = "";
      })
      .addCase(updateTenantAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const tenantSelector = tenantAdapter.getSelectors<RootState>(
  (state) => state.tenant
);

export const { tenantEmptyState } = tenantSlice.actions;
export const selectTenantSliceStatus = (state: any) => state.tenant.status;
export const selectTenantSliceReasonCode = (state: any) =>
  state.tenant.reasonCode;

//#endRegion Status

export default tenantSlice.reducer;
