//#region Type

import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import SmartPlanRepository from "./SmartPlanRepository";
import { Config } from "../../config/Config";
import _ from "lodash";
import { RootState } from "../../app/store";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";

export interface SmartPlan {
  id: number;
  insertionDate: Date;
  startDate: Date;
  endDate: Date;
  city: string;
  region: string;
  minPeople: number;
  maxPeople: number;
  includedServices: number[];
  price: number;
  gallery: number[];
  previewDescription: string;
  description: string;
  faqs: number[];
  isFavorite: boolean;
  image: string;
  duration: number;
  placesLeft: number;
  landform: TypeOfLandformValues;
  location: TypeOfLocationValues;
  inPromotion: boolean;
  activities: number[];
  smartDatePlan: number[];
}

export const typeOfLocationValues = {
  NORD: "NORD",
  SUD: "SUD",
  CENTRO: "CENTRO",
  ISOLE: "ISOLE",
};

export const typeOfLandformValues = {
  MOUNTAIN: "MOUNTAIN",
  SEA: "SEA",
  HILL: "HILL",
  LAKES: "LAKES",
  PARKS: "PARKS",
  CITY: "CITY",
};

export type TypeOfLocationValues = keyof typeof typeOfLocationValues;
export type TypeOfLandformValues = keyof typeof typeOfLandformValues;
//#endRegion Type

//#region API

export const getSmartPlanAsync = createAsyncThunk(
  "smartPlan/getSmartPlan",
  async (smartPlanId: number) => {
    const smartPlanRepository = new SmartPlanRepository();
    const response = await smartPlanRepository.getSmartPlan(smartPlanId);
    const smartPlan = _.get(response, Config.SMART_PLAN_RESPONSE);
    return smartPlan;
  }
);

//#endRegion API

//#region Slice
const smartPlanAdapter = createEntityAdapter<SmartPlan>({
  selectId: (smartPlan) => smartPlan.id,
});

export const smartPlanSlice = createSlice({
  name: "smartPlan",
  initialState: smartPlanAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    smartPlanEmptyState: (state) => {
      smartPlanAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getSmartPlanAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          smartPlanAdapter.upsertOne(state, action.payload);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getSmartPlanAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getSmartPlanAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const smartPlanSelector = smartPlanAdapter.getSelectors<RootState>(
  (state) => state.smartPlan
);

export const { smartPlanEmptyState } = smartPlanSlice.actions;
export const selectSmartPlanSliceStatus = (state: any) =>
  state.smartPlan.status;
export const selectSmartPlanSliceReasonCode = (state: any) =>
  state.smartPlan.reasonCode;

//#endRegion Status

export default smartPlanSlice.reducer;
