import "../../../feature/Smartplan/SmartplanDetails/SmartPlanDetails.css";
import React, { useEffect, useState } from "react";
import { Button } from "../Button/Button";
import { t, use } from "i18next";
import { IconLink } from "../icons/IconLink";
import { TwentyIcPhone } from "../icons/TwentyIcPhone";
import { IconMail } from "../icons/IconMail";

interface PublicAccountSummaryComponentProps {
  telephone?: string;
  email?: string;
  disabled?: boolean;
}

export const PublicAccountSummaryComponent: React.FC<
  PublicAccountSummaryComponentProps
> = ({ telephone, email, disabled }) => {
  const [disabledButton, setDisabledButton] = useState<boolean>();

  useEffect(() => {
    setDisabledButton(disabled);
  }, [disabled]);

  return (
    <div className="frame-17">
      <div className="text-wrapper-11">Contatti</div>
      <div className="rectangle-9" />

      {telephone && (
        <div className="frame-22-summary">
          <div className="text-wrapper-14">{t("wellness.telephone")}</div>
          <div className="frame-20">
            <TwentyIcPhone color="#36B39E" className="icon-instance-node-3" />
            <div className="text-wrapper-15">{telephone}</div>
          </div>
        </div>
      )}
      {email && (
        <div className="frame-22-summary">
          <div className="text-wrapper-14">{t("wellness.email")}</div>
          <div
            className="frame-20"
            style={{
              color: "var(--dark-500)",
              fontFamily: "Red Hat Display , Helvetica",
              fontSize: "16px",
              fontWeight: "800",
              letterSpacing: "0",
              lineHeight: "normal",
              marginTop: "-1px",
              position: "relative",
              width: " fit-content",
              paddingBottom: "10px",
            }}
          >
            <IconMail className="icon-instance-node-3" color="#36B39E" />
            <div>{email}</div>
          </div>
        </div>
      )}
    </div>
  );
};
