import HttpClient from "../../../utils/HttpClient";
import { PublicAccount } from "./PublicAccountSlice";

export default class PublicAccountRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getPublicAccounts() {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get("tenants/" + tenantId + "/accountDetail");
  }

  public getPublicAccount(accountDetailId: number) {
    return this.instance.get("/accountDetail/" + accountDetailId);
  }

  public getPublicAccountView(queryString?: string, providerType?: any) {
    const params = {
      providerType: providerType,
    };

    return this.instance.get("/accountDetails" + (queryString ?? ""), {
      params,
    });
  }

  public createPublicAccount(publicAccount: PublicAccount) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.post(
      "tenants/" + tenantId + "/accountDetail",
      publicAccount
    );
  }

  public partiallyUpdatePublicAccount(
    id: number,
    publicAccount: PublicAccount
  ) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.patch(
      "tenants/" + tenantId + "/accountDetail/" + id,
      publicAccount
    );
  }

  public getPublicAccountByTenantIdAndUserIdAsync(
    tenantId: number,
    userId: number
  ) {
    return this.instance.get(
      "/tenants/" + tenantId + "/accountDetail/" + userId
    );
  }
}
