export const TwentyIcTicket = ({ className, color = "grey", size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7247 3.82523L14.6909 4.08324L14.8397 3.52597L14.4318 3.11812L13.7247 3.82523ZM11.4142 1.51471L12.1213 0.807603L11.4142 0.100496L10.7071 0.807603L11.4142 1.51471ZM16.1748 6.27526L16.8819 5.56815L16.474 5.1603L15.9168 5.30912L16.1748 6.27526ZM18.4853 8.58578L19.1924 9.29288L19.8995 8.58578L19.1924 7.87867L18.4853 8.58578ZM8.58579 18.4853L7.87868 19.1924L8.58579 19.8995L9.29289 19.1924L8.58579 18.4853ZM6.27527 16.1748L5.30913 15.9167L5.16031 16.474L5.56816 16.8819L6.27527 16.1748ZM3.82524 13.7247L3.11813 14.4318L3.52598 14.8397L4.08325 14.6909L3.82524 13.7247ZM1.51472 11.4142L0.807614 10.7071L0.100507 11.4142L0.807614 12.1213L1.51472 11.4142ZM14.4318 3.11812L12.1213 0.807603L10.7071 2.22182L13.0176 4.53233L14.4318 3.11812ZM14.9497 5.05024C14.6889 4.7894 14.601 4.41976 14.6909 4.08324L12.7586 3.56722C12.4921 4.56516 12.7495 5.67838 13.5355 6.46446L14.9497 5.05024ZM15.9168 5.30912C15.5802 5.39899 15.2106 5.31108 14.9497 5.05024L13.5355 6.46446C14.3216 7.25054 15.4348 7.5079 16.4328 7.2414L15.9168 5.30912ZM19.1924 7.87867L16.8819 5.56815L15.4677 6.98237L17.7782 9.29288L19.1924 7.87867ZM9.29289 19.1924L19.1924 9.29288L17.7782 7.87867L7.87868 17.7782L9.29289 19.1924ZM5.56816 16.8819L7.87868 19.1924L9.29289 17.7782L6.98238 15.4676L5.56816 16.8819ZM5.05025 14.9497C5.31109 15.2106 5.399 15.5802 5.30913 15.9167L7.24141 16.4328C7.50791 15.4348 7.25055 14.3216 6.46447 13.5355L5.05025 14.9497ZM4.08325 14.6909C4.41978 14.601 4.78942 14.6889 5.05025 14.9497L6.46447 13.5355C5.67839 12.7494 4.56517 12.4921 3.56723 12.7586L4.08325 14.6909ZM0.807614 12.1213L3.11813 14.4318L4.53234 13.0176L2.22183 10.7071L0.807614 12.1213ZM10.7071 0.807603L0.807614 10.7071L2.22183 12.1213L12.1213 2.22182L10.7071 0.807603Z"
        fill={color}
      />
    </svg>
  );
};
