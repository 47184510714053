/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcLockNewPassword = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-lock-new-password ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8 10.5H2V17.5H15"
        stroke={color}
        strokeWidth="2"
      />
      <path
        className="path"
        d="M5 10.5V4.5C5 2.84315 6.34315 1.5 8 1.5V1.5C9.65685 1.5 11 2.84315 11 4.5V6"
        stroke={color}
        strokeWidth="2"
      />
      <path className="path" d="M19 11H11" stroke={color} strokeWidth="2" />
      <path className="path" d="M15 7L15 15" stroke={color} strokeWidth="2" />
    </svg>
  );
};
