import React from "react";
import { Button } from "../Button/Button";
import { TwentyIcArrowLineLeft } from "../icons/TwentyIcArrowLineLeft";
import { TwentyIcEdit } from "../icons/TwentyIcEdit";
import "./TopBar.css";
import { t } from "i18next";

export const TopBar = ({
  icon,
  hasDate,
  hasEdit,
  title,
  breadcrumbText,
  onClick,
}) => {
  const date = new Date().toString();

  const nameMonth = [
    t("month.january"),
    t("month.february"),
    t("month.march"),
    t("month.april"),
    t("month.may"),
    t("month.june"),
    t("month.july"),
    t("month.august"),
    t("month.september"),
    t("month.october"),
    t("month.november"),
    t("month.december"),
  ];

  // Funzione per ottenere il nome del mese
  function getNameMonth(mese) {
    return nameMonth[mese];
  }

  // Funzione per analizzare la stringa di data e generare il formato richiesto
  function formattaData(dataString) {
    const data = new Date(dataString);

    const day = data.getDate();
    const month = data.getMonth();
    const year = data.getFullYear();

    const nomeMese = getNameMonth(month);

    return `${day} ${nomeMese} ${year}`;
  }

  return (
    <div className="top-bar">
      <div className="top-bar-left-container">
        {breadcrumbText && (
          <div className="top-bar-breadcrumb" onClick={onClick}>
            {breadcrumbText}
          </div>
        )}
        <div className="top-bar-icon-title-container">
          {breadcrumbText ? (
            <div className="top-bar-icon-breadcrumb">
              <div className="botton-go-back">
                <Button
                  icon={<TwentyIcArrowLineLeft color="#000" />}
                  className="button-instance"
                  size="m"
                  style={{ iconLine: true }}
                  onClick={onClick}
                />
              </div>
            </div>
          ) : (
            icon && (
              <div className="top-bar-icon">{React.cloneElement(icon)}</div>
            )
          )}
          <div className="top-bar-title">{title}</div>
          {hasEdit && <TwentyIcEdit color="#000" />}
        </div>
      </div>
      {hasDate && <div className="top-bar-date">{formattaData(date)}</div>}
    </div>
  );
};
