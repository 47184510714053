import { useNavigate } from "react-router-dom";
import { Button } from "../../ui/components/Button/Button";
import { CheckLabel } from "../../ui/components/CheckLabel";
import { Logo } from "../../ui/components/Logo/Logo";
import "./PasswordChanged.css";
import { ElementIcTik } from "../../ui/components/ElementIcTik";
import { TwentyIcArrowLineLeft1 } from "../../ui/components/TwentyIcArrowLineLeft1";
import { t } from "i18next";

export const PasswordChanged = () => {
  const navigate = useNavigate();

  return (
    <div className="passwordChanged">
      <div className="passwordChanged-container">
        <div className="passwordChanged-logo">
          <Logo width="186px" height="28px" />
        </div>

        <div className="passwordChanged-content">
          <div className="passwordChanged-form-container">
            <span className="passwordChanged-up-span">
              <div className="frame">
                <ElementIcTik className="forty-ic-tik" />
              </div>
            </span>
            <span className="passwordChanged-desc-span">
              {t("resetPassword.passwordChanged.title")}
            </span>
            <span className="passwordChanged-desc">
              {t("resetPassword.passwordChanged.desc")}
            </span>
            <div className="passwordChanged-form">
              <div className="passwordChanged-input-container">
                {false && (
                  <CheckLabel
                    disabled="off"
                    text="Ricorda le mie credenziali"
                    type="switch"
                    className={undefined}
                    switchEllipseClassName={undefined}
                  />
                )}
              </div>
            </div>
            <div className="passwordChanged-button-wrapper">
              <Button
                size="XL"
                disabled={undefined}
                text={t("resetPassword.passwordChanged.buttonLabel")}
                type="line"
                state="default"
                icon={
                  <TwentyIcArrowLineLeft1
                    className={undefined}
                    onClick={undefined}
                    color="#36B39E"
                  />
                }
                iconRight={undefined}
                onClick={() => navigate("/login")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
