import i18next from "i18next";

const validations = {
  email: {
    rule: () =>
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
    formatter(fieldName) {
      return `${fieldName} ${i18next.t("validation.eMail")}`;
    },
  },
  numeric: {
    rule: () => /^[0-9,.]*$/,
    formatter(fieldName) {
      return `${fieldName} ${i18next.t("validation.numeric")}`;
    },
  },
  alphaNumeric: {
    rule: () => /^[a-z0-9,\s]*$/i,
    formatter(fieldName) {
      return `${fieldName} ${i18next.t("validation.alphaNumeric")}`;
    },
  },
  alphaNumericSpecialCharacter: {
    rule: () => /^[ \w,./#&àèéòùì]*$/,
    formatter(fieldName) {
      return `${fieldName} ${i18next.t(
        "validation.alphaNumericSpecialCharacter"
      )}`;
    },
  },
  alphabetic: {
    rule: () => /^[a-z\s]*$/i,
    formatter(fieldName) {
      return `${fieldName} ${i18next.t("validation.alphabetic")}`;
    },
  },
  isNotEmpty: {
    rule: () => /\S/,
    formatter(fieldName) {
      return `${fieldName} ${i18next.t("validation.isNotEmpty")}`;
    },
  },
  fiscalCode: {
    rule: () =>
      /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$/i,
    formatter(fieldName) {
      return `${fieldName} ${i18next.t("validation.fiscalCode")}`;
    },
  },
  phoneNumber: {
    rule: () =>
      /^((00|\+)\d{2,3})?(38[890]|34[7-90]|36[680]|33[3-90]|32\d)\d{6,7}$/i,
    formatter(fieldName) {
      return `${fieldName} ${i18next.t("validation.phoneNumber")}`;
    },
  },
  maxLength: {
    rule: (number) => ({
      test: (value) => value.length <= number,
    }),
    formatter(fieldName, number) {
      return number
        ? `${fieldName} ${i18next.t(
            "validation.canContainMax"
          )} ${number} ${i18next.t("validation.characters")}.`
        : `${fieldName} ${i18next.t("validation.maxLength")}`;
    },
  },
  minLength: {
    rule: (number) => ({
      test: (value) => value.length >= number,
    }),
    formatter(fieldName, number) {
      return number
        ? `${fieldName} ${i18next.t(
            "validation.canContainMin"
          )} ${number} ${i18next.t("validation.characters")}.`
        : `${fieldName} ${i18next.t("validation.minLength")} `;
    },
  },
  //   date: {
  //     rule: () => ({
  //       test: (value) =>
  //         dayjs(convertDateToIsoString(value, false, localeFormat)).isValid() &&
  //         value.length === 14,
  //     }),
  //     formatter() {
  //       return `${i18next.t("validation.date")}`;
  //     },
  //   },
  wrongDate: {
    rule: () => ({
      test: () => false,
    }),
    formatter() {
      return `${i18next.t("validation.date")}`;
    },
  },
};

export default validations;
