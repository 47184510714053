import { format, parseISO } from "date-fns";
import { ModalBody } from "../../../ui/components/Modals/ModalBody";
import { ModalFooter } from "../../../ui/components/Modals/ModalFooter";
import { ModalWrapper } from "../../../ui/components/Modals/ModalWrapper";
import { TransactionSlice } from "../TransactionSlice/TransactionSlice";
import { it } from "date-fns/locale";
import "../TransactionDetailsModal/TransactionDetailsModal.css";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  TransactionDetailsSlice,
  transactionDetailsSelector,
} from "../TransactionSlice/TransactionDetailsSlice";
import { useAppSelector } from "../../../app/hooks";
import _ from "lodash";

interface TransactionDetailsModalProps {
  onClose: (e: any) => any;
  onClick: (e: any) => any;
  transactionsDetails: TransactionSlice;
}

export const TransactionDetailsModal: React.FC<
  TransactionDetailsModalProps
> = ({ onClose, onClick, transactionsDetails }) => {
  const transactionDetails: TransactionDetailsSlice[] = useAppSelector(
    transactionDetailsSelector.selectAll
  );

  const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const convertDate = (inputDate: string) => {
    const parsedDate = parseISO(inputDate);

    const formattedDate = format(parsedDate, "EEE. dd MMM yyyy", {
      locale: it,
    }).replace(/(\b[a-z](?!\.))/g, (x) => capitalize(x));

    return formattedDate;
  };

  const convertTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");

    return `${hour}:${minute} `;
  };

  const conversionCredit = (
    type: string,
    balance: number,
    creditSend: number
  ) => {
    if (type === "SENT") {
      return balance - creditSend;
    } else {
      return balance + creditSend;
    }
  };

  const creditManagemenTable = (type: string) => {
    if (type === "SENT") {
      return "-";
    } else {
      return "+";
    }
  };

  const columnsActivity: ColumnsType<TransactionDetailsSlice> = [
    {
      title: "UTENTI",
      dataIndex: "",
      key: "",
      render: (firstName, record) => (
        <>
          <div className="table-transaction-details-user">
            <div className="admin-MR-wrapper-modal">
              <div className="admin-MR-modal">
                {record?.firstName &&
                  record?.lastName &&
                  record?.firstName[0] + record?.lastName[0]}
              </div>
            </div>
            <div className="table-transaction-details-user-name">
              {record?.firstName + " " + record?.lastName}

              <div className="table-transaction-details-user-email">
                {record?.email}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "CREDITI",
      dataIndex: "creditAmount",
      key: "creditAmount",
      render: (creditAmount, record) => (
        <>
          <div className="table-transaction-details-credit">
            {record?.creditAmount}
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="modal-wrapper-transaction-detail">
      <ModalWrapper open={true} closeAction={onClose}>
        <ModalBody title="Riepilogo invio crediti">
          <div className="summary-text">Riepilogo</div>
          <div>
            In data :{" "}
            {transactionsDetails &&
              convertDate(transactionsDetails?.date?.toString())}{" "}
            alle{" "}
            {transactionsDetails &&
              convertTimestamp(transactionsDetails?.date?.toString())}
          </div>
          <div className="table-sending-modal">
            <Table
              columns={columnsActivity}
              dataSource={transactionDetails}
              scroll={{ y: 240 }}
              pagination={false}
            ></Table>
          </div>
          <div className="summary-credits">
            <div className="container-credit-available">
              <div className="credit-available-modal">Crediti disponibili</div>
              <div className="credit-available-modal-text">
                {transactionsDetails?.balance}
              </div>
            </div>
            <div className="rectangle-10" />
            <div className="container-sub-credit-available">
              <div className="sub-credit-available-modal">
                Subtotale dei crediti da inviare
              </div>
              <div className="sub-credit-available-modal-text">
                {creditManagemenTable(transactionsDetails?.type)}{" "}
                {transactionsDetails?.totalCreditSent}
              </div>
            </div>
            <div className="rectangle-10" />
            <div className="container-remaining-credits">
              <div className="remaining-credits-modal">Crediti restanti</div>
              <div className="remaining-credits-modal-text">
                {conversionCredit(
                  transactionsDetails?.type,
                  transactionsDetails?.balance,
                  transactionsDetails?.totalCreditSent
                )}
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter
          primaryLabel={"Ok"}
          secondaryLabel={undefined}
          primaryAction={(e) => {
            onClick(e);
          }}
          secondaryAction={onClose}
          disablePrimaryButton={undefined}
        />
      </ModalWrapper>
    </div>
  );
};
