import React, { useEffect, useState } from "react";
import { TwentyIcCloseSmall } from "../icons/TwentyIcCloseSmall";
import "./SmartPlanFilterModal.css";
import { Button, Dropdown, MenuProps, Select, Space, Switch } from "antd";
import { t } from "i18next";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Slider } from "antd";
import { TwentyIcArrowDown } from "../icons/TwentyIcArrowDown";
import { TwentyIcTik } from "../icons/TwentyIcTik";
import Tag from "../Tag/Tag";
import { BaseOptionType } from "antd/es/select";
import { QueryParams } from "../../../feature/Smartplan/SmartPlan";
import _ from "lodash";

interface SmartPlanFilterModalProps {
  modalChange: (e: any) => any;
  setParam: (e: any) => any;
  isModalOpened: boolean;
  queryParams: QueryParams;
}

export const SmartPlanFilterModal: React.FC<SmartPlanFilterModalProps> = ({
  modalChange,
  setParam,
  isModalOpened,
  queryParams,
}) => {
  let minLimit = 0;
  let maxLimit = 10000;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [modalOpened, setModalOpened] = useState(isModalOpened);
  const [destinationDropdownSelected, setDestinationDropdownSelected] =
    useState("");
  const [durationSelected, setdurationSelected] = useState("");
  const [locationsSelected, setLocationsSelected] = useState<string[]>([]);
  const [monthsSelected, setMonthsSelected] = useState<string[]>([]);
  const [promotionsIncluded, setPromotionsIncluded] = useState(false);
  const [servicesSelected, setServicesSelected] = useState<string[]>([]);
  const [priceSelected, setPriceSelected] = useState<number | [number, number]>(
    [minLimit, maxLimit]
  );
  const [oldQueryParams, setOldQueryParams] = useState({} as QueryParams);

  const months = [
    t("smartPlan.components.monthsSelection.January"),
    t("smartPlan.components.monthsSelection.February"),
    t("smartPlan.components.monthsSelection.March"),
    t("smartPlan.components.monthsSelection.April"),
    t("smartPlan.components.monthsSelection.May"),
    t("smartPlan.components.monthsSelection.June"),
    t("smartPlan.components.monthsSelection.July"),
    t("smartPlan.components.monthsSelection.August"),
    t("smartPlan.components.monthsSelection.September"),
    t("smartPlan.components.monthsSelection.October"),
    t("smartPlan.components.monthsSelection.November"),
    t("smartPlan.components.monthsSelection.December"),
  ];

  useEffect(() => {
    if (!_.isEqual(oldQueryParams, queryParams) && !!queryParams) {
      // recupera dai query params  e setta gli hooks
      queryParams["minPrice"] &&
        queryParams["maxPrice"] &&
        setPriceSelected([queryParams["minPrice"], queryParams["maxPrice"]]);
      if (queryParams["duration"]) {
        if (queryParams["duration"] === 3) {
          setdurationSelected(t("smartPlan.components.duration.threeDays"));
        } else if (queryParams["duration"] === 7) {
          setdurationSelected(t("smartPlan.components.duration.oneWeek"));
        } else if (queryParams["duration"] === 14) {
          setdurationSelected(t("smartPlan.components.duration.twoWeeks"));
        }
      }

      if (queryParams["location"]) {
        if (Array.isArray(queryParams["location"])) {
          let locTemp = [] as string[];
          queryParams["location"].map((element: any) => {
            if (element === "NORD") {
              locTemp.push(t("smartPlan.components.location.nord"));
            } else if (element === "SUD") {
              locTemp.push(t("smartPlan.components.location.sud"));
            } else if (element === "CENTRO") {
              locTemp.push(t("smartPlan.components.location.center"));
            } else {
              locTemp.push(t("smartPlan.components.location.islands"));
            }
          });
          setLocationsSelected(locTemp);
        }
      }

      if (queryParams["months"]) {
        if (Array.isArray(queryParams["months"])) {
          let monthTemp = [] as string[];
          queryParams["months"].map((element: any) => {
            let month = element.split("-")[1];
            month = parseInt(month);
            month = month - 1;
            const selectedYear = element.split("-")[0];
            monthTemp.push(
              months[month].replace("*year", selectedYear.toString())
            );
          });
          setMonthsSelected(monthTemp);
        }
      }

      if (queryParams["includedService"]) {
        if (Array.isArray(queryParams["includedService"])) {
          let servTemp = [] as string[];
          queryParams["includedService"].map((element: any) => {
            if (element === "Masterclass") {
              servTemp.push(
                t("smartPlan.components.servicesSelection.masterclass")
              );
            } else if (element === "Coworking") {
              servTemp.push(
                t("smartPlan.components.servicesSelection.coworking")
              );
            } else if (element === "Formazione") {
              servTemp.push(
                t("smartPlan.components.servicesSelection.training")
              );
            } else {
              servTemp.push(t("smartPlan.components.servicesSelection.health"));
            }
          });
          setServicesSelected(servTemp);
        }
      }

      if (queryParams["landform"]) {
        if (queryParams["landform"] === "MOUNTAIN") {
          setDestinationDropdownSelected(
            t("smartPlan.components.landform.mountain")
          );
        } else if (queryParams["landform"] === "SEA") {
          setDestinationDropdownSelected(
            t("smartPlan.components.landform.sea")
          );
        } else if (queryParams["landform"] === "HILL") {
          setDestinationDropdownSelected(
            t("smartPlan.components.landform.hill")
          );
        } else if (queryParams["landform"] === "LAKES") {
          setDestinationDropdownSelected(
            t("smartPlan.components.landform.lakes")
          );
        } else if (queryParams["landform"] === "PARKS") {
          setDestinationDropdownSelected(
            t("smartPlan.components.landform.parks")
          );
        } else if (queryParams["landform"] === "CITY") {
          setDestinationDropdownSelected(
            t("smartPlan.components.landform.city")
          );
        }
      }

      queryParams["inPromotion"] &&
        setPromotionsIncluded(queryParams["inPromotion"]);
    }
    setOldQueryParams(queryParams);
  }, [isModalOpened]);

  const [refreshed, setRefreshed] = useState(false);

  const onPeopleClick: MenuProps["onClick"] = ({ key }) => {
    setDestinationDropdownSelected(key);
  };

  const onDurationClick: MenuProps["onClick"] = ({ key }) => {
    setdurationSelected(key);
  };

  const locationsHandleChange = (value: string[]) => {
    setLocationsSelected(value);
  };

  const monthsHandleChange = (value: string[]) => {
    setMonthsSelected(value);
  };

  const servicesHandleChange = (value: string[]) => {
    setServicesSelected(value);
  };

  const priceHandleChange = (value: number | [number, number]) => {
    if (Array.isArray(value)) {
      setPriceSelected(value);
    }
  };

  function restoreParams() {
    setdurationSelected("");
    setLocationsSelected([]);
    setMonthsSelected([]);
    setServicesSelected([]);
    setPriceSelected([minLimit, maxLimit]);
    setDestinationDropdownSelected("");
    setPromotionsIncluded(false);
    setRefreshed(!refreshed);
  }

  function paramBuilder() {
    let map = new Map();
    let optSelected = [] as string[];
    if (destinationDropdownSelected !== "") {
      if (
        destinationDropdownSelected === t("smartPlan.components.landform.sea")
      ) {
        map.set("landform", "SEA");
      } else if (
        destinationDropdownSelected ===
        t("smartPlan.components.landform.mountain")
      ) {
        map.set("landform", "MOUNTAIN");
      } else if (
        destinationDropdownSelected === t("smartPlan.components.landform.hill")
      ) {
        map.set("landform", "HILL");
      } else if (
        destinationDropdownSelected === t("smartPlan.components.landform.lakes")
      ) {
        map.set("landform", "LAKES");
      } else if (
        destinationDropdownSelected === t("smartPlan.components.landform.parks")
      ) {
        map.set("landform", "PARKS");
      } else if (
        destinationDropdownSelected === t("smartPlan.components.landform.city")
      ) {
        map.set("landform", "CITY");
      }
    }
    if (durationSelected !== "") {
      if (durationSelected === t("smartPlan.components.duration.threeDays")) {
        map.set("duration", 3);
      } else if (
        durationSelected === t("smartPlan.components.duration.oneWeek")
      ) {
        map.set("duration", 7);
      } else if (
        durationSelected === t("smartPlan.components.duration.twoWeeks")
      ) {
        map.set("duration", 14);
      }
    }
    if (locationsSelected.length > 0) {
      locationsSelected.map((element) => {
        switch (element) {
          case t("smartPlan.components.location.nord"):
            optSelected.push("NORD");
            break;
          case t("smartPlan.components.location.sud"):
            optSelected.push("SUD");
            break;
          case t("smartPlan.components.location.center"):
            optSelected.push("CENTRO");
            break;
          case t("smartPlan.components.location.islands"):
            optSelected.push("ISOLE");
            break;
        }
      });
      map.set("location", optSelected);
      optSelected = [];
    }
    if (monthsSelected.length > 0) {
      monthsSelected.forEach((element) => {
        const month = element.split(" ")[0];
        const selectedYear = element.split(" ")[1];
        switch (month + " " + selectedYear) {
          case t("smartPlan.components.monthsSelection.January").replace(
            "*year",
            selectedYear.toString()
          ):
            optSelected.push(selectedYear.toString() + "-01");
            break;
          case t("smartPlan.components.monthsSelection.February").replace(
            "*year",
            selectedYear.toString()
          ):
            optSelected.push(selectedYear.toString() + "-02");
            break;
          case t("smartPlan.components.monthsSelection.March").replace(
            "*year",
            selectedYear.toString()
          ):
            optSelected.push(selectedYear.toString() + "-03");
            break;
          case t("smartPlan.components.monthsSelection.April").replace(
            "*year",
            selectedYear.toString()
          ):
            optSelected.push(selectedYear.toString() + "-04");
            break;
          case t("smartPlan.components.monthsSelection.May").replace(
            "*year",
            selectedYear.toString()
          ):
            optSelected.push(selectedYear.toString() + "-05");
            break;
          case t("smartPlan.components.monthsSelection.June").replace(
            "*year",
            selectedYear.toString()
          ):
            optSelected.push(selectedYear.toString() + "-06");
            break;
          case t("smartPlan.components.monthsSelection.July").replace(
            "*year",
            selectedYear.toString()
          ):
            optSelected.push(selectedYear.toString() + "-07");
            break;
          case t("smartPlan.components.monthsSelection.August").replace(
            "*year",
            selectedYear.toString()
          ):
            optSelected.push(selectedYear.toString() + "-08");
            break;
          case t("smartPlan.components.monthsSelection.September").replace(
            "*year",
            selectedYear.toString()
          ):
            optSelected.push(selectedYear.toString() + "-09");
            break;
          case t("smartPlan.components.monthsSelection.October").replace(
            "*year",
            selectedYear.toString()
          ):
            optSelected.push(selectedYear.toString() + "-10");
            break;
          case t("smartPlan.components.monthsSelection.November").replace(
            "*year",
            selectedYear.toString()
          ):
            optSelected.push(selectedYear.toString() + "-11");
            break;
          case t("smartPlan.components.monthsSelection.December").replace(
            "*year",
            selectedYear.toString()
          ):
            optSelected.push(selectedYear.toString() + "-12");
            break;
        }
      });
      map.set("months", optSelected);
      optSelected = [];
    }

    if (servicesSelected.length > 0) {
      servicesSelected.map((element) => {
        switch (element) {
          case t("smartPlan.components.servicesSelection.training"):
            optSelected.push("Formazione");
            break;
          case t("smartPlan.components.servicesSelection.masterclass"):
            optSelected.push("Masterclass");
            break;
          case t("smartPlan.components.servicesSelection.coworking"):
            optSelected.push("Coworking");
            break;
          case t("smartPlan.components.servicesSelection.health"):
            optSelected.push("Servizi per il benessere");
            break;
        }
      });
      map.set("includedService", optSelected);
      optSelected = [];
    }
    if (promotionsIncluded) {
      map.set("inPromotion", true);
    }
    if (priceSelected) {
      let temp = priceSelected as [number, number];
      temp.sort((a, b) => a - b);

      if (temp[0] !== minLimit || temp[1] !== maxLimit) {
        map.set("minPrice", temp[0].toString());
        map.set("maxPrice", temp[1].toString());
      }
    }
    return map;
  }

  const serviceItems: BaseOptionType["items"] = [
    {
      label: t("smartPlan.components.servicesSelection.training"),
      value: t("smartPlan.components.servicesSelection.training"),
    },
    {
      label: t("smartPlan.components.servicesSelection.masterclass"),
      value: t("smartPlan.components.servicesSelection.masterclass"),
    },
    {
      label: t("smartPlan.components.servicesSelection.coworking"),
      value: t("smartPlan.components.servicesSelection.coworking"),
    },
    {
      label: t("smartPlan.components.servicesSelection.health"),
      value: t("smartPlan.components.servicesSelection.health"),
    },
  ];

  const dateItems = [];

  for (let i = 0; i < 12; i++) {
    const nextMonthIndex = (currentMonth + i) % 12;
    const nextMonth = months[nextMonthIndex];
    const year = currentMonth + i >= 12 ? currentYear + 1 : currentYear;

    const item = {
      label: `${nextMonth.replace("*year", year.toString())}`,
      value: `${nextMonth.replace("*year", year.toString())}`,
    };

    dateItems.push(item);
  }

  const peopleItems: MenuProps["items"] = [
    {
      label: t("smartPlan.components.landform.sea"),
      key: t("smartPlan.components.landform.sea"),
    },
    {
      label: t("smartPlan.components.landform.mountain"),
      key: t("smartPlan.components.landform.mountain"),
    },
    {
      label: t("smartPlan.components.landform.hill"),
      key: t("smartPlan.components.landform.hill"),
    },
    {
      label: t("smartPlan.components.landform.lakes"),
      key: t("smartPlan.components.landform.lakes"),
    },
    {
      label: t("smartPlan.components.landform.parks"),
      key: t("smartPlan.components.landform.parks"),
    },
    {
      label: t("smartPlan.components.landform.city"),
      key: t("smartPlan.components.landform.city"),
    },
  ];

  const locationItems: BaseOptionType["items"] = [
    {
      label: t("smartPlan.components.location.nord"),
      value: t("smartPlan.components.location.nord"),
    },
    {
      label: t("smartPlan.components.location.sud"),
      value: t("smartPlan.components.location.sud"),
    },
    {
      label: t("smartPlan.components.location.center"),
      value: t("smartPlan.components.location.center"),
    },
    {
      label: t("smartPlan.components.location.islands"),
      value: t("smartPlan.components.location.islands"),
    },
  ];

  const durationItems: MenuProps["items"] = [
    {
      label: t("smartPlan.components.duration.threeDays"),
      key: t("smartPlan.components.duration.threeDays"),
    },
    {
      label: t("smartPlan.components.duration.oneWeek"),
      key: t("smartPlan.components.duration.oneWeek"),
    },
    {
      label: t("smartPlan.components.duration.twoWeeks"),
      key: t("smartPlan.components.duration.twoWeeks"),
    },
  ];

  function tagRender(props: any) {
    const { label, value, closable, onClose } = props;

    return (
      <div style={{ paddingTop: "2px", paddingRight: "4px" }}>
        <Tag isRemoved={onClose} textOne={label} />
      </div>
    );
  }

  useEffect(() => {
    modalChange(modalOpened);
  }, [modalOpened]);

  return (
    <>
      <div className="mn-modal-backdrop"></div>
      {
        <div className="smartplan-filtermodal">
          <div className="smartplan-filtermodal-header">
            <div className="smartplan-filtermodal-header-text">
              {t("smartPlan.common.filters")}
            </div>
            <div
              className="smartplan-filtermodal-header-iconclose"
              onClick={() => {
                setModalOpened(!modalOpened);
              }}
            >
              <TwentyIcCloseSmall className={undefined}></TwentyIcCloseSmall>
            </div>
          </div>
          <div className="smartplan-filtermodal-content">
            <div className="smartplan-filtermodal-content-dropdowns">
              <div className="smartplan-filtermodal-content-dropdown">
                <div className="smartplan-filtermodal-text">
                  {t("smartPlan.common.destination")}
                </div>
                <Dropdown menu={{ items: peopleItems, onClick: onPeopleClick }}>
                  <Button
                    style={{
                      width: "100%",
                      height: "40px",
                    }}
                  >
                    <Space
                      style={{ width: "100%", placeContent: "space-between" }}
                    >
                      <div
                        className={
                          destinationDropdownSelected
                            ? "smartplan-filtermodal-text"
                            : "smartplan-filtermodal-text-select"
                        }
                        style={{
                          width: "130px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlignLast: "left",
                        }}
                      >
                        {destinationDropdownSelected
                          ? destinationDropdownSelected
                          : t("smartPlan.filterModal.select")}
                      </div>
                      <TwentyIcArrowDown
                        color="#36B39E"
                        className={undefined}
                      />
                    </Space>
                  </Button>
                </Dropdown>
              </div>
              <div className="smartplan-filtermodal-content-dropdown">
                <div className="smartplan-filtermodal-text">
                  {t("smartPlan.common.duration")}
                </div>
                <Dropdown
                  menu={{ items: durationItems, onClick: onDurationClick }}
                >
                  <Button style={{ width: "100%", height: "40px" }}>
                    <Space
                      style={{ width: "100%", placeContent: "space-between" }}
                    >
                      <div
                        className={
                          durationSelected
                            ? "smartplan-filtermodal-text"
                            : "smartplan-filtermodal-text-select"
                        }
                        style={{
                          width: "90px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlignLast: "left",
                        }}
                      >
                        {durationSelected
                          ? durationSelected
                          : t("smartPlan.filterModal.select")}
                      </div>
                      <TwentyIcArrowDown
                        color="#36B39E"
                        className={undefined}
                      />
                    </Space>
                  </Button>
                </Dropdown>
              </div>
            </div>
            <div className="smartplan-filtermodal-content-form">
              <div className="smartplan-filtermodal-text">
                {t("smartPlan.common.location")}
              </div>
              <Select
                suffixIcon={
                  locationsSelected.length === 0 && (
                    <TwentyIcArrowDown className={undefined} color="#36b39e" />
                  )
                }
                value={locationsSelected}
                tagRender={tagRender}
                placeholder={t("smartPlan.filterModal.select")}
                bordered={false}
                mode="multiple"
                allowClear
                style={{
                  paddingTop: "2px",
                  paddingBottom: "4px",
                  gap: "5px",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "40px",
                  height: "100%",
                  border: "1px solid #d9d9d9",
                  borderRadius: "5px",
                }}
                onChange={locationsHandleChange}
                options={locationItems}
              />
            </div>
            <div className="smartplan-filtermodal-content-form">
              <div className="smartplan-filtermodal-text">
                {t("smartPlan.common.month")}
              </div>
              <Select
                suffixIcon={
                  monthsSelected.length === 0 && (
                    <TwentyIcArrowDown className={undefined} color="#36b39e" />
                  )
                }
                value={monthsSelected}
                tagRender={tagRender}
                placeholder={t("smartPlan.filterModal.select")}
                bordered={false}
                mode="multiple"
                allowClear
                style={{
                  paddingTop: "2px",
                  paddingBottom: "4px",
                  gap: "5px",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "40px",
                  height: "100%",
                  border: "1px solid #d9d9d9",
                  borderRadius: "5px",
                }}
                onChange={monthsHandleChange}
                options={dateItems}
              />
            </div>
            <div className="smartplan-filtermodal-content-form">
              <div className="smartplan-filtermodal-text">
                {t("smartPlan.common.servicesIncluded")}
              </div>
              <Select
                removeIcon
                suffixIcon={
                  servicesSelected.length === 0 && (
                    <TwentyIcArrowDown className={undefined} color="#36b39e" />
                  )
                }
                value={servicesSelected}
                tagRender={tagRender}
                bordered={false}
                placeholder={t("smartPlan.filterModal.select")}
                mode="multiple"
                allowClear
                style={{
                  paddingTop: "2px",
                  paddingBottom: "4px",
                  gap: "5px",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "40px",
                  height: "100%",
                  border: "1px solid #d9d9d9",
                  borderRadius: "5px",
                }}
                onChange={servicesHandleChange}
                options={serviceItems}
              />
            </div>
            <div className="smartplan-filtermodal-content-toggle-button">
              <Switch
                checked={promotionsIncluded}
                style={
                  promotionsIncluded
                    ? { backgroundColor: "#36B39E" }
                    : { backgroundColor: "grey" }
                }
                checkedChildren={<CheckOutlined rev={undefined} />}
                unCheckedChildren={<CloseOutlined rev={undefined} />}
                onChange={() => setPromotionsIncluded(!promotionsIncluded)}
                defaultChecked={promotionsIncluded}
              />
              <div className="smartplan-filtermodal-text">
                {t("smartPlan.filterModal.promotionsIncluded")}
              </div>
            </div>
            <div className="smartplan-filtermodal-content-range-slider">
              <div className="smartplan-filtermodal-text">
                {t("smartPlan.components.rangeBoundPrice.price")}
              </div>
              <Slider
                value={priceSelected as [number, number]}
                onChange={priceHandleChange}
                defaultValue={[maxLimit, minLimit]}
                min={minLimit}
                max={maxLimit}
                style={{ width: "100%" }}
                range={{ draggableTrack: true }}
                trackStyle={[{ backgroundColor: "#36B39E" }]}
                handleStyle={[
                  { boxShadow: "0 0 0 0 #36B39E", borderRadius: "50%" },
                ]}
              />
              <div className="smartPlan-filtermodal-content-range-slider-text">
                <div
                  className="smartplan-filtermodal-text"
                  style={{ display: "flex" }}
                >
                  {Array.isArray(priceSelected) && priceSelected[0]}
                  {" €"}
                </div>
                <div
                  className="smartplan-filtermodal-text"
                  style={{ display: "flex" }}
                >
                  {Array.isArray(priceSelected) && priceSelected[1]}
                  {" €"}
                </div>
              </div>
            </div>
            <div className="smartPlan-filtermodal-content-buttons">
              <Button
                onClick={restoreParams}
                style={{
                  borderColor: "transparent",
                  boxShadow: "none",
                  height: "40px",
                }}
              >
                <Space>
                  <div className="smartplan-filtermodal-text">
                    {t("smartPlan.common.cancel")}
                  </div>
                </Space>
              </Button>
              <Button
                style={{
                  borderColor: "#36B39E",
                  backgroundColor: "#36B39E",
                  boxShadow: "none",
                  height: "40px",
                }}
                onClick={() => {
                  let param = paramBuilder();
                  setParam(param);
                  setModalOpened(!modalOpened);
                }}
              >
                <Space>
                  <TwentyIcTik
                    className={"smartPlan-filtermodal-content-vector-confirm"}
                  />
                  <div className="smartplan-filtermodal-text-white">
                    {t("smartPlan.common.confirm")}
                  </div>
                </Space>
              </Button>
            </div>
          </div>
          <div className="smartplan-filtermodal-footer"></div>
        </div>
      }
    </>
  );
};

export default SmartPlanFilterModal;
