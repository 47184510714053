/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcCalendar = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-calendar ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rect"
        height="16"
        rx="2"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
        width="16"
        x="2"
        y="2"
      />
      <path
        className="path"
        d="M18 6L2 6"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect
        className="rect"
        fill="#222729"
        height="1"
        stroke={color}
        width="1"
        x="5.5"
        y="9.5"
      />
      <rect
        className="rect"
        fill="#222729"
        height="1"
        stroke={color}
        width="1"
        x="5.5"
        y="13.5"
      />
      <rect
        className="rect"
        fill="#222729"
        height="1"
        stroke={color}
        width="1"
        x="9.5"
        y="9.5"
      />
      <rect
        className="rect"
        fill="#222729"
        height="1"
        stroke={color}
        width="1"
        x="9.5"
        y="13.5"
      />
      <rect
        className="rect"
        fill="#222729"
        height="1"
        stroke={color}
        width="1"
        x="13.5"
        y="9.5"
      />
      <rect
        className="rect"
        fill="#222729"
        height="1"
        stroke={color}
        width="1"
        x="13.5"
        y="13.5"
      />
    </svg>
  );
};
