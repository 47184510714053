import React from "react";

export const TwentyIcPinMap1 = ({ className }) => {
  return (
    <svg
      className={`twenty-ic-pin-map-1 ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 20 21"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M5.05025 4.44113C7.78392 1.85296 12.2161 1.85296 14.9497 4.44113C17.6834 7.02929 17.6834 11.2255 14.9497 13.8137L10 18.5L5.05025 13.8137C2.31658 11.2255 2.31658 7.02929 5.05025 4.44113Z"
        stroke="#36B39E"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        className="circle"
        cx="10"
        cy="9.5"
        r="2"
        stroke="#36B39E"
        strokeWidth="2"
      />
    </svg>
  );
};
