import { useNavigate } from "react-router-dom";
import { Button } from "../../ui/components/Button/Button";
import { CheckLabel } from "../../ui/components/CheckLabel";
import Form from "../../ui/components/Form/Form";
import { Logo } from "../../ui/components/Logo/Logo";
import "./RecoverPassword.css";
import AuthenticationService from "../../utils/AuthenticationService";
import { useEffect, useRef, useState } from "react";
import i18next, { t } from "i18next";
import { ToastNotification } from "../../ui/components/utils/ToastNotification";
import Input from "antd/es/input/Input";
import { IconMail } from "../../ui/components/icons/IconMail";

export const RecoverPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const authenticationService = new AuthenticationService();
  const handleGenerateEmail = (email: string) => {
    setIsLoading(true);
    authenticationService.generateEmail(
      email,
      (result: any) => {
        navigate("/send-email");
        setIsLoading(false);
      },
      (error) => {
        console.log("LOGIN ERROR: " + error);
        if (error.response === undefined || error.response.status === 500) {
          ToastNotification({
            status: "error",
            description: "",
            toastId: "loginFailed",
            title: i18next.t("loginPage.toastNotification.networkError"),
          });
        }
        setIsLoading(false);
      }
    );
  };

  const handleEmailChange = (data: any) => {
    setEmail(data.target.value);
  };

  const submitRef = useRef<any>();
  useEffect(() => {
    const keyUp = (event: any) => {
      if (event.keyCode === 13 && email !== "") {
        handleGenerateEmail(email);
      }
    };

    if (submitRef && submitRef.current) {
      submitRef.current.addEventListener("keydown", keyUp);
      let parentRef = submitRef;
      return () => {
        parentRef.current &&
          parentRef.current.removeEventListener("keydown", keyUp);
      };
    }
  });

  return (
    <div className="retrivePassword" ref={submitRef}>
      <div className="retrivePassword-container">
        <div className="retrivePassword-logo">
          <Logo width="186px" height="28px" />
        </div>

        <div className="retrivePassword-content">
          <div className="retrivePassword-form-container">
            <span className="retrivePassword-up-span">
              {t("resetPassword.retriverPassword.title")}
            </span>
            <span className="retrivePassword-desc-span">
              {t("resetPassword.retriverPassword.desc")}
            </span>
            <div className="retrivePassword-form">
              <div className="retrivePassword-input-container">
                <div className="retrivePassword-input-group">
                  <Form>
                    <span className="email-span">
                      {t("resetPassword.retriverPassword.heading")}
                    </span>
                    <Input
                      id="username-field"
                      value={email}
                      type="email"
                      style={{
                        width: "360px",
                        height: "50px",
                        marginTop: "4px",
                      }}
                      size="large"
                      placeholder="Email"
                      prefix={
                        <IconMail color={"#BDBEBF"} className={undefined} />
                      }
                      onChange={handleEmailChange}
                    />
                  </Form>
                </div>
                {false && (
                  <CheckLabel
                    disabled={undefined}
                    text="Ricorda le mie credenziali"
                    type="switch"
                    className={undefined}
                    switchEllipseClassName={undefined}
                  />
                )}
              </div>
            </div>
            <div className="retrivePassword-button-wrapper">
              <Button
                size="XL"
                disabled={email === "" ? true : false}
                text={
                  isLoading
                    ? ""
                    : t("resetPassword.retriverPassword.buttonLabel")
                }
                type={undefined}
                state={undefined}
                icon={undefined}
                iconRight={undefined}
                onClick={() => handleGenerateEmail(email)}
                isActionPerforming={isLoading}
              />
            </div>
          </div>
          <p className="retrivePassword-login">
            <span className="retrivePassword-span">
              {t("resetPassword.retriverPassword.footer1")}
            </span>
            <span
              className="retrivePassword-text-3"
              onClick={() => navigate("/login")}
            >
              {t("resetPassword.retriverPassword.footer2")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
