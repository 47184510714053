//#region IMPORT

import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import MeetingsRepository from "./MeetingsRepository";

//#endRegion IMPORT
//#region TYPE

export interface MeetingRequest {
  id?: number;
  meetingInvoiceStatusEnum?: MeetingInvoiceStatusEnum | null;
  meetingStatusEnum?: MeetingStatusEnum | null;
  annotation?: string | null;
}

export interface MeetingFilters {
  firstName: string | null;
  lastName: string | null;
  months: string | null;
  service: string | null;
  meetingStatusEnum: MeetingStatusEnum | null;
  meetingInvoiceStatusEnum: MeetingInvoiceStatusEnum | null;
  deleted: boolean;
}

export interface Meeting {
  id: number;
  invoiceDate: Date;
  invoicePrice: number;
  meetingName: string;
  meetingExecution: Date;
  firstName: string;
  lastName: string;
  meetingInvoiceStatusEnum: MeetingInvoiceStatusEnum;
  meetingStatusEnum: MeetingStatusEnum;
}

export interface MeetingSummary {
  id: number;
  invoiced: number;
  invoicedAvabile: number;
  invoicedPrediction: number;
  completed: number;
  pending: number;
  meetings: Meeting[];
}

export interface MeetingCreateRequest {
  invoiceDate: Date | null;
  invoicePrice: number;
  meetingStatus: MeetingStatusEnum;
  meetingInvoiceStatus: MeetingInvoiceStatusEnum;
  additionalInfo?: string;
  tenantId: number;
  providerId: number;
  bookingId: number;
}

export const typeOfMeetingInvoiceStatus = {
  NOT_INVOICED: "NOT_INVOICED",
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
};
export const typeOfMeetingStatus = {
  PROVIDER_APPROVED: "PROVIDER_APPROVED",
  APPROVED: "APPROVED",
  NOT_APPROVED: "NOT_APPROVED",
};

export type MeetingInvoiceStatusEnum = keyof typeof typeOfMeetingInvoiceStatus;
export type MeetingStatusEnum = keyof typeof typeOfMeetingStatus;

//#endRegion TYPE
//#region API

export const getMeetingsAsync = createAsyncThunk(
  "meetings/get",
  async (data: { filters?: MeetingFilters }) => {
    try {
      const meetingsRepository = new MeetingsRepository();
      const response = await meetingsRepository.getMeetings(data?.filters);
      const meetings = _.get(response, Config.MEETING_VIEW_RESPONSE);
      return meetings;
    } catch (error) {
      console.error("Error retriving meetings: ", error);
      return error;
    }
  }
);

export const updateMeetingsStatusAsync = createAsyncThunk(
  "meetings/update",
  async (data: { meetingsRequests: MeetingRequest[] }) => {
    try {
      const meetingsRepository = new MeetingsRepository();
      const response = await meetingsRepository.updateMeeting(
        data?.meetingsRequests
      );
      const meetings = _.get(response, Config.MEETING_VIEW_RESPONSE);
      return meetings;
    } catch (error) {
      console.error("Error retriving meetings: ", error);
      return error;
    }
  }
);

export const createMeetingAsync = createAsyncThunk(
  "meetings/create",
  async (data: { meeting: MeetingCreateRequest }) => {
    try {
      const meetingsRepository = new MeetingsRepository();
      const response = await meetingsRepository.createMeeting(data?.meeting);
      const meetings = _.get(response, Config.MEETING_VIEW_RESPONSE);
      return meetings;
    } catch (error) {
      console.error("Error retriving meetings: ", error);
      return error;
    }
  }
);

//#endRegion API
//#region SLICE

const meetingAdapter = createEntityAdapter<MeetingSummary>({
  selectId: (meeting) => meeting.id,
});

export const meetingSlice = createSlice({
  name: "meeting",
  initialState: meetingAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    meetingEmptyState: (state) => {
      meetingAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    meetingError: (state, error: any) => {
      state.reasonCode = error;
      state.status = "failed";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getMeetingsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          meetingAdapter.setOne(state, action.payload ?? {});
          state.status = "idle";
        }
      )
      .addCase(getMeetingsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getMeetingsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        createMeetingAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload.response?.status === 500) {
            state.status = "failed";
            state.reasonCode = action.payload.response?.status ?? "";
          }
          meetingAdapter.upsertMany(state, action.payload ?? []);
          state.status = "idle";
        }
      )
      .addCase(createMeetingAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(createMeetingAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        updateMeetingsStatusAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload.response?.status === 500) {
            state.status = "failed";
            state.reasonCode = action.payload.response?.status ?? "";
          }
          meetingAdapter.upsertMany(state, action.payload ?? []);
          state.status = "idle";
        }
      )
      .addCase(updateMeetingsStatusAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(updateMeetingsStatusAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

//#endRegion SLICE

//#region STATUS

export const meetingSelector = meetingAdapter.getSelectors<RootState>(
  (state) => state.meetings
);

export const { meetingEmptyState } = meetingSlice.actions;
export const selectMeetingSliceStatus = (state: any) => state.meetings.status;
export const selectMeetingSliceReasonCode = (state: any) =>
  state.meetings.reasonCode;

//#endRegion STATUS

export default meetingSlice.reducer;
