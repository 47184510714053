import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { TwentyIcEverywhere } from "../icons/TwentyIcEverywhere";
import { TwentyIcLesson } from "../icons/TwentyIcLesson";
import { TwentyIcZen } from "../icons/TwentyIcZen";
import "./MyOrders.css";
import { ImageConfig } from "../../../config/ImageConfig";
import { TwentyIcTicket } from "../icons/TwentyIcTicket/TwentyIcTicket";
import { TwentyIcCalendarSeet } from "../icons/TwentyIcCalendarSeet";
import { TwentyIcCreditCoins } from "../TwentyIcCreditCoins";
import Tag from "../Tag/Tag";

export interface MyOrdersProps {
  id: number;
  date: Date;
  price?: any;
  deposit?: string | undefined;
  image?: any;
  status?: any;
  titleOne?: string | undefined;
  titleTwo?: string | undefined;
  smartPlanId?: number | undefined;
  masterClassId?: number | undefined;
  wellnessBodyId?: number | undefined;
  wellnessPsychoId?: number | undefined;
  wellnessNutritionalId?: number | undefined;
  officeCoworkingId?: number | undefined;
  personsNumber?: number;
  isPending?: boolean;
  pendingDate?: Date;
  hubPendingStatus?: string;
  pendingStatus?: string;
  pendingOrderDate?: Date;
  type?: "MasterClass" | "SmartPlan" | "Wellness";
  deleted?: (e: any) => any;
  isWellnessService?: boolean;
}

const MyOrders: React.FC<MyOrdersProps> = ({
  id,
  date,
  image,
  price,
  deposit,
  titleOne,
  titleTwo,
  status,
  smartPlanId,
  masterClassId,
  wellnessBodyId,
  wellnessPsychoId,
  officeCoworkingId,
  wellnessNutritionalId,
  isPending,
  pendingOrderDate,
  hubPendingStatus,
  pendingStatus,
  pendingDate,
  personsNumber,
  type,
  deleted,
}) => {
  const months = [
    t("timeFormats.monthsFirstThree.January"),
    t("timeFormats.monthsFirstThree.February"),
    t("timeFormats.monthsFirstThree.March"),
    t("timeFormats.monthsFirstThree.April"),
    t("timeFormats.monthsFirstThree.May"),
    t("timeFormats.monthsFirstThree.June"),
    t("timeFormats.monthsFirstThree.July"),
    t("timeFormats.monthsFirstThree.August"),
    t("timeFormats.monthsFirstThree.September"),
    t("timeFormats.monthsFirstThree.October"),
    t("timeFormats.monthsFirstThree.November"),
    t("timeFormats.monthsFirstThree.December"),
  ];

  const navigate = useNavigate();

  let dateConvertedString = new Date(date);
  let newDate =
    dateConvertedString.getDate().toString() +
    " " +
    months[dateConvertedString.getMonth()] +
    " " +
    dateConvertedString.getFullYear().toString();

  let newPrice;

  if (
    price !== "" &&
    deposit !== "" &&
    price !== undefined &&
    deposit !== undefined &&
    personsNumber !== undefined
  ) {
    newPrice = `${price}€ ${t(
      "myOrders.account"
    )} ${deposit}€ (x${personsNumber})`;

    if (wellnessNutritionalId || wellnessBodyId || wellnessPsychoId)
      newPrice = `${price} Crediti`;
      status = "COMPLETED";
  } else {
    newPrice = t("myOrders.notAvabile");
  }

  if (isPending) {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="myorders-pending-container">
            <div className="myorders-pending-wrapper">
              <div className="myorders-pending-section-style">
                <TwentyIcTicket className={undefined} />
                <div style={{ fontWeight: 600 }}>Richiesta:</div>
                <div className="myorders-pending-section-style">
                  {pendingDate?.toLocaleDateString() +
                    "  ·  " +
                    pendingDate?.toLocaleTimeString()}
                </div>
                {"  ·  "}
                <div style={{ color: "#14ADCC", fontWeight: 600 }}>
                  {hubPendingStatus ?? ""}
                </div>
                <div className="myorders-pending-section-style">
                  {"  ·  "}
                  <TwentyIcCalendarSeet color="grey" className={undefined} />
                  {pendingOrderDate?.toLocaleDateString()}
                </div>
              </div>
            </div>
          </div>
          <div className="myorders-container-pending-secondsection" style={{}}>
            <div className="myorders-container-firstsection">
              <div className="myorders-container-image-container">
                <img
                  className="myorders-container-image"
                  alt=""
                  src={image ? image : ImageConfig.IMAGE_DEFAULT_SERVICE}
                />
              </div>
              <div className="myorders-container-information">
                <div className="myorders-container-information-first">
                  {type === "MasterClass" && (
                    <TwentyIcLesson color="#36B39E" className={undefined} />
                  )}
                  {type === "SmartPlan" && (
                    <TwentyIcEverywhere color="#36B39E" className={undefined} />
                  )}
                  {type === "Wellness" && (
                    <TwentyIcZen color="#36B39E" className={undefined} />
                  )}
                  <div className="myorders-container-information-first-text">
                    {titleOne !== "" ? titleOne : t("myOrders.notAvabile")}{" "}
                    {titleTwo && <div className="name-plate-pointer" />}{" "}
                    {titleTwo}
                  </div>
                </div>
                <div className="myorders-container-information-second">
                  <div className="myorders-container-information-second-row">
                    <div className="myorders-container-information-second-text-first">
                      {t("myOrders.id")}
                    </div>
                    <div className="myorders-container-information-second-text-second">
                      {"#" + id}
                    </div>
                  </div>
                  <div className="myorders-container-information-second-row">
                    <div className="myorders-container-information-second-text-first">
                      {t("myOrders.date")}
                    </div>
                    <div className="myorders-container-information-second-text-second">
                      {newDate !== "" ? newDate : t("myOrders.notAvabile")}
                    </div>
                  </div>
                  <div className="myorders-container-information-second-row">
                    <div className="myorders-container-information-second-text-first">
                      {t("myOrders.price")}
                    </div>
                    <div className="myorders-container-information-second-text-second">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {price}
                        <TwentyIcCreditCoins
                          size="18"
                          color={"grey"}
                          colorC={"grey"}
                          className={undefined}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="myorders-container-information-second-row">
                    <div className="myorders-container-information-second-text-first">
                      Stato richiesta:
                    </div>
                    <div className="myorders-container-information-second-text-second">
                      {pendingStatus}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="myorders-container-secondsection">
              <div
                className="myorders-container-buttonsection"
                style={{ alignItems: "center" }}
              >
                {!status && (
                  <Tag textOne="Da saldare" isRem={false} color="#FDEBD8" />
                )}
                <div
                  className="myorders-container-buttonsection-first"
                  onClick={() => {
                    smartPlanId &&
                      navigate("/smartPlan/" + smartPlanId + "/details");
                    masterClassId &&
                      navigate("/masterclass/" + masterClassId + "/details");
                    wellnessBodyId &&
                      navigate("/Wellness/Body/" + wellnessBodyId + "/details");
                    wellnessPsychoId &&
                      navigate(
                        "/Wellness/Psycho/" + wellnessPsychoId + "/details"
                      );
                    officeCoworkingId &&
                      navigate(
                        "/officeCoworking/" + officeCoworkingId + "/details"
                      );
                    wellnessNutritionalId &&
                      navigate(
                        "/Wellness/Nutritional/" +
                          wellnessNutritionalId +
                          "/details"
                      );
                  }}
                >
                  <div className="myorders-container-buttonsection-text">
                    {t("myOrders.informationDetails")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="myorders-container">
          <div className="myorders-container-firstsection">
            <div className="myorders-container-image-container">
              <img
                className="myorders-container-image"
                alt=""
                src={image ? image : ImageConfig.IMAGE_DEFAULT_SERVICE}
              />
            </div>
            <div className="myorders-container-information">
              <div className="myorders-container-information-first">
                {type === "MasterClass" && (
                  <TwentyIcLesson color="#36B39E" className={undefined} />
                )}
                {type === "SmartPlan" && (
                  <TwentyIcEverywhere color="#36B39E" className={undefined} />
                )}
                {type === "Wellness" && (
                  <TwentyIcZen color="#36B39E" className={undefined} />
                )}
                <div className="myorders-container-information-first-text">
                  {titleOne !== "" ? titleOne : t("myOrders.notAvabile")}{" "}
                  {titleTwo && <div className="name-plate-pointer" />}{" "}
                  {titleTwo}
                </div>
              </div>
              <div className="myorders-container-information-second">
                <div className="myorders-container-information-second-row">
                  <div className="myorders-container-information-second-text-first">
                    {t("myOrders.id")}
                  </div>
                  <div className="myorders-container-information-second-text-second">
                    {"#" + id}
                  </div>
                </div>
                <div className="myorders-container-information-second-row">
                  <div className="myorders-container-information-second-text-first">
                    {t("myOrders.date")}
                  </div>
                  <div className="myorders-container-information-second-text-second">
                    {newDate !== "" ? newDate : t("myOrders.notAvabile")}
                  </div>
                </div>
                <div className="myorders-container-information-second-row">
                  <div className="myorders-container-information-second-text-first">
                    {t("myOrders.price")}
                  </div>
                  <div className="myorders-container-information-second-text-second">
                    {newPrice}
                  </div>
                </div>
                <div className="myorders-container-information-second-row">
                  <div className="myorders-container-information-second-text-first">
                    {t("myOrders.status")}
                  </div>
                  <div className="myorders-container-information-second-text-second">
                    {status === "PENDING" && (
                      <div className="paystatus-pending">
                        {t("myOrders.payPending")}
                      </div>
                    )}
                    {status === "COMPLETED" && (
                      <div className="paystatus-completed">
                        {t("myOrders.payCompleted")}
                      </div>
                    )}
                    {status === "CANCELLED" && (
                      <div className="paystatus-canceled">
                        {t("myOrders.payCanceled")}
                      </div>
                    )}
                    {(status === undefined || status === "") &&
                      t("myOrders.notAvabile")}
                  </div>
                </div>
                <div className="myorders-container-information-second-row">
                  <div className="myorders-container-information-second-text-first">
                    {t("myOrders.personsNumber")}
                  </div>
                  <div className="myorders-container-information-second-text-second">
                    {!!personsNumber && personsNumber > 0
                      ? personsNumber
                      : t("myOrders.notAvabile")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="myorders-container-secondsection">
            <div className="myorders-container-buttonsection">
              <div
                className="myorders-container-buttonsection-first"
                onClick={() => {
                  smartPlanId &&
                    navigate("/smartPlan/" + smartPlanId + "/details");
                  masterClassId &&
                    navigate("/masterclass/" + masterClassId + "/details");
                  wellnessBodyId &&
                    navigate("/Wellness/Body/" + wellnessBodyId + "/details");
                  wellnessPsychoId &&
                    navigate(
                      "/Wellness/Psycho/" + wellnessPsychoId + "/details"
                    );
                  wellnessNutritionalId &&
                    navigate(
                      "/Wellness/Nutritional/" +
                        wellnessNutritionalId +
                        "/details"
                    );
                }}
              >
                <div className="myorders-container-buttonsection-text">
                  {t("myOrders.informationDetails")}
                </div>
              </div>
              <div
                className="myorders-container-buttonsection-second"
                onClick={() => {
                  smartPlanId && navigate("/smartplan/myOrders/" + id);
                  masterClassId && navigate("/masterclass/myOrders/" + id);
                  wellnessBodyId && navigate("/wellness/body/myOrders/" + id);
                  wellnessPsychoId &&
                    navigate("/wellness/psycho/myOrders/" + id);
                  wellnessNutritionalId &&
                    navigate("/wellness/nutritional/myOrders/" + id);
                }}
              >
                <div className="myorders-container-buttonsection-text">
                  {t("myOrders.orderDetails")}
                </div>
              </div>
            </div>
            {status !== "CANCELLED" && type !== "Wellness" && (
              <div
                className="myorders-container-buttonandtext"
                onClick={deleted}
              >
                {t("myOrders.undo") + " " + type}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default MyOrders;
