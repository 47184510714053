import { t } from "i18next";
import { TwentyIcCloseSmall } from "../../ui/components/icons/TwentyIcCloseSmall";
import { QueryParams } from "../Smartplan/SmartPlan";
import { useEffect, useState } from "react";
import { Button, Select, Slider, Space } from "antd";
import { TwentyIcTik } from "../../ui/components/icons/TwentyIcTik";
import { TwentyIcCreditCoins } from "../../ui/components/TwentyIcCreditCoins";
import {
  OfficeCoworkingFilters,
  getOfficeCoworkinFiltersAsync,
  officeCoworkingFiltersSelector,
} from "./OfficeCoworkingFiltersSlice";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { BaseOptionType } from "antd/lib/select";
import _ from "lodash";
import Tag from "../../ui/components/Tag/Tag";

interface OfficeCoworkingFilterModalProps {
  modalChange: (e: any) => any;
  setParam: (e: any) => any;
  isModalOpened: boolean;
  queryParams: any;
}

export const OfficeCoworkingFilterModal: React.FC<
  OfficeCoworkingFilterModalProps
> = ({ modalChange, setParam, isModalOpened, queryParams }) => {
  let minLimit = 0;
  let maxLimit = 10000;

  const [priceSelected, setPriceSelected] = useState<number | [number, number]>(
    [minLimit, maxLimit]
  );

  const [servicesSelected, setServicesSelected] = useState<string[]>([]);
  let locationsFromFilters: string[] = [];
  let servicesFromFilters: string[] = [];
  const [citiesSelected, setCitiesSelected] = useState<string[]>([]);

  const priceHandleChange = (value: number | [number, number]) => {
    if (Array.isArray(value)) {
      setPriceSelected(value);
    }
  };

  const servicesHandleChange = (value: string[]) => {
    setServicesSelected(value);
  };
  const citiesHandleChange = (value: string[]) => {
    setCitiesSelected(value);
  };

  const [cityItems, setCityItems] = useState<any>();
  const [servicesItems, setServicesItem] = useState<any>();

  let filters: OfficeCoworkingFilters = useAppSelector(
    officeCoworkingFiltersSelector.selectAll
  )[0];

  function tagRender(props: any) {
    const { label, value, closable, onClose } = props;

    return (
      <div style={{ paddingTop: "2px", paddingRight: "4px" }}>
        <Tag isRemoved={onClose} textOne={label} />
      </div>
    );
  }

  useEffect(() => {
    _.isEmpty(filters) && store.dispatch(getOfficeCoworkinFiltersAsync());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(queryParams)) {
      setPriceSelected([
        queryParams.get("minPrice") ?? minLimit,
        queryParams.get("maxPrice") ?? maxLimit,
      ]);

      if (!_.isEmpty(queryParams.get("cities"))) {
        for (let i = 0; i < queryParams.get("cities").length; i++) {
          locationsFromFilters.push(queryParams.get("cities")[i]);
        }
        locationsFromFilters.length > 0 &&
          setCitiesSelected(locationsFromFilters);
      }

      if (!_.isEmpty(queryParams.get("includedServices"))) {
        for (let i = 0; i < queryParams.get("includedServices").length; i++) {
          servicesFromFilters.push(queryParams.get("includedServices")[i]);
        }
        servicesFromFilters.length > 0 &&
          setServicesSelected(servicesFromFilters);
      }
    }
  }, []);

  useEffect(() => {
    if (filters?.cities.length > 0) {
      let newList: any = [];
      filters.cities.map((el) => {
        const newFilter: BaseOptionType = {
          label: el,
          value: el,
        };
        newList.push(newFilter);
      });
      setCityItems(newList);
    }
    if (filters?.services.length > 0) {
      let newList: any = [];
      filters.services.map((el) => {
        const newFilter: BaseOptionType = {
          label: t("coworking.filters." + el),
          value: el,
        };
        newList.push(newFilter);
      });
      setServicesItem(newList);
    }
  }, [filters]);

  function paramsBuilder() {
    let map = new Map();
    if (priceSelected) {
      let temp = priceSelected as [number, number];
      temp.sort((a, b) => a - b);

      if (temp[0] !== minLimit || temp[1] !== maxLimit) {
        map.set("minPrice", temp[0].toString());
        map.set("maxPrice", temp[1].toString());
      }
    }
    if (servicesSelected.length > 0) {
      let elementsServices = [] as string[];
      servicesSelected.map((el) => {
        elementsServices.push(el);
      });
      map.set("includedServices", elementsServices);
    }
    if (citiesSelected.length > 0) {
      let elementsCities = [] as string[];
      citiesSelected.map((el) => {
        elementsCities.push(el);
      });
      map.set("cities", elementsCities);
    }
    setParam(map);
  }

  return (
    <>
      <div className="mn-modal-backdrop"></div>
      <div className="smartplan-filtermodal">
        <div className="smartplan-filtermodal-header">
          <div className="smartplan-filtermodal-header-text">
            {t("smartPlan.common.filters")}
          </div>
          <div
            className="smartplan-filtermodal-header-iconclose"
            onClick={() => {
              modalChange(!isModalOpened);
            }}
          >
            <TwentyIcCloseSmall className={undefined}></TwentyIcCloseSmall>
          </div>
        </div>
        <div className="smartplan-filtermodal-content">
          <div className="smartplan-filtermodal-content-form">
            <div className="smartplan-filtermodal-text">Scegli una città</div>
            <Select
              placeholder={t("smartPlan.filterModal.select")}
              bordered={false}
              tagRender={tagRender}
              defaultValue={locationsFromFilters as any}
              mode="multiple"
              options={(cityItems as any) ?? undefined}
              onChange={citiesHandleChange}
              allowClear
              style={{
                paddingTop: "2px",
                paddingBottom: "4px",
                gap: "5px",
                alignItems: "center",
                width: "100%",
                minHeight: "40px",
                height: "100%",
                border: "1px solid #d9d9d9",
                borderRadius: "5px",
              }}
            />
          </div>
          <div className="smartplan-filtermodal-content-form">
            <div className="smartplan-filtermodal-text">
              Soluzioni nel coworking
            </div>
            <Select
              placeholder={t("smartPlan.filterModal.select")}
              bordered={false}
              tagRender={tagRender}
              defaultValue={servicesFromFilters}
              options={(servicesItems as any) ?? undefined}
              mode="multiple"
              allowClear
              onChange={servicesHandleChange}
              style={{
                paddingTop: "2px",
                paddingBottom: "4px",
                gap: "5px",
                alignItems: "center",
                width: "100%",
                minHeight: "40px",
                height: "100%",
                border: "1px solid #d9d9d9",
                borderRadius: "5px",
              }}
            />
          </div>

          <div className="smartplan-filtermodal-content-range-slider">
            <div className="smartplan-filtermodal-text">
              {t("smartPlan.components.rangeBoundPrice.price")}
            </div>
            <Slider
              value={priceSelected as [number, number]}
              onChange={priceHandleChange}
              defaultValue={[maxLimit, minLimit]}
              min={minLimit}
              max={maxLimit}
              style={{ width: "100%" }}
              range={{ draggableTrack: true }}
              trackStyle={[{ backgroundColor: "#36B39E" }]}
              handleStyle={[
                { boxShadow: "0 0 0 0 #36B39E", borderRadius: "50%" },
              ]}
            />
            <div className="smartPlan-filtermodal-content-range-slider-text">
              <div
                className="smartplan-filtermodal-text"
                style={{ display: "flex", alignItems: "center" }}
              >
                {Array.isArray(priceSelected) && priceSelected[0]}
                {
                  <TwentyIcCreditCoins
                    className={undefined}
                    color={"#35b39e"}
                    colorC={"#35b39e"}
                  />
                }
              </div>
              <div
                className="smartplan-filtermodal-text"
                style={{ display: "flex", alignItems: "center" }}
              >
                {Array.isArray(priceSelected) && priceSelected[1]}
                {
                  <TwentyIcCreditCoins
                    className={undefined}
                    color={"#35b39e"}
                    colorC={"#35b39e"}
                  />
                }
              </div>
            </div>
          </div>
          <div className="smartPlan-filtermodal-content-buttons">
            <Button
              onClick={() => {
                modalChange(!isModalOpened);
              }}
              style={{
                borderColor: "transparent",
                boxShadow: "none",
                height: "40px",
              }}
            >
              <Space>
                <div className="smartplan-filtermodal-text">
                  {t("smartPlan.common.cancel")}
                </div>
              </Space>
            </Button>
            <Button
              style={{
                borderColor: "#36B39E",
                backgroundColor: "#36B39E",
                boxShadow: "none",
                height: "40px",
              }}
              onClick={() => {
                paramsBuilder();
                modalChange(!isModalOpened);
              }}
            >
              <Space>
                <TwentyIcTik
                  className={"smartPlan-filtermodal-content-vector-confirm"}
                />
                <div className="smartplan-filtermodal-text-white">
                  {t("smartPlan.common.confirm")}
                </div>
              </Space>
            </Button>
          </div>
          <div className="smartplan-filtermodal-footer"></div>
        </div>
      </div>
    </>
  );
};

export default OfficeCoworkingFilterModal;
