import { t } from "i18next";
import React from "react";
import { MyOrdersProps } from "./MyOrders";
import "./OrderDetail.css";

const OrderDetailCard: React.FC<MyOrdersProps> = ({
  id,
  date,
  image,
  price,
  status,
  personsNumber,
  isWellnessService,
}) => {
  const months = [
    t("timeFormats.monthsFirstThree.January"),
    t("timeFormats.monthsFirstThree.February"),
    t("timeFormats.monthsFirstThree.March"),
    t("timeFormats.monthsFirstThree.April"),
    t("timeFormats.monthsFirstThree.May"),
    t("timeFormats.monthsFirstThree.June"),
    t("timeFormats.monthsFirstThree.July"),
    t("timeFormats.monthsFirstThree.August"),
    t("timeFormats.monthsFirstThree.September"),
    t("timeFormats.monthsFirstThree.October"),
    t("timeFormats.monthsFirstThree.November"),
    t("timeFormats.monthsFirstThree.December"),
  ];

  let newDate = new Date(date);

  let bookingDate =
    newDate.getDate().toString() +
    " " +
    months[newDate.getMonth()] +
    " " +
    newDate.getFullYear().toString();

  if (isWellnessService) status = "COMPLETED";

  return (
    <>
      <div className="orderdetail">
        <div className="orderdetail-container">
          <div className="orderdetail-title">{"Dettaglio ordine"}</div>
          <div className="orderdetail-separator" />
          <div className="orderdetail-container-description">
            <div className="myorders-container-information-second">
              <div className="myorders-container-information-second-row">
                <div className="myorders-container-information-second-text-first">
                  {t("myOrders.id")}
                </div>
                <div className="myorders-container-information-second-text-second">
                  {"#" + id}
                </div>
              </div>
              <div className="myorders-container-information-second-row">
                <div className="myorders-container-information-second-text-first">
                  {t("myOrders.date")}
                </div>
                <div className="myorders-container-information-second-text-second">
                  {bookingDate !== "" ? bookingDate : t("myOrders.notAvabile")}
                </div>
              </div>
              <div className="myorders-container-information-second-row">
                <div className="myorders-container-information-second-text-first">
                  {t("myOrders.price")}
                </div>
                <div className="myorders-container-information-second-text-second">
                  {isWellnessService
                    ? price + " Crediti · Online"
                    : price + "€"}
                </div>
              </div>
              <div className="myorders-container-information-second-row">
                <div className="myorders-container-information-second-text-first">
                  {t("myOrders.status")}
                </div>
                <div className="myorders-container-information-second-text-second">
                  {status === "PENDING" && (
                    <div className="paystatus-pending">
                      {t("myOrders.payPending")}
                    </div>
                  )}
                  {status === "COMPLETED" && (
                    <div className="paystatus-completed">
                      {t("myOrders.payCompleted")}
                    </div>
                  )}
                  {status === "CANCELLED" && (
                    <div className="paystatus-canceled">
                      {t("myOrders.payCanceled")}
                    </div>
                  )}
                  {(status === undefined || status === "") &&
                    t("myOrders.notAvabile")}
                </div>
              </div>
              <div className="myorders-container-information-second-row">
                <div className="myorders-container-information-second-text-first">
                  {t("myOrders.personsNumber")}
                </div>
                <div className="myorders-container-information-second-text-second">
                  {!!personsNumber && personsNumber > 0
                    ? personsNumber
                    : t("myOrders.notAvabile")}
                </div>
              </div>
            </div>
            {image && (
              <div className="orderdetail-image-container">
                <img className="orderdetail-image" src={image} alt="" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetailCard;
