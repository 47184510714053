import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import PublicAccountRepository from "./PublicAccountRepository";
import _ from "lodash";
import { Config } from "../../../config/Config";
import { EverywhereSuccessCodes } from "../../../config/EverywhereSuccessCodes";
import { RootState } from "../../../app/store";

export interface PublicAccount {
  id: number;
  providerType: string;
  personalPhotography: string;
  preferredShape: string;
  firstName: string;
  lastName: string;
  profileDescription: string;
  yearsOfExperience: number | undefined;
  residence: string;
  trainingAndSkills: string;
  publicEmail: string;
  publicTelephone: string;
  typologies: any[];
  userId: number;
}

export const getPublicAccountsAsync = createAsyncThunk(
  "publicAccount/getPublicAccountsAsync",
  async () => {
    const publicAccountRepository = new PublicAccountRepository();
    const response = await publicAccountRepository.getPublicAccounts();
    const publicAccount = _.get(response, Config.PUBLIC_ACCOUNT_RESPONSE);
    return publicAccount;
  }
);

export const getPublicAccountAsync = createAsyncThunk(
  "publicAccount/getPublicAccountAsync",
  async (data: { accountDetailId: number }) => {
    const publicAccountRepository = new PublicAccountRepository();
    const response = await publicAccountRepository.getPublicAccount(
      data.accountDetailId
    );
    const publicAccount = _.get(response, Config.PUBLIC_ACCOUNT_RESPONSE);
    return publicAccount;
  }
);

export const getPublicAccountByTenantIdAndUserIdAsync = createAsyncThunk(
  "publicAccount/getPublicAccountByTenantIdAndUserIdAsync",
  async (data: { tenantId: number; userId: number }) => {
    const publicAccountRepository = new PublicAccountRepository();
    const response =
      await publicAccountRepository.getPublicAccountByTenantIdAndUserIdAsync(
        data.tenantId,
        data.userId
      );
    const publicAccount = _.get(response, Config.PUBLIC_ACCOUNT_RESPONSE);
    return publicAccount;
  }
);

export const createPublicAccountAsync = createAsyncThunk(
  "publicAccount/createPublicAccountAsync",
  async (data: { publicAccount: any }) => {
    const publicAccountRepository = new PublicAccountRepository();
    const response = await publicAccountRepository.createPublicAccount(
      data.publicAccount
    );
    const publicAccount = _.get(response, Config.PUBLIC_ACCOUNT_RESPONSE);
    return publicAccount;
  }
);

export const partiallyUpdatePublicAccountAsync = createAsyncThunk(
  "publicAccount/partiallyUpdatePublicAccountAsync",
  async (data: { id: number; publicAccount: any }) => {
    const publicAccountRepository = new PublicAccountRepository();
    const response = await publicAccountRepository.partiallyUpdatePublicAccount(
      data.id,
      data.publicAccount
    );
    const publicAccount = _.get(response, Config.PUBLIC_ACCOUNT_RESPONSE);
    return publicAccount;
  }
);

const publicAccountAdapter = createEntityAdapter<PublicAccount>({
  selectId: (publicAccount) => publicAccount.id,
});

export const publicAccountSlice = createSlice({
  name: "publicAccount",
  initialState: publicAccountAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    publicAccountEmptyState: (state) => {
      publicAccountAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getPublicAccountsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          publicAccountAdapter.setAll(state, action.payload ?? []);
          state.status = "idle";
        }
      )
      .addCase(getPublicAccountsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getPublicAccountsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        getPublicAccountByTenantIdAndUserIdAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          publicAccountAdapter.setOne(state, action.payload ?? []);
          state.status = "idle";
        }
      )
      .addCase(
        getPublicAccountByTenantIdAndUserIdAsync.pending,
        (state: any) => {
          state.status = "loading";
        }
      )
      .addCase(
        getPublicAccountByTenantIdAndUserIdAsync.rejected,
        (state: any) => {
          state.status = "failed";
          state.reasonCode = "";
        }
      )
      .addCase(
        getPublicAccountAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          publicAccountAdapter.setAll(state, [action.payload] ?? []);
          state.status = "idle";
        }
      )
      .addCase(getPublicAccountAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getPublicAccountAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        createPublicAccountAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          publicAccountAdapter.upsertOne(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.POST;
        }
      )
      .addCase(createPublicAccountAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(createPublicAccountAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        partiallyUpdatePublicAccountAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload) {
            publicAccountAdapter.upsertOne(state, action.payload);
          }
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.PATCH;
        }
      )
      .addCase(partiallyUpdatePublicAccountAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(partiallyUpdatePublicAccountAsync.pending, (state: any) => {
        state.status = "loading";
      });
  },
});

export const publicAccountSelector =
  publicAccountAdapter.getSelectors<RootState>((state) => state.publicAccount);

export const { publicAccountEmptyState } = publicAccountSlice.actions;
export const selectPublicAccountSliceStatus = (state: any) =>
  state.publicAccount.status;
export const selectPublicAccountSliceReasonCode = (state: any) =>
  state.publicAccount.reasonCode;

//#endRegion Status

export default publicAccountSlice.reducer;
