/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcOrderBox = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-order-box ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 11.5V10.5C1.44772 10.5 1 10.9477 1 11.5H2ZM7.48508 11.5L8.4391 11.2003C8.30817 10.7835 7.9219 10.5 7.48508 10.5V11.5ZM18 11.5H19C19 10.9477 18.5523 10.5 18 10.5V11.5ZM12.5149 11.5V10.5C12.0781 10.5 11.6918 10.7835 11.5609 11.2003L12.5149 11.5ZM2 12.5H7.48508V10.5H2V12.5ZM3 15.5V11.5H1V15.5H3ZM4 16.5C3.44772 16.5 3 16.0523 3 15.5H1C1 17.1569 2.34315 18.5 4 18.5V16.5ZM16 16.5H4V18.5H16V16.5ZM17 15.5C17 16.0523 16.5523 16.5 16 16.5V18.5C17.6569 18.5 19 17.1569 19 15.5H17ZM17 11.5V15.5H19V11.5H17ZM12.5149 12.5H18V10.5H12.5149V12.5ZM11.5609 11.2003C11.3016 12.0256 10.6487 12.5 10 12.5V14.5C11.6734 14.5 12.9961 13.3048 13.469 11.7997L11.5609 11.2003ZM10 12.5C9.35128 12.5 8.69839 12.0256 8.4391 11.2003L6.53105 11.7997C7.00391 13.3048 8.32656 14.5 10 14.5V12.5Z"
        fill={color}
      />
      <path
        d="M2 11.5L2.63796 4.32292C2.72968 3.29105 3.59416 2.5 4.63011 2.5H15.3699C16.4058 2.5 17.2703 3.29104 17.362 4.32292L18 11.5"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
