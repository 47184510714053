import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { SmartDatePlan } from "../Smartplan/SmartDatePlan/SmartDatePlanSlice";
import { SmartPlan } from "../Smartplan/SmartPlanSlice";
import { UserInfo } from "../UserInfo/UserSlice";
import { WellnessBody } from "../Wellness/WellnessSlice/WellnessBodySlice";
import { WellnessNutritional } from "../Wellness/WellnessSlice/WellnessNutritionalSlice";
import { WellnessPsycho } from "../Wellness/WellnessSlice/WellnessPsychoSlice";
import { MasterClassDetail } from "../masterclass/MasterClassSlice";
import MyOrdersRepository from "./MyOrdersRepository";

export interface MyOrderDetail {
  id: number;
  bookingDate: Date;
  numberOfPeople: number;
  status: number;
  purchasedBy: number;
  tenantId: number;
  userInfo: UserInfo[];
  smartPlan?: SmartPlan;
  smartPlanDate?: SmartDatePlan;
  masterClass?: MasterClassDetail;
  wellnessBody?: WellnessBody;
  wellnessNutritional?: WellnessNutritional;
  wellnessPsycho?: WellnessPsycho;
}

export const getOrderAsync = createAsyncThunk(
  "myOrder/getOrder",
  async (tenantId: number) => {
    const myOrdersRepository = new MyOrdersRepository();
    const response = await myOrdersRepository.getOrderById(tenantId);
    const order = _.get(response, Config.MY_ORDER_RESPONSE);
    return order;
  }
);

export const createAssociationAsync = createAsyncThunk(
  "myOrder/createAssocation",
  async (data: { bookingId: number; queryString: string }) => {
    const myOrdersRepository = new MyOrdersRepository();
    const response = await myOrdersRepository.createAssociation(
      data.bookingId,
      data.queryString
    );
    const order = _.get(response, Config.MY_ORDER_RESPONSE);
    return order;
  }
);

export const deleteAssociationAsync = createAsyncThunk(
  "myOrder/deleteAssociation",
  async (data: { bookingId: number; queryString: string }) => {
    const myOrdersRepository = new MyOrdersRepository();
    const response = await myOrdersRepository.deleteAssociation(
      data.bookingId,
      data.queryString
    );
    const order = _.get(response, Config.MY_ORDER_RESPONSE);
    return order;
  }
);

//#region Slice

const myOrdersAdapter = createEntityAdapter<MyOrderDetail>({
  selectId: (myOrder) => myOrder.id,
});

export const myOrderDetailsSlice = createSlice({
  name: "myOrderDetails",
  initialState: myOrdersAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    elementOrdered: 0,
  }),
  reducers: {
    myOrdersEmptyState: (state) => {
      myOrdersAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
      state.elementOrdered = 0;
    },
    myOrdersElementOrderedEmptyState: (state) => {
      state.elementOrdered = 0;
      state.reasonCode = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getOrderAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          myOrdersAdapter.upsertOne(state, action.payload);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getOrderAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getOrderAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        createAssociationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          myOrdersAdapter.setOne(state, action.payload);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.POST;
        }
      )
      .addCase(createAssociationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(createAssociationAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        deleteAssociationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          myOrdersAdapter.setOne(state, action.payload);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.DELETE;
        }
      )
      .addCase(deleteAssociationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(deleteAssociationAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const myOrderDetailSelector = myOrdersAdapter.getSelectors<RootState>(
  (state) => state.myOrderDetail
);

export const { myOrdersEmptyState } = myOrderDetailsSlice.actions;
export const selectMyOrdersSliceStatus = (state: any) => state.myOrders.status;
export const selectMyOrdersSliceReasonCode = (state: any) =>
  state.myOrders.reasonCode;

//#endRegion Status

export default myOrderDetailsSlice.reducer;
