import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import HeaderMenu from "../../layout/HeaderMenu";
import { Footer } from "../../screens/Footer/Footer";
import { AssistanceBoxComponent } from "../../ui/components/AssistanceBoxComponent";
import OrderDetailCard from "../../ui/components/MyOrders/OrderDetailCard";
import { OrderSummary } from "../../ui/components/MyOrders/OrderSummary";
import { TopBar } from "../../ui/components/TopBar/TopBar";
import { TwentyIcArrowLineLeft } from "../../ui/components/icons/TwentyIcArrowLineLeft";
import {
  MeetingCreateRequest,
  createMeetingAsync,
} from "../Meetings/MeetingSlice";
import { getFilteredUsersInfoPaginationAsync } from "../MyTeam/TeamUsersInfoSlice";
import { WellnessPsycho } from "../Wellness/WellnessSlice/WellnessPsychoSlice";
import {
  MyOrderDetail,
  getOrderAsync,
  myOrderDetailSelector,
} from "./MyOrderDetailSlice";
import "./OrderDetail.css";

export interface WellnessPsychoOrderDetailProps {}

const WellnessPsychoOrderDetail: React.FC<
  WellnessPsychoOrderDetailProps
> = () => {
  const navigate = useNavigate();
  const [id, setId] = useState<number>(-1);
  const [wellnessPsycho, setWellnessPsycho] = useState<WellnessPsycho>();

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const businessName = localStorage.getItem("businessName");

  const orderRoute = useMatch("wellness/psycho/myOrders/:id");

  let order: MyOrderDetail = useAppSelector(
    (state) =>
      myOrderDetailSelector.selectById(state, id) ?? ({} as MyOrderDetail)
  );

  useCalendlyEventListener({
    onEventScheduled: (e) => createMeetingFromEvent(e.data.event),
  });

  function createMeetingFromEvent(url: any) {
    const currentDate = new Date();
    const options: Intl.DateTimeFormatOptions = {
      timeZone: "Europe/Rome",
      hour12: false,
    };
    const italyTimeString = currentDate.toLocaleString("en-US", options);
    const meetingFromPayload: MeetingCreateRequest = {
      invoiceDate: new Date(italyTimeString),
      invoicePrice: order.wellnessPsycho?.price ?? 0,
      meetingInvoiceStatus: "NOT_INVOICED",
      meetingStatus: "NOT_APPROVED",
      bookingId: order.id ?? -1,
      providerId: order.wellnessPsycho?.userInfo.id ?? -1,
      tenantId: order.wellnessPsycho?.tenant.id ?? -1,
    };
    store.dispatch(createMeetingAsync({ meeting: meetingFromPayload }));
  }

  useEffect(() => {
    if (orderRoute !== null) {
      if (orderRoute.params.id) {
        setId(parseInt(orderRoute.params.id));
        store.dispatch(getOrderAsync(parseInt(orderRoute.params.id)));
        store.dispatch(getFilteredUsersInfoPaginationAsync());
        return;
      }
    }
  }, [orderRoute]);

  useEffect(() => {
    if (order.wellnessPsycho) {
      setWellnessPsycho(order.wellnessPsycho);
    }
  }, [order]);

  return (
    <>
      {wellnessPsycho && (
        <>
          <HeaderMenu>
            <TopBar
              title={wellnessPsycho.name}
              hasEdit={false}
              hasDate={false}
              breadcrumbText={
                "Servizi per il benessere > I nostri psicologi > Dettaglio ordine"
              }
              icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
              onClick={() => navigate("/Wellness/Psycho/myOrders")}
            />
          </HeaderMenu>
          <div className="order-detail">
            <div className="order-detail-left-side">
              <OrderDetailCard
                id={order.id}
                date={order.bookingDate}
                price={wellnessPsycho.price}
                image={wellnessPsycho.image}
                status={order.status}
                personsNumber={order.numberOfPeople}
                isWellnessService={true}
              />
              {wellnessPsycho.link ? (
                <div className="calendly-inline-widget">
                  <InlineWidget url={wellnessPsycho.link} />
                </div>
              ) : null}
            </div>
            <div className="order-detail-right-side">
              <OrderSummary
                price={wellnessPsycho.price}
                numberOfPeople={order.numberOfPeople}
                bookingStatus={order.status}
                isWellnessService={true}
              ></OrderSummary>
              <div className="summary-column">
                <AssistanceBoxComponent
                  assistance={t(
                    "smartPlanDetail.componentAssistantBox.assistance"
                  )}
                  whatsApp={t("smartPlanDetail.componentAssistantBox.whatsApp")}
                  date={t("smartPlanDetail.componentAssistantBox.date")}
                  whatsAppNumber={3773604135}
                  whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."
                    .replace("firstName*", firstName ?? "")
                    .replace("lastName*", lastName ?? "")
                    .replace("companyName*", businessName ?? "")}
                  disabled={undefined}
                />
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default WellnessPsychoOrderDetail;
