export const wellnessFaqs: Array<{ [key: string]: string }> = [
  {
    question: "Posso riprogrammare il servizio acquistato?",
    answer:
      "Si, è possibile riprogrammare il servizio acquistato. Ogni fornitore mette a disposizione un calendar attraverso il quale si può programmare la consulenza scelta. ",
  },
  {
    question: "Quando posso usufruire del servizio?",
    answer:
      "Dopo aver acquistato il servizio, nell'area riservata 'miei ordini' troverai un calendario con le disponibilità.",
  },
  {
    question:
      "Come si svolgono le consulenze?",
    answer:
      "Le consulenze si svolgono online, tramite una videocall. Tutte le sessioni sono confidenziali e sicure.",
  },
  {
    question: "Quanto dura una consulenza?",
    answer:
      "La durata di una consulenza varia a seconda del tipo di servizio e sarà il consulente stesso ad indicare la durata.",
  }
];
