/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const TwentyIcLogout1 = ({ className }) => {
  return (
    <svg
      className={`twenty-ic-logout-1 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" opacity="0.5">
        <path
          className="path"
          d="M11 7V4H17V16H11V13"
          stroke="#222729"
          strokeWidth="2"
        />
        <path
          className="path"
          d="M6.66667 5C6.66667 7.91667 2 10 2 10M2 10C2 10 6.66667 12.0833 6.66667 15M2 10H15"
          stroke="#222729"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
