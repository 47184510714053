import { useTranslation } from "react-i18next";
import "./OfficeCoworkingActivityTail.css";
import { useNavigate } from "react-router-dom";
import { TwentyIcPinMap } from "../../ui/components/icons/TwentyIcPinMap";
import { TwentyIcArrowLineRight } from "../../ui/components/icons/TwentyIcArrowLineRight";

export interface OfficeCoworkingActivityTailProps {
  id: number | undefined;
  img: any;
  title: string | undefined;
  region: string | undefined;
  city: string | undefined;
  navigation?: (e: any) => any;
  arrow: boolean;
  reactionButton?: (e: any) => any;
}

const OfficeCoworkingActivityTailComponent: React.FC<
  OfficeCoworkingActivityTailProps
> = ({ id, img, title, region, city, arrow, navigation }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className="activity-tail__wrapper">
        <span className="activity-tail__image">
          <img className="activity-tail__container" alt="Rectangle" src={img} />
          {/* {isMostPopular && (
             <span className="activity-tail__most-popular">
               <div className="activity-tail__most-popular-text">
                 {t("Wellness.components.activityTail.mostPopular")}
               </div>
             </span>
           )} */}
          {/* <img
             className="activity-tail__frame"
             alt="Frame"
             src={!isFavorite ? favouritesImg : favouritesImgLiked}
             onClick={reactionButton}
           /> */}
        </span>
        <div className="activity-tail__frame-wrapper">
          <div className="activity-tail__sections">
            <div className="activity-tail__second-section">
              <div className="activity-tail__second-section-wrapper">
                <div className="activity-tail__second-title">{title}</div>
              </div>
            </div>
            <div className="activity-tail__first-section">
              <div style={{ display: "flex" }}>
                <TwentyIcPinMap color="#86D1C5" className="" />
                <div className="activity-tail__first-title">
                  {city + " · " + region}
                </div>
              </div>
              {arrow && (
                <div className="activity-tail____twentyicarrowlineright ">
                  <TwentyIcArrowLineRight
                    color="#36B39E"
                    className="activity-tail____wentyicarrowlineright"
                    onClick={() => {
                      navigate("/officeCoworking/" + id + "/details");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfficeCoworkingActivityTailComponent;
