import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { ImageConfig } from "../../../config/ImageConfig";
import HeaderMenu from "../../../layout/HeaderMenu";
import { Footer } from "../../../screens/Footer/Footer";
import { AssistanceBoxComponent } from "../../../ui/components/AssistanceBoxComponent";
import { Descriptions } from "../../../ui/components/Descriptions";
import { Map } from "../../../ui/components/Map/Map";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { WellnessSummaryComponent } from "../../../ui/components/WellnessSummaryComponent";
import { TwentyIcArrowLeft } from "../../../ui/components/icons/TwentyIcArrowLeft";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";
import { TwentyIcArrowRight } from "../../../ui/components/icons/TwentyIcArrowRight";
import { getPublicAccountByTenantIdAndUserIdAsync } from "../../Account/PublicAccount/PublicAccountSlice";
import { getWellnessBodyGalleyAsync } from "../../Gallery/GallerySlice";
import {
  IncludedService,
  includedServiceSelector,
} from "../../IncludedServices/IncludedServiceSlice";
import "../../Wellness/WellnessFeatures/WellnessBodyDetails.css";
import {
  getWellnessBodyDetailsAsync,
  wellnessBodySelector,
} from "../../Wellness/WellnessSlice/WellnessBodySlice";
import {
  getWellnessNutritionalDetailsAsync,
  wellnessNutritionalSelector,
} from "../../Wellness/WellnessSlice/WellnessNutritionalSlice";
import {
  getWellnessPsychoDetailsAsync,
  wellnessPsychoSelector,
} from "../../Wellness/WellnessSlice/WellnessPsychoSlice";

export const PreviewService = () => {
  const previewRoute = useMatch("/myService/previewService/:id");

  const userId = localStorage.getItem("customerId");
  const tenantId = localStorage.getItem("tenantId");

  const [galleryImage, setGalleryImage] = useState<any>([]);
  const [id, setId] = useState(0);
  const [publicAccount, setPublicAccount] = useState<any>();

  const [galleryOpen, setGalleryOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const openGallery = (index: number) => {
    setSelectedImageIndex(index);
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };
  const securityProfile = localStorage.getItem("securityProfile");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const typlology = queryParams.get("typology");
  const accountId = queryParams.get("accountId");
  const tenantIdFromURL = queryParams.get("tenantId");
  useEffect(() => {
    const fetchData = async () => {
      if (
        previewRoute !== null &&
        previewRoute.params.id &&
        accountId &&
        tenantIdFromURL
      ) {
        setId(parseInt(previewRoute.params.id));

        store
          .dispatch(
            getPublicAccountByTenantIdAndUserIdAsync({
              userId: parseInt(accountId),
              tenantId: parseInt(tenantIdFromURL),
            })
          )
          .then((response) => {
            if (response && response.payload && response.payload.providerType) {
              setPublicAccount(response.payload);
              switch (response.payload.providerType) {
                case "NUTRITIONIST":
                  store.dispatch(
                    getWellnessNutritionalDetailsAsync(
                      parseInt(previewRoute.params.id ?? "")
                    )
                  );
                  break;
                case "PSYCHOLOGIST":
                  store.dispatch(
                    getWellnessPsychoDetailsAsync(
                      parseInt(previewRoute.params.id ?? "")
                    )
                  );
                  break;
                case "COACH":
                  store.dispatch(
                    getWellnessBodyDetailsAsync(
                      parseInt(previewRoute.params.id ?? "")
                    )
                  );

                  break;
                default:
                  break;
              }
            }
          });
      }
    };

    fetchData();
  }, [previewRoute]);

  let wellness = useAppSelector((state) => {
    if (publicAccount && publicAccount?.providerType) {
      switch (publicAccount?.providerType) {
        case "COACH":
          return wellnessBodySelector.selectById(state, id);
        case "NUTRITIONIST":
          return wellnessNutritionalSelector.selectById(state, id);
        case "PSYCHOLOGIST":
          return wellnessPsychoSelector.selectById(state, id);
        default:
          return null;
      }
    } else if (localStorage.getItem("securityProfile") === "SUPER_ADMIN") {
      switch (typlology) {
        case "BODY":
          return wellnessBodySelector.selectById(state, id);
        case "NUTRITIONAL":
          return wellnessNutritionalSelector.selectById(state, id);
        case "PSYCHO":
          return wellnessPsychoSelector.selectById(state, id);
        default:
          return null;
      }
    } else {
      return null;
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      if (id && id > 0) {
        store
          .dispatch(
            getWellnessBodyGalleyAsync({
              wellnessId: id,
            })
          )
          .then((response: any) => {
            if (response && response?.payload && response.payload.gallery) {
              const galleryValues = Object.values(response.payload.gallery);

              const galleryList = galleryValues.map((item: any) => ({
                id: item.id,
                image: item.image,
              }));

              setGalleryImage(galleryList);
            }
          });
      }
    };

    fetchData();
  }, [id]);

  let includedService: IncludedService[] = useAppSelector(
    includedServiceSelector.selectAll
  );

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const businessName = localStorage.getItem("businessName");

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (galleryOpen) {
        if (e.key === "ArrowLeft") {
          const newIndex =
            currentImageIndex === 0
              ? galleryImage?.length - 1
              : currentImageIndex - 1;
          setSelectedImageIndex(newIndex);
          setCurrentImageIndex(newIndex);
        } else if (e.key === "ArrowRight") {
          const newIndex =
            currentImageIndex === galleryImage?.length - 1
              ? 0
              : currentImageIndex + 1;
          setSelectedImageIndex(newIndex);
          setCurrentImageIndex(newIndex);
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [galleryOpen, galleryImage, currentImageIndex]);

  const navigate = useNavigate();

  return (
    <>
      <HeaderMenu>
        <TopBar
          title={wellness?.name ?? ""}
          hasEdit={false}
          hasDate={false}
          breadcrumbText={t("wellness.wellness") + " > " + "Anteprima"}
          icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
          onClick={() => {
            navigate(-1);
          }}
        />
      </HeaderMenu>
      <div className="wellnessbodydetail-container">
        {publicAccount && publicAccount.providerType === "COACH" ? (
          <>
            <img
              className="rectangle-8"
              alt=""
              src={
                wellness && wellness?.image
                  ? wellness?.image
                  : ImageConfig.IMAGE_DEFAULT_SERVICE
              }
            />
          </>
        ) : (
          <div
            style={{
              width: "100%",
              position: "relative",
              alignItems: "center",
              top: "10px",
            }}
          >
            <img
              className="rectangle-8"
              alt=""
              src={ImageConfig.IMAGE_DEFAULT_SERVICE}
            />
            {publicAccount?.personalPhotography && (
              <img
                className="wellness-image2-user-provider-preview"
                alt=""
                src={publicAccount?.personalPhotography}
              />
            )}
          </div>
        )}

        <div className="overlap-2">
          <div className="wellnessbodydetail-bady-page">
            <div className="text-wrapper-21-service">
              {t("smartPlanDetail.smartPlanServiceComponent.service")}
            </div>
            <div className="service-container">
              {wellness?.remotelyOrPresenceEnum && (
                <div>
                  <div className="service-title">
                    {t("wellness.services.mode")}
                  </div>
                  <div className="service-subtitle">
                    {t("stringBuilder.remote")}
                  </div>
                </div>
              )}
              {wellness?.durationMeeting && (
                <div>
                  <div className="service-title">
                    {t("wellness.services.duration")}
                  </div>
                  <div className="service-subtitle">
                    {wellness?.durationMeeting}
                  </div>
                </div>
              )}
              {wellness?.city && (
                <div>
                  <div className="service-title">
                    {t("wellness.services.location")}
                  </div>
                  <div className="service-subtitle">{wellness?.city}</div>
                </div>
              )}
              {publicAccount?.providerType !== "NUTRITIONIST" && (
                <div>
                  <div className="service-title">
                    {t("wellness.services.typology")}
                  </div>
                  <div className="service-subtitle">
                    {!wellness?.isIndividual
                      ? t("stringBuilder.multiSession")
                      : t("stringBuilder.singleSession")}
                  </div>
                </div>
              )}
              {wellness?.numberMeeting && (
                <div>
                  <div className="service-title">
                    {t("wellness.services.sessions")}
                  </div>
                  <div className="service-subtitle">
                    {wellness?.numberMeeting > 1 ? (
                      <>{wellness?.numberMeeting} Sedute</>
                    ) : (
                      <>{wellness?.numberMeeting} Seduta</>
                    )}
                  </div>
                </div>
              )}
              <div>
                <div className="service-title">
                  {t("wellness.services.assistance")}
                </div>
                <div className="service-subtitle">
                  {t("wellness.services.included")}
                </div>
              </div>
            </div>
            <div className="service-container">
              {includedService.map((el) => (
                <div>
                  <div className="service-title">{el.title}</div>
                  <div className="service-subtitle">{el.description}</div>
                </div>
              ))}
            </div>
            <div className="master-class">
              {!_.isEmpty(galleryImage) && (
                <>
                  <div className="title-gallery">
                    {t("smartPlanDetail.gallery")}
                  </div>
                  <div className="flex-container-gallery">
                    {galleryImage.map((item: any, index: any) => (
                      <div className="gallery-container" key={index}>
                        <img
                          className="gallery-image"
                          src={item.image}
                          alt={`Image ${item.id}`}
                          onClick={() => {
                            openGallery(index);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
              {galleryOpen && (
                <div className="gallery-overlay">
                  <button className="close-button" onClick={closeGallery}>
                    x
                  </button>
                  <button
                    className="prev-button"
                    onClick={() => {
                      setSelectedImageIndex((prev) =>
                        prev === 0 ? galleryImage?.length - 1 : prev - 1
                      );
                    }}
                  >
                    <TwentyIcArrowLeft className="arrow-gallery" />
                  </button>
                  <button
                    className="next-button"
                    onClick={() => {
                      setSelectedImageIndex((prev) =>
                        prev === galleryImage?.length - 1 ? 0 : prev + 1
                      );
                    }}
                  >
                    <TwentyIcArrowRight
                      className="arrow-gallery"
                      onClick={undefined}
                    />
                  </button>
                  <div className="gallery">
                    <img
                      src={
                        galleryImage && galleryImage[selectedImageIndex].image
                      }
                      alt="img"
                      className="gallery-image-large"
                    />
                  </div>
                </div>
              )}
            </div>
            {wellness &&
              wellness?.description !== "" &&
              wellness?.description !== null && (
                <>
                  <div className="text-wrapper-21-description">
                    {t("smartPlanDetail.description.description")}
                  </div>
                  <Descriptions
                    initialText={
                      wellness?.description ? wellness?.description : ""
                    }
                    maxLength={300}
                  />
                </>
              )}
            {wellness?.remotelyOrPresenceEnum !== "REMOTE" &&
              wellness?.city && (
                <>
                  <div className="text-wrapper-21-map">
                    {t("smartPlanDetail.map")}
                  </div>
                  <Map city={wellness?.city} />
                </>
              )}
          </div>
          <div className="container-summary">
            <div className="summary-wrapper">
            <WellnessSummaryComponent
              type="BODY"
              email={wellness?.userInfo?.email}
              voucherPrice={wellness?.price ?? 0}
              telephone={wellness?.userInfo?.telephone}
              onClick={() => {}}
              disabled={true}
            />

            <AssistanceBoxComponent
              assistance={t("smartPlanDetail.componentAssistantBox.assistance")}
              whatsApp={t("smartPlanDetail.componentAssistantBox.whatsApp")}
              date={t("smartPlanDetail.componentAssistantBox.date")}
              whatsAppNumber={3773604135}
              whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."
                .replace("firstName*", firstName ?? "")
                .replace("lastName*", lastName ?? "")
                .replace("companyName*", businessName ?? "")}
              disabled={true}
            />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
