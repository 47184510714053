import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { SearchInput } from "../../ui/components/Input";
import "../MyService/MyServiceFilterBar.css";
import { Button, Space } from "antd";
import { TwentyIcAdd } from "../../ui/components/icons/TwentyIcAdd";
interface MyServiceFilterBarProps {
  searched: (e: any) => any;
  wellnessTableLength: number;
  setModalStatus: (e: any) => any;
}

export const MyServiceFilterBar: React.FC<MyServiceFilterBarProps> = ({
  searched,
  setModalStatus,
  wellnessTableLength,
}) => {
  const [modalStatuss, setModalStatuss] = useState(false);
  const secutiryProfile = localStorage.getItem("securityProfile");

  useEffect(() => {
    setModalStatus(modalStatuss);
  }, [modalStatuss]);

  return (
    <>
      <div className="myService-filterbar">
        <div className="title-text">Tutti i servizi </div>

        {/* SEARCH ZONE */}
        <div className="myService-filterbar-leftsection">
          <div className="myService-filterBar-search">
            <SearchInput
              baseInputText="Ricerca servizi"
              inputSearched={(e) => searched(e)}
            />
          </div>
          {secutiryProfile !== "SUPER_ADMIN" && (
            <Button
              style={{
                borderColor: "#36B39E",
                backgroundColor: "#36B39E",
                boxShadow: "none",
                height: "100%",
                display: "flex",
                gap: "4px",
                alignItems: "center",
              }}
              onClick={() => setModalStatuss(!modalStatuss)}
            >
              <Space style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TwentyIcAdd className={undefined} color="white" />
                </div>
                <div className="smartplan-filtermodal-text-white">
                  {t("myService.components.filterBar.newService")}
                </div>
              </Space>
            </Button>
          )}{" "}
        </div>
      </div>
      <div className="text-service">
        Servizi &nbsp;
        <div className="text-length"> ({wellnessTableLength})</div>
      </div>
      {/* END SEARCH ZONE */}
    </>
  );
};
