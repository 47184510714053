import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { UserInfo } from "../UserInfo/UserSlice";
import CreditRepository from "./CreditRepository";
import _ from "lodash";
import { Config } from "../../config/Config";
import { RootState } from "../../app/store";

export interface UserSendMapWrapper {
  userId: number;
  balance: number;
}
export interface Transaction {
  id: number;
  creditAmount: number;
  date: Date;
}

export interface CreditHistory {
  id: number;
  user: UserInfo;
  transaction: Transaction;
  totalRecharge: number;
  lastPurchaseAmount: number;
  creditsAvabile: number;
  date: Date;
}

export const rechargeEmployeeAsync = createAsyncThunk(
  "credits/rechargeEmployee",
  async (data: { userId: number; usersToRecharge: UserSendMapWrapper[] }) => {
    const creditRepository = new CreditRepository();
    const response = await creditRepository.rechargeEmployee(
      data.userId,
      data.usersToRecharge
    );
    return response;
  }
);

export const getMyAdminRechargeAsync = createAsyncThunk(
  "credits/getMyAdminRecharge",
  async (userId: number) => {
    const creditRepository = new CreditRepository();
    const response = await creditRepository.getMyAdminRecharge(userId);
    const recharges = _.get(response, Config.CREDIT_EMPLOYEE_RECHARGE_RESPONSE);
    return recharges;
  }
);

export const getMyEmployeeRechargeAsync = createAsyncThunk(
  "credits/getMyEmployeeRecharge",
  async (userId: number) => {
    const creditRepository = new CreditRepository();
    const response = await creditRepository.getMyEmployeeRecharge(userId);
    const recharges = _.get(response, Config.CREDIT_EMPLOYEE_RECHARGE_RESPONSE);
    const rechargesFiltered = recharges.filter(
      (el: CreditHistory) =>
        el.id.toString() !== localStorage.getItem("customerId")
    );
    return rechargesFiltered;
  }
);

const creditHistoryAdapter = createEntityAdapter<CreditHistory>({
  selectId: (creditHistory) => creditHistory.id,
});

export const creditHistorySlice = createSlice({
  name: "creditHistory",
  initialState: creditHistoryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    creditHistoryEmptyState: (state) => {
      creditHistoryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getMyEmployeeRechargeAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          creditHistoryAdapter.setAll(state, action.payload ?? []);
          state.status = "idle";
        }
      )
      .addCase(getMyEmployeeRechargeAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getMyEmployeeRechargeAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        getMyAdminRechargeAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          creditHistoryAdapter.setAll(state, action.payload ?? []);
          state.status = "idle";
        }
      )
      .addCase(getMyAdminRechargeAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getMyAdminRechargeAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        rechargeEmployeeAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.status = "idle";
        }
      )
      .addCase(rechargeEmployeeAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(rechargeEmployeeAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const creditHistorySelector =
  creditHistoryAdapter.getSelectors<RootState>((state) => state.creditHistory);

export const { creditHistoryEmptyState } = creditHistorySlice.actions;
export const selectCreditHistorySliceStatus = (state: any) =>
  state.creditHistory.status;
export const selectCreditHistorySliceReasonCode = (state: any) =>
  state.creditHistory.reasonCode;

export default creditHistorySlice.reducer;
