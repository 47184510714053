import React from "react";

export const TwentyIcArrowDown1 = ({ className }) => {
  return (
    <svg
      className={`twenty-ic-arrow-down-1 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M15 9C12.5 9 10 13 10 13C10 13 7.5 9 5 9"
        stroke="#36B39E"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
