import { useEffect, useState } from "react";
import "../MyServiceModal/DeleteServiceModal.css";
import { IconInfo } from "../../../ui/components/icons/IconInfo";
import { Button } from "antd";
import { IconTik } from "../../../ui/components/icons/IconTik";
import { t } from "i18next";

interface DeleteServiceModalProps {
  closeModal: (e: any) => any;
  statusModal: boolean;
  onClick: (e: any) => void;
}

export const DeleteServiceModal: React.FC<DeleteServiceModalProps> = ({
  closeModal,
  statusModal,
  onClick,
}) => {
  const [modalStatus, setModalStatus] = useState(statusModal);

  useEffect(() => {
    closeModal(modalStatus);
  }, [modalStatus]);

  return (
    <div className="mn-delete-service-modal-backdrop">
      <div className="delete-service-modal-container">
        <div className="delete-service-modal-icon-info-container">
          <IconInfo className={undefined} />
        </div>
        <div className="description-delete-modal">
          {t("provider.infoDelete")}
        </div>
        <div className="delete-service-modal-buttons-container">
          <Button
            className="delete-service-button-dimiss"
            onClick={() => setModalStatus(!modalStatus)}
          >
            <div className="delete-service-button-dimiss-text">
              {t("provider.cancelDelete")}
            </div>
          </Button>
          <Button
            className="delete-service-button-delete"
            onClick={(e) => onClick(e)}
          >
            <div className="delete-service-button-cancel-text">
              <IconTik className={undefined} color="#ffffff" />
              {t("provider.deleteService")}
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
