import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import OfficeCoworkingRepository from "./OfficeCoworkingRepository";
import { Config } from "../../config/Config";
import _ from "lodash";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { RootState } from "../../app/store";

export interface OfficeCoworkingFilters {
  id: number;
  services: string[];
  cities: string[];
}

export const getOfficeCoworkinFiltersAsync = createAsyncThunk(
  "officeCoworkingFilters/getOfficeFiltersCoworking",
  async () => {
    const officeCoworkingRepository = new OfficeCoworkingRepository();
    const response =
      await officeCoworkingRepository.getOfficeCoworkingFilters();
    const officeCoworkingFilters = _.get(
      response,
      Config.OFFICE_COWORKING_RESPONSE
    );
    return officeCoworkingFilters;
  }
);

const officeCoworkingFiltersAdapter =
  createEntityAdapter<OfficeCoworkingFilters>({
    selectId: (officeCoworkingFilters) => officeCoworkingFilters.id,
  });

export const officeCoworkingFiltersSlice = createSlice({
  name: "officeCoworkingFilters",
  initialState: officeCoworkingFiltersAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    officeCoworkingFiltersEmptyState: (state) => {
      officeCoworkingFiltersAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(
        getOfficeCoworkinFiltersAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          officeCoworkingFiltersAdapter.upsertOne(state, action.payload);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getOfficeCoworkinFiltersAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getOfficeCoworkinFiltersAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

export const officeCoworkingFiltersSelector =
  officeCoworkingFiltersAdapter.getSelectors<RootState>(
    (state) => state.officeCoworkingFilters
  );
export const { officeCoworkingFiltersEmptyState } =
  officeCoworkingFiltersSlice.actions;
export const selectOfficeCoworkingFiltersSliceStatus = (state: any) =>
  state.officeCoworkingFilters.status;
export const selectOfficeCoworkingFiltersSliceReasonCode = (state: any) =>
  state.officeCoworkingFilters.reasonCode;

export default officeCoworkingFiltersSlice.reducer;
