/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const TwentyIcArrowRight1 = ({ color = "#000", className }) => {
  return (
    <svg
      className={`twenty-ic-arrow-right-1 ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 20 21"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9 5.5C9 8 13 10.5 13 10.5C13 10.5 9 13 9 15.5"
        stroke="#000"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

TwentyIcArrowRight1.propTypes = {
  color: PropTypes.string,
};
