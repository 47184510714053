import { useEffect, useState } from "react";
import { Logo } from "../../../ui/components/Logo/Logo";
import { ModalBody } from "../../../ui/components/Modals/ModalBody";
import { ModalFooter } from "../../../ui/components/Modals/ModalFooter";
import { ModalWrapper } from "../../../ui/components/Modals/ModalWrapper";
import "../NewProviderAccount/NewProviderAccount.css";
import { useNavigate } from "react-router-dom";
import { store } from "../../../app/store";
import { UserInfo, getUserInfoAsync } from "../../UserInfo/UserSlice";
import { Input } from "antd";
import { TwentyIcLockNewPassword } from "../../../ui/components/icons/TwentyIcLockNewPassword";
import { TwentyIcHelp } from "../../../ui/components/icons/TwentyIcHelp";
import { TwentyIcUnlock } from "../../../ui/components/icons/TwentyIcUnlock";
import Form from "../../../ui/components/Form/Form";
import { ToastNotification } from "../../../ui/components/utils/ToastNotification";
import { t } from "i18next";
import {
  ChangePassword,
  updatePasswordOnFirstAccessAsync,
} from "../../ChangePassword/ChangePasswordSlice";

interface NewProviderAccountProps {
  isLogged: () => void;
  logoutDone: () => void;
}

export const NewProviderAccount = (Props: NewProviderAccountProps) => {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const tenantId = localStorage.getItem("tenantId");
  const userId = localStorage.getItem("customerId");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    store
      .dispatch(
        getUserInfoAsync({
          userInfoId: parseInt(userId ?? ""),
          tenantId: tenantId,
        })
      )
      .then((response) => {
        if (response && response.payload) {
          setUserInfo(response.payload);
        }
      });
  }, []);

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  const currentDate = new Date();
  const hoursItalian = currentDate.setHours(currentDate.getHours() + 2);

  const handleCheckPassword = () => {
    if (
      password !== "" &&
      password !== undefined &&
      confirmPassword !== "" &&
      confirmPassword !== undefined &&
      password === confirmPassword
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleChangePassword = (password: string, confirmPassword: string) => {
    setIsLoading(true);

    let errorMessage = "";

    if (password.length < 8) {
      errorMessage = t("toastNotification.password8Char");
    } else if (!/[A-Z]/.test(password)) {
      errorMessage = t("toastNotification.passwordMaiuscLetter");
    } else if (!/\d/.test(password)) {
      errorMessage = t("toastNotification.passwordContainsNumber");
    } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
      errorMessage = t("toastNotification.passwordContainsSpecialChar");
    }

    if (errorMessage) {
      ToastNotification({
        status: "error",
        description: errorMessage,
        toastId: "",
        title: t("toastNotification.incorrectCredentials"),
      });
      return;
    }

    const changePassword: ChangePassword = {
      id: 0,
      newPassword: password,
      userId: parseInt(userId ?? ""),
      lastUpdate: new Date(hoursItalian),
    };

    store
      .dispatch(
        updatePasswordOnFirstAccessAsync({ changePassword: changePassword })
      )
      .then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          ToastNotification({
            status: "success",
            description: "",
            toastId: "",
            title: t("toastNotification.passwordSucccess"),
          });
          localStorage.setItem("isLoggedIn", "true");
          Props.isLogged();
          setIsLoading(false);
          navigate("/home");
        } else {
          ToastNotification({
            status: "error",
            description: "",
            toastId: "loginFailed",
            title: "La nuova password è uguale alla precedente",
          });
          Props.logoutDone();
          setIsLoading(false);
          localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
  };

  return (
    <div className="daccountdetails">
      <div className="daccountdetails-container">
        <div className="daccountdetails-logo">
          <Logo width="186px" height="28px" />
        </div>
      </div>
      <div className="modal-wrapper-first-access">
        <ModalWrapper open={true}>
          <div className="modal-body-first-access">
            <ModalBody title="">
              <div className="title-modal-first-access">
                Ciao {userInfo?.firstname}!
              </div>
              <div className="description-modal-first-access">
                Prima di accedere alla piattaforma, aggiorna la tua password di
                accesso{" "}
              </div>
              <div className="description2-modal-first-access">
                Potrai impostare di nuovo le password dalla tua area personale
              </div>
              <div className="fisrt-access-modal-password">
                <Form>
                  <div className="first-access-first-row">
                    <span className="label-textfield-first-access">
                      Scegli una nuova password
                      <span className="icon-textfield">
                        <TwentyIcHelp className={undefined} />{" "}
                      </span>
                    </span>
                    <span className="modal-changepassword-first-access">
                      Min. 8 car.
                    </span>
                  </div>
                  <div className="first-access-password">
                    <Input
                      id="password-field"
                      value={password}
                      type="password"
                      size="large"
                      placeholder="Password"
                      prefix={
                        <TwentyIcLockNewPassword
                          color={"#BDBEBF"}
                          className={undefined}
                        />
                      }
                      onChange={handlePasswordChange}
                    />
                  </div>
                </Form>
                <Form>
                  <br />
                  <span className="label-textfield">
                    Ripeti la nuova password
                  </span>

                  <div>
                    <Input
                      id="password-field"
                      value={confirmPassword}
                      type="password"
                      size="large"
                      placeholder="Password"
                      prefix={
                        <TwentyIcUnlock
                          color={"#BDBEBF"}
                          className={undefined}
                        />
                      }
                      onChange={handleConfirmPasswordChange}
                    />
                  </div>
                </Form>
              </div>
            </ModalBody>
          </div>
          <ModalFooter
            disablePrimaryButton={handleCheckPassword()}
            primaryLabel={"Conferma"}
            // loadingPrimaryButton={isLoading}
            isActionPerforming={isLoading}
            primaryAction={() => {
              handleChangePassword(password, confirmPassword);
            }}
          />
        </ModalWrapper>
      </div>
    </div>
  );
};
