/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcPresentation = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-presentation ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M18 14V2H15.7778H4.22222H2V14H18Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M10 14V18"
        stroke={color}
        strokeLinecap="square"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M10 1V2"
        stroke={color}
        strokeLinecap="square"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M8 18H12"
        stroke={color}
        strokeLinecap="square"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M2 5H18"
        stroke={color}
        strokeLinecap="square"
        strokeWidth="2"
      />
    </svg>
  );
};
