import { format, parseISO } from "date-fns";
import { it } from "date-fns/locale";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import HeaderMenu from "../../../layout/HeaderMenu";
import { Footer } from "../../../screens/Footer/Footer";
import { AssistanceBoxComponent } from "../../../ui/components/AssistanceBoxComponent";
import { Descriptions } from "../../../ui/components/Descriptions";
import { Map } from "../../../ui/components/Map/Map";
import QuestionsAndAnswers from "../../../ui/components/QuestionsAndAnswersComponent/QuestionsAndAnswers";
import { SummaryComponent } from "../../../ui/components/SummaryComponent";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { TwentyIcArrowLeft } from "../../../ui/components/icons/TwentyIcArrowLeft";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";
import { TwentyIcArrowRight } from "../../../ui/components/icons/TwentyIcArrowRight";
import { Faq, faqSelector, getMasterClassFaqsAsync } from "../../Faq/FaqSlice";
import {
  Gallery,
  gallerySelector,
  getMasterClassGalleyAsync,
} from "../../Gallery/GallerySlice";
import {
  IncludedService,
  getMasterClassIncludedServiceAsync,
  includedServiceSelector,
} from "../../IncludedServices/IncludedServiceSlice";
import {
  MasterClassDetail,
  getMasterClassAsyc,
  masterClassSelector,
} from "../MasterClassSlice";
import "./MasterClassDetails.css";

import _ from "lodash";
import { ToastNotification } from "../../../ui/components/utils/ToastNotification";
import {
  CheckoutItem,
  MyOrder,
  createCheckoutSessionAsync,
  createOrderAsync,
  selectMyOrdersSliceStatus,
} from "../../myorders/MyOrdersSlice";
import { MasterClassModal } from "../masterclassModal/MasterClassModal";
import { formatText } from "../../../utils/utils";
import { Button, Input } from "antd";

export const MasterClassDetails = () => {
  const masterClassDetailsRoute = useMatch("masterclass/:id/details");

  const [id, setId] = useState<number>(-1);

  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const openGallery = (index: number) => {
    setSelectedImageIndex(index);
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };

  const [liked, setLiked] = useState(false);

  let masterClass: MasterClassDetail = useAppSelector(
    (state) =>
      masterClassSelector.selectById(state, id) ?? ({} as MasterClassDetail)
  );
  let includedService: IncludedService[] = useAppSelector(
    includedServiceSelector.selectAll
  );

  let faq: Faq[] = useAppSelector(faqSelector.selectAll);

  let gallery: Gallery[] = useAppSelector(gallerySelector.selectAll);

  const imageList = gallery.map((el) => el.image);
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const businessName = localStorage.getItem("businessName");

  const [bookNowModalId, setBookNowModalId] = useState<number>(-1);

  let bookNowModalObj: MasterClassDetail = useAppSelector(
    (state) =>
      masterClassSelector.selectById(state, bookNowModalId) ??
      ({} as MasterClassDetail)
  );

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (isGalleryOpen) {
        if (e.key === "ArrowLeft") {
          const newIndex =
            currentImageIndex === 0
              ? imageList.length - 1
              : currentImageIndex - 1;
          setSelectedImageIndex(newIndex);
          setCurrentImageIndex(newIndex);
        } else if (e.key === "ArrowRight") {
          const newIndex =
            currentImageIndex === imageList.length - 1
              ? 0
              : currentImageIndex + 1;
          setSelectedImageIndex(newIndex);
          setCurrentImageIndex(newIndex);
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isGalleryOpen, imageList, currentImageIndex]);

  const capitalize = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const formatData = (dataString: string): string => {
    const data = parseISO(dataString);
    const formatoData = "EEE dd MMM";
    const dataFormattata = format(data, formatoData, { locale: it });

    const parole = dataFormattata.split(" ");
    const dataFormattataConMaiuscole = parole
      .map((parola) => capitalize(parola))
      .join(" ");

    return dataFormattataConMaiuscole;
  };

  const [isBookingLoading, setIsBookingLoading] = useState(false);

  const redirectToStripe = (stripeCheckoutURL: string) => {
    if (stripeCheckoutURL) {
      window.location.href = stripeCheckoutURL;
    }
  };

  const date = new Date();
  const hoursItalian = date.setHours(date.getHours() + 2);

  const createActionOnClick = (numPerson: number) => {
    const userInfo = localStorage.getItem("customerId");
    const userInfoId = userInfo ? parseInt(userInfo, 10) : 0;
    const tenant = localStorage.getItem("tenantId");
    const tenantId = tenant ? parseInt(tenant, 10) : 0;
    const orderData: MyOrder = {
      id: 0,
      tenantId: tenantId,
      bookingDate: new Date(hoursItalian),
      numberOfPeople: numPerson,
      status: 1,
      purchasedBy: userInfoId,
      masterClassId: bookNowModalId,
    };
    store
      .dispatch(createOrderAsync({ myOrder: orderData }))
      .then((result) => {
        if (
          result.meta.requestStatus === "fulfilled" &&
          process.env.REACT_APP_PUBLIC_URL
        ) {
          const bookingId = result.payload.id;
          const checkoutItem: CheckoutItem = {
            successURL:
              process.env.REACT_APP_PUBLIC_URL +
              "masterclass/" +
              id +
              "/success/" +
              result.payload.id,
            cancelURL:
              process.env.REACT_APP_PUBLIC_URL +
              "masterclass/" +
              id +
              "/details",
          };
          store
            .dispatch(createCheckoutSessionAsync({ checkoutItem, bookingId }))
            .then((stripeResult: any) => {
              redirectToStripe(stripeResult.payload);
            });
        }
      })
      .catch(() => {
        ToastNotification({
          status: "error",
          description: "",
          toastId: "Booking not created",
          title: t("smartPlanDetail.toastNotification.networkError"),
        });
      })
      .finally(() => {
        setIsBookingLoading(false);
      });
    setIsBookingLoading(true);
  };

  useEffect(() => {
    if (masterClassDetailsRoute !== null) {
      if (masterClassDetailsRoute.params.id) {
        setId(parseInt(masterClassDetailsRoute.params.id));
        store.dispatch(
          getMasterClassAsyc({
            masterClassId: parseInt(masterClassDetailsRoute.params.id),
          })
        );
        store.dispatch(
          getMasterClassFaqsAsync({
            masterClassId: parseInt(masterClassDetailsRoute.params.id),
          })
        );
        store.dispatch(
          getMasterClassIncludedServiceAsync({
            masterClassId: parseInt(masterClassDetailsRoute.params.id),
          })
        );
        store.dispatch(
          getMasterClassGalleyAsync({
            masterClassId: parseInt(masterClassDetailsRoute.params.id),
          })
        );
        return;
      }
    }
  }, [masterClassDetailsRoute]);

  const formatDataModal = (
    dataString: string
  ): { giorno: string; mese: string } => {
    const data = new Date(dataString);

    const giorno = data.getDate().toString();
    const mese = new Intl.DateTimeFormat("it", { month: "long" }).format(data);

    return { giorno, mese };
  };

  const navigate = useNavigate();
  const defaultValuePerson = 1;
  const myOrderSliceStatus = useAppSelector(selectMyOrdersSliceStatus);
  const isLoading = myOrderSliceStatus === "loading";

  return (
    <>
      <HeaderMenu>
        <TopBar
          title={masterClass.title}
          hasEdit={false}
          hasDate={false}
          breadcrumbText={"Master Class > " + masterClass.title}
          icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
          onClick={() => navigate("/masterclass")}
        />
      </HeaderMenu>
      <div className="masterclassdetail-container">
        <img
          className="rectangle-8"
          alt=""
          src={masterClass.image ? masterClass.image : undefined}
        />
        <div className="overlap-2">
          <div className="masterclassdetail-bady-page">
            <div className="title-services">
              {t("masterClassDetail.masterClassServiceComponent.service")}
            </div>
            <div className="flex-container-masterclass">
              {includedService.map((el) => (
                <div>
                  <div className="service-title">{el.title}</div>
                  <div className="service-subtitle">{el.description}</div>
                </div>
              ))}
            </div>
            <div
              className="description-masterclass"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {masterClass && (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      masterClass.description?.replace(/\s+/g, " ").trim() ??
                      "",
                  }}
                />
              )}
            </div>
            <div className="masterclass-video">
              {t("masterClassDetail.video")}
            </div>
            <div className="flex-container-video">
              <iframe
                width="100%"
                height="360"
                src={masterClass && masterClass.video}
                title={masterClass && masterClass.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                border-radius="4px"
              ></iframe>
            </div>
            <div className="title-gallery">
              {t("masterClassDetail.gallery")}
            </div>
            <div className="master-class">
              <div className="flex-container-gallery">
                {gallery &&
                  gallery.map((item, index) => (
                    <div className="gallery-container" key={item.id}>
                      <img
                        className="gallery-image"
                        src={item.image}
                        alt={`Image ${item.id}`}
                        onClick={() => openGallery(index)}
                      />
                    </div>
                  ))}
              </div>
              {isGalleryOpen && (
                <div className="gallery-overlay">
                  <button className="close-button" onClick={closeGallery}>
                    x
                  </button>
                  <button
                    className="prev-button"
                    onClick={() =>
                      setSelectedImageIndex((prev) =>
                        prev === 0 ? imageList.length - 1 : prev - 1
                      )
                    }
                  >
                    <TwentyIcArrowLeft className="arrow-gallery" />
                  </button>
                  <button
                    className="next-button"
                    onClick={() =>
                      setSelectedImageIndex((prev) =>
                        prev === imageList.length - 1 ? 0 : prev + 1
                      )
                    }
                  >
                    <TwentyIcArrowRight
                      className="arrow-gallery"
                      onClick={undefined}
                    />
                  </button>
                  <div className="gallery">
                    <img
                      src={imageList[selectedImageIndex]}
                      alt="img"
                      className="gallery-image-large"
                    />
                  </div>
                </div>
              )}
            </div>
            {masterClass.city !== "null" && masterClass.city && (
              <>
                <div className="text-wrapper-21-map">
                  {t("masterClassDetail.map")}
                </div>
                <Map city={masterClass.city} />
              </>
            )}
            <div className="form-contatto-wrapper">
              <div className="frame-44">
                <h3>Contattaci</h3>
                <p>Parliamone per migliorare l'esperienza e definire le date</p>
              </div>
              <div className="contatto-smartplan">
                <label className="contatto-smartplan--label">
                  Data aprox. di partenza
                </label>
                <Input type="date"></Input>
                <label className="contatto-smartplan--label">
                  Scrivi la tua richiesta
                </label>
                <Input.TextArea rows={8}>
                  Scrivici la tua richiesta e proponi un periodo comodo per te
                </Input.TextArea>
                <Button className="contatto-smartplan--submit">
                  Invia richiesta
                </Button>
              </div>
            </div>
            <div className="frame-36">
              <div className="frame-22">
                <div className="text-wrapper-37">
                  {t(
                    "masterClassDetail.questionsAndAnswers.questionsAndAnswers"
                  )}
                </div>
                <div className="contattaci-su-wrapper">
                  <p className="contattaci-su">
                    <span className="text-wrapper-39">
                      {t("masterClassDetail.questionsAndAnswers.contactUs")}
                    </span>
                    <span className="text-wrapper-38">WhatsApp</span>
                    <span className="text-wrapper-39">
                      {t("masterClassDetail.questionsAndAnswers.callUs")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="container-answer-masterclass">
              <div className="line-bookNow-initial" />
              {faq.map((el) => (
                <div>
                  <QuestionsAndAnswers title={el.question} desc={el.answer} />
                </div>
              ))}
            </div>
          </div>{" "}
          <div className="container-summary">
            <div className="summary-wrapper">
              <SummaryComponent
                region={masterClass && masterClass.region}
                province={masterClass.province ? masterClass.province : " -- "}
                selectDate={"Richiedi informazioni"}
                startDate={
                  masterClass && masterClass.startDate
                    ? formatData(masterClass.startDate.toString())
                    : "Da definire"
                }
                endDate={
                  masterClass && masterClass.endDate
                    ? formatData(masterClass.endDate.toString())
                    : "Da definire"
                }
                destination={(masterClass && masterClass.city) ?? "--"}
                activity={
                  masterClass && masterClass.placesLeft
                    ? masterClass.placesLeft +
                      t("masterClassDetail.componentSummary.available")
                    : "--"
                }
                price={(masterClass && masterClass.price) ?? 0}
                destinationText={t(
                  "masterClassDetail.componentSummary.destination"
                )}
                activityText={t("masterClassDetail.componentSummary.freeSeats")}
                summary={t("masterClassDetail.componentSummary.summary")}
                start={t("masterClassDetail.componentSummary.start")}
                end={t("masterClassDetail.componentSummary.end")}
                startingFrom={t(
                  "masterClassDetail.componentSummary.startingFrom"
                )}
                downPayment={t(
                  "masterClassDetail.componentSummary.downPayment"
                )}
                descriptionP1={t(
                  "masterClassDetail.componentSummary.descriptionP1"
                )}
                descriptionP2={t(
                  "masterClassDetail.componentSummary.descriptionP2"
                )}
                onClick={() => setBookNowModalId(id)}
              />
              <AssistanceBoxComponent
                assistance={t(
                  "masterClassDetail.componentAssistantBox.assistance"
                )}
                whatsApp={t("masterClassDetail.componentAssistantBox.whatsApp")}
                date={t("masterClassDetail.componentAssistantBox.date")}
                whatsAppNumber={3773604135}
                whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."
                  .replace("firstName*", firstName ?? "")
                  .replace("lastName*", lastName ?? "")
                  .replace("companyName*", businessName ?? "")}
                disabled={undefined}
              />
            </div>
            {!_.isEmpty(bookNowModalObj) && (
              <MasterClassModal
                onClose={() => {
                  navigate("/masterclass/" + bookNowModalObj.id + "/details");
                  setBookNowModalId(-1);
                }}
                title={t("masterClassDetail.bookNowModal.title")}
                month={
                  /*
                  formatDataModal(bookNowModalObj.startDate.toString())
                    .mese.charAt(0)
                    .toUpperCase() +
                  formatDataModal(
                    bookNowModalObj.startDate.toString()
                  ).mese.slice(1)
                  */
                  "Settembre"
                }
                startDay={
                  /*
                  formatDataModal(bookNowModalObj.startDate.toString()).giorno
                  */
                  "--"
                }
                endDay={
                  /*
                  formatDataModal(bookNowModalObj.endDate.toString()).giorno
                  */
                  "--"
                }
                deposit={bookNowModalObj.deposit}
                price={bookNowModalObj.price}
                onClick={(npersone) =>
                  createActionOnClick(npersone ?? defaultValuePerson)
                }
                isActionPerforming={isLoading}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
