import { TwentyIcError } from "../../ui/components/icons/TwentyIcError";
import Tag from "../../ui/components/Tag/Tag";
import { Button } from "../../ui/components/Button/Button";
import "../Account/AccountDetails.css";
import { t } from "i18next";
import HeaderMenu from "../../layout/HeaderMenu";
import { TopBar } from "../../ui/components/TopBar/TopBar";
import { TwentyIcCameraAdd } from "../../ui/components/icons/TwentyIcCameraAdd";
import { TwentyIcLogout1 } from "../../ui/components/TwentyIcLogout1";
import { AssistanceBoxComponent } from "../../ui/components/AssistanceBoxComponent";
import { useEffect, useState } from "react";
import {
  UserInfo,
  getUserInfoAsync,
  partiallyUpdateUserInfoAsync,
  userInfoSelector,
} from "../UserInfo/UserSlice";
import { useAppSelector } from "../../app/hooks";
import { useMatch, useNavigate } from "react-router-dom";
import { store } from "../../app/store";
import {
  Tenant,
  getTenantAsync,
  tenantSelector,
  updateTenantAsync,
} from "../Tenant/TenantSlice";
import AuthenticationService from "../../utils/AuthenticationService";
import _ from "lodash";
import { Footer } from "../../screens/Footer/Footer";
import {
  ChangePassword,
  selectChangePaswordSliceStatus,
  updatePasswordAsync,
} from "../ChangePassword/ChangePasswordSlice";
import { ToastNotification } from "../../ui/components/utils/ToastNotification";
import { ChangePasswordModal } from "./AccountModal/ChangePasswordModal";
import { ChangeBusinessDataModal } from "./AccountModal/ChangeBusinessDataModal";
import { EditPersonalDataModal } from "./AccountModal/EditPersonalDataModal";
import { TwentyIcArrowRight } from "../../ui/components/icons/TwentyIcArrowRight";
import { Image } from "antd";
import { PublicAccount } from "./PublicAccount/PublicAccountSlice";
import { usePreviewContext } from "../../Context/PrewiewContext";

interface SidebarPageProps {
  logoutDone: () => void;
}

function AccountDetails(Props: SidebarPageProps) {
  const accountDetailsRoute = useMatch("account/:id/details");
  const admin = localStorage.getItem("admin");

  const [userId, setUserId] = useState<number>(-1);

  let userInfo: UserInfo = useAppSelector(
    (state) => userInfoSelector.selectById(state, userId) ?? ({} as UserInfo)
  );

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const businessName = localStorage.getItem("businessName");
  const securityProfile =
    localStorage.getItem("securityProfile") === "ADMIN" ||
    localStorage.getItem("securityProfile") === "PROVIDER";
  const user = localStorage.getItem("customerId");
  const tenantId = localStorage.getItem("tenantId");

  let tenant: Tenant = useAppSelector(
    (state) =>
      tenantSelector.selectById(state, tenantId ?? "") ?? ({} as Tenant)
  );

  const changePasswordSliceStatus = useAppSelector(
    selectChangePaswordSliceStatus
  );
  const isLoadingChangePassword = changePasswordSliceStatus === "loading";
  let refresh = false;

  useEffect(() => {
    if (accountDetailsRoute !== null) {
      if (accountDetailsRoute.params.id) {
        if (!refresh) {
          setUserId(parseInt(accountDetailsRoute.params.id));
          store.dispatch(
            getUserInfoAsync({
              userInfoId: parseInt(accountDetailsRoute.params.id),
              tenantId: tenantId,
            })
          );
          store.dispatch(getTenantAsync(parseInt(tenantId ?? "")));

          refresh = true;
        }
      }
    }
  }, [accountDetailsRoute]);

  let { value: publicAccountPreview, setValue: setPublicAccountPreview } =
    usePreviewContext();

  useEffect(() => {
    return () => {
      setPublicAccountPreview({} as PublicAccount);
    };
  }, [setPublicAccountPreview]);

  const initialData = {
    firstName: userInfo.firstname,
    lastName: userInfo.lastname,
    email: userInfo.email,
    telephone: userInfo.telephone,
    profilePhoto: userInfo.profilePhoto,
    fiscalCode: userInfo.fiscalCode,
    city: userInfo.city,
    virtualCooworking: userInfo.virtualOfficeURL,
    personalOfficeURL: "",
  };

  const navigate = useNavigate();
  const authenticationService = new AuthenticationService();

  const date = userInfo.activationDate;

  const nameMonth = [
    t("timeFormats.monthsFirstThree.January"),
    t("timeFormats.monthsFirstThree.February"),
    t("timeFormats.monthsFirstThree.March"),
    t("timeFormats.monthsFirstThree.April"),
    t("timeFormats.monthsFirstThree.May"),
    t("timeFormats.monthsFirstThree.June"),
    t("timeFormats.monthsFirstThree.July"),
    t("timeFormats.monthsFirstThree.August"),
    t("timeFormats.monthsFirstThree.September"),
    t("timeFormats.monthsFirstThree.October"),
    t("timeFormats.monthsFirstThree.November"),
    t("timeFormats.monthsFirstThree.December"),
  ];

  function getNameMonth(mese: number) {
    return nameMonth[mese];
  }

  function formattaData(dataString: Date) {
    const data = new Date(dataString);

    const day = data.getDate();
    const month = data.getMonth();
    const year = data.getFullYear();

    const nomeMese = getNameMonth(month);

    return `${day} ${nomeMese} ${year}`;
  }

  const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);

  const [modalChangeBusinessDate, setModalChangeBusinessData] = useState(false);

  const [changePasswordModalId, setChangePasswordModalId] =
    useState<number>(-1);

  let changePasswordModalObj: UserInfo = useAppSelector(
    (state) =>
      userInfoSelector.selectById(state, changePasswordModalId) ??
      ({} as UserInfo)
  );

  const [editPersonalDataModalId, setEditPersonalDataModalId] =
    useState<number>(-1);

  let editPersonalDataModalObj: UserInfo = useAppSelector(
    (state) =>
      userInfoSelector.selectById(state, editPersonalDataModalId) ??
      ({} as UserInfo)
  );

  const currentDate = new Date();
  const hoursItalian = currentDate.setHours(currentDate.getHours() + 2);

  const updatePassword = (newPassword: string, oldPassword: string) => {
    let errorMessage = "";

    if (newPassword.length < 8) {
      errorMessage = t("toastNotification.password8Char");
    } else if (!/[A-Z]/.test(newPassword)) {
      errorMessage = t("toastNotification.passwordMaiuscLetter");
    } else if (!/\d/.test(newPassword)) {
      errorMessage = t("toastNotification.passwordContainsNumber");
    } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(newPassword)) {
      errorMessage = t("toastNotification.passwordContainsSpecialChar");
    }

    if (errorMessage) {
      ToastNotification({
        status: "error",
        description: errorMessage,
        toastId: "",
        title: t("toastNotification.incorrectCredentials"),
      });
      return;
    }

    const changePassword: ChangePassword = {
      id: 0,
      newPassword: newPassword,
      oldPassword: oldPassword,
      userId: parseInt(user ?? ""),
      lastUpdate: new Date(hoursItalian),
    };

    setIsChangePasswordLoading(true);

    store
      .dispatch(updatePasswordAsync({ changePassword: changePassword }))
      .then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          setChangePasswordModalId(-1);
          setIsChangePasswordLoading(false);
          ToastNotification({
            status: "success",
            description: "",
            toastId: "",
            title: t("toastNotification.passwordSucccess"),
          });
          store.dispatch(
            getUserInfoAsync({
              userInfoId: parseInt(user ?? ""),
              tenantId: tenantId,
            })
          );
        } else {
          ToastNotification({
            status: "error",
            description: t("toastNotification.oldPassIncorrect"),
            toastId: "loginFailed",
            title: "Conflitti di password",
          });
        }
      });
  };

  const updatePersonalData = (
    firstName: string,
    lastName: string,
    email: string,
    telephone: string,
    profilePhoto: string,
    fiscalCode: string,

    city: string
  ) => {
    const updatePersonalData: any = {
      id: userId,
      firstname: firstName,
      lastname: lastName,
      email: email,
      fiscalCode: fiscalCode,
      profilePhoto: profilePhoto,
      city: city,
      telephone: telephone,
    };

    store
      .dispatch(
        partiallyUpdateUserInfoAsync({
          userInfo: updatePersonalData,
          tenantId: tenantId,
        })
      )
      .then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          setEditPersonalDataModalId(-1);
          ToastNotification({
            status: "success",
            description: "",
            toastId: "",
            title: "Dati peronali cambiati con successo",
          });
          store.dispatch(
            getUserInfoAsync({
              userInfoId: parseInt(user ?? ""),
              tenantId: tenantId,
            })
          );
        } else {
          setEditPersonalDataModalId(-1);
          ToastNotification({
            status: "error",
            description: "Internal server error",
            toastId: "loginFailed",
            title: "",
          });
        }
      });
  };

  const [dataFormat, setDataFormat] = useState("--");

  useEffect(() => {
    if (userInfo.lastUpdate) {
      const dataPart = userInfo.lastUpdate.toString().split("T")[0];
      const [year, month, day] = dataPart.split("-");
      const newDataFormat = `${day}/${month}/${year}`;
      setDataFormat(newDataFormat);
    } else {
      setDataFormat("--");
    }
  }, [userInfo.lastUpdate]);

  const userType = localStorage.getItem("securityProfile");

  return (
    <>
      <HeaderMenu>
        <div className="title">
          <TopBar
            title="Account"
            hasEdit={false}
            hasDate={false}
            icon={<TwentyIcError className="icon-instance-node-3" />}
            breadcrumbText={""}
          />
        </div>
      </HeaderMenu>
      <div className="accountdetail-container">
        <div className="overlap">
          <div className="accountdetail-bady-page">
            <div className="frame-25-date">
              <div className="frame-date">
                <div className="text-wrapper">{t("account.personalDate")}</div>
              </div>
              <div className="rectangle-5-date " />
              <div className="frame-27-date">
                <div className="frame-28-date">
                  <div className="button-change-personal-data">
                    <Button
                      size="M"
                      state="default"
                      type="line"
                      text="Edita dati personali"
                      onClick={() => setEditPersonalDataModalId(userId)}
                      disabled={undefined}
                      iconRight={undefined}
                    />
                  </div>
                  <div className="text-wrapper-17">
                    {userInfo.firstname + " " + userInfo.lastname}
                  </div>
                  <div className="frame-29-date">
                    <p className="CF">
                      <span className="span">{t("account.cf")}</span>
                      <span>{userInfo.fiscalCode}</span>
                    </p>
                    <p className="div-30">
                      <span className="span">{t("account.email")}</span>
                      <span>{userInfo.email}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="rectangle-5-change-pasword " />
              <div className="frame-27-date">
                <div className="frame-28-date">
                  <div className="text-wrapper-17">
                    {t("account.modalChangePassowrd.title")}
                  </div>
                  <div className="button-change-password">
                    <Button
                      size="M"
                      state="default"
                      type="line"
                      text="Cambia password"
                      onClick={() => setChangePasswordModalId(userId)}
                      disabled={undefined}
                      iconRight={undefined}
                    />
                  </div>
                  <div className="frame-29-date">
                    <p className="CF">
                      <span>
                        {t("account.modalChangePassowrd.lastChange") +
                          dataFormat}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {userType === "PROVIDER" && (
              <div className="frame-25-public-data">
                <div className="frame-public-data">
                  <div className="text-wrapper-public-data">
                    Account pubblico
                  </div>
                  <div className="arrow-public-account-public-data">
                    <TwentyIcArrowRight
                      className={undefined}
                      color="gray"
                      onClick={() => navigate("public-account")}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="frame-25-date">
              <div className="frame-date">
                <div className="text-wrapper">{t("account.corporateData")}</div>
              </div>
              <div className="rectangle-5-date " />
              <div className="frame-27-date">
                <div className="frame-28-date">
                  <div className="text-wrapper-17">{tenant.name}</div>
                  <div className="frame-29-date">
                    <p className="CF">
                      <span className="span">{t("account.pIva")}</span>
                      <span>{tenant.vatNumber}</span>
                    </p>
                    <p className="div-30">
                      <span className="span">{t("account.telephone")}</span>
                      <span>{tenant.telephone}</span>
                    </p>
                    <p className="div-30">
                      <span className="span">{t("account.contactEmail")}</span>
                      <span>{tenant.email}</span>
                    </p>
                    <p className="div-30">
                      <span className="span">{t("account.address")}</span>
                      <span>
                        {tenant.address && tenant.city
                          ? tenant.address +
                            " • " +
                            tenant.city +
                            "(" +
                            tenant.country +
                            ")"
                          : ""}
                      </span>
                    </p>
                  </div>
                  {securityProfile && (
                    <div className="button-change-business-data">
                      <Button
                        size="M"
                        state="default"
                        type="line"
                        text="Edita dati aziendali"
                        onClick={() => {
                          setModalChangeBusinessData(!modalChangeBusinessDate);
                        }}
                        disabled={undefined}
                        iconRight={undefined}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="frame-summary">
            <div className="div-20">
              <div className="div-30">
                <div className="div-40">
                  {userInfo && userInfo.profilePhoto ? (
                    <div className="profile-photo">
                      <Image
                        className="profile-image-adapter"
                        width={70}
                        height={70}
                        src={userInfo.profilePhoto}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="user-no-photo">
                      <TwentyIcCameraAdd className="icon-instance-node-2" />
                    </div>
                  )}
                  <div className="div-50">
                    <div className="text-wrapper-200">
                      {userInfo.firstname + " " + userInfo.lastname}
                    </div>
                    <div className="text-wrapper-300">
                      {userInfo.firstname && userInfo.lastname
                        ? userInfo.firstname.charAt(0) +
                          userInfo.lastname.charAt(0)
                        : ""}
                    </div>
                    <div className="text-wrapper-400">{userInfo.city}</div>
                  </div>
                  <div className="div-60">
                    <div className="text-wrapper-500">{userInfo.email}</div>
                    <div className="text-wrapper-600">
                      {date ? formattaData(date) : ""}
                    </div>
                  </div>
                </div>
                <div className="tag-summary">
                  {admin === "true" ? (
                    <Tag textTwo={userInfo.role} isRem={false} />
                  ) : null}
                  <Button
                    icon={<TwentyIcLogout1 className="icon-instance-node-2" />}
                    text={t("account.exit")}
                    size="m"
                    type="line"
                    state={undefined}
                    disabled={undefined}
                    iconRight={undefined}
                    onClick={() => {
                      authenticationService
                        .logout()
                        .then(
                          () => {
                            console.log("Successfully logged out");
                          },
                          (err: any) => {
                            console.log(
                              "Something went wrong trying to log out" +
                                err.message
                            );
                          }
                        )
                        .finally(() => {
                          Props.logoutDone();
                          navigate("/login");
                        });
                    }}
                  />
                </div>
              </div>
              <div className="rectangle-2" />
              <img
                className="subtract"
                alt="Subtract"
                src="https://c.animaapp.com/7N2ekGRE/img/subtract.svg"
              />
            </div>
            <AssistanceBoxComponent
              assistance={t("smartPlanDetail.componentAssistantBox.assistance")}
              whatsApp={t("smartPlanDetail.componentAssistantBox.whatsApp")}
              date={t("smartPlanDetail.componentAssistantBox.date")}
              whatsAppNumber={3773604135}
              whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."
                .replace("firstName*", firstName ?? "")
                .replace("lastName*", lastName ?? "")
                .replace("companyName*", businessName ?? "")}
              disabled={undefined}
            />
          </div>
          {!_.isEmpty(changePasswordModalObj) && (
            <ChangePasswordModal
              onClose={() => {
                setChangePasswordModalId(-1);
              }}
              title={t("account.modalChangePassowrd.title")}
              onClick={(newPassword: string, oldPassword: string) =>
                updatePassword(newPassword || "", oldPassword || "")
              }
              isActionPerforming={isLoadingChangePassword}
            />
          )}
          {!_.isEmpty(editPersonalDataModalObj) && (
            <EditPersonalDataModal
              onClose={() => {
                setEditPersonalDataModalId(-1);
              }}
              title={"Dati personali"}
              onClick={(
                firstName: string,
                lastName: string,
                email: string,
                telephone: string,
                profilePhoto: string,
                fiscalCode: string,
                city: string
              ) =>
                updatePersonalData(
                  firstName,
                  lastName,
                  email,
                  telephone,
                  profilePhoto,
                  fiscalCode,
                  city
                )
              }
              isActionPerforming={undefined}
              initialData={initialData}
            />
          )}
          {modalChangeBusinessDate && (
            <ChangeBusinessDataModal
              onClose={() => {
                setModalChangeBusinessData(!modalChangeBusinessDate);
              }}
              title={t("account.modalChangeBusinessData.title")}
              onClick={(tenant: Tenant) => {
                store.dispatch(updateTenantAsync(tenant)).then((result) => {
                  if (result.meta.requestStatus === "fulfilled") {
                    setModalChangeBusinessData(false);
                    ToastNotification({
                      status: "success",
                      description: "",
                      toastId: "",
                      title: t(
                        "account.modalChangeBusinessData.dataSuccessChanged"
                      ),
                    });
                    store.dispatch(
                      getUserInfoAsync({
                        userInfoId: parseInt(user ?? ""),
                        tenantId: tenantId,
                      })
                    );
                  } else {
                    setModalChangeBusinessData(false);
                    ToastNotification({
                      status: "error",
                      description: t(
                        "account.modalChangeBusinessData.networkError"
                      ),
                      toastId: "loginFailed",
                      title: "",
                    });
                  }
                });
              }}
              isActionPerforming={undefined}
              initialData={{
                name: tenant.name,
                vatNumber: tenant.vatNumber,
                email: tenant.email,
                telephone: tenant.telephone,
                address: tenant.address,
                city: tenant.city,
                province: tenant.province,
                businessName: tenant.businessName,
                prefix: tenant.prefix,
                country: tenant.country,
                zip: tenant.zip,
              }}
            />
          )}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AccountDetails;
