import { ReactNode } from "react";
import "./HeaderMenu.css";

interface HeaderMenuProps {
  children: ReactNode;
}

function HeaderMenu({ children }: HeaderMenuProps) {
  return <div className="row header-menu">{children} </div>;
}

export default HeaderMenu;
