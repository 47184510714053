/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcCalendarSeet = ({ className, color = "white" }) => {
  return (
    <svg
      className={`twenty-ic-calendar-seet ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rect"
        height="16"
        rx="2"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
        width="16"
        x="2"
        y="2"
      />
      <path
        className="path"
        d="M13 13H18L13 18V13Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        className="circle"
        cx="6"
        cy="5"
        fill="white"
        r="0.5"
        stroke={color}
      />
      <circle
        className="circle"
        cx="14"
        cy="5"
        fill="white"
        r="0.5"
        stroke={color}
      />
    </svg>
  );
};
