import HttpClient from "../../utils/HttpClient";

export default class TeamUsersInfoRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getFilteredUsers() {
    const tenantId = localStorage.getItem("tenantId");
    let path = "tenants/" + tenantId + "/team/users";
    return this.instance.get(path);
  }

  addNewUsersViaEmail(queryString: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "tenants/" + tenantId + "/addUsers" + (queryString ?? "");
    return this.instance.post(path);
  }
}
