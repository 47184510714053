/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconInfo = ({ className, color = "black" }) => {
  return (
    <svg
      className={`icon-info ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="circle"
        cx="10"
        cy="10"
        r="8"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M9.43991 15V7.87386H11.5V15H9.43991ZM8.5 9.40832V7.87386H11.5V9.40832H8.5ZM10.2382 7.17165C9.85193 7.17165 9.56438 7.07195 9.37554 6.87256C9.19528 6.6645 9.10515 6.40442 9.10515 6.09233C9.10515 5.77156 9.19528 5.51149 9.37554 5.31209C9.56438 5.10403 9.85193 5 10.2382 5C10.6245 5 10.9077 5.10403 11.088 5.31209C11.2768 5.51149 11.3712 5.77156 11.3712 6.09233C11.3712 6.40442 11.2768 6.6645 11.088 6.87256C10.9077 7.07195 10.6245 7.17165 10.2382 7.17165Z"
        fill={color}
      />
    </svg>
  );
};
