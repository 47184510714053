/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcAdd = ({ className, color = "" }) => {
  return (
    <svg
      className={`twenty-ic-add ${className}`}
      fill={color}
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M10 4V16M16 10H4"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
