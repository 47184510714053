import { useEffect, useRef, useState } from "react";
import { Button as Buttont } from "../../ui/components/Button/Button";
import PropTypes from "prop-types";
import { Logo } from "../../ui/components/Logo/Logo";
import "./NewDAccountDetails.css";
import { Button, DatePicker, Form, Input, Switch } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { IconMail } from "../../ui/components/icons/IconMail";
import { TwentyIcCalendarSeet } from "../../ui/components/icons/TwentyIcCalendarSeet";
import { IconTik } from "../../ui/components/icons/IconTik";
import { TwentyIcLockNewPassword } from "../../ui/components/icons/TwentyIcLockNewPassword";
import { capitalizeFirstLetter } from "../../utils/utils";
import { store } from "../../app/store";
import {
  createUserInfoAsync,
  selectUserInfoSliceStatus,
  userInfoReasonCodeAndStatusEmptyState,
  verifyUserInviteAsync,
} from "../UserInfo/UserSlice";
import { useAppSelector } from "../../app/hooks";
import { ToastNotification } from "../../ui/components/utils/ToastNotification";
import locale from "antd/es/date-picker/locale/it_IT";
import "dayjs/locale/it";

interface NewDAccountDetailsProps {
  loginDone: () => void;
}

function NewDAccountDetails(Props: NewDAccountDetailsProps) {
  const urlLocation = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [workArea, setWorkArea] = useState("");
  const [birthDate, setBirthDate] = useState();
  const [location, setLocation] = useState("");
  const [telephone, setTelephone] = useState("");
  const [cf, setCf] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

  let tempTenantId = "";
  let tempEmail = "";
  let tempInvite = "";

  const [reminder, setReminder] = useState(false);
  const [firstAccess, setFirstAccess] = useState(false);

  const navigate = useNavigate();

  const searchParams = new URLSearchParams(urlLocation.search);

  const status = useAppSelector(selectUserInfoSliceStatus);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handleSurnameChange = (e: any) => {
    setSurname(e.target.value);
  };

  const handleWorkAreaChange = (e: any) => {
    setWorkArea(e.target.value);
  };

  const handleLocationChange = (e: any) => {
    setLocation(e.target.value);
  };

  const handleBirthChange = (e: any) => {
    setBirthDate(e.toString());
  };

  const handleTelephoneChange = (e: any) => {
    if (/^[0-9+]+$/.test(e.target.value) || e.target.value === "") {
      setTelephone(e.target.value);
    }
  };

  const handleCfChange = (e: any) => {
    if (e.target.value.length <= 16) {
      setCf(e.target.value.toUpperCase());
    }
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (event: any) => {
    if (password.length < 8) {
      ToastNotification({
        status: "error",
        description: t("validation.minLength") + " (8)",
        toastId: "Password error",
        title: t("validation.passwordNotValid"),
      });
    } else if (!/[A-Z]/.test(password)) {
      ToastNotification({
        status: "error",
        description: t("validation.maiuscLetter"),
        toastId: "Password error",
        title: t("validation.passwordNotValid"),
      });
    } else if (!/\d/.test(password)) {
      ToastNotification({
        status: "error",
        description: t("validation.numberLetter"),
        toastId: "Password error",
        title: t("validation.passwordNotValid"),
      });
    } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
      ToastNotification({
        status: "error",
        description: t("validation.specialLetter"),
        toastId: "Password error",
        title: t("validation.passwordNotValid"),
      });
    } else {
      event.preventDefault();
      setFirstAccess(true);
    }
  };

  const handleFinalSumbit = (event: any) => {
    event.preventDefault();
    if (name.length === 0) {
      ToastNotification({
        status: "error",
        description: t("validation.minLength"),
        toastId: "name error",
        title: t("validation.nameNotValid"),
      });
    } else if (surname.length === 0) {
      ToastNotification({
        status: "error",
        description: t("validation.minLength"),
        toastId: "surname error",
        title: t("validation.surnameNotValid"),
      });
    } else if (workArea.length === 0) {
      ToastNotification({
        status: "error",
        description: t("validation.minLength"),
        toastId: "workArea error",
        title: t("validation.roleNotValid"),
      });
    } else if (birthDate === undefined) {
      ToastNotification({
        status: "error",
        description: "",
        toastId: "birthdate error",
        title: t("validation.date"),
      });
    } else if (location.length === 0) {
      ToastNotification({
        status: "error",
        description: t("validation.minLength"),
        toastId: "location error",
        title: t("validation.locationNotValid"),
      });
    } else if (telephone.length === 0) {
      ToastNotification({
        status: "error",
        description: t("validation.phoneNumber"),
        toastId: "telephone error",
        title: t("validation.phoneNotValid"),
      });
    } else if (cf.length < 16) {
      ToastNotification({
        status: "error",
        description: t("validation.fiscalCode"),
        toastId: "cf error",
        title: t("validation.fiscalCodeNotValid"),
      });
    } else {
      localStorage.removeItem("token");
      if (reminder) {
        localStorage.setItem("email", email);

        localStorage.setItem("password", password);
      } else {
        localStorage.removeItem("email");

        localStorage.removeItem("password");
      }

      const newUser = {
        email: email,
        password: password,
        firstname: capitalizeFirstLetter(name),
        lastname: capitalizeFirstLetter(surname),
        role: capitalizeFirstLetter(workArea),
        birthDate: birthDate,
        city: location.toUpperCase(),
        telephone: telephone,
        fiscalCode: cf,
      };

      store
        .dispatch(
          createUserInfoAsync({
            data: newUser,
            tenantId: tenantId,
            inviteCode: inviteCode,
          })
        )
        .then(() => {
          if (status === "idle") {
            ToastNotification({
              status: "success",
              description: "",
              toastId: "User created",
              title: t("toastNotification.profileCreated"),
            });
            store.dispatch(userInfoReasonCodeAndStatusEmptyState);
          } else {
            ToastNotification({
              status: "error",
              description: "",
              toastId: "User not created",
              title: t("toastNotification.networkError"),
            });
            store.dispatch(userInfoReasonCodeAndStatusEmptyState);
          }
          navigate("/login");
        });
    }
  };

  const submitRef = useRef<any>();

  useEffect(() => {
    const keyUp = (event: any) => {
      if (event.keyCode === 13 && password !== "" && email !== "") {
        handleSubmit(event);
      }
    };

    if (submitRef && submitRef.current) {
      submitRef.current.addEventListener("keydown", keyUp);

      let parentRef = submitRef;

      return () => {
        parentRef.current &&
          parentRef.current.removeEventListener("keydown", keyUp);
      };
    }
  });

  useEffect(() => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("customerId");
    localStorage.removeItem("tenantId");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("chatToken");
    localStorage.removeItem("email");
    localStorage.removeItem("i18nextLng");
    localStorage.removeItem("profilePic");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("admin");
    tempInvite = searchParams.get("inviteCode") ?? "";
    tempEmail = searchParams.get("email") ?? "";
    tempTenantId = searchParams.get("tenantId") ?? "";
    if (tempTenantId !== "" && tempEmail !== "" && tempInvite !== "") {
      store
        .dispatch(
          verifyUserInviteAsync({ email: tempEmail, inviteCode: tempInvite })
        )
        .then((validation) => {
          if (
            validation.payload === false ||
            validation.payload === undefined
          ) {
            //if the request doesn't give "true" (as user Invited) the user is linked to the login Page
            navigate("/login");
          }
        });
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    setEmail(tempEmail);
    setTenantId(tempTenantId);
    setInviteCode(tempInvite);
  }, [tempEmail, tempTenantId, tempInvite]);

  return (
    <div className="daccountdetails">
      <div className="daccountdetails-container">
        <div className="daccountdetails-logo">
          <Logo width="186px" height="28px" />
        </div>

        <div className="daccountdetails-content">
          <div className="daccountdetails-form-container">
            {!firstAccess ? (
              <>
                {" "}
                <span className="daccountdetails-up-span">
                  {t("loginPage.firstAccess")}
                </span>
                <span className="daccountdetails-desc-span">
                  {t("loginPage.compileCredentials")}
                </span>
                <div className="daccountdetails-form">
                  <div className="daccountdetails-input-container">
                    <div className="daccountdetails-input-group">
                      <Form>
                        <span className="email-span">
                          {t("loginPage.email")}
                        </span>

                        <Input
                          disabled={true}
                          id="username-field"
                          value={email}
                          type="email"
                          style={{ width: "360px", height: "50px" }}
                          size="large"
                          placeholder="Email"
                          prefix={
                            <IconMail color={"#BDBEBF"} className={undefined} />
                          }
                          onChange={handleEmailChange}
                        />
                      </Form>

                      <Form>
                        <span className="password-span">
                          {t("loginPage.newPassword")}
                        </span>

                        <Input
                          id="password-field"
                          defaultValue={password}
                          value={password}
                          type="password"
                          style={{ width: "360px", height: "50px" }}
                          size="large"
                          placeholder="Password"
                          prefix={
                            <TwentyIcLockNewPassword
                              color={"#BDBEBF"}
                              className={undefined}
                            />
                          }
                          onChange={handlePasswordChange}
                        />
                      </Form>
                    </div>

                    <div className="daccountdetails-reminder-container">
                      <Switch
                        checked={reminder}
                        style={
                          reminder
                            ? { backgroundColor: "#36B39E" }
                            : { backgroundColor: "#BDBEBF" }
                        }
                        checkedChildren={<CheckOutlined rev={undefined} />}
                        onChange={() => setReminder(!reminder)}
                        defaultChecked={reminder}
                      />

                      <div className="daccountdetails-form-text">
                        {t("loginPage.remindCredentials")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="daccountdetails-button-wrapper">
                  <Buttont
                    textColor={"white"}
                    size="XL"
                    onClick={handleSubmit}
                    disabled={email === "" || password === ""}
                    text={t("loginPage.login")}
                    type={undefined}
                    state={undefined}
                    icon={undefined}
                    iconRight={undefined}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="daccountdetails-title">
                  <div className="daccountdetails-title-one">
                    {t("loginPage.regFirstText")}
                  </div>
                  <div className="daccountdetails-title-two">
                    {t("loginPage.regSecondText")}
                  </div>
                </div>
                <Form layout="vertical" style={{ width: "100%" }}>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      width: "100%",
                      gap: "20px",
                    }}
                  >
                    <Form.Item
                      style={{ width: "100%", marginBottom: "8px" }}
                      label={
                        <div className="daccountdetails-form-text">
                          {t("account.name")}
                        </div>
                      }
                    >
                      <Input
                        defaultValue={name}
                        onChange={handleNameChange}
                        style={{ width: "100%", height: "35px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <div className="daccountdetails-form-text">
                          {t("account.surname")}
                        </div>
                      }
                      style={{ width: "100%", marginBottom: "8px" }}
                    >
                      <Input
                        defaultValue={surname}
                        onChange={handleSurnameChange}
                        style={{ width: "100%", height: "35px" }}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    label={
                      <div className="daccountdetails-form-text">
                        {t("account.workArea")}
                      </div>
                    }
                    style={{ width: "100%", marginBottom: "8px" }}
                  >
                    <Input
                      defaultValue={workArea}
                      onChange={handleWorkAreaChange}
                      style={{ width: "100%", height: "35px" }}
                    />
                  </Form.Item>

                  <Form.Item
                    label={
                      <div className="daccountdetails-form-text">
                        {t("account.birthDate")}
                      </div>
                    }
                    style={{ width: "100%", marginBottom: "8px" }}
                  >
                    <DatePicker
                      locale={locale}
                      onChange={handleBirthChange}
                      placeholder=""
                      format={dateFormatList}
                      suffixIcon={
                        <TwentyIcCalendarSeet
                          color="#36B39E"
                          className={undefined}
                        ></TwentyIcCalendarSeet>
                      }
                      size="large"
                      style={{ width: "100%", height: "35px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <div className="daccountdetails-form-text">
                        {t("account.location")}
                      </div>
                    }
                    style={{ width: "100%", marginBottom: "8px" }}
                  >
                    <Input
                      defaultValue={location}
                      onChange={handleLocationChange}
                      style={{ width: "100%", height: "35px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <div className="daccountdetails-form-text">
                        {t("account.telephone")}
                      </div>
                    }
                    style={{ width: "100%", marginBottom: "8px" }}
                  >
                    <Input
                      value={telephone}
                      defaultValue={telephone}
                      onChange={handleTelephoneChange}
                      style={{ width: "100%", height: "35px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <div className="daccountdetails-form-text">
                        {t("account.cfExtended")}
                      </div>
                    }
                    style={{ width: "100%", marginBottom: "16px" }}
                  >
                    <Input
                      value={cf}
                      defaultValue={cf}
                      onChange={handleCfChange}
                      style={{ width: "100%", height: "35px" }}
                    />
                  </Form.Item>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      className="daccountdetails-button-confirm"
                      icon={
                        <IconTik
                          color="#36B39E"
                          className={undefined}
                        ></IconTik>
                      }
                      onClick={handleFinalSumbit}
                    >
                      <div className="daccountdetails-form-text">
                        {t("loginPage.continue")}
                      </div>
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </div>
        </div>

        <div className="daccountdetails-footer-bottom">
          <div className="logdaccountdetailsin-text-4">
            © EverywhereSB, s.r.l
          </div>

          <div className="daccountdetails-text-5">
            <div className="daccountdetails-text-6">
              contact@everywheretew.it
            </div>

            <div className="daccountdetails-text-6">Privacy Policy</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewDAccountDetails;

NewDAccountDetails.propTypes = {
  loginDone: PropTypes.func.isRequired,
};

NewDAccountDetails.defaultProps = {
  loginDone: () => {},
};
